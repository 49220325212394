/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useTranslation } from 'react-i18next';

const ModalJustSetZero = ({ visible, onClick }) => {
  const { t } = useTranslation();
  if (!visible) return null;

  return (
    <div className='t-modal' style={{ visibility: 'visible', opacity: 1, pointerEvents: 'auto' }}>
      <div className='content' style={{ padding: '20px 30px', opacity: 1, height: 'initial', overflow: 'auto', textAlign: 'left' }}>
        <div className='text-center'>
          <i className="i-danger" />
          <h3>
            ผู้สมัครเคยใช้สิทธิ์ ขอรับเงินสนับสนุนค่าสมัครคัดเลือกฯ รอบที่ 3 Admission จากสำนักงานปลัดกระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัย และนวัตกรรม 
          </h3>
          <p>
            การสมัครหลังจากนี้ผู้สมัครจะต้องดำเนินการจ่ายค่าสมัคร คัดเลือกส่วนที่เพิ่มขึ้นนี้เองโดยไม่สามารถนำมาขอรับเงิน สนับสนุนค่าสมัครเพิ่มเติมได้อีก
          </p>
          <a className="btn-main cursor-pointer" onClick={onClick}>
            {t('รับทราบ')}
          </a>
        </div>
      </div>
    </div>
  );
}

export default ModalJustSetZero;
