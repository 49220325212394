import { useState, useRef } from 'react';

const useEditPicture = (profile = {}) => {
  const pictureRef = useRef();
  const [collapsed, setCollapsed] = useState(profile.picture);

  return {
    pictureRef,
    pictureCollapsed: collapsed,
    onPictureCollapsedChange: setCollapsed,
  };
};

export default useEditPicture;
