/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import _ from 'lodash';
import cn from 'classnames';

// Components
import Search from './Search';
import FavoriteItem from './FavoriteItem';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useFavorites from 'hooks/round3/useFavorites';
import useAdmission from 'hooks/round3/useAdmission';

const AdmissionPrepare = () => {
  const { t } = useTranslation();
	const { favorites, studentStatus, isApplicable, onAdd, onRemove } = useFavorites();
  const { projects } = useAdmission(favorites, studentStatus);
	const profile = useSelector((state) => _.get(state, 'app.user') || {});

  return (
    <main className="site-body">
      <div className="t-form">
        <h1>ทดลองจัดอันดับรอบ 3 Admission</h1>
        <div className="r-all">
          <div className="r-3 active">
            <div className="content">
              <div className={cn('t-collapse', { active: true })}>
                <h3 className="r-3-bar t-toggle">
                  <i className="i-apply" />
                  {t('สาขาวิชาที่สนใจ')}{(projects || []).length ? ` (${(projects || []).length}/10)` : ''}
                  <i className="i-toggle i-down-teal" />
                </h3>
                <div className="t-content">
                  <ul className="r-choose -fav">
										{(favorites || []).map((data, index) =>
											<FavoriteItem
												key={data._id}
												index={index}
												data={data}
												favorites={favorites}
												studentStatus={studentStatus}
												onRemove={onRemove} />)}
									</ul>
                  {isApplicable && (
                    <Search
                      profile={profile}
                      favorites={favorites}
                      studentStatus={studentStatus}
                      onAddToFavorites={onAdd} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default AdmissionPrepare;
