import { useState } from 'react';
import _ from 'lodash';
import { compressImage, getNewestTicket } from 'helpers';
import scores from 'data/gpa.score.json';
import useEducation from './useEducation';

const useSectionGPAScore = (profile, pendingTickets) => {
  const { valueOf } = getNewestTicket(pendingTickets, 'update_gpa_score');
  const [gpa21, setGpa21] = useState(valueOf('gpa21') || profile.gpa21 || '');
  const [gpa22, setGpa22] = useState(valueOf('gpa22') || profile.gpa22 || '');
  const [gpa23, setGpa23] = useState(valueOf('gpa23') || profile.gpa23 || '');
  const [gpa24, setGpa24] = useState(valueOf('gpa24') || profile.gpa24 || '');
  const [gpa25, setGpa25] = useState(valueOf('gpa25') || profile.gpa25 || '');
  const [gpa26, setGpa26] = useState(valueOf('gpa26') || profile.gpa26 || '');
  const [gpa27, setGpa27] = useState(valueOf('gpa27') || profile.gpa27 || '');
  const [gpa28, setGpa28] = useState(valueOf('gpa28') || profile.gpa28 || '');

  const [creditGpa21, setCreditGpa21] = useState(valueOf('credit_gpa21') || profile.credit_gpa21 || '');
  const [creditGpa22, setCreditGpa22] = useState(valueOf('credit_gpa22') || profile.credit_gpa22 || '');
  const [creditGpa23, setCreditGpa23] = useState(valueOf('credit_gpa23') || profile.credit_gpa23 || '');
  const [creditGpa24, setCreditGpa24] = useState(valueOf('credit_gpa24') || profile.credit_gpa24 || '');
  const [creditGpa25, setCreditGpa25] = useState(valueOf('credit_gpa25') || profile.credit_gpa25 || '');
  const [creditGpa26, setCreditGpa26] = useState(valueOf('credit_gpa26') || profile.credit_gpa26 || '');
  const [creditGpa27, setCreditGpa27] = useState(valueOf('credit_gpa27') || profile.credit_gpa27 || '');
  const [creditGpa28, setCreditGpa28] = useState(valueOf('credit_gpa28') || profile.credit_gpa28 || '');

  const [transcriptFrontFile, setTranscriptFrontFile] = useState();
  const [transcriptBackFile, setTranscriptBackFile] = useState();


  return {
    ...useEducation(profile, pendingTickets),

    gpa21,
    setGpa21,
    gpa22,
    setGpa22,
    gpa23,
    setGpa23,
    gpa24,
    setGpa24,
    gpa25,
    setGpa25,
    gpa26,
    setGpa26,
    gpa27,
    setGpa27,
    gpa28,
    setGpa28,

    creditGpa21,
    setCreditGpa21,
    creditGpa22,
    setCreditGpa22,
    creditGpa23,
    setCreditGpa23,
    creditGpa24,
    setCreditGpa24,
    creditGpa25,
    setCreditGpa25,
    creditGpa26,
    setCreditGpa26,
    creditGpa27,
    setCreditGpa27,
    creditGpa28,
    setCreditGpa28,

    transcriptFrontFile,
    onTranscriptFrontFileChange: async (e) => {
      const _file = await compressImage(e.target.files[0]);
      setTranscriptFrontFile(_file);
    },

    transcriptBackFile,
    onTranscriptBackFileChange: async (e) => {
      const _file = await compressImage(e.target.files[0]);
      setTranscriptBackFile(_file);
    },

    fnName: (fieldName) => `set${fieldName[0].toUpperCase()}${fieldName.slice(1)}`,
    scores,
    validateScore: (fieldName, score) => {
      const { label, min, max } = _.find(scores, (_score) => _score.fieldName === fieldName);
      if (score < min) return `เกรด ${label} ต้องมีค่าไม่น้อยกว่า ${min}`;
      if (score > max) return `เกรด ${label} ต้องมีค่าไม่มากกว่า ${max}`;
      return null;
    },
    validateCredit: (creditFieldName, credit) => {
      const { label } = _.find(scores, (_score) => _score.creditFieldName === creditFieldName);
      if (credit.length && credit <= 0) return `หน่วยกิต ${label} ต้องมีค่ามากกว่า 0`;
      if (credit > 99) return `หน่วยกิต ${label} ต้องมีค่าไม่มากกว่า 99`;
      return null;
    },
    validateRequired: (fieldName, score, credit) => {
      const { label } = _.find(scores, (_score) => _score.fieldName === fieldName);
      if ((score && !credit) || (!score && credit)) return `จำเป็นต้องกรอกข้อมูล ${label} ทั้งเกรดและหน่วยกิต`;
      return null;
    },
  };
};

export default useSectionGPAScore;
