import React from 'react';
import cn from 'classnames';

const steps = [
  { stepId: 1, title: 'สมัครสอบ' },
  { stepId: 2, title: 'รอยืนยันการสมัคร' },
  { stepId: 3, title: 'ยืนยัน OTP' },
  { stepId: 4, title: 'รอชำระเงิน' },
  { stepId: 5, title: 'สำเร็จ' }
];

const ExamSteps = ({ activeIndex, redeemed, someSitesChanged }) => {
  const _getSuffix = (stepId) => {
    if (redeemed && stepId === 3) return ' และขอรับเงินสนับสนุน';
    if (someSitesChanged && stepId === 3) return ' แก้ไขสนามสอบ'
    return '';
  }
  return (
    <ul className="t-step">
      {steps
      .filter(({ stepId }) => {
        if (redeemed) return stepId !== 4;
        if (someSitesChanged) return stepId !== 4;
        return true;
      })
      .map(({ stepId, title }) => (
        <li key={stepId} className={cn({ active: stepId === activeIndex })}>
          <span>{title}{_getSuffix(stepId)}</span>
        </li>
      ))}
    </ul>
  );
}

export default ExamSteps;
