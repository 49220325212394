import { useEffect, useState, useRef } from 'react';
import { getNewestTicket } from 'helpers';
import scores from 'data/gpa.score.json';

const useEditGPAScore = (profile = {}, pendingTickets = []) => {
  const gpaScoreRef = useRef();
  const { ticket, valueOf } = getNewestTicket(pendingTickets, 'update_gpa_score');

  const [collapsed, setCollapsed] = useState(true);
  const [waiting, setWaiting] = useState(Boolean(ticket));
  useEffect(() => setWaiting(Boolean(ticket)), [ticket]);

  const [initialState, setInitialState] = useState(
    scores.reduce(
      (state, { key, fieldName, creditFieldName }) => ({
        ...state,
        [fieldName]: valueOf(key) || profile[key] || '',
        [creditFieldName]: valueOf(`credit_${key}`) || profile[`credit_${key}`] || '',
      }),
      {}
    )
  );

  useEffect(() => {
    if (!collapsed) {
      setInitialState(gpaScoreRef.current.getState());
    }
  }, [collapsed]);

  return {
    gpaScoreRef,
    gpaScoreCollapsed: collapsed,
    onGPAScoreCollapsedChange: (collapsed, waiting) => {
      setCollapsed(collapsed);
      setWaiting(ticket ? true : waiting);
    },
    gpaScoreInitialState: initialState,
    gpaScoreWaiting: waiting,
  };
};

export default useEditGPAScore;
