/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import cn from 'classnames';
import moment from 'moment';

// Components
import EmailVerification from 'components/otp/EmailVerification';
import MobileVerification from 'components/otp/MobileVerification';

// Hooks
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';
import useAnnouncement from 'hooks/round3/useAnnouncement';

// Data
import enabled from 'data/enabled.json';

const Round3Apply = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reprocessKey = useParams().reprocessKey;

  const { loading, admissions, replies, timestamp } = useAnnouncement();
  const quotaUsed = replies.length;

  const [priorities, setPriorities] = useState([]);
  useEffect(() => {
    if (admissions.length) {
      const offer = _.find(admissions, ({ applicant_status }) => applicant_status === '2');
      const expired = !moment(timestamp).isBefore(enabled.round3.reply_end);
      if (!reprocessKey || replies.length >= 3 || expired) {
        navigate('/profile')
      } else {
        const priorities = _.padStart(parseInt(reprocessKey, 16).toString(), 10, '0')
          .split('')
          .map((value, index) => value === '1' ? index + 1 : 0)
          .filter(value => value);

        if (offer && priorities.some(_priority => _priority > offer.priority)) {
          navigate('/profile')
        } else if (priorities.sort().slice().join(',') === ((_.last(replies) || {}).ds_priorities || []).join(',')) {
          navigate('/profile')
        } else {
          setPriorities(priorities);
        }
      }
    }
  }, [admissions, replies, reprocessKey, timestamp, navigate]);

  const profile = useSelector((state) => _.get(state, 'app.user') || {});
  const courseMap = useSelector((state) => _.get(state, 'app.courseMap')) || {};

  const projects = admissions.filter(({ priority }) => priorities.includes(priority));
  const [verifyMethod, setVerifyMethod] = useState();
  const [otpVisible, setOtpVisible] = useState(false);

  const onEmailOtpSuccess = useCallback(() => {
    dispatch({ type: 'UPDATE_USER', result: { ...profile, email_is_verified: 1 } });
    navigate(-1);
  }, [dispatch, profile, navigate]);

  const onSmsOtpSuccess = useCallback(() => {
    dispatch({ type: 'UPDATE_USER', result: { ...profile, telephone_is_verified: 1 } });
    navigate(-1);
  }, [dispatch, profile, navigate]);


  useEffect(() => {
    const classNames = document.getElementById('root').className.split(' ');
    if (loading) {
      document.getElementById('root').className = _.uniq(classNames.concat(['is-loading'])).join(' ');
    } else {
      document.getElementById('root').className = classNames.filter((name) => name !== 'is-loading').join(' ');
    }
  }, [loading]);

  if (loading) {
    return (
      <main className="site-body">
        <div className="t-loading" />
      </main>
    )
  }

  return (
    <main className="site-body">
      <div className="t-form">
        <h1>{t('รอบ')} 3 Admission</h1>
        {!otpVisible && (
          <>
            <h2 className="sub">{t('กรุณาเลือกช่องทางในการรับรหัส OTP ยืนยันการขอประมวลผลครั้งที่ 2 ')}</h2>
            <div className="r-programs">
              {(projects || []).map(({ program_project, priority }) => {
                const lang = 'th';
                const project = courseMap[program_project];
                const majorName = _.get(project, `major_name_${lang}`);
                const projectName = _.get(project, `project_name_${lang}`);
                return (
                  <div className="r-program">
                    <div className="order">{t('อันดับที่')} {priority}</div>
                    <div
                      className="u-logo"
                      style={{ backgroundImage: `url('/assets/img/logo/${_.get(project, 'university_id')}.png')` }} />
                    <ul>
                      <li>
                        <i className="i-u"></i>
                        <b>{_.get(project, `university_name_${lang}`)}</b>
                      </li>
                      <li>
                        <i className="i-pin"></i>
                        <b>{_.get(project, `campus_name_${lang}`)}</b>
                      </li>
                      <li>
                        <i>•</i>
                        <span>{_.get(project, `faculty_name_${lang}`)}</span>
                      </li>
                      <li>
                        <i>•</i>
                        <span>{_.get(project, `field_name_${lang}`)}</span>
                      </li>
                      {majorName && majorName !== '0' ? (
                        <li>
                          <i>•</i>
                          <span>{t('วิชาเอก')} {majorName}</span>
                        </li>
                      ) : null}
                      <li>
                        <i>•</i>
                        <span>{_.get(project, `program_name_${lang}`)}</span>
                      </li>
                      {lang === 'th' ? (
                        <li>
                          <i>•</i>
                          <span>{_.get(project, `program_type_name_th`)}</span>
                        </li>
                      ) : null}
                      {projectName && projectName !== '0' ? (
                        <li>
                          <i>•</i>
                          <span>{t('โครงการ ')}{projectName}</span>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                );
              })}
            </div>
          </>
        )}
        <div className="i-hearts">
          <i className={cn('i-heart', { '-blink': quotaUsed === 2, '-used': quotaUsed > 2 })} />
          <i className={cn('i-heart', { '-blink': quotaUsed === 1, '-used': quotaUsed > 1 })} />
          <i className={cn('i-heart', { '-blink': quotaUsed === 0, '-used': quotaUsed > 0 })} />
          <small>
            {t('ตัดสินใจครั้งที่')} {1 + quotaUsed}/3
          </small>
        </div>
        <>
          {!otpVisible ? (
            <MethodSelector
              verifyMethod={verifyMethod}
              setVerifyMethod={setVerifyMethod}
              onClick={() => setOtpVisible(true)}
            />
          ) : verifyMethod === 'email' ? (
            <EmailVerification
              studentType="reprocess-round3"
              roundType="3_2568"
              email={profile.email}
              bodyParams={{ priorities }}
              onSuccess={onEmailOtpSuccess} />
          ) : verifyMethod === 'mobile' ? (
            <MobileVerification
              studentType="reprocess-round3"
              roundType="3_2568"
              mobile={profile.telephone}
              bodyParams={{ priorities }}
              onSuccess={onSmsOtpSuccess} />
          ) : null}
        </>
      </div>
    </main>
  );
}

export default Round3Apply;


const MethodSelector = ({ onClick, verifyMethod, setVerifyMethod }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});
  const formattedMobile = [
    profile.telephone.slice(0, 3),
    profile.telephone.slice(3, 6),
    profile.telephone.slice(-4),
  ].join(' ');

  return (
    <>
      <ul className="r-radio">
        <li>
          <label>
            <input
              type="radio"
              name="confirm"
              checked={verifyMethod === 'mobile'}
              onClick={() => setVerifyMethod('mobile')}
            />
            <strong>{t('ยืนยันการขอประมวลผลครั้งที่ 2 ผ่านเบอร์มือถือ')}</strong>
            <small>
              {t('หมายเลข')} {formattedMobile}
            </small>
          </label>
        </li>
        <li>
          <label>
            <input
              type="radio"
              name="confirm"
              checked={verifyMethod === 'email'}
              onClick={() => setVerifyMethod('email')}
            />
            <strong>{t('ยืนยันการขอประมวลผลครั้งที่ 2 ผ่านอีเมล')}</strong>
            <small>{profile.email}</small>
          </label>
        </li>
      </ul>

      <div className="action">
        <a
          className={cn('btn-main', { disabled: !verifyMethod, 'cursor-pointer': Boolean(verifyMethod) })}
          onClick={!verifyMethod ? () => { } : onClick}
        >
          {t('ส่ง OTP เพื่อยืนยันการขอประมวลผลครั้งที่ 2 ')}
        </a>
        <h4><a onClick={() => navigate(-1)} className="cursor-pointer color-gray">{t('ยกเลิก')}</a></h4>
      </div>
      <p className="text-center _small">{t('หากมีการเปลี่ยนแปลงเบอร์มือถือหรืออีเมล')}
        {t('สามารถแก้ไขได้ที่หน้า')} <Link to='/profile/applicant-info' className="color-black"><u>ข้อมูลการลงทะเบียน</u></Link></p>
    </>
  );
};
