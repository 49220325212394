import { useEffect, useState, useRef } from 'react';
import scores from 'data/english.score.json';

const useEditEnglishScore = (profile = {}) => {
  const englishScoreRef = useRef();
  const [collapsed, setCollapsed] = useState(true);

  const [initialState, setInitialState] = useState(
    scores.reduce((state, { key, fieldName }) => ({ ...state, [fieldName]: profile[key] }), {})
  );

  useEffect(() => {
    if (!collapsed) {
      setInitialState(englishScoreRef.current.getState());
    }
  }, [collapsed]);

  return {
    englishScoreRef,
    englishScoreCollapsed: collapsed,
    onEnglishScoreCollapsedChange: setCollapsed,
    englishScoreInitialState: initialState,
  };
};

export default useEditEnglishScore;
