import { useEffect } from 'react';
import Cookies from 'js-cookie';

// Hooks
import { useNavigate, useParams } from 'react-router-dom';

const RedirectLoggedIn = () => {
  const token = useParams().token;
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      Cookies.set('accessToken', token, { expires: 1 / 24 });
      navigate('/profile');
    }
  }, [navigate, token]);

  return <div></div>;
}

export default RedirectLoggedIn;
