import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import _ from 'lodash';
import { callAPI, getRoundState } from 'helpers';
import moment from 'moment';

// Components
import RoundContent from '../RoundContent';
import ModalOfferReplyConsent from './ModalRound41OfferReplyConsent';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useQuery from 'hooks/useQuery';

// Data
import enabled from 'data/enabled'

const roundType = '41_2568';
const Round41 = ({ loading, offerCount, offers, replies, timestamp, expired }) => {
  const { t } = useTranslation();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});
  const query = useQuery();

  const [active, setActive] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const { flow } = getRoundState({ roundType, timestamp, query });
  useEffect(() => {
    if (flow === 'default' && !_.get(profile, 'consents_accepted_at.offer_reply_41_2568')) {
      setModalVisible(true);
    }
  }, [flow, profile]);

  const [accepting, setAccepting] = useState(false);
  const _onAcceptConsent = async (consentType) => {
    if (accepting) return;
    try {
      setAccepting(true);
      await callAPI({
        method: 'POST',
        url: '/applicants/me/consents',
        body: {
          consentType,
        },
      });
      setAccepting(false);
    } catch (error) {
      console.error(`Error while trying to accept consent: ${error.message}`);
      setAccepting(false);
    }
    setModalVisible(false);
  };

  return (
    <div className={cn('t-collapse', { active })}>
      <h3 className='r-4-bar t-toggle' onClick={() => setActive(_active => !_active)}>
        {t(`ประกาศผลช่วงที่ 1: ${moment(enabled.round41.reply_start).add(543, 'years').format('DD MMM YY')}`)}
        <i className="i-toggle i-down-teal"></i>
      </h3>
      <RoundContent
        flow={flow}
        roundType={roundType}
        loading={loading}
        offerCount={offerCount}
        offers={offers}
        replies={replies}
        timestamp={timestamp} />
      <ModalOfferReplyConsent
        visible={offers.length && modalVisible && flow === 'default'}
        onSubmit={() => _onAcceptConsent('offer_reply_41_2568')}
      />
    </div>
  )
}

export default Round41;