import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import reducers from './reducers';

// Registrations
import StudentLookup from 'containers/registrations/StudentLookup';
import RegistrationForm from 'containers/registrations/RegistrationForm';
import TcasForgotPassword from 'containers/registrations/TcasForgotPassword';
import NotFound from 'containers/registrations/NotFound';
import BlacklistForm from 'containers/registrations/BlacklistForm';
import RedirectLoggedIn from 'containers/registrations/RedirectLoggedIn';
import RedirectOldStudent from 'containers/registrations/RedirectOldStudent';

// Profile
import Profile from 'containers/profile/Profile';
import ApplicantInfo from 'containers/profile/ApplicantInfo';
import Dashboard from 'containers/profile/Dashboard';
import OfferAccept from 'containers/profile/OfferAccept';
import OfferReject from 'containers/profile/OfferReject';
import OfferCancel from 'containers/profile/OfferCancel';
import ApplyRound3 from 'containers/profile/ApplyRound3';
import SetZero from 'containers/profile/SetZero';
import ReprocessRound3 from 'containers/profile/ReprocessRound3';
import RedeemRound3 from 'containers/profile/RedeemRound3';

// Exam
import TGATTPATSummary from 'containers/profile/apply-exams/TGATTPATSummary';
import TGATTPATConfirmation from 'containers/profile/apply-exams/TGATTPATConfirmation';
import TGATTPATDetail from 'containers/profile/apply-exams/TGATTPATDetail';
import TGATTPATUpdateSites from 'containers/profile/apply-exams/TGATTPATUpdateSites';
import TGATTPATPostpone from 'containers/profile/apply-exams/TGATTPATPostpone';

import ALevelSummary from 'containers/profile/apply-exams/ALevelSummary';
import ALevelConfirmation from 'containers/profile/apply-exams/ALevelConfirmation';
import ALevelDetail from 'containers/profile/apply-exams/ALevelDetail';
import ALevelUpdateSites from 'containers/profile/apply-exams/ALevelUpdateSites';

import Exam4YVeto from 'containers/profile/apply-exams/Exam4YVeto';

import ExamApplicationInfo from 'containers/profile/ExamApplicationInfo';
import ExamScores from 'containers/profile/ExamScores';
import ExamRecheck from 'containers/profile/ExamRecheck';
import ExamRecheckConsent from 'containers/profile/ExamRecheckConsent';
import ExamAnswers from 'containers/profile/ExamAnswers';
import ExamAnswerDetail from 'containers/profile/ExamAnswerDetail';

// QRCode Generator
import ExamResult from 'containers/ExamResult';
import AdmissionPrepare from 'components/profile/enrollments/round3/AdmissionPrepare';

import R3RedemptionReceipt from 'containers/R3RedemptionReceipt';

const middleware = [];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(...middleware)));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<StudentLookup />} />
            <Route path="/form" element={<RegistrationForm />} />
            <Route path="/veto/:token" element={<BlacklistForm />} />
            <Route path="/redirect-l/:token" element={<RedirectLoggedIn />} />
            <Route path="/redirect-o/:token" element={<RedirectOldStudent />} />
            <Route path="/:citizenId/not-found" element={<NotFound />} />
            <Route path="/:studentType/forgot-password" element={<TcasForgotPassword />} />
          </Route>
          <Route path="/profile" element={<Profile />}>
            <Route path="/profile/applicant-info" element={<ApplicantInfo />} />
            <Route index element={<Dashboard />} />

            <Route path="/profile/tgattpat-postpone/confirm" element={<TGATTPATPostpone action='confirm' />} />
            <Route path="/profile/tgattpat-postpone/cancel" element={<TGATTPATPostpone action='cancel' />} />

            <Route path="/profile/apply-exams/tgattpat" element={<TGATTPATSummary />} />
            <Route path="/profile/apply-exams/tgattpat/confirmation" element={<TGATTPATConfirmation />} />
            <Route path="/profile/apply-exams/tgattpat/update-sites" element={<TGATTPATUpdateSites />} />
            <Route path="/profile/apply-exams/tgattpat/veto" element={<Exam4YVeto examType='tgattpat' />} />
            <Route path="/profile/apply-exams/tgattpat/:day" element={<TGATTPATDetail />} />

            {/* <Route path="/profile/apply-exams/alevel" element={<ALevelSummary />} />
            <Route path="/profile/apply-exams/alevel/confirmation" element={<ALevelConfirmation />} />
            <Route path="/profile/apply-exams/alevel/update-sites" element={<ALevelUpdateSites />} />
            <Route path="/profile/apply-exams/alevel/:day" element={<ALevelDetail />} /> */}

            <Route path="/profile/offer-accept/:roundType/:offerId" element={<OfferAccept />} />
            <Route path="/profile/offer-reject/:roundType" element={<OfferReject />} />
            <Route path="/profile/offer-cancel/:roundType" element={<OfferCancel />} />

            <Route exact path="/profile/exam-application-info/tgattpat" element={<ExamApplicationInfo type='tgattpat' />} />
            <Route exact path="/profile/exam-application-info/alevel" element={<ExamApplicationInfo type='alevel' />} />
            <Route exact path="/profile/exam-scores/tgattpat" element={<ExamScores type='tgattpat' />} />
            <Route exact path="/profile/exam-scores/alevel" element={<ExamScores type='alevel' />} />
            <Route exact path="/profile/exam-recheck/tgattpat" element={<ExamRecheck type='tgattpat' />} />
            <Route exact path="/profile/exam-recheck/alevel" element={<ExamRecheck type='alevel' />} />
            <Route exact path="/profile/exam-recheck-consent/tgattpat" element={<ExamRecheckConsent type='tgattpat' />} />
            <Route exact path="/profile/exam-recheck-consent/alevel" element={<ExamRecheckConsent type='alevel' />} />
            <Route exact path="/profile/exam-answers/tgattpat" element={<ExamAnswers type='tgattpat' />} />
            <Route exact path="/profile/exam-answers/alevel" element={<ExamAnswers type='alevel' />} />
            <Route path="/profile/exam-answers/:appId" element={<ExamAnswerDetail />} />

            {/* <Route path="/profile/round3" element={<AdmissionPrepare />} /> */}
            <Route path="/profile/redeem-round3" element={<RedeemRound3 />} />
            <Route path="/profile/favorite-round3" element={<AdmissionPrepare />} />
            <Route path="/profile/apply-round3" element={<ApplyRound3 />} />
            <Route path="/profile/set-zero" element={<SetZero />} />
            <Route path="/profile/reprocess-round3/:reprocessKey" element={<ReprocessRound3 />} />
          </Route>
          <Route path="/er/:hash" element={<ExamResult type='tgattpat' />} />
          <Route path="/er/:hash/tgattpat" element={<ExamResult type='tgattpat' />} />
          <Route path="/er/:hash/alevel" element={<ExamResult type='alevel' />} />
          <Route path='/r3/:hash' element={<R3RedemptionReceipt />} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
