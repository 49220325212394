/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

// Components
import SectionPicture from 'components/profile/SectionPicture';
import SectionBasicInfo from 'components/profile/SectionBasicInfo';
import SectionParent from 'components/profile/SectionParent';
import SectionAddress from 'components/profile/SectionAddress';
import SectionEducation from 'components/profile/SectionEducation';
import SectionGPAScore from 'components/profile/SectionGPAScore';
import SectionGEDScore from 'components/profile/SectionGEDScore';
import SectionVNETScore from 'components/profile/SectionVNETScore';
import SectionEnglishScore from 'components/profile/SectionEnglishScore';
import SectionUniversityScore from 'components/profile/SectionUniversityScore';
import SectionTgatTpatScore from 'components/profile/SectionTgatTpatScore';
import SectionALevelScore from 'components/profile/SectionALevelScore';

import EmailVerification from 'components/otp/EmailVerification';
import MobileVerification from 'components/otp/MobileVerification';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useEditPicture from 'hooks/edit-profile/useEditPicture';
import useEditBasicInfo from 'hooks/edit-profile/useEditBasicInfo';
import useEditParent from 'hooks/edit-profile/useEditParent';
import useEditAddress from 'hooks/edit-profile/useEditAddress';
import useEditEducation from 'hooks/edit-profile/useEditEducation';
import useEditGPAScore from 'hooks/edit-profile/useEditGPAScore';
import useEditGEDScore from 'hooks/edit-profile/useEditGEDScore';
import useEditVNETScore from 'hooks/edit-profile/useEditVNETScore';
import useEditEnglishScore from 'hooks/edit-profile/useEditEnglishScore';

const steps = [
  { stepId: 1, title: 'ลงทะเบียน' },
  { stepId: 2, title: 'เลือกวิธีเข้าระบบ' },
  { stepId: 3, title: 'ตรวจสอบข้อมูล' },
];
const ApplicantInfo = () => {
  const profile = useSelector((state) => _.get(state, 'app.user') || {});
  const pendingTickets = useSelector((state) => _.get(state, 'app.pendingTickets') || {});
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { basicInfoRef, basicInfoInitialState, basicInfoCollapsed, onBasicInfoCollapsedChange, basicInfoWaiting } =
    useEditBasicInfo(profile, pendingTickets);
  const { parentRef, parentInitialState, parentCollapsed, onParentCollapsedChange } = useEditParent(profile);
  const { addressRef, addressInitialState, addressCollapsed, onAddressCollapsedChange } = useEditAddress(profile);
  const { educationRef, educationInitialState, educationCollapsed, onEducationCollapsedChange, educationWaiting } =
    useEditEducation(profile, pendingTickets);
  const { pictureCollapsed, onPictureCollapsedChange } = useEditPicture(profile);

  const { gpaScoreRef, gpaScoreInitialState, gpaScoreCollapsed, onGPAScoreCollapsedChange, gpaScoreWaiting } =
    useEditGPAScore(profile, pendingTickets);
  const { gedScoreRef, gedScoreInitialState, gedScoreCollapsed, onGEDScoreCollapsedChange, gedScoreWaiting } =
    useEditGEDScore(profile, pendingTickets);
  const { vnetScoreRef, vnetScoreInitialState, vnetScoreCollapsed, onVNETScoreCollapsedChange, vnetScoreWaiting } =
    useEditVNETScore(profile, pendingTickets);

  const { englishScoreRef, englishScoreInitialState, englishScoreCollapsed, onEnglishScoreCollapsedChange } =
    useEditEnglishScore(profile);

  const [emailOtpVisible, setEmailOtpVisible] = useState(false);
  const [smsOtpVisible, setSmsOtpVisible] = useState(false);

  const onEmailOtpSuccess = useCallback(() => {
    dispatch({ type: 'UPDATE_USER', result: { ...profile, email_is_verified: 1 } });
    setEmailOtpVisible(false);
  }, [dispatch, profile]);

  const onSmsOtpSuccess = useCallback(() => {
    dispatch({ type: 'UPDATE_USER', result: { ...profile, telephone_is_verified: 1 } });
    setSmsOtpVisible(false);
  }, [dispatch, profile]);

  if (emailOtpVisible) {
    return (
      <main className="site-body">
        <div className="t-sec t-profile">
          <EmailVerification email={profile.email} onSuccess={onEmailOtpSuccess} />
        </div>
      </main>
    );
  }
  if (smsOtpVisible) {
    return (
      <main className="site-body">
        <div className="t-sec t-profile">
          <MobileVerification mobile={profile.telephone} onSuccess={onSmsOtpSuccess} />
        </div>
      </main>
    );
  }

  // const editable = [1, 2, 3, 5, 7].includes(_.get(profile, 'student_status.code'));
  const editable = true;
  const hasIssues =
    pendingTickets.length ||
    !basicInfoCollapsed ||
    !parentCollapsed ||
    !addressCollapsed ||
    !educationCollapsed ||
    !gpaScoreCollapsed ||
    !gedScoreCollapsed ||
    !vnetScoreCollapsed ||
    !englishScoreCollapsed;
  return (
    <>
      <main className="site-body">
        <div className="t-form">
          <h1>{hasIssues ? t('ลงทะเบียน • เข้าสู่ระบบ') : t('ข้อมูลการลงทะเบียน')}</h1>
          {!hasIssues ? null : (
            <ul className="t-step">
              {steps.map(({ stepId, title }) => (
                <li key={stepId} className={classNames({ active: stepId === 3 })}>
                  <span>{title}</span>
                </li>
              ))}
            </ul>
          )}

          <div className="t-form">
            {Boolean(pendingTickets.length) && (
              <div className="t-box -info">
                <h2>{t('บัญชีนี้อยู่ระหว่างการยืนยันข้อมูล')}</h2>
                {t('ทีมงาน TCAS68 กำลังตรวจสอบและยืนยันสถานะ ใช้เวลาไม่เกิน 72 ชั่วโมง')}
              </div>
            )}
            {profile.email_is_verified || profile.telephone_is_verified ? (
              <div className="t-box -success">
                <h4 className="s-flex gap-2 m-0">
                  <i className="i-yes i-20 "></i> ลงทะเบียนเรียบร้อยแล้ว
                </h4>
              </div>
            ) : (
              <div className="t-box -noti">
                {
                  !profile.email_is_verified && !profile.telephone_is_verified ? (
                    <>
                      <h2>ท่านยังไม่ได้ยืนยันอีเมลหรือเบอร์มือถือ</h2>
                      <p>
                        <strong>ต้องยืนยันอย่างน้อย 1 ช่องทาง</strong>
                        <br />
                        หากไม่ยืนยัน จะไม่สามารถขอรหัสผ่านใช้ครั้งเดียว (One Time Password: OTP) ได้
                        <br />
                        สามารถยืนยันได้ถึงวันสมัครสอบวันสุดท้าย หรือ วันที่ต้องการยืนยันสิทธิ์ในแต่ละรอบ
                      </p>
                    </>
                  ) : null

                  //   : !profile.email_is_verified ? (
                  //   <>
                  //     <h2>{t('ท่านยังไม่ได้ยืนยันอีเมล')}</h2>
                  //     <p>{t('แนะนำให้ท่านยืนยันอีเมลเพิ่มเติม เพื่อเพิ่มช่องทางการรับข้อมูลจากระบบ')}</p>
                  //   </>
                  // ) : !profile.telephone_is_verified ? (
                  //   <>
                  //     <h2>{t('ท่านยังไม่ได้ยืนยันเบอร์มือถือ')}</h2>
                  //     <p>{t('แนะนำให้ท่านยืนยันเบอร์มือถือเพิ่มเติม เพื่อเพิ่มช่องทางการรับข้อมูลจากระบบ')}</p>
                  //   </>
                  // )
                }
                <label>
                  {!profile.email_is_verified && (
                    <a onClick={() => setEmailOtpVisible(true)} className="s-flex cursor-pointer">
                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M15.9998 2.66666C8.64788 2.66666 2.6665 8.64804 2.6665 16C2.6665 23.3519 8.64788 29.3333 15.9998 29.3333C23.3518 29.3333 29.3332 23.3519 29.3332 16C29.3332 8.64804 23.3518 2.66666 15.9998 2.66666ZM10.6665 9.99999H21.3332C22.3394 9.99999 23.1641 10.7449 23.3045 11.7122L15.9998 15.1601L8.69515 11.7122C8.83553 10.7449 9.66027 9.99999 10.6665 9.99999ZM8.6665 13.9114L15.5728 17.1706C15.7063 17.2336 15.8522 17.2663 15.9998 17.2663C16.1475 17.2663 16.2934 17.2336 16.4269 17.1706L23.3332 13.9114V20C23.3332 21.1047 22.4378 22 21.3332 22H10.6665C9.56184 22 8.6665 21.1047 8.6665 20V13.9114Z"
                          fill="currentColor"
                        />
                      </svg>
                      &nbsp;{t('ยืนยันอีเมล')} <b>{profile.email}</b>
                    </a>
                  )}
                </label>
                <label>
                  {!profile.telephone_is_verified && (
                    <a onClick={() => setSmsOtpVisible(true)} className="s-flex cursor-pointer">
                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M20.375 4H11.625C10.1777 4 9 5.2114 9 6.7V25.3C9 26.7886 10.1777 28 11.625 28H20.375C21.8222 28 23 26.7886 23 25.3V6.7C23 5.2114 21.8222 4 20.375 4ZM16 7C16.483 7 16.875 7.4032 16.875 7.9C16.875 8.3968 16.483 8.8 16 8.8C15.517 8.8 15.125 8.3968 15.125 7.9C15.125 7.4032 15.517 7 16 7ZM17.4583 25H14.5417C14.0581 25 13.6667 24.5974 13.6667 24.1C13.6667 23.6026 14.0581 23.2 14.5417 23.2H17.4583C17.9419 23.2 18.3333 23.6026 18.3333 24.1C18.3333 24.5974 17.9419 25 17.4583 25ZM19.8273 14.9368L15.5497 19.3366C15.3788 19.5124 15.1547 19.6 14.9307 19.6C14.7067 19.6 14.4828 19.5124 14.3118 19.3366L12.1727 17.1364C11.8309 16.7848 11.8309 16.2154 12.1727 15.8638C12.5146 15.5122 13.0682 15.5122 13.41 15.8638L14.9302 17.4274L18.5888 13.6642C18.9307 13.3126 19.4842 13.3126 19.8261 13.6642C20.1679 14.0158 20.1685 14.5852 19.8273 14.9368Z"
                          fill="currentColor"
                        />
                      </svg>
                      &nbsp;{t('ยืนยันเบอร์มือถือ')}
                      <b>{profile.telephone}</b>
                    </a>
                  )}
                </label>
              </div>
            )}
            <SectionPicture
              profile={profile}
              collapsed={pictureCollapsed}
              onCollapsedChange={onPictureCollapsedChange}
            />
            <SectionEducation
              ref={educationRef}
              editable={editable}
              initialState={educationInitialState}
              profile={profile}
              pendingTickets={pendingTickets}
              collapsed={educationCollapsed}
              onCollapsedChange={onEducationCollapsedChange}
              waiting={educationWaiting}
            />
            <SectionBasicInfo
              ref={basicInfoRef}
              initialState={basicInfoInitialState}
              profile={profile}
              pendingTickets={pendingTickets}
              collapsed={basicInfoCollapsed}
              onCollapsedChange={onBasicInfoCollapsedChange}
              onEmailOtpVisibilityChange={setEmailOtpVisible}
              onSmsOtpVisibilityChange={setSmsOtpVisible}
              waiting={basicInfoWaiting}
            />
            <SectionParent
              ref={parentRef}
              initialState={parentInitialState}
              profile={profile}
              collapsed={parentCollapsed}
              onCollapsedChange={onParentCollapsedChange}
            />
            <SectionAddress
              ref={addressRef}
              initialState={addressInitialState}
              profile={profile}
              collapsed={addressCollapsed}
              onCollapsedChange={onAddressCollapsedChange}
            />
            <SectionGPAScore
              ref={gpaScoreRef}
              editable={editable}
              initialState={gpaScoreInitialState}
              profile={profile}
              pendingTickets={pendingTickets}
              collapsed={gpaScoreCollapsed}
              onCollapsedChange={onGPAScoreCollapsedChange}
              waiting={gpaScoreWaiting}
            />
            <SectionTgatTpatScore profile={profile} />
            <SectionALevelScore profile={profile} />
            <SectionGEDScore
              ref={gedScoreRef}
              editable={editable}
              initialState={gedScoreInitialState}
              profile={profile}
              pendingTickets={pendingTickets}
              collapsed={gedScoreCollapsed}
              onCollapsedChange={onGEDScoreCollapsedChange}
              waiting={gedScoreWaiting}
            />
            <SectionVNETScore
              ref={vnetScoreRef}
              editable={editable}
              initialState={vnetScoreInitialState}
              profile={profile}
              pendingTickets={pendingTickets}
              collapsed={vnetScoreCollapsed}
              onCollapsedChange={onVNETScoreCollapsedChange}
              waiting={vnetScoreWaiting}
            />
            <SectionEnglishScore
              ref={englishScoreRef}
              editable={editable}
              initialState={englishScoreInitialState}
              profile={profile}
              collapsed={englishScoreCollapsed}
              onCollapsedChange={onEnglishScoreCollapsedChange}
            />
            <SectionUniversityScore profile={profile} />
          </div>
          <h4 className="text-center">
            <Link className="color-blue" to="/profile/">
              {t('ดูข้อมูลการสมัครสอบ →')}
            </Link>
          </h4>
        </div>
      </main>
      <footer className="site-footer">
        <a href="http://www.cupt.net/" target="_blank" rel="noopener noreferrer">
          <img src="/assets/img/logo/cupt.png" alt="CUPT Logo" className="logo" width="228" height="44" />
        </a>
        <div className="address">
          <b>{t('สมาคมที่ประชุมอธิการบดีแห่งประเทศไทย')}</b>
          {t('328 อาคารสำนักงานคณะกรรมการการอุดมศึกษา แขวงทุ่งพญาไท เขตราชเทวี กรุงเทพฯ')} <br />
          {t('โทรศัพท์ 02-126-5111 วันทำการ จันทร์-ศุกร์ เวลา 9.00-17.30 น.')} &nbsp;
          <Link to="/privacy">{t('เงื่อนไขการใช้งานและนโยบายความเป็นส่วนตัว')}</Link>
        </div>
        <div className="social">
          <a href="https://www.facebook.com/CUPTmytcas/" target="_blank" rel="noopener noreferrer">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
              <title>facebook</title>
              <path
                fill="#ffffff"
                d="M16 0.625c4.25 0 7.875 1.5 10.875 4.5s4.5 6.625 4.5 10.875c0 4.25-1.5 7.813-4.5 10.875-3 3-6.625 4.5-10.875 4.5s-7.875-1.5-10.875-4.5c-3-3.063-4.5-6.625-4.5-10.875s1.5-7.875 4.5-10.875c3-3 6.625-4.5 10.875-4.5zM19.625 11.188c0 0 0-2.5 0-2.5s-2.25 0-2.25 0c-1 0-1.75 0.375-2.375 1.063-0.688 0.688-1 1.563-1 2.625 0 0 0 1.375 0 1.375s-2.438 0-2.438 0c0 0 0 2.375 0 2.375s2.438 0 2.438 0c0 0 0 7.125 0 7.125s2.75 0 2.75 0c0 0 0-7.125 0-7.125s2.875 0 2.875 0c0 0 0-2.375 0-2.375s-2.875 0-2.875 0c0 0 0-1.625 0-1.625 0-0.625 0.188-0.938 0.625-0.938 0 0 2.25 0 2.25 0z"
              ></path>
            </svg>
          </a>
          <a href="https://twitter.com/MytcasC" target="_blank" rel="noopener noreferrer">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
              <title>twitter</title>
              <path
                fill="#ffffff"
                d="M16 0.625c4.25 0 7.875 1.5 10.875 4.5s4.5 6.625 4.5 10.875c0 4.25-1.5 7.813-4.5 10.875-3 3-6.625 4.5-10.875 4.5s-7.813-1.5-10.875-4.5c-3-3.063-4.5-6.625-4.5-10.875s1.5-7.875 4.5-10.875c3.063-3 6.625-4.5 10.875-4.5zM22.25 13.188c0.688-0.5 1.125-1 1.5-1.563-0.688 0.25-1.25 0.438-1.75 0.5 0.625-0.438 1.125-0.938 1.375-1.688-0.625 0.313-1.313 0.563-1.938 0.688-0.625-0.563-1.313-0.875-2.25-0.875-0.813 0-1.563 0.313-2.125 0.875-0.563 0.625-0.875 1.313-0.875 2.125 0 0.063 0 0.125 0.063 0.313 0 0.188 0 0.313 0 0.375-2.563-0.125-4.625-1.188-6.188-3.188-0.313 0.5-0.438 1-0.438 1.563 0 1.063 0.438 1.875 1.375 2.5-0.563 0-1-0.188-1.375-0.438 0 0 0 0.063 0 0.063 0 0.75 0.188 1.375 0.625 1.938 0.5 0.563 1.063 0.875 1.813 1.063-0.375 0.063-0.625 0.125-0.813 0.125-0.25 0-0.438-0.063-0.563-0.063 0.438 1.375 1.375 2.063 2.813 2.063-1.063 0.875-2.313 1.313-3.75 1.313 0 0-0.75 0-0.75 0 1.5 0.875 3 1.313 4.625 1.313 2.625 0 4.688-0.875 6.25-2.625 1.625-1.75 2.375-3.75 2.375-6 0 0 0-0.375 0-0.375z"
              ></path>
            </svg>
          </a>
        </div>
      </footer>
    </>
  );
};

export default ApplicantInfo;
