import React, { useState } from 'react';
import _ from 'lodash';
import cn from 'classnames';
import moment from 'moment';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Data
import enabled from 'data/enabled';

const statusMap = {
  pending: 'รอการชำระเงิน',
  paid: 'ชำระเงินเรียบร้อยแล้ว',
  canceled: 'ยกเลิกใบแจ้งชำระเงิน',
};
const paymentMethodMap = {
  qr: 'QR Code',
  cs: 'เคาน์เตอร์เซอร์วิส (เซเว่น อีเลฟเว่น)',
};

const AdmissionLogs = ({ timestamp, logs = [], offer = {}, offer2 = {}, offerI = {}, doubleSorted = false, interviewed = false, replies: _replies = [], admissions = [] }) => {
  const { t } = useTranslation();
  const courseMap = useSelector((state) => _.get(state, 'app.courseMap')) || {};

  const [activeTab, setActiveTab] = useState('register');
  const [logActive, setLogActive] = useState(false);
  const replies = _replies.filter(({ is_canceled }) => !is_canceled);
  const canceledAt = _.get(_.find(_replies, ({ is_canceled }) => is_canceled), 'timestamp');

  const getAmountSuffix = (payment_amount, total_amount) => {
    if (payment_amount) {
      if (total_amount === payment_amount) return '';
      return `(ชำระเพิ่มครั้งนี้ ${payment_amount.toFixed(2)} บาท)`;
    }
    return '(ไม่ต้องชำระเพิ่ม)';
  };

  const renderAnnouncement = () => {
    if (moment(timestamp).isBefore(enabled.round3.reply_start)) return null;

    if (offer._id) {
      const project = courseMap[offer.program_project];
      const majorName = _.get(project, `major_name_th`);
      const projectName = _.get(project, `project_name_th`);
      return (
        <div className='card-log'>
          <h4>
            <strong>{t('ผลการประมวลผลครั้งที่')} 1</strong>
          </h4>
          <ul>
            <li>
              {t('ท่านติดอันดับที่')} {offer.priority}
            </li>
          </ul>
          <p>
            <strong>
              {t('อันดับ')} {offer.priority}-{_.get(project, `university_name_th`)} {_.get(project, `campus_name_th`)}
            </strong>
            <br />
            {_.get(project, `faculty_name_th`)} {t('สาขา')}
            {_.get(project, `field_name_th`)}{' '}
            {majorName && majorName !== '0' ? (
              <>
                {t('วิชาเอก')}
                {majorName}{' '}
              </>
            ) : null}
            {_.get(project, `program_name_th`)} {_.get(project, `program_type_name_th`) ? `(${_.get(project, `program_type_name_th`)})` : null}
            {projectName && projectName !== '0' ? (
              <>
                {' '}
                {t('โครงการ ')}
                {projectName}
              </>
            ) : null}
          </p>
          <div className='datetime'>
            <small className='color-gray'>{t('20 พ.ค. 68 08:00:00 น.')}</small>
          </div>
        </div>
      );
    }

    return (
      <div className='card-log'>
        <h4>
          <strong>{t('ผลการประมวลผลครั้งที่')} 1</strong>
        </h4>
        <ul>
          <li>{t('ท่านไม่ผ่านการคัดเลือกในรอบนี้')}</li>
        </ul>
        <div className='datetime'>
          <small className='color-gray'>{t('20 พ.ค. 68 08:00:00 น.')}</small>
        </div>
      </div>
    );
  };

  const renderInterviewed = (timestamp) => {
    if (moment(timestamp).isBefore(enabled.round3.reprocess_announce_start)) return null;

    const { program_project, applicant_status } = offerI || (doubleSorted ? offer2 : offer);
    const project = courseMap[program_project];
    const majorName = _.get(project, `major_name_th`);
    const projectName = _.get(project, `project_name_th`);
    const interviewFailed = ['5', '6', '7'].includes(applicant_status);

    return (
      <div className='card-log'>
        <h4>
          <strong className={cn({ 'color-green': !interviewFailed, 'color-red': interviewFailed })}>{interviewFailed ? t('ท่านไม่ผ่านการสัมภาษณ์') : t('ท่านผ่านการสัมภาษณ์')}</strong>
        </h4>
        <ul>
          <li>{interviewFailed ? t('ท่านไม่ผ่านการสัมภาษณ์') : t('ท่านผ่านการสัมภาษณ์')}</li>
        </ul>
        <p>
          <strong>
            {t('อันดับ')} {offer2.priority}-{_.get(project, `university_name_th`)} {_.get(project, `campus_name_th`)}
          </strong>
          <br />
          {_.get(project, `faculty_name_th`)} {t('สาขา')}
          {_.get(project, `field_name_th`)}{' '}
          {majorName && majorName !== '0' ? (
            <>
              {t('วิชาเอก')}
              {majorName}{' '}
            </>
          ) : null}
          {_.get(project, `program_name_th`)} {_.get(project, `program_type_name_th`) ? `(${_.get(project, `program_type_name_th`)})` : null}
          {projectName && projectName !== '0' ? (
            <>
              {' '}
              {t('โครงการ ')}
              {projectName}
            </>
          ) : null}
        </p>
        <div className='datetime'>
          <small className='color-gray'>{t('2 มิ.ย. 68 เวลา 09.00.00 น.')}</small>
        </div>
      </div>
    );
  };

  const renderAnnouncement2 = (timestamp) => {
    if (moment(timestamp).isBefore(enabled.round3.reprocess_announce_start)) return null;

    if (offer2._id) {
      const project = courseMap[offer2.program_project];
      const majorName = _.get(project, `major_name_th`);
      const projectName = _.get(project, `project_name_th`);
      return (
        <div className='card-log'>
          <h4>
            <strong>{t('ผลการประมวลผลครั้งที่')} 2</strong>
          </h4>
          <ul>
            <li>
              {t('ท่านติดอันดับที่')} {offer2.priority}
            </li>
          </ul>
          <p>
            <strong>
              {t('อันดับ')} {offer2.priority}-{_.get(project, `university_name_th`)} {_.get(project, `campus_name_th`)}
            </strong>
            <br />
            {_.get(project, `faculty_name_th`)} {t('สาขา')}
            {_.get(project, `field_name_th`)}{' '}
            {majorName && majorName !== '0' ? (
              <>
                {t('วิชาเอก')}
                {majorName}{' '}
              </>
            ) : null}
            {_.get(project, `program_name_th`)} {_.get(project, `program_type_name_th`) ? `(${_.get(project, `program_type_name_th`)})` : null}
            {projectName && projectName !== '0' ? (
              <>
                {' '}
                {t('โครงการ ')}
                {projectName}
              </>
            ) : null}
          </p>
          <div className='datetime'>
            <small className='color-gray'>{moment(enabled.round3.reprocess_announce_start).add(543, 'year').format('DD MMM YY เวลา HH.mm.ss น.')}</small>
          </div>
        </div>
      );
    }

    if (offer._id && !replies.length) {
      return (
        <div className='card-log'>
          <h4>
            <strong className='color-red'>{t('ไม่ได้ดำเนินการยืนยันสิทธิ์ในช่วงเวลาที่กำหนด')}</strong>
          </h4>
          <ul>
            <li>{t('ไม่ได้ดำเนินการยืนยันสิทธิ์ในช่วงเวลาที่กำหนด')}</li>
          </ul>
          <div className='datetime'>
            <small className='color-gray'>{t('22 พ.ค. 68 00:00:00 น.')}</small>
          </div>
        </div>
      );
    }

    if (!admissions.some(({ double_sort }) => double_sort)) return null;
    return (
      <div className='card-log'>
        <h4>
          <strong>{t('ผลการประมวลผลครั้งที่')} 2</strong>
        </h4>
        <ul>
          <li>{t('ท่านไม่ผ่านการคัดเลือกในรอบนี้')}</li>
        </ul>
        <div className='datetime'>
          <small className='color-gray'>{moment(enabled.round3.reprocess_announce_start).add(543, 'year').format('DD MMM YY เวลา HH.mm.ss น.')}</small>
        </div>
      </div>
    );
  };

  const renderProject = (priority) => {
    const { program_project } = _.find(admissions, (data) => data.priority === priority) || {};
    const project = courseMap[program_project];
    const majorName = _.get(project, `major_name_th`);
    const projectName = _.get(project, `project_name_th`);
    return (
      <>
        <p>
          <strong>
            {t('อันดับ')} {priority}-{_.get(project, `university_name_th`)} {_.get(project, `campus_name_th`)}
          </strong>
          <br />
          {_.get(project, `faculty_name_th`)} {t('สาขา')}
          {_.get(project, `field_name_th`)}{' '}
          {majorName && majorName !== '0' ? (
            <>
              {t('วิชาเอก')}
              {majorName}{' '}
            </>
          ) : null}
          {_.get(project, `program_name_th`)} {_.get(project, `program_type_name_th`) ? `(${_.get(project, `program_type_name_th`)})` : null}
          {projectName && projectName !== '0' ? (
            <>
              {' '}
              {t('โครงการ ')}
              {projectName}
            </>
          ) : null}
        </p>
      </>
    );
  };

  const renderCanceled = (timestamp) => {
    return (
      <div className='card-log'>
        <h4>
          <strong className='color-red'>{t('สละสิทธิ์')}</strong>
        </h4>
        <ul>
          <li>{t('ท่านสละสิทธิ์ในรอบนี้')}</li>
        </ul>
        <div className='datetime'>
          <small className='color-gray'>
            {moment(timestamp).format('DD MMM')} {(moment(timestamp).year() + 543) % 100} {moment(timestamp).format('HH:mm:ss')} น.
          </small>
        </div>
      </div>
    )
  }

  const renderReply = ({ _id, enrollment_offer, ds_priorities, timestamp }, index) => {
    const replyIndex = replies.length - index;
    const accepted = Boolean(enrollment_offer);
    const rejected = !enrollment_offer && !(ds_priorities || []).length;
    const reprocessed = Boolean((ds_priorities || []).length);
    const rejectText = offer._id ? (offer.priority !== 1 ? 'ไม่ใช้สิทธิ์และไม่ขอใช้สิทธิ์ประมวลผลครั้งที่ 2' : 'ไม่ใช้สิทธิ์') : 'ไม่ขอใช้สิทธิ์ประมวลผลครั้งที่ 2';

    return (
      <div key={_id} className='card-log'>
        <h4>
          <strong className={cn({ 'color-orange': reprocessed, 'color-red': rejected, 'color-green': accepted })}>
            {t(accepted ? 'ยืนยันสิทธิ์' : rejected ? rejectText : reprocessed ? 'ขอรับการประมวลผลครั้งที่ 2' : '')} ({t('ตัดสินใจครั้งที่')} {replyIndex}/3)
          </strong>
        </h4>
        <ul>
          {accepted && (
            <li>
              {t('ยืนยันอันดับที่')} {offer.priority}
            </li>
          )}
          {rejected && <li>{rejectText}</li>}
          {reprocessed && (
            <li>
              {t('เลือกอันดับใหม่')} {(ds_priorities || []).length} {t('อันดับ')}
            </li>
          )}
        </ul>
        {accepted && renderProject(offer.priority)}
        {reprocessed && (ds_priorities || []).map(renderProject)}
        <div className='datetime'>
          <small className='color-gray'>
            {moment(timestamp).format('DD MMM')} {(moment(timestamp).year() + 543) % 100} {moment(timestamp).format('HH:mm:ss')} น.
          </small>
        </div>
      </div>
    );
  };

  if (!(logs || []).concat(replies || []).length) return null;
  return (
    <div className={cn({'r-3-log t-collapse': !offer, 'r-more': offer, active: logActive })}>
      <div className='_space'></div>
      <h4 className={cn({ 'r-3-log_header t-toggle color-gray': !offer })} onClick={() => setLogActive((_active) => !_active)}>
        {t('ประวัติการดำเนินการ')} {offer ? <i className='i-more' /> : <i className='i-toggle i-down-teal' />}
      </h4>
      <div className={cn('_heading', { 't-content': !offer, more: offer })}>
        <div className='t-tabs'>
          <ul className='t-tab-nav'>
            <li onClick={() => setActiveTab('register')} className={cn('t-nav-item', { active: activeTab === 'register' })} data-tab='register'>
              {t('การสมัคร')}
            </li>
            <li onClick={() => setActiveTab('payment')} className={cn('t-nav-item', { active: activeTab === 'payment' })} data-tab='payment'>
              {t('การชำระเงิน')}
            </li>
          </ul>
          <div id='register' className={cn('t-tab-content', { active: activeTab === 'register' })}>
            {interviewed && renderInterviewed(timestamp)}
            {doubleSorted && canceledAt && renderCanceled(canceledAt)}
            {doubleSorted && renderAnnouncement2(timestamp)}
            {!doubleSorted && canceledAt && renderCanceled(canceledAt)}
            {(replies || []).slice().reverse().map(renderReply)}
            {Boolean(offer) && renderAnnouncement(timestamp)}
            {logs.map(({ apply_id, confirm_id, program_projects, total_amount, payment_amount, redeem_amount, created_at }, index) => {
              return (
                <div key={index} className='card-log'>
                  <h4>
                    <strong>
                      {t('การสมัครครั้งที่')} {apply_id} / {t('ยืนยันครั้งที่')} {confirm_id}
                    </strong>
                  </h4>
                  <ul>
                    <li>
                      {t('จำนวนอันดับ')} : {(program_projects || []).length} {t('อันดับ')}
                    </li>
                  </ul>
                  {(program_projects || []).map(({ _id, priority }) => {
                    const project = courseMap[_id];
                    const majorName = _.get(project, `major_name_th`);
                    const projectName = _.get(project, `project_name_th`);
                    return (
                      <p key={_id}>
                        <strong>
                          {t('อันดับ')} {priority}-{_.get(project, `university_name_th`)} {_.get(project, `campus_name_th`)}
                        </strong>
                        <br />
                        {_.get(project, `faculty_name_th`)} {t('สาขา')}
                        {_.get(project, `field_name_th`)}{' '}
                        {majorName && majorName !== '0' ? (
                          <>
                            {t('วิชาเอก')}
                            {majorName}{' '}
                          </>
                        ) : null}
                        {_.get(project, `program_name_th`)} {_.get(project, `program_type_name_th`) ? `(${_.get(project, `program_type_name_th`)})` : null}
                        {projectName && projectName !== '0' ? (
                          <>
                            {' '}
                            {t('โครงการ ')}
                            {projectName}
                          </>
                        ) : null}
                      </p>
                    );
                  })}
                  <ul>
                    <li className='color-blue'>
                      {t('ยอดชำระ')} : {total_amount.toFixed(2)} บาท {getAmountSuffix(payment_amount || redeem_amount, total_amount)}
                    </li>
                  </ul>
                  <div className='datetime'>
                    <small className='color-gray'>
                      {moment(created_at).format('DD MMM')} {(moment(created_at).year() + 543) % 100}{' '}
                      {moment(created_at).format('HH:mm:ss')} น.
                    </small>
                  </div>
                </div>
              );
            })}
          </div>
          <div id='payment' className={cn('t-tab-content', { active: activeTab === 'payment' })}>
            {logs.map(({ apply_id, confirm_id, total_amount = 0, payment_amount = 0, redeem_amount = 0, status, payment_method, created_at }, index) => {
              const statusClassName = cn({
                'color-green': status === 'paid',
                'color-red': ['canceled', 'pending'].includes(status),
              });
              return (
                <div key={index} className='card-log'>
                  <h4>
                    <strong>
                      {t('การสมัครครั้งที่')} {apply_id} / {t('ยืนยันครั้งที่')} {confirm_id}
                    </strong>
                  </h4>
                  <ul>
                    <li className='color-blue'>
                      {t('ยอดชำระ')} : {total_amount.toFixed(2)} บาท {getAmountSuffix(payment_amount || redeem_amount, total_amount)}
                    </li>
                    <li className='color-blue'>
                      {t('สถานะ')} : <span className={statusClassName}>{statusMap[status]}</span>
                    </li>
                    {status === 'paid' && paymentMethodMap[payment_method] && (
                      <li className='color-blue'>
                        {t('ช่องทาง')} : <span className='color-green'>{paymentMethodMap[payment_method]}</span>
                      </li>
                    )}
                  </ul>
                  <div className='datetime'>
                    <small className='color-gray'>
                      {moment(created_at).format('DD MMM')} {(moment(created_at).year() + 543) % 100}{' '}
                      {moment(created_at).format('HH:mm:ss')} น.
                    </small>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className='_space'></div>
    </div>
  );
};

export default AdmissionLogs;
