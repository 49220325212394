/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import _ from 'lodash';
import classNames from 'classnames';

// Hooks
import { useTranslation } from 'react-i18next';
import useSectionGPAScore from './score-hooks/useSectionGPAScore';

// Helpers
import { callAPI } from 'helpers';
import { errorMessages, validateTranscriptFrontFile, validateTranscriptBackFile } from 'validations';

// Components
import ErrorBox from 'components/registrations/ErrorBox';

const ID = 'section-gpa';
const SectionGPAScore = forwardRef(({ initialState, profile = {}, pendingTickets = [], collapsed, onCollapsedChange, waiting, editable }, ref) => {
  const { t } = useTranslation();
  const {
    schoolYear,
    programCode,

    scores,
    fnName,
    validateScore,
    validateCredit,
    validateRequired,
    transcriptFrontFile,
    transcriptBackFile,
    onTranscriptFrontFileChange,
    onTranscriptBackFileChange,
    ...scoreProps
  } = useSectionGPAScore(profile, pendingTickets);
  const [errors, setErrors] = useState([]);

  const onScoreChange = (fieldName) => (e) => {
    const { max } = _.find(scores, (_score) => _score.fieldName === fieldName);
    const value = `${e.target.value || ''}`;
    if (value && isNaN(value)) return;
    if (value < 0) return;
    if (value > max) return;
    if (value.includes('.') && value.split('.').pop().length > 2) return;
    scoreProps[fnName(fieldName)](e.target.value);
  };

  const onCreditChange = (creditFieldName) => (e) => {
    const value = `${e.target.value || ''}`;
    if (value && isNaN(value)) return;
    if (value < 0) return;
    if (value > 99) return;
    if (value.includes('.') && value.split('.').pop().length > 1) return;
    scoreProps[fnName(creditFieldName)](e.target.value);
  };

  useImperativeHandle(ref, () => ({
    getState: () =>
      scores.reduce(
        (state, { fieldName, creditFieldName }) => ({
          ...state,
          [fieldName]: scoreProps[fieldName],
          [creditFieldName]: scoreProps[creditFieldName],
        }),
        {}
      ),
  }));

  const noChanges = scores.reduce((result, { fieldName, creditFieldName }) => result && parseFloat(scoreProps[fieldName] || '0', 10) === parseFloat(initialState[fieldName] || '0', 10) && parseFloat(scoreProps[creditFieldName] || '0', 10) === parseFloat(initialState[creditFieldName] || '0', 10), true);

  const [saving, setSaving] = useState(false);
  const onButtonClick = async () => {
    const _messages = errorMessages(
      scores.reduce(
        (_errors, { fieldName, creditFieldName }) => ({
          ..._errors,
          [fieldName]: validateScore(fieldName, scoreProps[fieldName]),
          [creditFieldName]: validateCredit(creditFieldName, scoreProps[creditFieldName]),
          [`required${fieldName}`]: validateRequired(fieldName, scoreProps[fieldName], scoreProps[creditFieldName]),
        }),
        {
          transcriptFrontFile: validateTranscriptFrontFile(transcriptFrontFile),
          transcriptBackFile: validateTranscriptBackFile(transcriptBackFile),
        }
      )
    );

    if (noChanges) {
      onCancel();
    } else if (_messages.length) {
      setErrors(_messages);
      const container = document.getElementById(ID);
      if (container) {
        container.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      setErrors([]);

      if (saving) return;
      try {
        setSaving(true);
        const formData = new FormData();
        formData.append('transcriptFrontFile', transcriptFrontFile);
        formData.append('transcriptBackFile', transcriptBackFile);
        scores.forEach(({ fieldName, creditFieldName }) => {
          formData.append(fieldName, scoreProps[fieldName]);
          formData.append(creditFieldName, scoreProps[creditFieldName]);
        });

        await callAPI({
          method: 'PUT',
          url: '/applicants/me/gpa-scores',
          formData,
        });

        onCollapsedChange(true, true);
        setSaving(false);
      } catch (error) {
        setErrors(errorMessages);
        const container = document.getElementById(ID);
        if (container) {
          container.scrollIntoView({ behavior: 'smooth' });
        }

        console.error(`Error while trying to save gpa score data: ${errorMessages.toString()}`);
        setSaving(false);
      }
    }
  };

  const onCancel = () => {
    scores.forEach(({ fieldName, creditFieldName }) => {
      scoreProps[fnName(fieldName)](initialState[fieldName]);
      scoreProps[fnName(creditFieldName)](initialState[creditFieldName]);
    });
    onCollapsedChange(true, waiting);
  };

  if (programCode !== '1') return null;
  if (collapsed) {
    const existedScores = scores.filter(({ creditFieldName, fieldName }) => scoreProps[creditFieldName] || scoreProps[fieldName]);
    return (
      <div className={classNames('t-box', { '-waiting': waiting })}>
        <h2 className='title'>
          {waiting && <span>{t('(รอตรวจสอบ)')}&nbsp;</span>}
          {t('ข้อมูลคะแนนหลักสูตรแกนกลาง')}
          {/* [At the beginning] */}
          {schoolYear < 2567 && (
            <a className='cursor-pointer' onClick={() => onCollapsedChange(false, waiting)}>
              {t('ขอแก้ไขข้อมูล')}
            </a>
          )}
        </h2>
        {existedScores.length ? (
          <table className='table-line'>
            <thead>
              <tr>
                <th>{t('กลุ่มสาระ')}</th>
                <th>{t('หน่วยกิต')}</th>
                <th>GPA</th>
              </tr>
            </thead>
            <tbody>
              {existedScores.map(({ key, label, creditFieldName, fieldName }) => (
                <tr key={key}>
                  <td>{label}</td>
                  <td>{scoreProps[creditFieldName] || t('ยังไม่มีข้อมูล')}</td>
                  <td>{scoreProps[fieldName] || t('ยังไม่มีข้อมูล')}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          // <div>{t('ไม่มีข้อมูลคะแนน หากต้องการเพิ่มคะแนน กรุณากดขอแก้ไขข้อมูล')}</div>
          <div>{schoolYear === '2567' ? t('รอผลคะแนนเดือนเมษายน 2568') : t('ไม่มีข้อมูลคะแนน หากต้องการเพิ่มคะแนน กรุณากดขอแก้ไขข้อมูล')}</div>
        )}
      </div>
    );
  }

  return (
    <div id={ID} className='t-box -choose'>
      <h2 className='title'>
        {t('แก้ไขข้อมูลคะแนนหลักสูตรแกนกลาง')}
        <a className='cursor-pointer' onClick={onCancel}>
          {t('ยกเลิก')}
        </a>
      </h2>
      <ErrorBox isFull hidden={!errors.length} errors={errors} />
      <div className='t-row'>
        {scores.map(({ key, fieldName, creditFieldName, label }) => {
          return (
            <div key={key} className='col-2c'>
              <h3>
                {t('กลุ่มสาระ')}
                {label}
              </h3>
              <label>
                <b>{t('หน่วยกิต')}</b>
                <input type='text' value={scoreProps[creditFieldName]} onChange={onCreditChange(creditFieldName)} />
              </label>
              <label>
                <b>GPA</b>
                <input type='text' value={scoreProps[fieldName]} onChange={onScoreChange(fieldName)} />
              </label>
            </div>
          );
        })}
      </div>
      <hr />
      {!noChanges && (
        <>
          <div className='t-row'>
            <div className='col -full'>
              <label>{t('แนบหลักฐานใบปพ.1 (Transcript)')}</label>
            </div>
            <div className='col required'>
              <div className='transcript'>
                <img src='/assets/img/transcript_thai_1.png' alt='รูปด้านหน้าใบปพ.1' />
                {t('รูปด้านหน้าใบปพ.1')}
              </div>
              <label>{t('อัปโหลดรูปด้านหน้า')}</label>
              <input name='transcript-front' type='file' accept='image/*' onChange={onTranscriptFrontFileChange} />
            </div>
            <div className='col required'>
              <div className='transcript'>
                <img src='/assets/img/transcript_thai_2.png' alt='รูปด้านหลังใบปพ.1' />
                {t('รูปด้านหลังใบปพ.1')}
              </div>
              <label>{t('อัปโหลดรูปด้านหลัง')}</label>
              <input name='transcript-back' type='file' accept='image/*' onChange={onTranscriptBackFileChange} />
            </div>
          </div>
          <hr />
        </>
      )}
      <a className='btn-main cursor-pointer' onClick={onButtonClick}>
        {t('ยืนยันข้อมูลคะแนนหลักสูตรแกนกลาง')}
      </a>
    </div>
  );
});

export default SectionGPAScore;
