import { useEffect, useState, useRef } from 'react';
import { getNewestTicket } from 'helpers';

const useEditBasicInfo = (profile = {}, pendingTickets = []) => {
  const isComplete = Boolean(
    profile.title &&
      profile.first_name &&
      profile.last_name &&
      profile.citizen_id &&
      profile.email &&
      profile.telephone &&
      profile.login_method &&
      profile.weight &&
      profile.height
  );

  const basicInfoRef = useRef();
  const { ticket: nameTicket, valueOf: nameValueOf } = getNewestTicket(pendingTickets, ['update_name']);
  const { ticket: srTicket, valueOf: srValueOf } = getNewestTicket(pendingTickets, ['update_special_request']);

  const [collapsed, setCollapsed] = useState(isComplete);
  useEffect(() => setCollapsed(isComplete), [isComplete]);
  const [waiting, setWaiting] = useState(Boolean(nameTicket || srTicket));
  useEffect(() => setWaiting(Boolean(nameTicket || srTicket)), [nameTicket, srTicket]);

  const [initialState, setInitialState] = useState({
    citizenId: profile.citizen_id || '',
    email: profile.email || '',
    mobile: profile.telephone || '',
    title: nameValueOf('title') | profile.title || '',
    firstName: nameValueOf('first_name') || profile.first_name || '',
    lastName: nameValueOf('last_name') || profile.last_name || '',
    birthDate: nameValueOf('birth_date') || profile.birth_date || '',
    firstNameAddtional: profile.first_name_additional || '',
    lastNameAdditional: profile.last_name_additional || '',
    fatherName: profile.father_name || '',
    motherName: profile.mother_name || '',
    loginMethod: profile.login_method || '',
    weight: profile.weight || '',
    height: profile.height || '',
    specialRequest: srValueOf('special_request') || profile.special_request || ''
  });

  useEffect(() => {
    if (!collapsed) {
      setInitialState(basicInfoRef.current.getState());
    }
  }, [collapsed]);

  return {
    basicInfoRef,
    basicInfoCollapsed: collapsed,
    onBasicInfoCollapsedChange: (collapsed, waiting) => {
      setCollapsed(collapsed);
      setWaiting((nameTicket || srTicket) ? true : waiting);
    },
    basicInfoInitialState: initialState,
    basicInfoWaiting: waiting,
  };
};

export default useEditBasicInfo;
