/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import { callAPI, getRoundState, getRoundDescription, isCitizenId, isGNumber } from 'helpers';

// Components
import { Link } from 'react-router-dom';
import Search from './Search';
import FavoriteItem from './FavoriteItem';
import AdmissionEdit from './AdmissionEdit';
import AdmissionConfirm from './AdmissionConfirm';
import AdmissionApplied from './AdmissionApplied';
import AdmissionLogs from './AdmissionLogs';
import ModalChooseRound3 from 'components/profile/enrollments/round3/ModalChooseRound3';
import ModalJustSetZero from 'components/profile/enrollments/round3/ModalJustSetZero'
import ModalPassportStudent from 'components/profile/enrollments/round3/ModalPassportStudent'
import RoundContent from '../RoundContent';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useFavorites from 'hooks/round3/useFavorites';
import useAdmission from 'hooks/round3/useAdmission';

// Data
import enabled from 'data/enabled.json';

const roundType = '3_2568';
const Round3 = () => {
	const { t } = useTranslation();

	const { loading, favorites, studentStatus = {}, isApplicable, redeemableAmount, onAdd, onRemove, onSelect, onDragEnd, onRemovePriority } = useFavorites();
	const { projects, availables } = useAdmission(favorites, studentStatus);
	const profile = useSelector((state) => _.get(state, 'app.user') || {});
	const [active, setActive] = useState(false);
	const [editable, setEditable] = useState(1);
	const [modalVisible, setModalVisible] = useState(false);

	const [favoriteActive, setFavoriteActive] = useState(true);
	const [editActive, setEditActive] = useState(true);
	const [admissionLoading, setAdmissionLoading] = useState(false);
	const [admissions, setAdmissions] = useState([]);
	const [offerProjects, setOfferProjects] = useState([]);
	const [logs, setLogs] = useState([]);
	const [timestamp, setTimestamp] = useState();
	const [isExpired, setExpired] = useState(false);
	const [justSetZero, setJustSetZero] = useState(false);
	const [passportVisible, setPassportVisible] = useState(false);

  const { flow } = getRoundState({ roundType, timestamp });
  const description = getRoundDescription({ roundType, timestamp});

	useEffect(() => {
		const fetchAdmissions = async () => {
			try {
				setAdmissionLoading(true);
				const { result, offer_projects, timestamp } = await callAPI({ url: '/applicants/me/admissions' });
				setLogs(_.reverse(_.cloneDeep(result)));

				const admissions = result.filter(({ set_zero }) => !set_zero);
				setJustSetZero(_.get(_.last(result), 'set_zero'));
				setAdmissions(admissions);
				setAdmissionLoading(false);
				setOfferProjects(offer_projects);
				setFavoriteActive(_active => admissions.length ? false : _active);
				setEditable(admissions.length ? 0 : 1);
				setTimestamp(timestamp);
				setExpired(moment(timestamp).isAfter(enabled.round3.apply_end));
				setActive(true);
			} catch (error) {
				console.log({ error });
				setAdmissionLoading(false);
			}
		}

		// fetchAdmissions();
	}, []);

	// useEffect(() => {
	// 	if (!studentStatus.code || [4, 8, 13, 14, 15, 20, 21].includes(studentStatus.code) || isExpired) {

	// 	} else if (!isCitizenId(profile.citizen_id) && !isGNumber(profile.citizen_id)) {
	// 		if (!_.get(profile, 'consents_accepted_at.passport_round3_2568')) {
	// 			setPassportVisible(true);
	// 		}
	// 	} else if (typeof _.get(profile, 'skip_r3_redemption') === 'undefined') {
	// 		navigate('/profile/redeem-round3');
	// 	}
	// }, [profile, studentStatus, isExpired, navigate]);

  const [accepting, setAccepting] = useState(false);
  const _onAcceptConsent = async (consentType) => {
    if (accepting) return;
    try {
      setAccepting(true);
      await callAPI({
        method: 'POST',
        url: '/applicants/me/consents',
        body: {
          consentType,
        },
      });
      setAccepting(false);
    } catch (error) {
      console.error(`Error while trying to accept consent: ${error.message}`);
      setAccepting(false);
    }
    setPassportVisible(false);
  };

	const currentApplyId = logs.length
		? (_.get(_.maxBy(logs.filter(({ set_zero }) => set_zero), 'apply_id'), 'apply_id') || 0) + 1
		: 1;

	if (flow === 'not-open') {
		return (
			<div className={cn('r-3', { active })}>
				<header onClick={() => setActive((_active) => !_active)}>
					<b>
						<small>{t('รอบ')}</small>3
					</b>
					<h3>
						Admission
						<small>{'รับสมัคร 6 - 12 พ.ค. 68' || t(description)}</small>
					</h3>
					<i className="i-down" />
				</header>
				{/* <div className="content -announce">
					<div>
						<h3>{t('ทดลองจัดอันดับสาขาวิชาที่สนใจ')}</h3>
						<p>
							<div>{t('สามารถค้นหาและจัดอันดับสาขาวิชาที่สนใจ')}</div>
							<div>{t('เพื่อใช้ในการสมัครรอบ 3 Admission')}</div>
						</p>
					</div>
					<Link to='/profile/favorite-round3' className={cn('btn-icon cursor-pointer')}>
						<b className="text">{t('ทดลองจัดอันดับ')}</b>
					</Link>
				</div> */}
				<RoundContent
					flow={flow}
					roundType={roundType}
					loading={loading}
					timestamp={timestamp} />
			</div>
		);
	}

	return (
		<div className={cn('r-3', { active })}>
			<header onClick={() => setActive((_active) => !_active)}>
				<b>
					<small>{t('รอบ')}</small>3
				</b>
				<h3>
					Admission
					<small>{t(isExpired ? 'หมดเขตการสมัครแล้ว' : 'รับสมัคร 6-12 พ.ค. 68')}</small>
				</h3>
				<i className="i-down" />
			</header>
			{(loading || admissionLoading)
				? null
				: [4, 8, 13, 14, 15, 20, 21].includes(studentStatus.code)
					? (
						<div className="content">
							<div>
								<h3>{t('ท่านไม่มีสิทธิ์สมัครในรอบนี้')}</h3>
								{[4, 8, 13, 14, 15, 20, 21].includes(studentStatus.code) && <p>{studentStatus.message.split(' --> ')[0]}</p>}
							</div>
						</div>
					) : isExpired ? (
						<div className="content">
							<AdmissionApplied
								logs={logs}
								isExpired
								studentStatus={studentStatus}
								admissions={admissions}
								offerProjects={offerProjects} />
							<AdmissionLogs logs={logs} />
						</div>
					) :  (
						<div className="content">
							<div className={cn('t-collapse', { active: favoriteActive })}>
								<h3 className="r-3-bar t-toggle" onClick={() => setFavoriteActive(_active => !_active)}>
									<i className="i-fav" />
									{t('สาขาวิชาที่สนใจ')}{favorites.length ? ` (${favorites.length}/20)` : ''}
									<i className="i-toggle i-down-teal" />
								</h3>
								<div className="t-content">
									<ul className="r-choose -fav">
										{(favorites || []).map((data, index) =>
											<FavoriteItem
												key={data._id}
												index={index}
												data={data}
												favorites={favorites}
												studentStatus={studentStatus}
												onRemove={onRemove} />)}
									</ul>
									<Search
										profile={profile}
										favorites={favorites}
										studentStatus={studentStatus}
										onAddToFavorites={onAdd} />
								</div>
							</div>
							{(admissions.length && !editable)
								? <AdmissionApplied
									logs={logs}
									studentStatus={studentStatus}
									admissions={admissions}
									onEdit={() => setEditable(1)} />
								: (isApplicable ? (
									<div className={cn("t-collapse", { active: editActive })}>
										<h3 className="r-3-bar t-toggle" onClick={() => setEditActive(_active => !_active)}>
											<i className="i-apply" />
											{t('สาขาวิชาที่สมัคร')}{(projects || []).length ? ` (${(projects || []).length}/10)` : ''}
											<i className="i-toggle i-down-teal" />
										</h3>
										<div className="t-content">
											<h4><b>{t('การสมัครครั้งที่')} {currentApplyId}</b></h4>
											{favorites.some(({ priority }) => priority) ? (
												<>
													{editable === 1 ? (
														<AdmissionEdit
															favorites={favorites}
															studentStatus={studentStatus}
															redeemableAmount={redeemableAmount}
															onModalOpen={() => setModalVisible(true)}
															onDragEnd={onDragEnd}
															onRemovePriority={onRemovePriority}
															onSaveSuccess={(noChanges) => setEditable((noChanges && admissions.length) ? 0 : 2)}
															quotaUsed={admissions.length}
															onBack={() => setEditable(0)} />
													) : editable === 2 ? (
														<AdmissionConfirm
															admissions={admissions}
															favorites={favorites}
															studentStatus={studentStatus}
															redeemableAmount={redeemableAmount}
															onEdit={() => setEditable(1)}
															quotaUsed={admissions.length}
															onBack={() => setEditable(0)} />
													) : null}
												</>
											) : (
												<>
													<p>{t('กรุณาเลือกสาขาวิชาที่จะสมัคร เรียงตามความสนใจ สามารถเลือกได้สูงสุด 10 อันดับ')}</p>
													{(availables || []).length ? (
														<a className="btn-add cursor-pointer disabled" onClick={() => setModalVisible(true)}>
															<i className="i-add" />
															{t('เลือกสาขาวิชาอันดับ')} 1
														</a>
													) : (
														<h4 className="color-teal">{t('กรุณาเลือกสาขาวิชาที่สนใจ')}</h4>
													)}
												</>
											)}
										</div>
									</div>
								) : null)}
							<AdmissionLogs logs={logs} />
						</div>
					)
			}
			<ModalChooseRound3
				favorites={favorites}
				studentStatus={studentStatus}
				visible={modalVisible}
				onSelect={onSelect}
				onClose={() => setModalVisible(false)} />
			<ModalJustSetZero
				visible={justSetZero}
				onClick={() => setJustSetZero(false)} />
			<ModalPassportStudent
				visible={passportVisible}
				onClick={() => _onAcceptConsent('passport_round3_2568')} />
		</div >
	);
};

export default Round3;
