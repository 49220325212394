/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import _ from 'lodash';

// Hooks
import { useTranslation } from 'react-i18next';

// Helpers
import { errorMessages } from 'validations';
import { callAPI } from 'helpers';

// Components
import ErrorBox from 'components/registrations/ErrorBox';

// Hooks
import useSectionEnglishScore from './score-hooks/useSectionEnglishScore';

const ID = 'section-english';
const SectionEnglishScore = forwardRef(({ initialState, profile = {}, collapsed, onCollapsedChange, editable }, ref) => {
  const { t } = useTranslation();

  const { scores, fnName, validateScore, ...scoreProps } = useSectionEnglishScore(profile);
  const [errors, setErrors] = useState([]);

  const onValueChange = (fieldName) => (e) => {
    const { max, alphanumeric } = _.find(scores, (_score) => _score.fieldName === fieldName);
    const value = `${e.target.value || ''}`;
    if (!alphanumeric) {
      if (value && isNaN(value)) return;
      if (value < 0) return;
      if (value > max) return;
      if (value.includes('.') && value.split('.').pop().length > 2) return;
    }
    scoreProps[fnName(fieldName)](e.target.value);
  };

  useImperativeHandle(ref, () => ({
    getState: () => scores.reduce((state, { fieldName }) => ({ ...state, [fieldName]: scoreProps[fieldName] }), {}),
  }));

  const noChanges = scores.reduce(
    (result, { fieldName }) =>
      result && parseFloat(scoreProps[fieldName] || '0', 10) === parseFloat(initialState[fieldName] || '0', 10),
    true
  );
  const [saving, setSaving] = useState(false);
  const onButtonClick = async () => {
    const _messages = errorMessages(
      scores.reduce(
        (_errors, { fieldName }) => ({
          ..._errors,
          [fieldName]: validateScore(fieldName, scoreProps[fieldName]),
        }),
        {}
      )
    );

    if (noChanges) {
      onCancel();
    } else if (_messages.length) {
      setErrors(_messages);
      const container = document.getElementById(ID);
      if (container) {
        container.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      setErrors([]);

      if (saving) return;
      try {
        setSaving(true);
        const { result } = await callAPI({
          method: 'PUT',
          url: '/applicants/me/english-scores',
          body: scores.reduce((_body, { fieldName }) => ({ ..._body, [fieldName]: scoreProps[fieldName] }), {}),
        });

        scores.forEach(({ fieldName, key }) => {
          scoreProps[fnName(fieldName)](result[key]);
        });
        onCollapsedChange(true);
        setSaving(false);
      } catch (error) {
        const errorMessages = (_.get(error, 'error.errors') || []).map(({ msg }) => msg);
        setErrors(errorMessages);
        const container = document.getElementById(ID);
        if (container) {
          container.scrollIntoView({ behavior: 'smooth' });
        }

        console.error(`Error while trying to save english score data: ${errorMessages.toString()}`);
        setSaving(false);
      }
    }
  };

  const onCancel = () => {
    scores.forEach(({ key, fieldName }) => {
      scoreProps[fnName(fieldName)](initialState[fieldName]);
    });
    onCollapsedChange(true);
  };
  if (collapsed) {
    const existedScores = scores.filter(({ fieldName }) => scoreProps[fieldName]);
    return (
      <div className="t-box">
        <h2 className="title">
          {t('ข้อมูลคะแนนภาษาอังกฤษ')}
          {editable && (
            <a className="cursor-pointer" onClick={() => onCollapsedChange(false)}>
              {t('แก้ไขข้อมูล')}
            </a>
          )}
        </h2>

        {existedScores.length ? (
          <table className="table-line">
            <thead>
              <tr>
                <th>TEST</th>
                <th>SCORE</th>
              </tr>
            </thead>
            <tbody>
              {existedScores.map(({ key, fieldName, label }) => (
                <tr key={key}>
                  <td>{label}</td>
                  <td>{scoreProps[fieldName] || t('ยังไม่มีข้อมูล')}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div>{t('ไม่มีข้อมูลคะแนน หากต้องการเพิ่มคะแนน กรุณากดแก้ไขข้อมูล')}</div>
        )}
      </div>
    );
  }
  return (
    <div id={ID} className="t-box -choose">
      <h2 className="title">
        {t('แก้ไขข้อมูลคะแนนภาษาอังกฤษ')}
        <a className="cursor-pointer" onClick={onCancel}>
          {t('ยกเลิก')}
        </a>
      </h2>
      <ErrorBox isFull hidden={!errors.length} errors={errors} />
      <div className="t-row">
        {scores.map(({ key, fieldName, label, max }) => (
          <div key={key} className="col-1c">
            <label>
              <b>{label}</b>
              <span style={{ fontSize: '0.8em', position: 'absolute', right: 20, top: 9, color: '#888' }}>{max ? `/${max}` : ''}</span>
              <input type="text" value={scoreProps[fieldName]} onChange={onValueChange(fieldName)} />
            </label>
          </div>
        ))}
      </div>
      <hr />
      <a className="btn-main cursor-pointer" onClick={onButtonClick}>
        {t('ยืนยันข้อมูลคะแนนภาษาอังกฤษ')}
      </a>
    </div>
  );
});

export default SectionEnglishScore;
