/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import { callAPI, getCitizenIdType, getCitizenTypeLabel } from 'helpers';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import config from 'config';

// Components
import ModalTermsAndConditions from 'components/registrations/ModalTermsAndConditions';
// import ModalPDPAConsent from 'components/registrations/ModalPDPAConsent';
import NotFoundTicket from 'components/registrations/NotFoundTicket';
import FacebookLogin from 'components/profile/FacebookLogin';
import GoogleLogin from 'components/profile/GoogleLogin';

// Hooks
import usePassword from 'hooks/usePassword';
import NotFoundSubmitted from 'components/registrations/NotFoundSubmitted';
import useQuery from 'hooks/useQuery';

const Lookup = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { passwordHidden, onPasswordHiddenChange } = usePassword();
  const query = useQuery();
  const registrable = true;

  // const _consent = Cookies.get('consent_accepted_at');
  // const [consentAccepted, setConsentAccepted] = useState(Boolean(_consent));
  // useEffect(() => setConsentAccepted(_consent), [_consent]);

  const [lookingUp, setLookingUp] = useState(false);
  const [citizenId, setCitizenId] = useState(query.get('citizen_id') || '');
  const [citizenIdType, setCitizenIdType] = useState();
  const [password, setPassword] = useState('');
  const [lookupState, setLookupState] = useState();
  const [ticketSuccessEmail, setTicketSuccessEmail] = useState('');
  const [loginMethod, setLoginMethod] = useState('social');
  const [wrongCitizenId, setWrongCitizenId] = useState(false);

  useEffect(() => {
    setCitizenIdType(getCitizenIdType(citizenId));
    setLookupState();
    setWrongCitizenId();
    setPassword('');
  }, [citizenId]);

  useEffect(() => {
    const citizenId = query.get('citizen_id') || '';
    setCitizenId(citizenId);
    setLookupState(query.get('state'));

    if (query.get('next') === '1') {
      const _onLookup = async () => {
        if (lookingUp || !citizenId) return;
        try {
          setLookingUp(true);
          const result = await callAPI({
            method: 'POST',
            url: '/applicants/student-lookup',
            body: { citizenId },
          });

          setLookupState(result.state);
          setSocialLogin(result.social_login);
          Cookies.set('sessionToken', result.token, { expires: 1 / 24 });
          if (['obec', 'obec-past', 'ticket'].includes(result.state)) {
            navigate('/form');
          } else if (result.state === 'tutor-suspected') {
            navigate(`/veto/${result.blacklist_token}`);
          }

          setLookingUp(false);
        } catch (error) {
          console.error(new Error(`Error while trying to lookup by citizenId: ${error.message}`));
          setLookingUp(false);
        }
      };
      _onLookup();
    }
  }, [navigate, query]);

  const citizenTypeLabel = getCitizenTypeLabel(t, citizenIdType);

  const _onCitizenIdChange = (e) => {
    // if (!consentAccepted) return;
    const id = e.target.value;
    if (!id.match(new RegExp('^[a-zA-Z0-9]{0,13}$', 'g'))) return;
    setCitizenId(id.toUpperCase());
    setLoginFail(false);
  };

  const _onCitizenIdEnter = (e) => {
    if (e.key === 'Enter') {
      _onLoginClick();
    }
  };

  const _onPasswordEnter = (e) => {
    if (e.key === 'Enter') {
      _onLoginClick();
    }
  }; 

  const _onThaidLogin = () => {
    const redirect_uri = 'https://tcas-student-api-thaid-dot-tcas65.as.r.appspot.com/thaid/callback&scope=pid';
    const url = `${config.thaid.host}/api/v2/oauth2/auth/?response_type=code&client_id=${config.thaid.clientId}&redirect_uri=${redirect_uri}&state=${citizenId}`;
    window.location.href = url;
  }

  const [socialLogin, setSocialLogin] = useState();
  const _onLookup = async () => {
    if (!citizenIdType) return setWrongCitizenId(true);
    if (lookingUp || !citizenId) return;
    try {
      setLookingUp(true);
      const result = await callAPI({
        method: 'POST',
        url: '/applicants/student-lookup',
        body: { citizenId },
      });

      setLookupState(result.state);
      setSocialLogin(result.social_login);
      Cookies.set('sessionToken', result.token, { expires: 1 / 24 });
      if (['obec', 'obec-past', 'ticket'].includes(result.state)) {
        navigate('/form');
      } else if (result.state === 'tutor-suspected') {
        navigate(`/veto/${result.blacklist_token}`);
      }

      setLookingUp(false);
    } catch (error) {
      console.error(new Error(`Error while trying to lookup by citizenId: ${error.message}`));
      setLookingUp(false);
    }
  };

  const [loggingIn, setLoggingIn] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [loginFail, setLoginFail] = useState(false);
  const _onTCASLogin = async () => {
    if (loggingIn) return;
    try {
      setLoggingIn(true);
      setLoginFail(false);
      const { access_token } = await callAPI({
        method: 'POST',
        url: '/tcas-past/login',
        body: {
          citizenId,
          password,
        },
      });
      Cookies.set('accessToken', access_token, { expires: 1 / 24 });
      setLoggingIn(false);
      setLoginFail(false);
      navigate('/profile/applicant-info');
    } catch (error) {
      console.error(new Error(`Error while trying to log in using TCAS account: ${error.message}`));
      setLoggingIn(false);
      setModalVisible(false);
      setLoginFail(true);
    }
  };

  const _onPasswordLogin = async () => {
    if (loggingIn) return;
    try {
      setLoggingIn(true);
      setLoginFail(false);
      const { access_token } = await callAPI({
        method: 'POST',
        url: '/applicants/login-with-password',
        body: {
          citizenId,
          password,
        },
      });
      Cookies.set('accessToken', access_token, { expires: 1 / 24 });
      Cookies.remove('email_timer_ends_at');
      Cookies.remove('mobile_timer_ends_at');
      // navigate('/profile');
      window.location.href = '/profile';
      setLoggingIn(false);
      setLoginFail(false);
    } catch (error) {
      console.error(new Error(`Error while trying to log in: ${error.message}`));
      setLoggingIn(false);
      setLoginFail(true);
    }
  };

  const _onLoginClick = () => {
    // if (!consentAccepted) return;
    if (['tcas-past', 'tcas'].includes(lookupState)) {
      setModalVisible(true);
    } else if (lookupState === 'registered' && (loginMethod === 'password' || !socialLogin)) {
      _onPasswordLogin();
    } else {
      _onLookup();
    }
  };

  const [fbLoggingIn, setFbLoggingIn] = useState(false);
  const _onFacebookLogin = async (accessToken) => {
    if (fbLoggingIn) return;
    try {
      setFbLoggingIn(true);
      const { access_token } = await callAPI({
        method: 'POST',
        url: '/socials/login-with-facebook',
        body: { accessToken },
      });
      Cookies.set('accessToken', access_token, { expires: 1 / 24 });
      Cookies.remove('email_timer_ends_at');
      Cookies.remove('mobile_timer_ends_at');
      // navigate('/profile');
      window.location.href = '/profile';
      setFbLoggingIn(false);
    } catch (error) {
      const errorMessage = _.get(error, 'json.error.message');
      alert(errorMessage);
      console.error(new Error(`Error while trying to login with facebook: ${errorMessage}`));
      setFbLoggingIn(false);
    }
  };

  const [ggLoggingIn, setGgLoggingIn] = useState(false);
  const _onGoogleLogin = async (idToken) => {
    if (ggLoggingIn) return;
    try {
      setGgLoggingIn(true);
      const { access_token } = await callAPI({
        method: 'POST',
        url: '/socials/login-with-google',
        body: { idToken },
      });
      Cookies.set('accessToken', access_token, { expires: 1 / 24 });
      Cookies.remove('email_timer_ends_at');
      Cookies.remove('mobile_timer_ends_at');
      // navigate('/profile');
      window.location.href = '/profile';
      setGgLoggingIn(false);
    } catch (error) {
      const errorMessage = _.get(error, 'json.error.message');
      alert(errorMessage);
      console.error(new Error(`Error while trying to login with google: ${errorMessage}`));
      setGgLoggingIn(false);
    }
  };

  const onPasswordChange = (e) => {
    const value = `${e.target.value || ''}`;
    if (value.includes(' ')) return;
    setPassword(value);
  };

  // const _onConsentAccept = () => {
  //   Cookies.set('consent_accepted_at', new Date().getTime(), { expires: 365 });
  //   setConsentAccepted(true);
  // };

  const loginDisabled =
    !citizenId.length ||
    lookupState === 'not-found' ||
    (lookupState === 'tcas-past' && !password) ||
    (lookupState === 'tcas' && !password);
  return (
    <div className="t-form">
      {!ticketSuccessEmail && (
        <>
          <h1>
            {t('ลงทะเบียน')} • {t('เข้าสู่ระบบ')}
          </h1>
          <p className="text-center">
            <img src="/assets/img/thai_id.svg" width="213" height="122" alt="Thai ID Card" />
          </p>
          {!lookupState && wrongCitizenId ? (
            <div className="t-box -error -half">
              <h2>{t('ผิดพลาด')}</h2>
              {t('รูปแบบเลขประจำตัวของท่านไม่ถูกต้อง กรุณาตรวจสอบใหม่อีกครั้ง')}
            </div>
          ) : lookupState === 'not-found' ? (
            <div className="t-box -error -half">
              <h2>{t('ผิดพลาด')}</h2>
              <div>
                {t(
                  'กรุณาตรวจสอบหมายเลขของท่านอีกครั้ง หากไม่พบข้อมูลของท่าน กรุณาแจ้งข้อมูลเพิ่มเติมเพื่อทำการตรวจสอบโดยละเอียด'
                )}
              </div>
            </div>
          ) : (
            lookupState !== 'registered' &&
            lookupState !== 'tcas-past' &&
            lookupState !== 'tcas' && (
              <div className="t-box -info -half">
                {t('เข้าระบบลงทะเบียนด้วย')} <b>{t('เลขประจำตัวประชาชน 13 หลัก')}</b> {t('หากไม่มี ให้ใช้')}{' '}
                <b>G Number</b> {t('หรือ')}
                <b> Passport Number</b>
              </div>
            )
          )}
          {lookupState === 'banned' && (
            <div className="t-box -error -half">
              <h2>{t('ถูกระงับ')}</h2>
              {t('บัญชีนี้ถูกระงับการใช้งาน หากมีข้อสงสัย กรุณาติดต่อทปอ.')}{' '}
              <a href="https://fb.me/CUPTmytcas">fb.me/CUPTmytcas</a>
            </div>
          )}
          {loginFail && (
            <div className="t-box -error -half">
              <h2>{t('ผิดพลาด')}</h2>
              {t('รหัสผ่านไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง')}
            </div>
          )}
          <div className="t-row">
            <div
              className={classNames('col required', {
                valid: lookupState && lookupState !== 'not-found',
                invalid: lookupState === 'not-found',
              })}
            >
              <label className="has-meta">
                <span>{t('เลขประจำตัว')}</span>
                {citizenIdType === 'citizen_id' && <span className="meta"> ({citizenTypeLabel}) </span>}
              </label>
              <input
                type="text"
                className={classNames({ invalid: lookupState === 'not-found' })}
                required
                value={citizenId}
                onChange={_onCitizenIdChange}
                onKeyPress={_onCitizenIdEnter}
              />
            </div>
            {(lookupState === 'tcas-past' ||
              lookupState === 'tcas' ||
              (lookupState === 'registered' && (loginMethod === 'password' || !socialLogin))) && (
              <div className="t-row">
                <div className="col required">
                  <label>{t('รหัสผ่าน')}</label>
                  <input
                    type={passwordHidden ? 'password' : 'text'}
                    placeholder={t('กรอกรหัสผ่าน')}
                    value={password}
                    onChange={onPasswordChange}
                    onKeyPress={_onPasswordEnter}
                  />
                  <i
                    className={classNames('i-viewpass cursor-pointer', { active: !passwordHidden })}
                    onClick={onPasswordHiddenChange}
                  />
                </div>
              </div>
            )}
          </div>
          {lookupState !== 'registered' ||
          (lookupState === 'registered' && (loginMethod === 'password' || !socialLogin)) ? (
            <div className="action">
              <p>
                <a
                  className={classNames('btn-main cursor-pointer', { disabled: loginDisabled })}
                  onClick={loginDisabled ? () => {} : _onLoginClick}
                >
                  {t('เข้าสู่ระบบ')}
                </a>
              </p>
              <p>
                <a className={classNames('btn-thaid cursor-pointer')} onClick={_onThaidLogin}>
                  <img alt="ThaiD" src="/assets/img/thaid.svg" width="32" height="32" />
                  <span>{t('ดำเนินการต่อด้วย ThaiD')}</span>
                </a>
              </p>
            </div>
          ) : (
            <p className="action">
              <div className="text-center">
                {socialLogin === 'facebook' ? (
                  <FacebookLogin onAuthenticate={_onFacebookLogin} />
                ) : (
                  socialLogin === 'google' && <GoogleLogin onAuthenticate={_onGoogleLogin} />
                )}
                {socialLogin && loginMethod === 'social' && (
                  <div>
                    <p className="t-or">
                      <span className="or">{t('หรือ')}</span>
                    </p>
                    <p>
                      <a className="_heading cursor-pointer" onClick={() => setLoginMethod('password')}>
                        {t('เข้าสู่ระบบด้วยรหัสผ่าน?')}
                      </a>
                    </p>
                  </div>
                )}
              </div>
            </p>
          )}

          {(lookupState === 'tcas-past' ||
            lookupState === 'tcas' ||
            (lookupState === 'registered' && (loginMethod === 'password' || !socialLogin))) && (
            <div className="text-center _heading">
              <Link to={`/${lookupState}/forgot-password`}>{t('ลืมรหัสผ่าน?')}</Link>
              {loginMethod === 'password' && (
                <span>
                  &nbsp;{t('หรือ')}{' '}
                  <a classNmae="cursor-pointer" onClick={() => setLoginMethod('social')}>
                    {t('เข้าสู่ระบบด้วยโซเชียล')}
                  </a>
                </span>
              )}
            </div>
          )}
        </>
      )}
      {registrable && !ticketSuccessEmail && lookupState === 'not-found' && (
        <details>
          <summary>{t('แจ้งข้อมูลเพิ่มเติม')}</summary>
          <div className="t-panel">
            <NotFoundTicket
              citizenId={citizenId}
              citizenTypeLabel={citizenTypeLabel}
              onSubmitSuccess={(email) => setTicketSuccessEmail(email)}
            />
          </div>
        </details>
      )}
      {Boolean(ticketSuccessEmail) && <NotFoundSubmitted email={ticketSuccessEmail} />}
      <ModalTermsAndConditions visible={modalVisible} onSubmit={_onTCASLogin} onClose={() => setModalVisible(false)} />
      {/* <ModalPDPAConsent visible={!consentAccepted} onSubmit={_onConsentAccept} /> */}
    </div>
  );
};

export default Lookup;
