/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useTranslation } from 'react-i18next';

const ModalOfferCancelConsent = ({ visible, onSubmit }) => {
  const { t } = useTranslation();
  if (!visible) return null;
  return (
    <div className="t-modal" style={{ visibility: 'visible', opacity: 1, pointerEvents: 'auto' }}>
      <div
        className="content"
        style={{ padding: '20px 30px', opacity: 1, height: 'initial', overflow: 'auto', textAlign: 'left' }}
      >
        <h1>ข้อกำหนดและเงื่อนไขในการสละสิทธิ์</h1>
        <p className="text-center">
          กรุณาอ่านรายละเอียดให้ครบถ้วน และคลิกปุ่ม <strong>"รับทราบ"</strong> ในส่วนล่างสุด
        </p>
        <br />
        <h2>การสละสิทธิ์</h2>
        <p>
          ผู้สมัครต้องดำเนินการสละสิทธิ์ให้แล้วเสร็จภายในวันเวลาที่กำหนด สามารถเลือกดำเนินการได้ 1 ครั้งจาก 3
          ช่วงเวลาเพื่อสมัครรอบต่อไปได้คือ
        </p>
        <div className='color-orange'>
        <p>
          <b>
            
              <u>ช่วงที่ 1</u> สามารถดำเนินการได้ภายในเวลา 23.59 น. ของวันที่ 7 กุมภาพันธ์ 2568 เพื่อสมัครรอบ 2 หรือรอบ 3
              หรือรอบ 4
            
          </b>
        </p>
        <p>
          <b>
            <u>ช่วงที่ 2</u> สามารถดำเนินการได้ภายในเวลา 23.59 น. ของวันที่ 4 พฤษภาคม 2568 เพื่อสมัครรอบ 3 หรือรอบ 4
          </b>
        </p>
        <p>
          <b>
            <u>ช่วงที่ 3</u> สามารถดำเนินการได้ภายในเวลา 23.59 น. ของวันที่ 26 พฤษภาคม 2568 เฉพาะผู้ที่ยืนยันสิทธิ์รอบ 3 Admission และไม่เคยสละสิทธิ์มาก่อนเท่านั้น
          </b>
          </p>
          </div>
        <p>
          <b>หมายเหตุ : หากสละสิทธิ์ครั้งที่ 2 จะไม่สามารถเข้าสู่กระบวนการสมัครและคัดเลือกในรอบต่อไปได้</b>
        </p>
        <p>โดย</p>
        <ol>
          <li>การสละสิทธิ์ต้องดำเนินการในระบบ TCAS68 เท่านั้น และขอยกเลิกการสละสิทธิ์ในภายหลังไม่ได้</li>
          <li>
            การแจ้งสละสิทธิ์ที่มหาวิทยาลัย โดยไม่ดำเนินการกดปุ่ม “สละสิทธิ์” ในระบบ TCAS68 ตามเวลาที่กำหนด
            ไม่ถือเป็นการสละสิทธิ์ในระบบ TCAS68 ซึ่งจะไม่สามารถสมัครคัดเลือกในรอบต่อไปได้
          </li>
        </ol>

        <div className="text-right">
          <small>
            <em>ปรับปรุงล่าสุดเมื่อวันที่ 4 กุมภาพันธ์ 2566</em>
          </small>
        </div>
        <hr />

        <div className="action">
          <a className="btn-main cursor-pointer" onClick={onSubmit}>
            {t('รับทราบ')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ModalOfferCancelConsent;
