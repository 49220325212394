/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

// Components
import AsyncSelect from 'react-select/async';
import ErrorBox from 'components/registrations/ErrorBox';

// Helpers
import { callAPI, getProvinceName, getDistrictName, getSubDistrictName, getNewestTicket } from 'helpers';
import { errorMessages, validateSchoolName, validatePostalCode, validateProvinceId, validateDistrictId, validateSubDistrictId, validateAddress, validateTranscriptFrontFile, validateTranscriptBackFile, validateRAEFile, validateGPAFile, validateGpax } from 'validations';

// Hooks
import { useTranslation } from 'react-i18next';
import useSchoolSelect from './education-hooks/useSchoolSelect';
import useNewSchool from './education-hooks/useNewSchool';
import useDocument from './education-hooks/useDocument';
import useProgram from './education-hooks/useProgram';

// Master data
import programMap from 'data/programs.json';
import postalCodes from 'data/postalCodes.json';
import countries from 'data/countries.json';

const postalCodeMap = _.keyBy(postalCodes, 'zipCode');
const ID = 'section-education';
const SectionEducation = forwardRef(({ initialState = {}, profile = {}, pendingTickets = [], collapsed, onCollapsedChange, waiting, editable }, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    selectedSchool,

    schoolCode,
    onSchoolCodeChange,

    loading,
    currentSchool,

    onOptionsLoad,
    defaultOptions,
    selectedOption,
    onSelectedOptionChange,
  } = useSchoolSelect(profile, pendingTickets);

  const { programCode, onProgramCodeChange, schoolYear, onSchoolYearChange, gpaxField, gpax, onGpaxChange } = useProgram(
    {
      program_code: profile.program_code || selectedSchool.program_code,
      school_year: profile.school_year || selectedSchool.school_year,
      gpax5_score: profile.gpax5_score || selectedSchool.gpax5_score,
      gpax6_score: profile.gpax6_score || selectedSchool.gpax6_score,
    },
    pendingTickets
  );

  const { onDocumentTypeChange, transcriptFrontFile, onTranscriptFrontFileChange, transcriptBackFile, onTranscriptBackFileChange, raeFile, onRAEFileChange, gpaFile, onGPAFileChange } = useDocument(profile, pendingTickets);

  const { valueOf } = getNewestTicket(pendingTickets, ['update_school', 'update_foreign_school']);
  const [schoolCountryType, setSchoolCountryType] = useState(valueOf('school_country_name') || profile.school_country_name ? 'inter' : 'thai');

  useEffect(() => setSchoolCountryType(programCode < 5 ? 'thai' : 'inter'), [programCode]);

  const { schoolName, onSchoolNameChange, postalCode, onPostalCodeChange, onPostalCodeEnChange, provinceId, onProvinceIdChange, districtId, onDistrictIdChange, subDistrictId, onSubDistrictIdChange, address, onAddressChange, countryState, onCountryStateChange, countryName, onCountryNameChange } = useNewSchool(profile, pendingTickets, schoolCountryType);

  const onCancel = () => {
    onSchoolNameChange({ target: { value: initialState.schoolName } });
    onSchoolCodeChange(initialState.schoolCode);
    onSelectedOptionChange(initialState.selectedOption);
    onProvinceIdChange({ target: { value: initialState.provinceId } });
    onProgramCodeChange({ target: { value: initialState.programCode } });
    onSchoolYearChange({ target: { value: initialState.schoolYear } });
    onGpaxChange({ target: { value: initialState.gpax } });
    onCollapsedChange(true, waiting);
  };

  const [mode, setMode] = useState((profile.schools || []).length > 1 ? 'multiple' : collapsed ? 'edit' : 'add');

  const [errors, setErrors] = useState([]);
  const [saving, setSaving] = useState(false);

  let noChanges = programCode === initialState.programCode && schoolYear === initialState.schoolYear && gpax === initialState.gpax;

  if (noChanges) {
    if (schoolCountryType === 'thai' && initialState.schoolCountryType === 'thai') {
      if (_.get(selectedOption, 'value') === 'other' && _.get(initialState, 'selectedOption.value') === 'other') {
        noChanges = schoolName === initialState.schoolName && postalCode === initialState.schoolPostalCode && provinceId === initialState.schoolProvinceId && districtId === initialState.schoolDistrictId && subDistrictId === initialState.schoolSubDistrictId && address === initialState.schoolAddress;
      } else {
        noChanges = _.get(selectedOption, 'value') === _.get(initialState, 'selectedOption.value');
      }
    } else {
      noChanges = schoolName === initialState.schoolName && address === initialState.schoolAddress && countryState === initialState.countryState && countryName === initialState.countryName && postalCode === initialState.schoolPostalCode;
    }
  }

  const onButtonClick = async () => {
    if (mode === 'multiple' && schoolCode !== 'other') {
      try {
        setSaving(true);
        await callAPI({
          method: 'PUT',
          url: '/applicants/me/schools',
          body: { schoolCode },
        });
        onCollapsedChange(true);
        setSaving(false);
        setMode('edit');
      } catch (error) {
        console.error(`Error while trying to save school data: ${error.message}`);
        setSaving(false);
      }
    } else {
      let _messages = errorMessages({
        gpax: validateGpax(gpax, `GPAX ${gpaxField[4]}`),
      });

      // School validation
      if (schoolCountryType === 'thai') {
        if (_.get(selectedOption, 'value') === 'other') {
          _messages = _messages.concat(
            errorMessages({
              schoolName: validateSchoolName(schoolName),
              postalCode: validatePostalCode(postalCode),
              provinceId: validateProvinceId(provinceId),
              districtId: validateDistrictId(districtId, provinceId),
              subDistrictId: validateSubDistrictId(subDistrictId, districtId, provinceId),
              address: validateAddress(address),
            })
          );
        }
      } else if (programCode !== '6') {
        _messages = _messages.concat(
          errorMessages({
            schoolName: validateSchoolName(schoolName),
            address: !address && 'จำเป็นต้องระบุที่อยู่',
            countryState: !countryState && 'จำเป็นต้องระบุรัฐ',
            countryName: !countryName && 'จำเป็นต้องระบุประเทศ',
            postalCode: !postalCode && 'จำเป็นต้องระบุรหัสไปรษณีย์',
          })
        );
      }

      // Document validation
      if (mode !== 'multiple' || (mode === 'multiple' && schoolCode === 'other')) {
        if (programCode < 5) {
          _messages = _messages.concat(
            errorMessages({
              transcriptFrontFile: validateTranscriptFrontFile(transcriptFrontFile),
              transcriptBackFile: validateTranscriptBackFile(transcriptBackFile),
            })
          );
        } else {
          _messages = _messages.concat(
            errorMessages({
              raeFile: validateRAEFile(raeFile),
              gpaFile: validateGPAFile(gpaFile),
            })
          );
        }
      }

      if (mode === 'edit' && noChanges) {
        onCancel();
      } else if (_messages.length) {
        setErrors(_messages);
        const container = document.getElementById(ID);
        if (container) {
          container.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        setErrors([]);

        if (saving) return;
        try {
          setSaving(true);
          const formData = new FormData();
          formData.append('programCode', programCode);
          formData.append('schoolYear', schoolYear || '2567');
          formData.append('gpax', gpax);
          formData.append('schoolCountryType', schoolCountryType);

          if (schoolCountryType === 'thai') {
            if (!selectedOption) {
              formData.append('schoolCode', 'other');
              formData.append('schoolName', schoolName);
              formData.append('schoolPostalCode', postalCode);
              formData.append('schoolProvinceId', provinceId);
              formData.append('schoolProvinceName', getProvinceName(provinceList, provinceId));
              formData.append('schoolDistrictId', districtId);
              formData.append('schoolDistrictName', getDistrictName(districtList, districtId));
              formData.append('schoolSubDistrictId', subDistrictId);
              formData.append('schoolSubDistrictName', getSubDistrictName(subDistrictList, subDistrictId));
              formData.append('schoolAddress', address);
            } else if (_.get(selectedOption, 'value')) {
              formData.append('schoolCode', _.get(selectedOption, 'value'));
            }
          } else {
            formData.append('schoolCode', 'other');
            formData.append('schoolName', schoolName);
            formData.append('schoolAddress', address);
            formData.append('schoolCountryState', countryState);
            formData.append('schoolCountryName', countryName);
            formData.append('schoolPostalCode', postalCode);
          }

          if (programCode < 5) {
            formData.append('transcriptFrontFile', transcriptFrontFile);
            formData.append('transcriptBackFile', transcriptBackFile);
          } else {
            formData.append('raeFile', raeFile);
            formData.append('gpaFile', gpaFile);
          }

          await callAPI({
            method: 'PUT',
            url: '/applicants/me/education',
            formData,
          });
          setMode('edit');
          onCollapsedChange(true, true);
          setSaving(false);
          dispatch({
            type: 'UPDATE_EDUCATION',
            result: { school_year: schoolYear || '2567', program_code: programCode },
          });
        } catch (error) {
          const errorMessages = (_.get(error, 'error.errors') || []).map(({ msg }) => msg);
          setErrors(errorMessages);
          const container = document.getElementById(ID);
          if (container) {
            container.scrollIntoView({ behavior: 'smooth' });
          }

          console.error(`Error while trying to save education data: ${errorMessages.toString()}`);
          setSaving(false);
        }
      }
    }
  };

  useImperativeHandle(ref, () => ({
    getState: () => {
      const { provinceList = [], districtList = [], subDistrictList = [] } = postalCodeMap[postalCode] || {};

      return {
        schoolCode,
        schoolCountryType,
        selectedOption,

        schoolName,
        schoolPostalCode: postalCode,
        schoolProvinceId: provinceId,
        schoolProvinceName: getProvinceName(provinceList, provinceId),
        schoolDistrictId: districtId,
        schoolDistrictName: getDistrictName(districtList, districtId),
        schoolSubDistrictId: subDistrictId,
        schoolSubDistrictName: getSubDistrictName(subDistrictList, subDistrictId),
        schoolAddress: address,
        countryState,
        countryName,

        programCode,
        schoolYear,
        gpax,

        transcriptFrontFile,
        transcriptBackFile,
        raeFile,
        gpaFile,
      };
    },
  }));

  const { provinceList = [], districtList = [], subDistrictList = [] } = postalCodeMap[postalCode] || {};
  const fDistrictList = districtList.filter((_data) => _data.provinceId === provinceId);
  const fSubDistrictList = subDistrictList.filter((_data) => _data.provinceId === provinceId && _data.districtId === districtId);

  const schoolTicket = getNewestTicket(pendingTickets, ['update_school', 'update_foreign_school']);

  if (collapsed) {
    const _schoolName = schoolName || schoolTicket.valueOf('school_name') || currentSchool.school_name || profile.school_name;
    const _schoolCountryName = countryName || schoolTicket.valueOf('school_country_name') || profile.school_country_name;
    return (
      <div className={classNames('t-box', { '-waiting': waiting })}>
        <h2 className='title'>
          {waiting && <span>{t('(รอตรวจสอบ)')}&nbsp;</span>}
          {t('ข้อมูลการศึกษา')}
          {editable && (
            <a className='cursor-pointer' onClick={() => onCollapsedChange(false, waiting)}>
              {t('ขอแก้ไขข้อมูล')}
            </a>
          )}
        </h2>
        <table>
          <tbody>
            <tr>
              <th>{t('ชื่อโรงเรียน')}</th>
              <td>{_schoolName}</td>
            </tr>
            {_schoolCountryName ? (
              <>
                <tr>
                  <th>Country</th>
                  <td>{_schoolCountryName}</td>
                </tr>
              </>
            ) : (
              <tr>
                <th>{t('จังหวัด')}</th>
                <td>{provinceId ? getProvinceName(provinceList, provinceId) : currentSchool.school_province_name}</td>
              </tr>
            )}
            <tr>
              <th>{t('หลักสูตร')}</th>
              <td>{t(programMap[programCode || selectedSchool.program_code])}</td>
            </tr>
            <tr>
              <th>{t('ปีการศึกษา')}</th>
              <td>{schoolYear || selectedSchool.school_year}</td>
            </tr>
            <tr>
              <th>GPAX {gpaxField[4]}</th>
              <td>{gpax}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  const isDisableSchoolYear = profile.source === 'obec' && schoolYear === 2567;

  return (
    <div id={ID} className='t-box -choose'>
      <h2 className='title'>
        {mode === 'multiple' ? t('เลือกข้อมูลโรงเรียนที่ใช้สมัคร') : mode === 'add' ? t('เพิ่มข้อมูลการศึกษา') : t('แก้ไขข้อมูลการศึกษา')}
        {mode === 'edit' && (
          <a className='cursor-pointer' onClick={onCancel}>
            {t('ยกเลิก')}
          </a>
        )}
      </h2>
      <ErrorBox isFull hidden={!errors.length} errors={errors} />
      {mode === 'multiple' && (
        <div>
          {(profile.schools || []).map((_school) => {
            // [At the beginning]
            const gpax_code = _school.program_code > 4 || _school.school_year < 2567 ? 6 : 5;
            
            // [When GPAX6 is ready]
            // const gpax_code = 6;

            return (
              <div key={_school.school_code}>
                <label>
                  <table>
                    <tbody>
                      <tr>
                        <td rowSpan='2'>
                          <input type='radio' name='school' checked={_school.school_code === schoolCode} onChange={() => onSchoolCodeChange(_school.school_code)} />
                        </td>
                        <th>{t('ชื่อโรงเรียน')}</th>
                        <td>{_school.school_name}</td>
                      </tr>
                      <tr>
                        <th>{t('หลักสูตร')}</th>
                        <td> {programMap[_school.program_code]}</td>
                      </tr>
                      <tr>
                        <td></td>
                        <th>{t('จังหวัด')}</th>
                        <td>{_school.school_province_name}</td>
                      </tr>
                      <tr>
                        <td></td>
                        <th>{t('ปีการศึกษา')}</th>
                        <td>{_school.school_year}</td>
                      </tr>
                      <tr>
                        <td></td>
                        <th>GPAX {gpax_code}</th>
                        <td>{_school[`gpax${gpax_code}_score`]}</td>
                      </tr>
                    </tbody>
                  </table>
                </label>
                <hr />
              </div>
            );
          })}
          <label>
            <table>
              <tbody>
                <tr>
                  <td rowSpan='2'>
                    <input type='radio' name='school' checked={schoolCode === 'other'} onChange={() => onSchoolCodeChange('other')} />
                  </td>
                  <th>{t('โรงเรียนอื่น')}</th>
                  <td>{t('แนบหลักฐานเพิ่มเติม')}</td>
                </tr>
              </tbody>
            </table>
          </label>
        </div>
      )}

      {(mode !== 'multiple' || (mode === 'multiple' && schoolCode === 'other')) && (
        <div>
          <div className='t-row'>
            <div className='col required'>
              <label>{t('หลักสูตร')}</label>
              <select required value={programCode} onChange={onProgramCodeChange}>
                <option></option>
                {Object.keys(programMap).map((programCode) => (
                  <option key={programCode} value={programCode}>
                    {programMap[programCode]}
                  </option>
                ))}
              </select>
            </div>
            <div className='col required'>
              <label>{t('ปีการศึกษา')} </label>
              <select disabled={isDisableSchoolYear} required value={schoolYear} onChange={onSchoolYearChange}>
                {Array(28)
                  .fill()
                  .map((_, i) => (
                    <option key={i} value={`${2567 - i}`}>
                      {2567 - i}
                    </option>
                  ))}
              </select>
            </div>
            <div className='col required'>
              {/* [At the beginning] */}
              <label>GPAX {programCode > 4 || schoolYear < 2567 ? 6 : 5}</label>
              {/* [When GPAX 6 is ready] */}
              {/* <label>GPAX 6</label> */}
              <input type='text' value={gpax} onChange={onGpaxChange} />
            </div>
          </div>
          <hr />
          <div className='t-row'>
            {programCode !== '6' && (
              <div className='col required'>
                <label>{t('ประเทศ')}</label>
                <select required value={schoolCountryType} disabled>
                  <option value='thai'>{t('ไทย')}</option>
                  <option value='inter'>{t('ต่างประเทศ')}</option>
                </select>
              </div>
            )}
            {schoolCountryType === 'thai' ? (
              <>
                {selectedOption ? (
                  <>
                    <div className='col required choose-school'>
                      <label>{t('ชื่อโรงเรียน')}</label>
                      <AsyncSelect placeholder='พิมพ์เพื่อค้นหาชื่อโรงเรียน' noOptionsMessage={() => <div style={{ textAlign: 'left' }}>พิมพ์เพื่อค้นหาชื่อโรงเรียน</div>} defaultOptions={defaultOptions} loadOptions={onOptionsLoad} value={selectedOption} onChange={onSelectedOptionChange} />
                    </div>
                    <div className='col -full required'>
                      <label>ที่อยู่โรงเรียน</label>
                      <input type='text' value={loading ? '' : `${currentSchool.school_tmbl_name || ''} ${currentSchool.school_amph_name || ''} ${currentSchool.school_province_name || ''} ${currentSchool.school_zipcode || ''}`.trim()} disabled />
                    </div>
                    <div className='col -full'>
                      <b>{t('หมายเหตุ')}</b>
                      <br />
                      1. {t('หากข้อมูลถูกต้องแล้ว ไม่ต้องส่งเอกสารเพิ่มเติม')}
                      <br />
                      2. {t('กรณีไม่พบข้อมูลโรงเรียนในรายการข้างต้น')}&nbsp;
                      {/* <a className="cursor-pointer" onClick={() => onSelectedOptionChange(null)}>
                          {t('คลิกที่นี่เพื่อแจ้งขอเพิ่มชื่อโรงเรียน')}
                        </a> */}
                      <a className='cursor-pointer' href='http://m.me/CUPTmytcas' target='_blank'>
                        {t('ติดต่อทีมงานทางเพจ myTCAS.com')}
                      </a>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='col required'>
                      <label>{t('ระบุชื่อโรงเรียน')}</label>
                      <input type='text' value={schoolName || ''} onChange={onSchoolNameChange} />
                    </div>

                    <div className='col required'>
                      <label>{t('รหัสไปรษณีย์')}</label>
                      <input type='text' value={postalCode} onChange={onPostalCodeChange} />
                    </div>
                    <div className='col required'>
                      <label>{t('จังหวัด')}</label>
                      {provinceList.length > 1 ? (
                        <select required value={provinceId} onChange={onProvinceIdChange}>
                          <option></option>
                          {(provinceList || []).map(({ provinceId, provinceName }) => (
                            <option key={provinceId} value={provinceId}>
                              {provinceName}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input type='text' disabled value={t(getProvinceName(provinceList, provinceId))} />
                      )}
                    </div>
                    <div className='col required'>
                      <label>{t('เขต/อำเภอ')}</label>
                      {districtList.length > 1 && fDistrictList.length > 0 ? (
                        <select required value={districtId} onChange={onDistrictIdChange}>
                          <option></option>
                          {fDistrictList.map(({ districtId, districtName }) => (
                            <option key={districtId} value={districtId}>
                              {t(districtName)}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input type='text' disabled value={t(getDistrictName(districtList, districtId))} />
                      )}
                    </div>
                    <div className='col required'>
                      <label>{t('แขวง/ตำบล')}</label>
                      {subDistrictList.length > 1 && fSubDistrictList.length > 0 ? (
                        <select required value={subDistrictId} onChange={onSubDistrictIdChange}>
                          <option></option>
                          {fSubDistrictList.map(({ subDistrictId, subDistrictName }) => (
                            <option key={subDistrictId} value={subDistrictId}>
                              {subDistrictName}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input type='text' disabled value={t(getSubDistrictName(subDistrictList, subDistrictId))} />
                      )}
                    </div>
                    <div className='col required'>
                      <label>ที่อยู่โรงเรียน</label>
                      <input type='text' value={address} onChange={onAddressChange} />
                    </div>
                  </>
                )}
              </>
            ) : (
              programCode === '5' && (
                <>
                  <div className='col required'>
                    <label>{t('ชื่อโรงเรียน')}</label>
                    <input type='text' value={schoolName} onChange={onSchoolNameChange} />
                  </div>
                  <div className='col required'>
                    <label>{t('ที่อยู่')}</label>
                    <input type='text' value={address} onChange={onAddressChange} />
                  </div>
                  <div className='col required'>
                    <label>{t('รัฐ')}</label>
                    <input type='text' value={countryState} onChange={onCountryStateChange} />
                  </div>
                  <div className='col required'>
                    <label>{t('ประเทศ')}</label>
                    <select required value={countryName} onChange={onCountryNameChange}>
                      <option></option>
                      {countries.map((countryName, index) => (
                        <option key={index} value={countryName}>
                          {countryName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='col required'>
                    <label>{t('รหัสไปรษณีย์')}</label>
                    <input type='text' value={postalCode} onChange={onPostalCodeEnChange} />
                  </div>
                </>
              )
            )}
          </div>

          {programCode !== '6' && <hr />}

          {(mode === 'add' || !noChanges) && (
            <>
              <label>{t('เอกสารหลักฐานการศึกษา')}</label>
              <div className='inline-group'>
                {programCode < 5 ? (
                  <>
                    <input type='radio' name='transcript' id='thai_transcript' checked onChange={() => onDocumentTypeChange('transcript')} />
                    <label htmlFor='thai_transcript'>{t('ใบปพ. 1')}</label>
                  </>
                ) : (
                  <>
                    <input type='radio' name='transcript' id='inter_transcript' checked onChange={() => onDocumentTypeChange('certificate')} />
                    <label htmlFor='inter_transcript'>
                      {t('ใบรับรองเทียบวุฒิการศึกษาและใบเทียบผลการเรียนเฉลี่ยสะสม')} ({t('สามารถยื่นคำร้องขอได้ที่')}
                      &nbsp;{' '}
                      <a href='https://hsces.atc.chula.ac.th/' target='_blank'>
                        <b>
                          <u>{t('เว็บไซต์นี้')}</u>
                        </b>
                      </a>
                      )
                    </label>
                  </>
                )}
              </div>

              <div className='t-row' style={{ display: programCode < 5 ? 'flex' : 'none' }}>
                <div className='col required'>
                  <div className='transcript'>
                    <img src='/assets/img/transcript_thai_1.png' alt='รูปด้านหน้าใบปพ.1' />
                    {t('รูปด้านหน้าใบปพ.1')}
                  </div>
                  <label>{t('อัปโหลดรูปด้านหน้า')}</label>
                  <input name='transcript-front' type='file' accept='image/*' onChange={onTranscriptFrontFileChange} />
                </div>
                <div className='col required'>
                  <div className='transcript'>
                    <img src='/assets/img/transcript_thai_2.png' alt='รูปด้านหลังใบปพ.1' />
                    {t('รูปด้านหลังใบปพ.1 (กรณีมีหน้าเดียว ให้อัปโหลดรูปหน้าแรกแทน')}
                  </div>
                  <label>{t('อัปโหลดรูปด้านหลัง')}</label>
                  <input name='transcript-back' type='file' accept='image/*' onChange={onTranscriptBackFileChange} />
                </div>
              </div>
              <div className='t-row' style={{ display: programCode < 5 ? 'none' : 'flex' }}>
                <div className='col required'>
                  <div className='transcript'>
                    <img src='/assets/img/transcript_en_1.png' alt='รูปใบเทียบวุฒิการศึกษา' />
                    {t('รูปใบเทียบวุฒิการศึกษา')}
                  </div>
                  <label>{t('อัปโหลดรูป')}</label>
                  <input name='rae' type='file' accept='image/*' onChange={onRAEFileChange} />
                </div>
                <div className='col required'>
                  <div className='transcript'>
                    <img src='/assets/img/transcript_en_2.png' alt='รูปใบเทียบผลการเรียนเฉลี่ยสะสม' />
                    {t('รูปใบเทียบผลการเรียนเฉลี่ยสะสม (GPAX)')}
                  </div>
                  <label>{t('อัปโหลดรูป')}</label>
                  <input name='gpa' type='file' accept='image/*' onChange={onGPAFileChange} />
                </div>
                <div className='t-col -full'>
                  <b>หมายเหตุ:</b> ในกรณีที่ยังไม่มีเอกสารใบรับรองเทียบวุฒิการศึกษาและใบเทียบผลการเรียนเฉลี่ยสะสม ให้ใช้ใบแทนใบรับรองเทียบวุฒิการศึกษา โดยขอได้ที่{' '}
                  <a href='https://hsces.atc.chula.ac.th/' target='_blank'>
                    <b>
                      <u>เว็บไซต์นี้</u>
                    </b>
                  </a>{' '}
                  อัปโหลดเข้าระบบทั้ง 2 จุด และกรอกคะแนน <b>GPAX6 เป็น 0.00</b> เท่านั้น เพื่อให้ทีมงาน TCAS68 อนุมัติข้อมูลการศึกษาก่อน
                </div>
              </div>
              <hr />
            </>
          )}
        </div>
      )}
      <a className='btn-main cursor-pointer' onClick={onButtonClick}>
        {t('ยืนยันข้อมูลด้านการศึกษา')}
      </a>
    </div>
  );
});

export default SectionEducation;
