import { useState, useEffect } from 'react';
import { callAPI } from 'helpers';
import _ from 'lodash';

// Hooks
import { useDispatch } from 'react-redux';

const useAnnouncement = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [{ admissions, replies, timestamp }, setData] = useState({ admissions: [], replies: [], timestamp: null });
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [courses, { admissions, replies, timestamp }, { result }] = await Promise.all([
          callAPI({ fullURL: 'https://tcas68.s3.ap-southeast-1.amazonaws.com/mytcas/courses_round3.json?timestamp' }),
          callAPI({ url: '/applicants/me/admission-result' }),
          callAPI({ url: '/applicants/me/admissions' })
        ]);

        dispatch({ type: 'UPDATE_COURSES', courses });
        setData({
          admissions,
          replies: replies.map(reply => ({ ...reply, ds_priorities: (reply.ds_priorities || []).sort((a, b) => a - b).slice() })),
          timestamp
        });

        setLogs(_.reverse(_.cloneDeep(result)));
        setLoading(false);
      } catch (error) {
        console.log({ error });
        setLoading(false);
      }
    }

    fetchData();
  }, [dispatch]);

  return {
    loading,
    admissions,
    logs,
    replies,
    timestamp
  }
}

export default useAnnouncement;