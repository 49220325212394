/* eslint-disable no-useless-escape */
import _ from 'lodash';
import moment from 'moment';
import provinces from 'data/provinces';
import districts from 'data/districts';
import subDistricts from 'data/subDistricts';
import postalCodes from 'data/postalCodes.min';

const today = moment().format('YYYY-MM-DD');
const _isValidEmail = (email) =>
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

export const errorMessages = (errors = {}) =>
  Object.keys(errors)
    .reduce((array, key) => array.concat(errors[key]), [])
    .filter((msg) => msg);

export const validateEmail = (email) => {
  if (!email) return 'จำเป็นต้องระบุอีเมล';
  if (!_isValidEmail(email)) return 'รูปแบบอีเมลไม่ถูกต้อง';
  return null;
};

export const validateMobile = (mobile) => {
  if (!mobile) return 'จำเป็นต้องระบุเบอร์โทรศัพท์มือถือ';
  if (mobile.length !== 10) return 'รูปแบบเบอร์โทรศัพท์มือถือไม่ถูกต้อง';
  return null;
};

export const validateTitle = (title) => {
  if (!title) return 'จำเป็นต้องระบุคำนำหน้าชื่อ';
  if (!['นาย', 'นางสาว'].includes(title)) return 'คำนำหน้าชื่อ เป็นได้แค่ นาย หรือ นางสาว เท่านั้น';
  return null;
};

export const validateFirstName = (firstName) => {
  if (!firstName) return 'จำเป็นต้องระบุชื่อ';
  return null;
};

export const validateLastName = (lastName) => {
  if (!lastName) return 'จำเป็นต้องระบุนามสกุล';
  return null;
};

export const validateBirthDate = (birthDate) => {
  if (!birthDate) return 'จำเป็นต้องระบุวันเกิด';
  return null;
};

export const validateSpecialRequest = (specialRequest, visibility) => {
  if (!visibility) return null;
  if (!specialRequest) return 'จำเป็นต้องระบุความต้องการความช่วยเหลือพิเศษ';
  return null;
};

export const validatePassword = (password) =>
  _.compact([
    !/(?=.{8,})/.test(password) ? 'รหัสผ่านต้องมีจำนวนไม่น้อยกว่า 8 หลัก' : undefined,
    !/(?=.*[A-Z])/.test(password) ? 'รหัสผ่านต้องมีตัวอักษรภาษาอังกฤษพิมพ์ใหญ่ A - Z อย่างน้อย 1 ตัว' : undefined,
    !/(?=.*[a-z])/.test(password) ? 'รหัสผ่านต้องมีตัวอักษรภาษาอังกฤษพิมพ์เล็ก a - z อย่างน้อย 1 ตัว' : undefined,
    !/(?=.*[0-9])/.test(password) ? 'รหัสผ่านต้องมีตัวเลข 0 - 9 อย่างน้อย 1 ตัว' : undefined,
  ]);

export const validateCitizenCardFile = (file) => {
  if (!file) return 'จำเป็นต้องอัปโหลดรูปด้านหน้าบัตรประจำตัว';
  return null;
};

export const validateSelfieFile = (file) => {
  if (!file) return 'จำเป็นต้องอัปโหลดรูปถ่ายคู่บัตรประจำตัว';
  return null;
};

export const validateSpecialRequestFile = (file) => {
  if (!file) return 'จำเป็นต้องอัปโหลดรูปหลักฐานต้องการความช่วยเหลือพิเศษ';
  return null;
}

export const validateTranscriptFrontFile = (file) => {
  if (!file) return 'จำเป็นต้องอัปโหลดรูปด้านหน้าใบปพ.1';
  return null;
};

export const validateTranscriptBackFile = (file) => {
  if (!file) return 'จำเป็นต้องอัปโหลดรูปด้านหลังใบปพ.1';
  return null;
};

export const validateRAEFile = (file) => {
  if (!file) return 'จำเป็นต้องอัปโหลดรูปใบเทียบวุฒิการศึกษา';
  return null;
};

export const validateGPAFile = (file) => {
  if (!file) return 'จำเป็นต้องอัปโหลดรูปใบเทียบผลการเรียนเฉลี่ยสะสม (GPAX)';
  return null;
};

export const validateGatPatFile = (file) => {
  if (!file) return 'จำเป็นต้องอัปโหลดรูปใบเทียบคะแนน GAT PAT';
  return null;
};

export const validate9SubFile = (file) => {
  if (!file) return 'จำเป็นต้องอัปโหลดรูปใบคะแนนวิชาสามัญ';
  return null;
};

export const validateGedFile = (file) => {
  if (!file) return 'จำเป็นต้องอัปโหลดรูป GED Transcript';
  return null;
};

export const validateGedDate = (date) => {
  if (!date) return 'จำเป็นต้องระบุวันที่สอบ';
  if (date.localeCompare(today) === 1) return 'ไม่อนุญาตให้กรอกวันที่ในอนาคต';
  return null;
};

export const validateVnetFile = (file) => {
  if (!file) return 'จำเป็นต้องอัปโหลดรูปใบคะแนน V-NET';
  return null;
};

export const validateSchoolName = (schoolName) => {
  if (!schoolName) return 'จำเป็นต้องระบุชื่อโรงเรียน';
  return null;
};

export const validatePostalCode = (postalCode) => {
  if (!postalCode) return 'จำเป็นต้องระบุรหัสไปรษณีย์';
  if (!postalCodes.includes(postalCode)) return `รหัสไปรษณีย์ผิด`;
  return null;
};

export const validateProvinceId = (provinceId) => {
  if (!provinceId) return 'จำเป็นต้องระบุจังหวัด';
  const province = _.find(provinces, (province) => province.provinceId === provinceId);
  if (!province) return 'กรุณาเลือกจังหวัดใหม่อีกครั้ง';
  return false;
};

export const validateDistrictId = (districtId, provinceId) => {
  if (!districtId) return `จำเป็นต้องระบุเขต/อำเภอ`;
  const district = _.find(districts, (district) => district.districtId === districtId);
  if (!district) return `กรุณาเลือกเขต/อำเภอใหม่อีกครั้ง`;
  if (district.provinceId !== provinceId) return `กรุณาเลือกเขต/อำเภอใหม่อีกครั้ง`;
  return false;
};

export const validateSubDistrictId = (subDistrictId, districtId, provinceId) => {
  if (!subDistrictId) return `จำเป็นต้องระบุแขวง/ตำบล`;
  const subDistrict = _.find(subDistricts, (subDistrict) => subDistrict.subDistrictId === subDistrictId);
  if (!subDistrict) return `กรุณาเลือกแขวง/ตำบลใหม่อีกครั้ง`;
  if (subDistrict.districtId !== districtId) return `กรุณาเลือกแขวง/ตำบลใหม่อีกครั้ง`;
  if (subDistrict.provinceId !== provinceId) return `กรุณาเลือกแขวง/ตำบลใหม่อีกครั้ง`;
  return null;
};

export const validateAddress = (address) => {
  if (!address) return 'จำเป็นต้องระบุที่อยู่';
  return null;
};

export const validateGpax = (gpax, fieldName) => {
  if (!gpax) return `จำเป็นต้องระบุ ${fieldName}`;
  if (gpax < 0) return `${fieldName} มีค่าได้ต่ำสุด 0.00`;
  if (gpax > 4) return `${fieldName} มีค่าได้สูงสุด 4.00`;
  return null;
};

export const validateWeight = (weight) => {
  if (!weight) return 'จำเป็นต้องระบุน้ำหนัก';
  if (weight < 20) return 'น้ำหนักต้องมีค่าไม่น้อยกว่า 20 กิโลกรัม';
  if (weight > 200) return 'น้ำหนักต้องมีค่าไม่มากกว่า 200 กิโลกรัม';
  return null;
};

export const validateHeight = (height) => {
  if (!height) return 'จำเป็นต้องระบุส่วนสูง';
  if (height < 100) return 'ส่วนสูงต้องมีค่าไม่น้อยกว่า 100 เซนติเมตร';
  if (height > 250) return 'ส่วนสูงต้องมีค่าไม่มากกว่า 250 เซนติเมตร';
  return null;
};

const _characters = ['่', '้', '๊', '๋', '็', '์', 'ํ', 'ิ', 'ี', 'ื'];
export const notAllowed = (fieldType, _value = '') => {
  const value = `${_value || ''}`;
  switch (fieldType) {
    case 'email':
      if (/[ก-๙]/g.test(value)) return true;
      if (value.includes(' ')) return true;
      return false;
    case 'mobile':
      if (value.length > 10) return true;
      if (value && isNaN(value)) return true;
      if (value < 0) return true;
      return false;
    case 'postalCode':
      if (value.includes(' ')) return true;
      if (value.length > 5) return true;
      if (value === 0) return true;
      return false;
    case 'postalCodeEn':
      if (value.includes(' ')) return true;
      if (value.length > 10) return true;
      if (value === 0) return true;
      return false;
    case 'textTH':
      if (_characters.some((_char) => value.startsWith(_char) || value.includes(`${_char}${_char}`))) {
        return true;
      }
      return false;
    case 'number':
      if (value && isNaN(value)) return true;
      if (value < 0) return true;
      return false;
    case 'gpax':
      if (value && isNaN(value)) return true;
      if (value < 0) return true;
      if (value > 4) return true;
      if (value.length > 4) return true;
      return false;
    case 'weight':
      if (value && isNaN(value)) return true;
      if (value.includes(' ')) return true;
      if (value < 0) return true;
      if (value > 200) return true;
      return false;
    case 'height':
      if (value && isNaN(value)) return true;
      if (value.includes(' ')) return true;
      if (value < 0) return true;
      if (value > 250) return true;
      return false;
    default:
      return false;
  }
};
