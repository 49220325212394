/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useCallback } from 'react';
import _ from 'lodash';
import { callAPI } from 'helpers';
import cn from 'classnames';

// Hooks
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';

// Components
import EmailVerification from 'components/otp/EmailVerification';
import MobileVerification from 'components/otp/MobileVerification';

const SetZero = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});

  const [loading, setLoading] = useState(false);
  const [admissions, setAdmissions] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [verifyMethod, setVerifyMethod] = useState();

  const onEmailOtpSuccess = useCallback(() => {
    dispatch({ type: 'UPDATE_USER', result: { ...profile, email_is_verified: 1 } });
    navigate(-1);
  }, [dispatch, profile, navigate]);

  const onSmsOtpSuccess = useCallback(() => {
    dispatch({ type: 'UPDATE_USER', result: { ...profile, telephone_is_verified: 1 } });
    navigate(-1);
  }, [dispatch, profile, navigate]);

  useEffect(() => {
    const fetchAdmissions = async () => {
      try {
        setLoading(true);
        const { result } = await callAPI({ url: '/applicants/me/admissions' });
        if (_.get(_.last(result.filter(({ set_zero }) => !set_zero)), 'confirm_id') < 3) navigate('/profile');
        setAdmissions(result);
        setLoading(false);
      } catch (error) {
        console.log({ error });
        setLoading(false);
      }
    };

    fetchAdmissions();
  }, [navigate]);

  useEffect(() => {
    const classNames = document.getElementById('root').className.split(' ');
    if (loading) {
      document.getElementById('root').className = _.uniq(classNames.concat(['is-loading'])).join(' ');
    } else {
      document.getElementById('root').className = classNames.filter((name) => name !== 'is-loading').join(' ');
    }
  }, [loading]);

  const recentAdmission = _.last(admissions) || {};
  return (
    <main className="site-body">
      <div className="t-form">
        {loading && <div className="t-loading" />}
        <div className="r-reset-bg"></div>
        <div className="r-reset">
          {recentAdmission.status === 'pending' ? (
            <PendingState recentAdmission={recentAdmission} />
          ) : currentStep === 1 ? (
            <Step1 onNext={() => setCurrentStep(2)} />
          ) : currentStep === 2 ? (
            <Step2
              mobile={profile.telephone}
              email={profile.email}
              verifyMethod={verifyMethod}
              setVerifyMethod={setVerifyMethod}
              onNext={() => setCurrentStep(3)}
            />
          ) : currentStep === 3 && verifyMethod === 'email' ? (
            <div className="content-scroll">
              <div className="text-center">
                <i className="i-danger"></i>
              </div>
              <h1>{t('ดำเนินการสมัครใหม่')} (Set Zero)</h1>
              <h2 className="sub t-box text-center -error">กรอกรหัสยืนยัน (OTP) เพื่อยืนยันการทำรายการ</h2>
              <EmailVerification studentType="set-zero-round3" email={profile.email} onSuccess={onEmailOtpSuccess} />
            </div>
          ) : currentStep === 3 && verifyMethod === 'mobile' ? (
            <div className="content-scroll">
              <div className="text-center">
                <i className="i-danger"></i>
              </div>
              <h1>{t('ดำเนินการสมัครใหม่')} (Set Zero)</h1>
              <h2 className="sub t-box text-center -error">กรอกรหัสยืนยัน (OTP) เพื่อยืนยันการทำรายการ</h2>
              <MobileVerification
                studentType="set-zero-round3"
                mobile={profile.telephone}
                onSuccess={onSmsOtpSuccess}
              />
            </div>
          ) : null}
        </div>
      </div>
    </main>
  );
};

export default SetZero;

const PendingState = ({ recentAdmission }) => {
  const { t } = useTranslation();

  const [invoiceOpening, setInvoiceOpening] = useState(false);
  const onInvoicePdfOpen = async () => {
    if (invoiceOpening) return;
    try {
      setInvoiceOpening(true);
      const ua = window.navigator.userAgent;
      const { pdf } = await callAPI({
        genPdf: true,
        method: 'POST',
        url: '/apply-round3/invoice-pdf',
      });

      if (ua.includes('Safari')) {
        window.location.href = pdf;
      } else {
        window.open(pdf, '_blank');
      }

      setInvoiceOpening(false);
    } catch (error) {
      console.error(`Error while trying to download invoice pdf: ${error.message}`);
      setInvoiceOpening(false);
    }
  };

  return (
    <div className="content-scroll">
      <div className="text-center">
        <i className="i-danger"></i>
      </div>
      <h1>{t('ดำเนินการสมัครใหม่')} (Set Zero)</h1>
      <div className="t-box -error">
        <b>
          {t(
            'ท่านยังไม่ได้ชำระเงินค่าสมัครครั้งก่อนหรือยังชำระเงินไม่ครบจำนวน กรุณาชำระเงินค่าสมัครที่ยังค้างชำระอยู่ให้เรียบร้อยก่อน จึงจะสามารถยกเลิกการสมัครแล้วดำเนินการสมัครใหม่ (Set Zero) ได้'
          )}
        </b>
      </div>
      <div className="t-summary">
        <dl>
          <dt>{t('จำนวนอันดับสาขาวิชา (เลือกได้สูงสุด 10 อันดับ)')}</dt>
          <dd>
            <b>{(recentAdmission.program_projects || []).length}</b> {t('อันดับ')}
          </dd>
        </dl>
        <dl>
          <dt className="color-red">{t('ยอดค้างชำระทั้งหมด')}</dt>
          <dd className="color-red">
            <b>{recentAdmission.payment_amount.toFixed(2)}</b> {t('บาท')}
          </dd>
        </dl>
      </div>
      <div className="text-center">
        <h4 className="h-status  color-red">
          {t('สถานะ')}: {t('รอการชำระเงิน')}
        </h4>
        <p>
          <a onClick={onInvoicePdfOpen} className="btn-main -large cursor-pointer">
            {t('พิมพ์ใบแจ้งชำระเงิน')} {recentAdmission.payment_amount.toFixed(2)} {t('บาท')}
          </a>
        </p>
        <h4>
          <a className="cursor-pointer" onClick={() => window.location.reload()}>
            <i className="i-reload" />
            {t('คลิกเพื่อตรวจสอบสถานะล่าสุด')}
          </a>
        </h4>
      </div>
    </div>
  );
};

const Step1 = ({ onNext }) => {
  const { t } = useTranslation();

  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const [checked7, setChecked7] = useState(false);
  const [allChecked, setAllChecked] = useState(false);

  useEffect(() => {
    setAllChecked(checked1 && checked2 && checked3 && checked4 && checked5 && checked6 && checked7);
  }, [checked1, checked2, checked3, checked4, checked5, checked6, checked7]);

  return (
    <>
      <div className="content-scroll">
        <div className="text-center">
          <i className="i-danger"></i>
        </div>
        <h1>{t('ดำเนินการสมัครใหม่')} (Set Zero)</h1>
        <div className="t-box -error">
          <b>
            {t(
              'หากท่านต้องการเปลี่ยนแปลงสาขาวิชา/อันดับที่เลือกสมัคร ท่านจะต้องยกเลิกการสมัครเดิม แล้วจึงดำเนินการสมัครใหม่'
            )}{' '}
            (Set Zero) {t('ได้ โดยการสมัครแต่ละครั้งจะมีค่าดำเนินการ และ')}{' '}
            <u>{t('ไม่สามารถใช้สิทธิ์รับเงินสนับสนุนค่าสมัครคัดเลือกฯ รอบที่ 3 Admission ได้อีก')}</u>{' '}
            {t('ซึ่งมีเงื่อนไขดังนี้')}
          </b>
        </div>
        <h4 className="text-center">
          {t('กรุณาอ่านและ')} <b>{t('คลิกยอมรับ')}</b> {t('ข้อกำหนดและเงื่อนไขทุกข้อ ก่อนคลิก')}{' '}
          <b>{t('สมัครใหม่')} (Set Zero)</b>
        </h4>
        <ul className="r-consent">
          <li>
            <label>
              <input type="checkbox" checked={checked1} onChange={(e) => setChecked1(e.target.checked)} />
              <span>
                1.{' '}
                {t(
                  'กรณีที่ท่านยังไม่ได้ชำระเงินค่าสมัครครั้งก่อนหรือยังชำระเงินไม่ครบจำนวนท่านต้องชำระเงินค่าสมัครที่ยังค้างชำระอยู่ให้เรียบร้อยก่อน จึงจะสามารถยกเลิก การสมัคร แล้วเริ่มสมัครใหม่ (Set Zero) ได้'
                )}
              </span>
            </label>
          </li>
          <li>
            <label>
              <input type="checkbox" checked={checked2} onChange={(e) => setChecked2(e.target.checked)} />
              <span>
                2.{' '}
                {t(
                  'ท่านสามารถยืนยันการเลือกสาขาวิชาในอันดับต่าง ๆ ได้ 3 ครั้ง ต่อ 1 การสมัคร หากยืนยันการเลือกครบ 3 ครั้งแล้วท่านจะไม่สามารถดำเนินการเปลี่ยนแปลงสาขาวิชาได้อีก ถ้าต้องการทำการสมัครใหม่ (Set Zero)'
                )}
              </span>
            </label>
          </li>
          <li>
            <label>
              <input type="checkbox" checked={checked3} onChange={(e) => setChecked3(e.target.checked)} />
              <span>
                3.{' '}
                {t(
                  'ถ้าท่านต้องการเปลี่ยนแปลงการยืนยันการเลือกสาขาวิชาในอันดับต่าง ๆ อีก ท่านต้องยกเลิก การสมัครเดิมแล้วดำเนินการสมัครใหม่ (Set Zero) รวมทั้งชำระเงินใหม่อีกครั้ง'
                )}
              </span>
            </label>
          </li>
          <li>
            <label>
              <input type="checkbox" checked={checked4} onChange={(e) => setChecked4(e.target.checked)} />
              <span>
                4.{' '}
                {t(
                  'ใบสมัครก่อนหน้าจะถือเป็นโมฆะ และไม่สามารถย้อนกลับไปใช้ใบสมัครก่อนหน้าได้ นอกจากเลือกสมัครสาขาวิชาในอันดับต่าง ๆ ให้เหมือนเดิม'
                )}
              </span>
            </label>
          </li>
          <li>
            <label>
              <input type="checkbox" checked={checked5} onChange={(e) => setChecked5(e.target.checked)} />
              <span>
                5.{' '}
                {t(
                  'ผู้สมัครสามารถสมัครได้หลายครั้ง โดยไม่จำกัดในช่วงเวลาที่กำหนด ซึ่งระบบจะยึดข้อมูลการสมัครครั้งล่าสุดที่ดำเนินการเรียบร้อยแล้วในการประมวลผลเท่านั้น'
                )}
              </span>
            </label>
          </li>
          <li>
            <label>
              <input type="checkbox" checked={checked6} onChange={(e) => setChecked6(e.target.checked)} />
              <span>
                6.{' '}
                {t(
                  'โดยการสมัครแต่ละครั้งสามารถยืนยันการเปลี่ยนแปลงสาชาวิชาได้สูงสุด 3 ครั้ง (นับจำนวนครั้งที่ดำเนินการยืนยันการเลือกสาขาสำเร็จด้วยรหัส OTP)'
                )}
              </span>
            </label>
          </li>
          <li>
            <label>
              <input type="checkbox" checked={checked7} onChange={(e) => setChecked7(e.target.checked)} />
              <span>
                7.{' '}
                {t(
                  'ผู้สมัครจะไม่สามารถใช้สิทธิ์รับเงินสนับสนุนค่าสมัครคัดเลือกฯ รอบที่ 3 Admission ได้อีก จะต้องดำเนินการจ่ายค่าสมัครคัดเลือกใหม่ด้วยตนเอง'
                )}
              </span>
            </label>
          </li>
          <li>
            <label>
              <input type="checkbox" checked={allChecked} />
              <b>{t('ข้าพเจ้าได้อ่านและยอมรับข้อกำหนดและเงื่อนไขดังกล่าวสำหรับกรณี "สมัครใหม่ (Set Zero)')}"</b>
            </label>
          </li>
        </ul>
      </div>
      <div className="content-bottom">
        <Link className="btn-cancel" to="/profile">
          <span className="icon">×</span> {t('ยกเลิก')}
        </Link>
        <a
          className={cn('btn-main -danger', {
            'cursor-pointer': allChecked,
            disabled: !allChecked,
          })}
          onClick={allChecked ? onNext : () => {}}
        >
          {t('สมัครใหม่')} (Set Zero)
        </a>
      </div>
    </>
  );
};

const Step2 = ({ mobile = '', email = '', verifyMethod, setVerifyMethod, onNext }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formattedMobile = [mobile.slice(0, 3), mobile.slice(3, 6), mobile.slice(-4)].join(' ');
  const disabled = !verifyMethod;
  return (
    <div className="content-scroll">
      <div className="text-center">
        <i className="i-danger"></i>
      </div>
      <h1>{t('ดำเนินการสมัครใหม่')} (Set Zero)</h1>
      <h2 className="sub">{t('กรุณาเลือกช่องทางในการรับรหัส OTP เพื่อยืนยัน')}</h2>
      <div className="t-box text-center -error">
        <b>{t('ทำการยกเลิกการสมัครเดิมและยืนยันการสมัครใหม่')} (Set Zero)</b>
      </div>
      <ul className="r-radio">
        <li>
          <label>
            <input
              type="radio"
              name="confirm"
              checked={verifyMethod === 'mobile'}
              onClick={() => setVerifyMethod('mobile')}
            />
            <strong>{t('ยืนยันการสมัครใหม่ผ่านเบอร์มือถือ')}</strong>
            <small>
              {t('หมายเลข')} {formattedMobile}
            </small>
          </label>
        </li>
        <li>
          <label>
            <input
              type="radio"
              name="confirm"
              checked={verifyMethod === 'email'}
              onClick={() => setVerifyMethod('email')}
            />
            <strong>{t('ยืนยันการสมัครใหม่ผ่านอีเมล')}</strong>
            <small>{email}</small>
          </label>
        </li>
      </ul>
      <div className="action">
        <a className={cn('btn-main', { disabled, 'cursor-pointer': !disabled })} onClick={onNext}>
          {t('ส่ง OTP เพื่อยืนยันการสมัคร')}
        </a>
        <h4>
          <a className="color-gray cursor-pointer" onClick={() => navigate(-1)}>
            {t('ยกเลิก')}
          </a>
        </h4>
      </div>
      <p className="text-center _small">
        {t('หากมีการเปลี่ยนแปลงเบอร์มือถือหรืออีเมล')}
        {t('สามารถแก้ไขได้ที่หน้า')}{' '}
        <Link to="/profile/applicant-info" className="color-black">
          <u>{t('ข้อมูลการลงทะเบียน')}</u>
        </Link>
      </p>
    </div>
  );
};
