import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Helpers
import { getCitizenIdType, getCitizenTypeLabel } from 'helpers';

// Components
import NotFoundTicket from 'components/registrations/NotFoundTicket';

const NotFound = () => {
  const citizenId = useParams().citizenId;
  const { t } = useTranslation();
  const citizenTypeLabel = getCitizenTypeLabel(t, getCitizenIdType(citizenId));

  return (
    <div className="t-form">
      <NotFoundTicket citizenId={citizenId} citizenTypeLabel={citizenTypeLabel} isTcas />
    </div>
  );
};

export default NotFound;
