import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

// Components
import BasicInfoForm from 'components/registrations/BasicInfoForm';
import SocialLoginForm from 'components/registrations/SocialLoginForm';

// Hooks
import useRegistration from 'hooks/useRegistration';
import { callAPI } from 'helpers';

const steps = [
  { stepId: 1, title: 'ลงทะเบียน' },
  { stepId: 2, title: 'เลือกวิธีเข้าระบบ' },
  { stepId: 3, title: 'ตรวจสอบข้อมูล' },
];

const RegistrationForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const sessionToken = Cookies.get('sessionToken');
  const [currentSession, setCurrentSession] = useState();
  const [verifying, setVerifying] = useState(false);
  useEffect(() => {
    const verify = async () => {
      try {
        setVerifying(true);
        const currentSession = await callAPI({ url: '/applicants/current-session' });
        setCurrentSession(currentSession);
        setVerifying(false);
      } catch (error) {
        console.error(new Error(`Error while trying to verfiy:${error.message}`));
        setVerifying(false);
        Cookies.remove('sessionToken');
      }
    };

    if (!sessionToken) {
      navigate('/');
    } else {
      verify();
    }
  }, [navigate, sessionToken]);

  const props = useRegistration(currentSession || {});
  const { currentStep } = props;

  return (
    <div>
      <div className="t-form">
        {!props.notFound && <h1>{t('ลงทะเบียน • เข้าสู่ระบบ')}</h1>}

        {!props.notFound && (
          <ul className="t-step">
            {steps.map(({ stepId, title }) => (
              <li key={stepId} className={classNames({ active: stepId === currentStep })}>
                <span>{title}</span>
              </li>
            ))}
          </ul>
        )}

        {!verifying && currentStep === 1 && <BasicInfoForm {...props} />}
        {!verifying && currentStep === 2 && (
          <SocialLoginForm
            infoHeading={t(
              'เพื่อให้ง่ายต่อการเข้าสู่ระบบในครั้งต่อไป ท่านสามารถเชื่อมต่อกับเครือข่ายสังคมที่ใช้อีเมลเดียวกับที่ระบุไว้ได้'
            )}
            {...props}
          />
        )}
      </div>
    </div>
  );
};

export default RegistrationForm;
