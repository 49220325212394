import React, { useState } from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { notApplicable, getProjectScore, getFullScore } from 'helpers';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useAdmission from 'hooks/round3/useAdmission';

const FavoriteItem = ({ index, data, studentStatus, favorites, onRemove }) => {
  const lang = 'th';
  const { t } = useTranslation();
  const { nextPriority } = useAdmission(favorites, studentStatus);

  const profile = useSelector((state) => _.get(state, 'app.user') || {});
  const courseMap = useSelector((state) => _.get(state, 'app.courseMap')) || {};
  const project = courseMap[data.program_project];
  const majorName = _.get(project, `major_name_${lang}`);
  const projectName = _.get(project, `project_name_${lang}`);
  const errors = notApplicable(profile, project, { studentStatus, favorites, nextPriority });

  const [errorActive, setErrorActive] = useState(false);

  if (!project) return null;
  return (
    <li key={project._id} className={cn({ error: Boolean(errors.length) })}>
      <label>
        <div className="num"><b>{index + 1}</b></div>
        <h4 className="score">{t('คะแนน')} {getProjectScore(profile, project, 1)}/{getFullScore(project)} {_.get(project, 'scores.priority_score') ? ' (กรณีที่เลือกเป็นอันดับที่ 1)' : ''}</h4>
        <span
          className="u-logo"
          style={{ backgroundImage: `url('/assets/img/logo/${_.get(project, 'university_id')}.png')` }}
        />
        <strong>{_.get(project, `university_name_${lang}`)}</strong>
        {_.get(project, `campus_name_${lang}`)}
        <br />
        {_.get(project, `faculty_name_${lang}`)}
        <br />
        {_.get(project, `field_name_${lang}`)}
        <br />
        {majorName && majorName !== '0' ? (
          <>
            {t('วิชาเอก')}
            {majorName}
            <br />
          </>
        ) : null}
        {_.get(project, `program_name_${lang}`)}
        <br />
        {lang === 'th' ? `(${_.get(project, `program_type_name_th`)})` : null}
        {projectName && projectName !== '0' ? (
          <>
            <br />
            {t('โครงการ ')}
            {projectName}
          </>
        ) : null}
      </label>
      {Boolean(errors.length) && (
        <div className={cn('t-collapse', { active: errorActive })}>
          <div className="t-toggle" onClick={() => setErrorActive(_active => !_active)}>
            {t('ไม่ผ่านเงื่อนไขการรับ ไม่สามารถสมัครได้')}<i className="i-toggle i-down-red"></i>
          </div>
          <div className="t-content">
            <ul>
              {errors.map(({ messages = [], key, label, expected, value }) => {
                if (messages.length > 1) {
                  return (
                    <li key={key}>
                      <i className="i-no"></i>
                      <span>{messages[0].label}</span>
                      {messages.map(({ expected, value }, index) => (
                        <div key={index}>
                          <b>{expected}</b>
                          <span>({t('ผู้สมัคร')}: <b className="color-red">{value}</b>)</span>
                        </div>
                      ))}
                    </li>
                  )
                }
                if (!value && value !== 0) {
                  return (
                    <li key={key}>
                      <i className="i-no"></i>
                      <span>{label}</span>
                    </li>
                  )
                }
                return (
                  <li key={key}>
                    <i className="i-no"></i>
                    <span>{label}</span>
                    <b>{expected}</b>
                    <span>({t('ผู้สมัคร')}: <b className="color-red">{value}</b>)</span>
                  </li>
                )
              })}
            </ul>
          </div >
        </div >
      )}
      <i className="i-del cursor-pointer" onClick={() => onRemove(data._id)} />
    </li >
  )
}

export default FavoriteItem;