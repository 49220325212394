/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import _ from 'lodash';

// Components
import ErrorBox from 'components/registrations/ErrorBox';

// Hooks
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

// Master data
import postalCodes from 'data/postalCodes.json';

// Helpers
import { callAPI } from 'helpers';
import { notAllowed, errorMessages, validatePostalCode, validateProvinceId, validateDistrictId, validateSubDistrictId, validateAddress } from 'validations';
import { getProvinceName, getDistrictName, getSubDistrictName } from 'helpers';

const postalCodeMap = _.keyBy(postalCodes, 'zipCode');
const ID = 'section-address';
const SectionAddress = forwardRef(({ initialState, profile = {}, collapsed, onCollapsedChange }, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [postalCode, setPostalCode] = useState(profile.zipcode || '');
  const [provinceId, setProvinceId] = useState(profile.province_id || '');
  const [districtId, setDistrictId] = useState(profile.amph_d || '');
  const [subDistrictId, setSubDistrictId] = useState(profile.tmbl_id || '');
  const [address, setAddress] = useState(profile.address || '');
  const [errors, setErrors] = useState([]);
  const [mode, setMode] = useState(collapsed ? 'edit' : 'add');

  useEffect(() => {
    setPostalCode(profile.zipcode);
    setProvinceId(profile.province_id);
    setDistrictId(profile.amph_id);
    setSubDistrictId(profile.tmbl_id);
    setAddress(profile.address);
  }, [profile]);

  useImperativeHandle(ref, () => ({
    getState: () => {
      const { provinceList = [], districtList = [], subDistrictList = [] } = postalCodeMap[postalCode] || {};
      return {
        provinceId,
        provinceName: getProvinceName(provinceList, provinceId),
        districtId,
        districtName: getDistrictName(districtList, districtId),
        subDistrictId,
        subDistrictName: getSubDistrictName(subDistrictList, subDistrictId),
        address,
        postalCode,
      };
    },
  }));

  useEffect(() => {
    const { provinceList = [], districtList = [], subDistrictList = [] } = postalCodeMap[postalCode] || {};
    if (provinceList.length <= 1) {
      setProvinceId(_.get(provinceList, '0.provinceId'));
    }
    if (districtList.length <= 1) {
      setDistrictId(_.get(districtList, '0.districtId'));
    }
    if (subDistrictList.length <= 1) {
      setSubDistrictId(_.get(subDistrictList, '0.subDistrictId'));
    }
  }, [postalCode]);

  const onPostalCodeChange = (e) => {
    if (notAllowed('postalCode', e.target.value)) return;
    setPostalCode(e.target.value);
  };
  const onProvinceIdChange = (e) => setProvinceId(e.target.value);
  const onDistrictIdChange = (e) => setDistrictId(e.target.value);
  const onSubDistrictIdChange = (e) => setSubDistrictId(e.target.value);
  const onAddressChange = (e) => {
    if (notAllowed('textTH', e.target.value)) return;
    setAddress(e.target.value);
  };

  const [saving, setSaving] = useState(false);
  const onButtonClick = async () => {
    const _messages = errorMessages({
      provinceId: validateProvinceId(provinceId),
      districtId: validateDistrictId(districtId, provinceId),
      subDistrictId: validateSubDistrictId(subDistrictId, districtId, provinceId),
      postalCode: validatePostalCode(postalCode),
      address: validateAddress(address),
    });

    const noChanges = provinceId === initialState.provinceId && districtId === initialState.districtId && subDistrictId === initialState.subDistrictId && postalCode === initialState.postalCode && address === initialState.address;

    if (_messages.length) {
      setErrors(_messages);
      const container = document.getElementById(ID);
      if (container) {
        container.scrollIntoView({ behavior: 'smooth' });
      }
    } else if (noChanges) {
      onCancel();
    } else {
      setErrors([]);
      setMode('edit');

      if (saving) return;
      try {
        setSaving(true);
        const { provinceList = [], districtList = [], subDistrictList = [] } = postalCodeMap[postalCode] || {};
        const { result } = await callAPI({
          method: 'PUT',
          url: '/applicants/me/address',
          body: {
            provinceId,
            provinceName: getProvinceName(provinceList, provinceId),
            districtId,
            districtName: getDistrictName(districtList, districtId),
            subDistrictId,
            subDistrictName: getSubDistrictName(subDistrictList, subDistrictId),
            address,
            postalCode,
          },
        });
        dispatch({ type: 'UPDATE_USER', result });
        onCollapsedChange(true);
        setSaving(false);
      } catch (error) {
        const errorMessages = (_.get(error, 'json.errors') || []).map(({ msg }) => msg);
        setErrors(errorMessages);
        const container = document.getElementById(ID);
        if (container) {
          container.scrollIntoView({ behavior: 'smooth' });
        }

        console.error(`Error while trying to save parent data: ${errorMessages.toString()}`);
        setSaving(false);
      }
    }
  };

  const onCancel = () => {
    setProvinceId(initialState.provinceId);
    setDistrictId(initialState.districtId);
    setSubDistrictId(initialState.subDistrictId);
    setPostalCode(initialState.postalCode);
    setAddress(initialState.address);
    onCollapsedChange(true);
  };

  const { provinceList = [], districtList = [], subDistrictList = [] } = postalCodeMap[postalCode] || {};
  const fDistrictList = districtList.filter((_data) => _data.provinceId === provinceId);
  const fSubDistrictList = subDistrictList.filter((_data) => _data.provinceId === provinceId && _data.districtId === districtId);

  if (collapsed) {
    return (
      <div className='t-box'>
        <h2 className='title'>
          {t('ข้อมูลที่อยู่ที่ติดต่อได้')}
          <a className='cursor-pointer' onClick={() => onCollapsedChange(false)}>
            {t('ขอแก้ไขข้อมูล')}
          </a>
        </h2>
        <table>
          <tbody>
            <tr>
              <th>{t('ที่อยู่')}</th>
              <td>{address}</td>
            </tr>
            <tr>
              <th>{t('ตำบล')}</th>
              <td>{getSubDistrictName(subDistrictList, subDistrictId)}</td>
            </tr>
            <tr>
              <th>{t('อำเภอ')}</th>
              <td>{getDistrictName(districtList, districtId)}</td>
            </tr>
            <tr>
              <th>{t('จังหวัด')}</th>
              <td>{getProvinceName(provinceList, provinceId)}</td>
            </tr>
            <tr>
              <th>{t('รหัสไปรษณีย์')}</th>
              <td>{postalCode}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div id={ID} className='t-box -choose'>
      <h2 className='title'>
        {mode === 'add' ? 'เพิ่มข้อมูลที่อยู่ที่ติดต่อได้' : 'แก้ไขข้อมูลที่อยู่ที่ติดต่อได้'}
        {mode === 'edit' && (
          <a className='cursor-pointer' onClick={onCancel}>
            {t('ยกเลิก')}
          </a>
        )}
      </h2>

      <ErrorBox isFull hidden={!errors.length} errors={errors} />
      <div className='t-row'>
        <div className='col required'>
          <label>{t('รหัสไปรษณีย์')}</label>
          <input type='text' value={postalCode} onChange={onPostalCodeChange} />
        </div>
        <div className='col required'>
          <label>{t('จังหวัด')}</label>
          {provinceList.length > 1 ? (
            <select required value={provinceId} onChange={onProvinceIdChange}>
              <option></option>
              {(provinceList || []).map(({ provinceId, provinceName }) => (
                <option key={provinceId} value={provinceId}>
                  {provinceName}
                </option>
              ))}
            </select>
          ) : (
            <input type='text' disabled value={t(getProvinceName(provinceList, provinceId))} />
          )}
        </div>
        <div className='col required'>
          <label>{provinceId === '1' ? t('เขต') : t('อำเภอ')}</label>
          {districtList.length > 1 && fDistrictList.length > 0 ? (
            <select required value={districtId} onChange={onDistrictIdChange}>
              <option></option>
              {fDistrictList.map(({ districtId, districtName }) => (
                <option key={districtId} value={districtId}>
                  {t(districtName)}
                </option>
              ))}
            </select>
          ) : (
            <input type='text' disabled value={t(getDistrictName(districtList, districtId))} />
          )}
        </div>
        <div className='col required'>
          <label>{provinceId === '1' ? t('แขวง') : t('ตำบล')}</label>
          {subDistrictList.length > 1 && fSubDistrictList.length > 0 ? (
            <select required value={subDistrictId} onChange={onSubDistrictIdChange}>
              <option></option>
              {fSubDistrictList.map(({ subDistrictId, subDistrictName }) => (
                <option key={subDistrictId} value={subDistrictId}>
                  {subDistrictName}
                </option>
              ))}
            </select>
          ) : (
            <input type='text' disabled value={t(getSubDistrictName(subDistrictList, subDistrictId))} />
          )}
        </div>
        <div className='col required'>
          <label>{t('ที่อยู่ บ้านเลขที่ ถนน')}</label>
          <input type='text' value={address} onChange={onAddressChange} />
        </div>
      </div>
      <hr />
      <a className='btn-main cursor-pointer' onClick={onButtonClick}>
        {t('ยืนยันข้อมูลที่อยู่')}
      </a>
    </div>
  );
});

export default SectionAddress;
