/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useTranslation } from 'react-i18next';

const ModalOfferReplyConsent = ({ visible, onSubmit }) => {
  const { t } = useTranslation();
  if (!visible) return null;
  return (
    <div className="t-modal" style={{ visibility: 'visible', opacity: 1, pointerEvents: 'auto' }}>
      <div
        className="content"
        style={{ padding: '20px 30px', opacity: 1, height: 'initial', overflow: 'auto', textAlign: 'left' }}
      >
        <h1>ข้อกำหนดและเงื่อนไขในการยืนยันสิทธิ์/ไม่ใช้สิทธิ์</h1>
        <p className="text-center">
          กรุณาอ่านรายละเอียดให้ครบถ้วน และคลิกปุ่ม <strong>"รับทราบ"</strong> ในส่วนล่างสุด
        </p>
        <br />
        <h2>1. คู่มือสำหรับผู้สมัคร</h2>
        <p>
          ผู้สมัครต้องศึกษาข้อกำหนดต่างๆ ในคู่มือสำหรับผู้สมัครคัดเลือกกลางบุคคลเข้าศึกษาในสถาบันอุดมศึกษา ปีการศึกษา
          2568 (TCAS68) อย่างละเอียดรอบคอบ โดย Download คู่มือได้เว็บไซต์ mytcas.com
        </p>
        <h2>2. การตรวจสอบข้อมูล</h2>
        <p>
          ผู้สมัครต้องตรวจสอบข้อมูลต่าง ๆ ในระบบให้มีความถูกต้องและสมบูรณ์ที่สุด
          ซึ่งถือเป็นหน้าที่และความรับผิดชอบของผู้สมัครในการให้ข้อมูลที่เป็นจริงแก่ระบบ เพื่อใช้ในการคัดเลือก
          เมื่อปิดระบบรับสมัคร ข้อมูลทั้งหมดจะถือว่าผู้สมัครยอมรับว่าครบถ้วนและถูกต้องดีแล้ว
          สามารถนำไปใช้ในการคัดเลือกได้ ทั้งนี้ การปลอมเอกสารเพื่อใช้แก้ไขข้อมูลในระบบ เป็นการดำเนินการที่มีความผิดตาม
          พรบ. คอมพิวเตอร์ ปี พ.ศ. 2560
        </p>
        <h2>3. การยืนยันสิทธิ์</h2>
        <p>
          ถ้าต้องการเข้าศึกษาในสาขาวิชาที่ผ่านการคัดเลือก ให้กด "ยืนยันสิทธิ์"
          ในสาขาวิชาที่ต้องการเข้าศึกษาเพียงสาขาวิชาเดียวเท่านั้น โดยดำเนินการให้แล้วเสร็จภายในเวลา 23.59 น. ของวันที่ 7
          กุมภาพันธ์ 2568 ขั้นตอนการยืนยันสิทธิ์ มีขั้นตอนดังนี้
        </p>
        <ol className="t-ol">
          <li>
            <b>3.1</b>
            กดปุ่ม "ยืนยันสิทธิ์" ของสาขาวิชาที่ต้องการเข้าศึกษาเพียงสาขาวิชาเดียวเท่านั้น
            ส่วนสาขาวิชาอื่นที่ผ่านการคัดเลือกแต่ไม่ต้องการเข้าศึกษา ไม่ต้องดำเนินการใด ๆ
          </li>
          <li>
            <b>3.2</b>
            กดขอรหัส OTP ผ่านช่องทางเบอร์โทรศัพท์มือถือหรืออีเมลที่ได้ลงทะเบียนไว้แล้ว
          </li>
          <li>
            <b>3.3</b>
            นำรหัส OTP ที่ได้รับกรอกเข้าระบบให้เรียบร้อย ทั้งนี้ ให้กดขอรหัส OTP เพียงครั้งเดียว แล้วรอระบบส่งรหัส OTP
            ไปที่ช่องทางที่ต้องการรับรหัส หากรอนานเกิน 10 นาที จึงกดขอรหัส OTP ใหม่อีกครั้ง
          </li>
          <li>
            <b>3.4</b>
            หากกดขอรหัส OTP หลายครั้ง ต้องรอให้ระบบส่งรหัสไปตามจำนวนครั้งที่กดขอรหัส และนำรหัส OTP
            ที่ได้รับครั้งสุดท้ายป้อนกลับเข้าระบบ (ค่า Ref. ที่ได้รับรหัส OTP ต้องตรงกับที่ปรากฏอยู่บนหน้าจอของระบบ)
            จึงจะสามารถยืนยันสิทธิ์เข้าศึกษาได้
          </li>
          <li>
            <b>3.5</b>
            หากกดยืนยันสิทธิ์เข้าศึกษาภายในเวลาที่กำหนด จะถือว่า ผู้ผ่านการคัดเลือกได้สิทธิ์เข้าศึกษาในสาขาวิชานั้น
            และไม่สามารถสมัครคัดเลือกในรอบต่อไปได้
          </li>
          <li>
            <b>3.6</b>
            ระบบอนุญาตให้เปลี่ยนแปลงการยืนยันสิทธิ์เข้าศึกษาได้รวมทั้งสิ้น 3 ครั้ง โดยนับจำนวนการยืนยันรหัส OTP
            ได้สำเร็จสำหรับการยืนยันสิทธิ์และการไม่ใช้สิทธิ์
          </li>
        </ol>
        <h2>4. การไม่ต้องการเข้าศึกษาในสาขาวิชาที่ผ่านการคัดเลือก (ไม่ดำเนินการใดๆ หรือไม่ใช้สิทธิ์)</h2>
        <p>ถ้าไม่ต้องการเข้าศึกษาในทุกสาขาวิชาที่ผ่านการคัดเลือก สามารถเลือกดำเนินการ ได้ดังนี้</p>
        <ol className="t-ol">
          <li>
            <b>4.1</b> <strong>ไม่กดปุ่ม "ยืนยันสิทธิ์"</strong> ของสาขาวิชาใด ๆ ที่ผ่านการคัดเลือก
            จนถึงเวลาปิดระบบยืนยันสิทธิ์ ณ เวลา 24.00 น. ของวันที่ <strong>7 กุมภาพันธ์ 2568</strong> หรือ
          </li>
          <li>
            <b>4.2</b>
            <strong>ให้กดปุ่ม "ไม่ใช้สิทธิ์"</strong> ที่อยู่ด้านล่างของรายชื่อสาขาวิชาที่ผ่านการคัดเลือก ภายในเวลา
            23.59 น. ของวันที่ <strong>7 กุมภาพันธ์ 2568</strong> โดยกดขอรหัส OTP เพื่อยืนยันตัวตนด้วยเช่นกัน
          </li>
          <li>
            <b>4.3</b> ในกรณีที่กดยืนยันสิทธิ์ไปแล้ว แต่ต้องการเปลี่ยนใจไม่เข้าศึกษาในทุกสาขาวิชาที่ผ่านการคัดเลือก
            โดยดำเนินการขอรหัส OTP ได้สำเร็จไม่ถึง 3 ครั้งและยังไม่ถึงเวลาปิดระบบยืนยันสิทธิ์ ให้กดปุ่ม "ไม่ใช้สิทธิ์"
            ภายในเวลา 23.59 น. ของวันที่ <strong>7 กุมภาพันธ์ 2568</strong> โดยกดขอรหัส OTP เพื่อยืนยันตัวตนด้วยเช่นกัน
          </li>
          <li>
            <b>4.4</b> ในกรณีที่กดยืนยันสิทธิ์ไปครบ 3 ครั้งแล้ว หรือ ระบบปิดการยืนยันสิทธิ์แล้ว
            ให้รอระบบเปิดการสละสิทธิ์ แล้วกดปุ่ม "สละสิทธิ์"
            ซึ่งถือเป็นการยกเลิกการยืนยันสิทธิ์ที่ได้ดำเนินการไปเรียบร้อยแล้ว และจะขอยกเลิกการสละสิทธิ์ในภายหลังไม่ได้
            โดยกดขอรหัส OTP เพื่อยืนยันตัวตนด้วยเช่นกัน
          </li>
        </ol>

        <div className="text-right">
          <small>
            <em>ปรับปรุงล่าสุดเมื่อวันที่ 4 กุมภาพันธ์ 2566</em>
          </small>
        </div>
        <hr />

        <div className="action">
          <a className="btn-main cursor-pointer" onClick={onSubmit}>
            {t('รับทราบ')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ModalOfferReplyConsent;
