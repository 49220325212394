import { useEffect, useState, useRef } from 'react';
import { getNewestTicket } from 'helpers';
import scores from 'data/ged.score.json';
// import useEducation from 'components/profile/score-hooks/useEducation';

const useEditGEDScore = (profile = {}, pendingTickets = []) => {
  const gedScoreRef = useRef();
  const { ticket, valueOf } = getNewestTicket(pendingTickets, 'update_ged_score');

  const [initialState, setInitialState] = useState(
    scores.reduce(
      (state, { key, fieldName }) => ({
        ...state,
        [fieldName]: valueOf(key) || profile[key],
      }),
      { gedDate: valueOf('ged_date') || profile.ged_date, gedScore: valueOf('ged_score') || profile.ged_score }
    )
  );

  const [collapsed, setCollapsed] = useState(true);
  const [waiting, setWaiting] = useState(Boolean(ticket));
  useEffect(() => setWaiting(Boolean(ticket)), [ticket]);

  useEffect(() => {
    if (!collapsed) {
      setInitialState(gedScoreRef.current.getState());
    }
  }, [collapsed]);

  return {
    gedScoreRef,
    gedScoreCollapsed: collapsed,
    onGEDScoreCollapsedChange: (collapsed, waiting) => {
      setCollapsed(collapsed);
      setWaiting(ticket ? true : waiting);
    },
    gedScoreInitialState: initialState,
    gedScoreWaiting: waiting,
  };
};

export default useEditGEDScore;
