import React from 'react';
import { useTranslation } from 'react-i18next';
import scores from 'data/university.score.json';

const SectionUniversityScore = ({ profile }) => {
  const { t } = useTranslation();

  const existedScores = scores.filter(({ key }) => profile[key]);
  return (
    <div className="t-box">
      <h2 className="title">{t('ข้อมูลคะแนนวิชาเฉพาะของมหาวิทยาลัย')}</h2>

      {existedScores.length ? (
        <table className="table-line col-last-num">
          <thead>
            <tr>
              <th>{t('สถาบัน')}</th>
              <th>{t('วิชา')}</th>
              <th>{t('คะแนน')}</th>
            </tr>
          </thead>
          <tbody>
            {existedScores.map(({ key, universityName, label }) => (
              <tr key={key}>
                <td>{t(universityName)}</td>
                <td>{t(label)}</td>
                <td>{profile[key] || t('ยังไม่มีข้อมูล')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div>{t('ไม่มีข้อมูลคะแนน')}</div>
      )}
    </div>
  );
};

export default SectionUniversityScore;
