import React from 'react';
import cn from 'classnames';
import _ from 'lodash';
import { isFlSubject } from 'helpers';

// Hooks
import { useTranslation } from 'react-i18next';

const ExamSlotInfo = ({ slot, fl, examType, footer, confirmations = [], specialRequests = {}, showPaymentStatus }) => {
  const { t } = useTranslation();

  const orderPaid = (confirmations
    .filter(({ order_status }) => order_status === 'paid')
    .reduce((array, confirmation) => array.concat(confirmation.subjects), [])
    .includes(slot.subjectKey));
  const tgatRedeemed = confirmations.some(({ tgat_redeemed, subjects }) => tgat_redeemed && subjects.includes('TGAT')) && slot.subjectKey === 'TGAT';
  const alreadyPaid = showPaymentStatus
    ? (orderPaid || tgatRedeemed)
    : false;

  const confirmedSubjects = _.uniq(confirmations.reduce((array, confirmation) => array.concat(confirmation.subjects), []));
  const recentlyAdded = confirmedSubjects.length && !confirmedSubjects.includes(slot.subjectKey);
  const specialRequestKey = isFlSubject(slot.subjectCode) ? 'Foreign' : slot.subjectKey;
  return (
    <>
      <li style={{ marginBottom: 0 }} className={cn({ '-noti': recentlyAdded })}>
        <div className="s-flex -between">
          {recentlyAdded
            ? <h4 className="title">{t(fl ? 'วิชาสอบที่แก้ไข' : 'วิชาสอบที่เพิ่ม')}</h4>
            : <div className="_heading color-blue"><b>{t(fl ? 'วิชาสอบภาษาต่างประเทศ' : 'วิชาสอบ')}</b></div>}
          {showPaymentStatus ? (
            <div className={cn("_heading", { 'color-green': alreadyPaid, 'color-red': !alreadyPaid })}>
              <b>{alreadyPaid ? t('ชำระเงินแล้ว') : t('รอการชำระเงิน')}</b>
            </div>
          ) : recentlyAdded
            ? <div className="_heading color-orange"><b>{t(fl ? 'รอยืนยันแก้ไขวิชาสอบภาษาต่างประเทศ' : 'รอยืนยันการสมัครสอบ')}</b></div>
            : null}
        </div>
        <ul className="list-style-none">
          <li>{t('รหัสวิชา')} <b>{slot.subjectCode}</b></li>
          <li>{t('ชื่อวิชา')} <b>{slot.subjectKey} {slot.subjectName}</b></li>
          <li>{t('เวลา')} <b>{slot.time}</b></li>
          {examType === 'pbt' ? (
            <li>
              {t('ประเภทข้อสอบ')} <i className="i-paper" />
              <b>{t('กระดาษ')}</b>
            </li>
          ) : examType === 'cbt' ? (
            <li>
              {t('ประเภทข้อสอบ')} <i className="i-computer" />
              <b>{t('คอมพิวเตอร์')}</b>
            </li>
          ) : null}
        </ul>
        {Boolean((slot.subSubjects || []).length) && (
          <>
            <span className="remark color-blue">
              *{t('สำหรับผู้ต้องการสอบวิชาเหล่านี้')}
            </span>
            <hr className="hr-dashed" />
            <ul className="list-style-disc">
              {slot.subSubjects.map(({ subjectCode, subjectKey, subjectName }) =>
                <li key={subjectCode}>
                  {t('รหัสวิชา')} <b>{subjectCode}</b><br />
                  {t('ชื่อวิชา')} <b>{subjectKey} {subjectName}</b>
                </li>
              )}
            </ul>
          </>
        )}
        {Boolean(specialRequests[specialRequestKey]) && (
          <>
            <hr className="hr-dashed" />
            <h4 className="color-orange m-0 flex items-center gap-1">
              <i className="i-disability"></i>
              <small><b>{specialRequests[specialRequestKey]}</b></small>
            </h4>
          </>
        )}
      </li>
      {footer}
    </>
  );
}

export default ExamSlotInfo;