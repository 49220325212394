module.exports = (() => {
  if (process.env.NODE_ENV === 'production') {
    return process.env.REACT_APP_STAGING
      ? {
          apiHost: 'https://tcas65-staging-dot-tcas65.as.r.appspot.com',
          genPdfApiHost: 'https://tcas-staging-pdf-dot-tcas65.as.r.appspot.com',
          facebookAppId: '552078457771249',
          thaid: {
            host: 'https://imauth.bora.dopa.go.th',
            clientId: 'U09kc01LUHRHMVlzSXZHdlFVVjdEYTBBMTdMNUJFU1M'
          }
          // thaid: {
          //   host: 'https://imauthsbx.bora.dopa.go.th',
          //   clientId: 'RlJCR2poM2RFdEtVeUN5cjRnYklxbWY4Q09HZ0NORXU'
          // }
        }
      : { 
          apiHost: 'https://tcas65.as.r.appspot.com',
          genPdfApiHost: 'https://tcas-pdf-dot-tcas65.as.r.appspot.com',
          facebookAppId: '552078457771249',
          thaid: {
            host: 'https://imauth.bora.dopa.go.th',
            clientId: 'U09kc01LUHRHMVlzSXZHdlFVVjdEYTBBMTdMNUJFU1M'
          }
        };
  }

  if (process.env.REACT_APP_STAGING) {
    return {
      apiHost: 'https://tcas65-staging-dot-tcas65.as.r.appspot.com',
      genPdfApiHost: 'https://tcas-staging-pdf-dot-tcas65.as.r.appspot.com',
      // apiHost: 'http://localhost:8020',
      facebookAppId: '552078457771249',
      thaid: {
        host: 'https://imauth.bora.dopa.go.th',
        clientId: 'U09kc01LUHRHMVlzSXZHdlFVVjdEYTBBMTdMNUJFU1M'
      }
      // thaid: {
      //   host: 'https://imauthsbx.bora.dopa.go.th',
      //   clientId: 'RlJCR2poM2RFdEtVeUN5cjRnYklxbWY4Q09HZ0NORXU'
      // }
    };
  }

  // Default config
  return {
    apiHost: 'https://tcas65-staging-dot-tcas65.as.r.appspot.com',
    genPdfApiHost: 'https://tcas-staging-pdf-dot-tcas65.as.r.appspot.com',
    // apiHost: 'http://localhost:8020',
    // genPdfApiHost: 'http://localhost:8021',
    // apiHost: 'https://tcas65.as.r.appspot.com',
    // genPdfApiHost: 'https://tcas-pdf-dot-tcas65.as.r.appspot.com',
    facebookAppId: '552078457771249',
    thaid: {
      host: 'https://imauth.bora.dopa.go.th',
      clientId: 'U09kc01LUHRHMVlzSXZHdlFVVjdEYTBBMTdMNUJFU1M'
    }
    // thaid: {
    //   host: 'https://imauthsbx.bora.dopa.go.th',
    //   clientId: 'RlJCR2poM2RFdEtVeUN5cjRnYklxbWY4Q09HZ0NORXU'
    // }
  };
})();
