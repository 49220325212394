/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react';
import _ from 'lodash';
import { wordsWithStyles, renderBoldString } from 'helpers';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const Search = ({ favorites, onAddToFavorites }) => {
  const { t } = useTranslation();
  const courses = useSelector((state) => _.get(state, 'app.courses')) || {};

  const [keyword, setKeyword] = useState('');
  const [{ searchResult, keywords }, setSearchResult] = useState({ searchResult: [] });

  const _onSearch = (e) => {
    setKeyword(e.target.value);
    const keywords = e.target.value.trim().split(' ');
    let searchResult = courses.filter(course => course.receive_student_number).filter((course) =>
      keywords.reduce((result, word) => {
        const containedInUniversity = (course.university_name_th || '').indexOf(word) > -1;
        const containedInFaculty = (course.faculty_name_th || '').indexOf(word) > -1;
        const containedInCampus = (course.campus_name_th || '').indexOf(word) > -1;
        const containedInField = (course.field_name_th || '').indexOf(word) > -1;
        const containedInProgram = (course.program_name_th || '').indexOf(word) > -1;
        const containedInMajor = (course.major_name_th || '').indexOf(word) > -1;
        const containedInProject = (course.project_name_th || '').indexOf(word) > -1;

        const isContained =
          containedInUniversity ||
          containedInFaculty ||
          containedInCampus ||
          containedInField ||
          containedInProgram ||
          containedInMajor ||
          containedInProject ||
          (course.university_id === '031' && (word || '').indexOf('กสพท') > -1);
        if (!isContained && _.last(word) === 'ะ') {
          const newWord = word.slice(0, word.length - 1);
          const containedInUniversity = (course.university_name_th || '').indexOf(newWord) > -1;
          const containedInFaculty = (course.faculty_name_th || '').indexOf(newWord) > -1;
          const containedInCampus = (course.campus_name_th || '').indexOf(newWord) > -1;
          const containedInField = (course.field_name_th || '').indexOf(newWord) > -1;
          const containedInProgram = (course.program_name_th || '').indexOf(newWord) > -1;
          const containedInMajor = (course.major_name_th || '').indexOf(newWord) > -1;
          const containedInProject = (course.project_name_th || '').indexOf(newWord) > -1;
          return (
            result && (containedInUniversity || containedInFaculty || containedInCampus || containedInField || containedInProgram || containedInMajor || containedInProject)
          );
        }
        return result && isContained;
      }, true),
    );

    setSearchResult({
      keywords,
      searchResult: searchResult.length === courses.length ? [] : searchResult,
    });
  };

  const _onClose = () => {
    setKeyword('');
    setSearchResult({ keywords: '', searchResult: [] });
  }

  return (
    <>
      <p>
        {t('เลือกสาขาวิชาที่ท่านสนใจได้สูงสุด 20 อันดับ')}
        <br className="_mobile" />
        {t('เพื่อใช้ในการสมัครรอบ Admission')}
      </p>
      <div className="t-search">
        <div className="input-group">
          <input type="text"
            placeholder="พิมพ์ชื่อมหาวิทยาลัย คณะ หรือหลักสูตร"
            value={keyword} onChange={_onSearch} />
          <i className="i-search" />
        </div>
      </div>
      {_.get(searchResult, 0) && (
        <div className="t-result">
          <h2>
            <strong>{t('ผลการค้นหา')}</strong>
            <span className="sep">•</span>
            <b>{searchResult.length} {t('หลักสูตร')}</b>
            <i className="i-close" onClick={_onClose} />
          </h2>
          <ul className="t-programs">
            {searchResult.slice(0, 100).map((row, index) => {
              const programNameString = `${row.program_name_th}${row.major_name_th ? ` สาขาวิชา${row.major_name_th}` : ''}`;
              const logoBG = '/assets/img/logo/' + row.university_id + '.png';
              const contains = favorites.some(({ program_project }) => program_project === row._id);
              return (
                <li key={index}>
                  <a>
                    <span className="u-logo" style={{ backgroundImage: `url(${logoBG})` }}></span>
                    <h3>
                      {wordsWithStyles(
                        keywords,
                        `${row.university_name_th}${row.campus_name_en === 'Main Campus' ? '' : ` ${row.campus_name_th}`}`,
                      ).map(renderBoldString)}
                      {contains ? (
                        <b className="choose"><i className="i-checked" /></b>
                      ) : favorites.length === 20 ? null : (
                        <b className="choose cursor-pointer" onClick={() => onAddToFavorites(row._id)}>
                          <i className="i-choose" /> {t('เลือก')}
                        </b>
                      )}
                    </h3>
                    <b>
                      {wordsWithStyles(keywords, row.faculty_name_th).map(renderBoldString)} -{' '}
                      {wordsWithStyles(keywords, row.field_name_th).map(renderBoldString)}
                    </b>
                    <span>{wordsWithStyles(keywords, programNameString).map(renderBoldString)}</span>
                    <span>{(programNameString || '').indexOf(row.program_type_name_th) === -1 ? `(${row.program_type_name_th})` : ''}</span>
                    {row.project_name_th && <span>โครงการ {wordsWithStyles(keywords, row.project_name_th).map(renderBoldString)}</span>}
                  </a>
                  <small>
                    <a target="_blank" href={`https://www.mytcas.com/programs/${row.program_id}${(row.major_id && row.major_id !== '0') ? `?major=${row.major_id}` : ''}`}>
                      {t('ดูรายละเอียด')} →
                    </a>
                  </small>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
}

export default Search;
