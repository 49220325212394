/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { callAPI } from 'helpers';
import Cookies from 'js-cookie';
import cn from 'classnames';

// Hooks
import { useTranslation } from 'react-i18next';
import useOTPHandlers from 'hooks/useOTPHandlers';

const TIME_LIMIT = 600;
const NON_AUTO_VERIFY_LIST = [
  'offer-reply',
  'apply-round3',
  'set-zero-round3',
  'reprocess-round3',
  'apply-exams',
  'update-exam-sites',
  'update-fl-subject',
  'redeem-round3-agree',
  'redeem-round3-disagree',
  'tgattpat-postpone'
];
const getFlowCode = (studentType) => {
  if (!studentType) return 'verify-student';
  if (
    [
      'offer-reply',
      'apply-round3',
      'set-zero-round3',
      'reprocess-round3',
      'apply-exams',
      'update-exam-sites',
      'update-fl-subject',
      'redeem-round3-agree',
      'redeem-round3-disagree',
      'tgattpat-postpone'
    ].includes(studentType)
  )
    return studentType;
  if (['tcas', 'tcas-past'].includes(studentType)) return 'tcas-forgot-password';
  return 'registered-forgot-password';
};

const MobileVerification = ({ mobile, studentType, roundType, offerId, isCanceled, bodyParams, onSuccess }) => {
  const { digitRefs, digits, onOTPChange } = useOTPHandlers();
  const { t } = useTranslation();

  const [requesting, setRequesting] = useState(false);
  const timerEndsAt = Cookies.get('mobile_timer_ends_at') ? parseInt(Cookies.get('mobile_timer_ends_at')) : null;
  const [timer, setTimer] = useState(timerEndsAt ? parseInt((timerEndsAt - new Date().getTime()) / 1000, 10) : 0);

  useEffect(() => {
    const _interval = setInterval(() => setTimer((_time) => _time - (timer ? 1 : 0)), 1000);
    return () => clearInterval(_interval);
  }, [timer]);

  const [refCode, setRefCode] = useState('-');
  const flowCode = getFlowCode(studentType);

  useEffect(() => {
    const requestOtp = async () => {
      if (requesting) return;
      try {
        setRequesting(true);
        Cookies.remove('mobile_timer_ends_at');
        if (['offer-reply', 'reprocess-round3'].includes(flowCode)) {
          Cookies.remove('current_offer_id');
        }

        const { refCode } = await callAPI({
          method: 'POST',
          url: `/otp/${flowCode}/sms-v2-request`,
          body:
            flowCode === 'offer-reply'
              ? {
                  offerId,
                  type: roundType,
                  status: isCanceled ? 'canceled' : null,
                }
              : bodyParams,
        });
        setRefCode(refCode);
        setRequesting(false);
        setTimer(TIME_LIMIT);
        Cookies.set('mobile_timer_ends_at', new Date().getTime() + TIME_LIMIT * 1000, {
          expires: TIME_LIMIT / (24 * 60 * 60),
        });
        if (flowCode === 'offer-reply') {
          Cookies.set('current_offer_id', offerId || '', { expires: TIME_LIMIT / (24 * 60 * 60) });
        }
      } catch (error) {
        console.error(new Error(`Error while trying to request sms OTP: ${_.get(error, 'message')}`));
        setRequesting(false);
      }
    };

    const requestRef = async () => {
      if (requesting) return;
      try {
        setRequesting(true);
        const { refCode } = await callAPI({
          method: 'POST',
          url: `/otp/ref-code-by-${flowCode.includes('forgot-password') ? 'session' : 'access'}-token/sms-v2-request`,
        });
        setRefCode(refCode);
        setRequesting(false);
      } catch (error) {
        console.error(new Error(`Error while trying to request sms REF: ${_.get(error, 'message')}`));
        setRequesting(false);
      }
    };
    if (!refCode) {
      if (!timer) {
        requestOtp();
      } else if (flowCode === 'offer-reply' && Cookies.get('current_offer_id') !== `${offerId || ''}`) {
        requestOtp();
      } else if (flowCode === 'reprocess-round3') {
        requestOtp();
      } else {
        requestRef();
      }
    } else if (refCode === '-') {
      requestOtp();
    }
  }, [flowCode, timer, refCode, requesting, offerId, roundType]);

  const _onResendOtp = async () => {
    try {
      if (requesting) return;
      setRequesting(true);
      Cookies.remove('mobile_timer_ends_at');
      await callAPI({
        method: 'POST',
        url: `/otp/${flowCode}/sms-v2-resend`,
        body: { ...bodyParams, refCode },
      });
      setRequesting(false);
      setTimer(TIME_LIMIT);
    } catch (error) {
      console.error(new Error(`Error while trying to request sms OTP: ${_.get(error, 'message')}`));
      setRequesting(false);
    }
  };

  const otpCode = digits.join('');
  const [verifying, setVerifying] = useState(false);
  const [verifyFail, setVerifyFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  useEffect(() => {
    const verifyOtp = async () => {
      if (verifying) return;
      try {
        setVerifying(true);
        setVerifyFail(false);
        const { token } = await callAPI({
          method: 'POST',
          url: `/otp/${flowCode}/sms-v2-verify`,
          body: {
            refCode,
            otpCode,
          },
        });
        setVerifying(false);
        setVerifyFail(false);
        Cookies.remove('mobile_timer_ends_at');
        onSuccess(token);
      } catch (error) {
        console.error(new Error(`Error while trying to verify sms OTP: ${_.get(error, 'message')}`));
        setVerifying(false);
        setVerifyFail(true);
        setErrorMessage(error.statusCode === 401 ? 'รหัสยืนยัน (OTP) 6 หลักไม่ถูกต้อง' : _.get(error, 'message'));
      }
    };

    if (otpCode.length === 6 && !NON_AUTO_VERIFY_LIST.includes(flowCode)) {
      verifyOtp();
    }
  }, [onSuccess, refCode, otpCode, flowCode]);

  const [offerReplying, setOfferReplying] = useState(false);
  const _onOfferReply = async () => {
    if (offerReplying) return;
    try {
      setOfferReplying(true);
      setVerifyFail(false);
      const result = await callAPI({
        method: 'POST',
        url: '/otp/offer-reply/sms-v2-verify',
        body: {
          refCode,
          otpCode,
          offerId,
          type: roundType,
          status: isCanceled ? 'canceled' : null,
        },
      });
      setOfferReplying(false);
      setVerifyFail(false);
      Cookies.remove('mobile_timer_ends_at');
      onSuccess(result);
    } catch (error) {
      console.error(new Error(`Error while trying to reply an enrollment offer: ${_.get(error, 'message')}`));
      setOfferReplying(false);
      setVerifyFail(true);
      setErrorMessage(error.statusCode === 401 ? 'รหัสยืนยัน (OTP) 6 หลักไม่ถูกต้อง' : _.get(error, 'message'));
    }
  };

  const [applyingR3, setApplyingR3] = useState(false);
  const _onApplyRound3 = async () => {
    if (applyingR3) return;
    try {
      setApplyingR3(true);
      setVerifyFail(false);

      const result = await callAPI({
        method: 'POST',
        url: '/otp/apply-round3/sms-v2-verify',
        body: { ...bodyParams, refCode, otpCode },
      });
      setApplyingR3(false);
      setVerifyFail(false);
      Cookies.remove('mobile_timer_ends_at');
      onSuccess(result);
    } catch (error) {
      console.error(new Error(`Error while trying to apply round 3: ${_.get(error, 'message')}`));
      setApplyingR3(false);
      setVerifyFail(true);
      setErrorMessage(error.statusCode === 401 ? 'รหัสยืนยัน (OTP) 6 หลักไม่ถูกต้อง' : _.get(error, 'message'));
    }
  };

  const [s0Submitting, setS0Submitting] = useState(false);
  const _onSetZero = async () => {
    if (s0Submitting) return;
    try {
      setS0Submitting(true);
      setVerifyFail(false);

      const result = await callAPI({
        method: 'POST',
        url: '/otp/set-zero-round3/sms-v2-verify',
        body: { refCode, otpCode },
      });
      setS0Submitting(false);
      setVerifyFail(false);
      Cookies.remove('mobile_timer_ends_at');
      onSuccess(result);
    } catch (error) {
      console.error(new Error(`Error while trying to set zero: ${_.get(error, 'message')}`));
      setS0Submitting(false);
      setVerifyFail(true);
      setErrorMessage(error.statusCode === 401 ? 'รหัสยืนยัน (OTP) 6 หลักไม่ถูกต้อง' : _.get(error, 'message'));
    }
  };

  const [reprocessingR3, setReprocessingR3] = useState(false);
  const _onReprocessR3 = async () => {
    if (reprocessingR3) return;
    try {
      setReprocessingR3(true);
      setVerifyFail(false);

      const result = await callAPI({
        method: 'POST',
        url: '/otp/reprocess-round3/sms-v2-verify',
        body: { ...bodyParams, refCode, otpCode },
      });
      setReprocessingR3(false);
      setVerifyFail(false);
      Cookies.remove('mobile_timer_ends_at');
      onSuccess(result);
    } catch (error) {
      console.error(new Error(`Error while trying to reprocess round3: ${_.get(error, 'message')}`));
      setReprocessingR3(false);
      setVerifyFail(true);
      setErrorMessage(error.statusCode === 401 ? 'รหัสยืนยัน (OTP) 6 หลักไม่ถูกต้อง' : _.get(error, 'message'));
    }
  };

  const [applyingExams, setApplyingExams] = useState(false);
  const _onApplyExams = async () => {
    if (applyingExams) return;
    try {
      setApplyingExams(true);
      setVerifyFail(false);

      const result = await callAPI({
        method: 'POST',
        url: '/otp/apply-exams/sms-v2-verify',
        body: { ...bodyParams, refCode, otpCode },
      });
      setApplyingExams(false);
      setVerifyFail(false);
      Cookies.remove('mobile_timer_ends_at');
      onSuccess(result);
    } catch (error) {
      console.error(new Error(`Error while trying to apply exams: ${_.get(error, 'message')}`));
      setApplyingExams(false);
      setVerifyFail(true);
      setErrorMessage(error.statusCode === 401 ? 'รหัสยืนยัน (OTP) 6 หลักไม่ถูกต้อง' : _.get(error, 'message'));
    }
  };

  const [updatingSites, setUpdatingSites] = useState(false);
  const _onUpdateSites = async () => {
    if (updatingSites) return;
    try {
      setUpdatingSites(true);
      setVerifyFail(false);

      const result = await callAPI({
        method: 'POST',
        url: '/otp/update-exam-sites/sms-v2-verify',
        body: { ...bodyParams, refCode, otpCode },
      });
      setUpdatingSites(false);
      setVerifyFail(false);
      Cookies.remove('mobile_timer_ends_at');
      onSuccess(result);
    } catch (error) {
      console.error(new Error(`Error while trying to update sites: ${_.get(error, 'message')}`));
      setUpdatingSites(false);
      setVerifyFail(true);
      setErrorMessage(error.statusCode === 401 ? 'รหัสยืนยัน (OTP) 6 หลักไม่ถูกต้อง' : _.get(error, 'message'));
    }
  };

  const [updatingFlSubject, setUpdatingFlSubject] = useState(false);
  const _onUpdateFlSubject = async () => {
    if (updatingFlSubject) return;
    try {
      setUpdatingFlSubject(true);
      setVerifyFail(false);

      const result = await callAPI({
        method: 'POST',
        url: '/otp/update-fl-subject/sms-v2-verify',
        body: { ...bodyParams, refCode, otpCode },
      });
      setUpdatingFlSubject(false);
      setVerifyFail(false);
      Cookies.remove('mobile_timer_ends_at');
      onSuccess(result);
    } catch (error) {
      console.error(new Error(`Error while trying to update foreign language subject: ${_.get(error, 'message')}`));
      setUpdatingFlSubject(false);
      setVerifyFail(true);
      setErrorMessage(error.statusCode === 401 ? 'รหัสยืนยัน (OTP) 6 หลักไม่ถูกต้อง' : _.get(error, 'message'));
    }
  };

  const [redeemingR3Agree, setRedeemingR3Agree] = useState(false);
  const _onRedeemR3Agree = async () => {
    if (redeemingR3Agree) return;
    try {
      setRedeemingR3Agree(true);
      setVerifyFail(false);

      const result = await callAPI({
        method: 'POST',
        url: '/otp/redeem-round3-agree/sms-v2-verify',
        body: { refCode, otpCode },
      });

      setRedeemingR3Agree(false);
      setVerifyFail(false);
      Cookies.remove('mobile_timer_ends_at');
      onSuccess(result);
    } catch (error) {
      console.error(new Error(`Error while trying to redeemR3Agree: ${_.get(error, 'message')}`));
      setRedeemingR3Agree(false);
      setVerifyFail(true);
      setErrorMessage(error.statusCode === 401 ? 'รหัสยืนยัน (OTP) 6 หลักไม่ถูกต้อง' : _.get(error, 'message'));
    }
  };

  const [redeemingR3Disagree, setRedeemingR3Disagree] = useState(false);
  const _onRedeemR3Disagree = async () => {
    if (redeemingR3Disagree) return;
    try {
      setRedeemingR3Disagree(true);
      setVerifyFail(false);

      const result = await callAPI({
        method: 'POST',
        url: '/otp/redeem-round3-disagree/sms-v2-verify',
        body: { refCode, otpCode },
      });

      setRedeemingR3Disagree(false);
      setVerifyFail(false);
      Cookies.remove('mobile_timer_ends_at');
      onSuccess(result);
    } catch (error) {
      console.error(new Error(`Error while trying to redeemR3Disagree: ${_.get(error, 'message')}`));
      setRedeemingR3Disagree(false);
      setVerifyFail(true);
      setErrorMessage(error.statusCode === 401 ? 'รหัสยืนยัน (OTP) 6 หลักไม่ถูกต้อง' : _.get(error, 'message'));
    }
  };

  const [postponing, setPostponing] = useState(false);
  const _onTGATTPATPostpone = async () => {
    if (postponing) return;
    try {
      setPostponing(true);
      setVerifyFail(false);

      const result = await callAPI({
        method: 'POST',
        url: '/otp/tgattpat-postpone/sms-v2-verify',
        body: { ...bodyParams, refCode, otpCode }
      });

      setPostponing(false);
      setVerifyFail(false);
      Cookies.remove('mobile_timer_ends_at');
      onSuccess(result);
    } catch (error) {
      console.error(new Error(`Error while trying to postpone TGATTPAT: ${_.get(error, 'message')}`));
      setPostponing(false);
      setVerifyFail(true);
      setErrorMessage(error.statusCode === 401 ? 'รหัสยืนยัน (OTP) 6 หลักไม่ถูกต้อง' : _.get(error, 'message'));
    }
  }

  const _renderText = (flowCode) => {
    switch (flowCode) {
      case 'offer-reply':
        return isCanceled
          ? 'ยืนยัน OTP และยืนยันการสละสิทธิ์'
          : offerId
          ? 'ยืนยัน OTP และยืนยันสิทธิ์'
          : 'ยืนยัน OTP และยืนยันการไม่ใช้สิทธิ์';
      case 'apply-round3':
        return 'ยืนยัน OTP และยืนยันการสมัคร';
      case 'reprocess-round3':
        return 'ยืนยัน OTP และยืนยันการขอประมวลผลครั้งที่ 2';
      case 'apply-exams':
        return 'ยืนยัน OTP และยืนยันการสมัครสอบ';
      case 'update-exam-sites':
        return 'ยืนยัน OTP และยืนยันการแก้ไขสนามสอบ';
      case 'update-fl-subject':
        return 'ยืนยัน OTP และยืนยันการแก้ไขวิชาสอบภาษาต่างประเทศ';
      case 'redeem-round3-agree':
        return 'ยืนยัน OTP และยืนยันการรับเงินสนับสนุนฯ';
      case 'redeem-round3-disagree':
        return 'ยืนยัน OTP และยืนยันไม่ขอรับเงินสนับสนุนฯ';
      case 'tgattpat-postpone':
        return bodyParams.action === 'cancel'
          ? 'ยืนยัน OTP และยกเลิกลงทะเบียนย้ายวันสอบ'
          : 'ยืนยัน OTP และยืนยันลงทะเบียนย้ายวันสอบ';
      default:
        return 'ยืนยัน OTP และยืนยันตัวตน';
    }
  };

  const _renderButton = () => {
    const formatText = (loading) => {
      const text = _renderText(flowCode);
      if (loading) return `กำลัง${text}...`;
      return text;
    };

    const data = {
      'offer-reply': {
        loading: offerReplying,
        onClick: _onOfferReply,
      },
      'apply-round3': {
        loading: applyingR3,
        onClick: _onApplyRound3,
      },
      'set-zero-round3': {
        loading: s0Submitting,
        onClick: _onSetZero,
      },
      'reprocess-round3': {
        loading: reprocessingR3,
        onClick: _onReprocessR3,
      },
      'apply-exams': {
        loading: applyingExams,
        onClick: _onApplyExams,
      },
      'update-exam-sites': {
        loading: updatingSites,
        onClick: _onUpdateSites,
      },
      'update-fl-subject': {
        loading: updatingFlSubject,
        onClick: _onUpdateFlSubject,
      },
      'redeem-round3-agree': {
        loading: redeemingR3Agree,
        onClick: _onRedeemR3Agree,
      },
      'redeem-round3-disagree': {
        loading: redeemingR3Disagree,
        onClick: _onRedeemR3Disagree,
      },
      'tgattpat-postpone': {
        loading: postponing,
        onClick: _onTGATTPATPostpone
      }
    }[flowCode];

    if (!data) return null;
    const { loading, onClick } = data;
    return (
      <div className="action">
        <a
          className={cn('btn-main', {
            loading,
            disabled,
            'cursor-pointer': !disabled,
          })}
          onClick={onClick}
        >
          {formatText(loading)}
        </a>
      </div>
    );
  };

  if (requesting || !refCode) return null;
  const formattedMobile = [mobile.slice(0, 3), mobile.slice(3, 6), mobile.slice(-4)].join(' ');
  const disabled = otpCode.length < 6;
  return (
    <section>
      {verifyFail ? (
        <div className="t-box -error">{t(errorMessage)}</div>
      ) : ['set-zero-round3'].includes(studentType) ? null : (
        <div className="t-box -info -half -nofi">
          {t('ระบบได้ส่งรหัสยืนยัน (OTP) 6 หลัก ไปยังเบอร์มือถือ')} <strong>{formattedMobile}</strong>{' '}
          {t('ของท่าน กรุณาตรวจสอบ SMS')} {_renderText(flowCode)}
        </div>
      )}

      <div className="text-center">
        <h2>{t('รหัสยืนยัน (OTP) ที่ได้รับทางมือถือ')}</h2>
        <p className="sub-title">
          <small>
            {t('โค้ดอ้างอิง')}: {refCode}
          </small>
        </p>
        <form>
          <div className="t-otp">
            {Array(6)
              .fill()
              .map((_, index) => (
                <input
                  id={`d${index + 1}`}
                  key={index}
                  disabled={verifying}
                  ref={digitRefs[index]}
                  onChange={(e) => {
                    onOTPChange(index, e.target.value);
                    if (e.target.value && index < 5) {
                      digitRefs[index + 1].current.focus();
                    }
                  }}
                  value={digits[index]}
                  type="number"
                  pattern="[0-9]*"
                  autoComplete="one-time-code"
                  maxLength="1"
                  required
                />
              ))}
          </div>
        </form>
        {Boolean(timer) && (
          <p>
            {t('กรุณากรอกภายใน')}{' '}
            <b className="color-counter">
              {parseInt(timer / 60, 10)}:{_.padStart(timer % 60, 2, '0')}
            </b>{' '}
            {t('นาที')}
          </p>
        )}
      </div>
      {timer ? (
        <h4 className="text-center">{t('หากไม่ได้รับรหัสยืนยัน (OTP) กรุณารอหมดเวลาแล้วคลิกขอรหัสใหม่')}</h4>
      ) : (
        <h4 className="text-center">
          {t('หากไม่ได้รับรหัสยืนยัน (OTP) ')} &nbsp;
          <a className="cursor-pointer" onClick={_onResendOtp}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.99997 2.5C9.67123 2.5 9.35923 2.52735 9.06328 2.56429C8.95307 2.57588 8.84628 2.60934 8.74916 2.6627C8.65203 2.71606 8.56653 2.78826 8.49764 2.87506C8.42876 2.96186 8.37787 3.06154 8.34797 3.16824C8.31806 3.27495 8.30974 3.38655 8.32349 3.49651C8.33723 3.60647 8.37277 3.71258 8.42802 3.80865C8.48327 3.90471 8.55712 3.98879 8.64525 4.05596C8.73339 4.12314 8.83403 4.17206 8.9413 4.19988C9.04857 4.22769 9.16031 4.23383 9.26999 4.21794C9.52153 4.18654 9.76287 4.16667 9.99997 4.16667C13.2317 4.16667 15.8333 6.76823 15.8333 10C15.8333 13.2318 13.2317 15.8333 9.99997 15.8333C6.7682 15.8333 4.16663 13.2318 4.16663 10C4.16663 8.90206 4.47448 7.88281 4.99997 7.00928V7.08333C4.9984 7.19376 5.01881 7.3034 5.05998 7.40587C5.10116 7.50834 5.1623 7.60161 5.23983 7.68025C5.31737 7.75889 5.40976 7.82134 5.51164 7.86396C5.61353 7.90659 5.72286 7.92853 5.8333 7.92853C5.94374 7.92853 6.05307 7.90659 6.15495 7.86396C6.25684 7.82134 6.34923 7.75889 6.42677 7.68025C6.5043 7.60161 6.56544 7.50834 6.60662 7.40587C6.64779 7.3034 6.66819 7.19376 6.66663 7.08333V4.79655V4.58333C6.66661 4.36233 6.57881 4.15038 6.42253 3.9941C6.26626 3.83783 6.05431 3.75002 5.8333 3.75H3.3333C3.22287 3.74844 3.11324 3.76884 3.01076 3.81002C2.90829 3.8512 2.81502 3.91233 2.73638 3.98987C2.65774 4.0674 2.59529 4.1598 2.55267 4.26168C2.51005 4.36356 2.4881 4.4729 2.4881 4.58333C2.4881 4.69377 2.51005 4.80311 2.55267 4.90499C2.59529 5.00687 2.65774 5.09926 2.73638 5.1768C2.81502 5.25434 2.90829 5.31547 3.01076 5.35665C3.11324 5.39783 3.22287 5.41823 3.3333 5.41667H4.0706C3.08822 6.68496 2.49997 8.27566 2.49997 10C2.49997 14.1324 5.86757 17.5 9.99997 17.5C14.1324 17.5 17.5 14.1324 17.5 10C17.5 5.8676 14.1324 2.5 9.99997 2.5Z"
                fill="currentColor"
              />
            </svg>
            {t('คลิกเพื่อรับรหัสยืนยัน OTP ใหม่อีกครั้ง')}
          </a>
        </h4>
      )}
      {_renderButton()}
    </section>
  );
};

export default MobileVerification;
