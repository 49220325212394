/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import cn from 'classnames';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useAdmission from 'hooks/round3/useAdmission';

const ModalChooseRound3 = ({ visible, favorites, studentStatus, onSelect, onClose }) => {
  const { t } = useTranslation();
  const courseMap = useSelector((state) => _.get(state, 'app.courseMap')) || {};
  const { availables, nextPriority } = useAdmission(favorites, studentStatus);

  const [favoriteId, setfavoriteId] = useState();
  const disabled = !favoriteId;

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.removeAttribute('style');
    }
  }, [visible]);

  const _onSelect = () => {
    onSelect(favoriteId, nextPriority);
    onClose();
  }

  if (!visible) return null;
  return (
    <div className="t-modal -scroll" style={{ visibility: 'visible', opacity: 1 }}>
      <div className="content" style={{ opacity: 1, height: 'initial', padding: 20, overflowY: 'auto' }}>
        <div className="text-center">
          <h1 className="color-blue">{t('อันดับที่')} {nextPriority}</h1>
          <h4 className="sub">{t('กรุณาเลือกสาขาวิชาที่จะสมัคร')}</h4>
          <ul className="r-choose">
            {(availables || []).map((row, index) => {
              const lang = 'th';
              const project = courseMap[row.program_project];
              const majorName = _.get(project, `major_name_${lang}`);
              const projectName = _.get(project, `project_name_${lang}`);
              if (!project) return null;
              return (
                <li key={project._id}>
                  <label>
                    <div className="num"><b>{index + 1}</b></div>
                    <input
                      type="radio"
                      name="r-3"
                      checked={favoriteId === row._id}
                      onChange={() => setfavoriteId(row._id)} />
                    <span
                      className="u-logo"
                      style={{ backgroundImage: `url('/assets/img/logo/${_.get(project, 'university_id')}.png')` }}
                    />
                    <strong>{_.get(project, `university_name_${lang}`)}</strong>
                    {_.get(project, `campus_name_${lang}`)}
                    <br />
                    {_.get(project, `faculty_name_${lang}`)}
                    <br />
                    {_.get(project, `field_name_${lang}`)}
                    <br />
                    {majorName && majorName !== '0' ? (
                      <>
                        {t('วิชาเอก')}
                        {majorName}
                        <br />
                      </>
                    ) : null}
                    {_.get(project, `program_name_${lang}`)}
                    <br />
                    {lang === 'th' ? `(${_.get(project, `program_type_name_th`)})` : null}
                    {projectName && projectName !== '0' ? (
                      <>
                        <br />
                        {t('โครงการ ')}
                        {projectName}
                      </>
                    ) : null}
                  </label>
                </li>
              )
            })}
          </ul>
        </div>
        <div className="_space"></div>
        <div className="content-bottom">
          <a className="btn-cancel cursor-pointer" onClick={onClose}>
            <span className="icon">×</span> {t('ยกเลิก')}
          </a>
          <a className={cn('btn-main', { disabled, 'cursor-pointer': !disabled })} onClick={disabled ? () => { } : _onSelect}> {t('ยืนยัน')}</a>
        </div>
      </div>
    </div >
  );
};

export default ModalChooseRound3;