/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { callAPI, notApplicable, getProjectScore, getFullScore } from 'helpers';
import { Link } from 'react-router-dom';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const statusMap = {
  pending: 'รอการชำระเงิน',
  paid: 'ชำระเงินเรียบร้อยแล้ว',
  canceled: 'ยกเลิกใบแจ้งชำระเงิน',
};
const AdmissionApplied = ({ studentStatus, admissions, offerProjects, onEdit, isExpired }) => {
  const { t } = useTranslation();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});
  const courseMap = useSelector((state) => _.get(state, 'app.courseMap')) || {};

  const [sectionActive, setSectionActive] = useState(true);
  const [editActive, setEditActive] = useState(false);
  const [errorActive, setErrorActive] = useState({});

  const paidAdmissions = admissions.filter(({ status }) => status === 'paid');
  const paidAmount = _.sumBy(paidAdmissions, 'payment_amount') + _.sumBy(paidAdmissions, 'redeem_amount');
  const paidSubjectCount = (_.get(_.maxBy(paidAdmissions, 'total_amount'), 'program_projects') || []).length;

  let recentAdmission = _.last(isExpired ? paidAdmissions : admissions) || {};
  const recentPaidAdmission = _.last(paidAdmissions) || {};
  const quotaUsed = admissions.length;

  // if (isExpired) {
  //   recentAdmission.program_projects = offerProjects;
  // }

  const [invoiceOpening, setInvoiceOpening] = useState(false);
  const onInvoicePdfOpen = async () => {
    if (invoiceOpening) return;
    try {
      setInvoiceOpening(true);
      const ua = window.navigator.userAgent;
      const { pdf } = await callAPI({
        genPdf: true,
        method: 'POST',
        url: '/apply-round3/invoice-pdf'
        // url: '/applicants/me/apply-round3-invoice-pdf',
      });

      if (ua.includes('Safari')) {
        window.location.href = pdf;
      } else {
        window.open(pdf, '_blank');
      }

      setInvoiceOpening(false);
    } catch (error) {
      console.error(`Error while trying to download invoice pdf: ${error.message}`);
      setInvoiceOpening(false);
    }
  };

  const [summaryOpening, setSummaryOpening] = useState(false);
  const onSummaryPdfOpen = async () => {
    if (summaryOpening) return;
    try {
      setSummaryOpening(true);
      const ua = window.navigator.userAgent;
      const { pdf } = await callAPI({
        genPdf: true,
        method: 'POST',
        url: '/apply-round3/summary-pdf'
        // url: '/applicants/me/apply-round3-summary-pdf',
      });

      if (ua.includes('Safari')) {
        window.location.href = pdf;
      } else {
        window.open(pdf, '_blank');
      }

      setSummaryOpening(false);
    } catch (error) {
      console.error(`Error while trying to download summary pdf: ${error.message}`);
      setSummaryOpening(false);
    }
  };

  if (!recentAdmission._id) {
    return (
      <div className={cn('t-collapse', { active: sectionActive })}>
        <div className='t-content'>
          <h3>{t('ท่านไม่ได้สมัครในรอบนี้')}</h3>
        </div>
      </div>
    );
  }

  return (
    <div className={cn('t-collapse', { active: sectionActive })}>
      <h3 className='r-3-bar t-toggle' onClick={() => setSectionActive((_active) => !_active)}>
        <i className='i-apply'></i>
        {t('สาขาวิชาที่สมัคร')}
        {(recentAdmission.program_projects || []).length ? ` (${(recentAdmission.program_projects || []).length}/10)` : ''}
        <i className='i-toggle i-down-teal' />
      </h3>
      <div className='t-content'>
        <h4>
          <b>
            {t('การสมัครครั้งที่')} {recentAdmission.apply_id} / {t('ยืนยันครั้งที่')} {recentAdmission.confirm_id}
          </b>
        </h4>
        {recentAdmission.status === 'paid' ? (
          <p>
            <b className='color-green'>{t('ชำระเงินเรียบร้อยแล้ว')}</b>
          </p>
        ) : recentAdmission.status === 'pending' ? (
          <p>
            {t('ท่านยืนยันการสมัครแล้ว')} <b className='color-red'>{t('รอการชำระเงิน')}</b>
          </p>
        ) : null}
        <ul className='r-choose -confirmed'>
          {(recentAdmission.program_projects || []).map(({ _id, priority }) => {
            const project = courseMap[_id];
            const majorName = _.get(project, `major_name_th`);
            const projectName = _.get(project, `project_name_th`);
            const errors = notApplicable(profile, project, { studentStatus, favorites: [], nextPriority: priority });
            return (
              <li key={priority} className={cn({ error: Boolean(errors.length) })}>
                <label>
                  <div className='order'>
                    {t('อันดับที่')} {priority}
                  </div>
                  <h4 className='score'>
                    {t('คะแนน')} {getProjectScore(profile, project, priority)}/{getFullScore(project)}
                  </h4>
                  <span className='u-logo' style={{ backgroundImage: `url('/assets/img/logo/${_.get(project, 'university_id')}.png')` }} />
                  <strong>{_.get(project, `university_name_th`)}</strong>
                  {_.get(project, `campus_name_th`)}
                  <br />
                  {_.get(project, `faculty_name_th`)}
                  <br />
                  {_.get(project, `field_name_th`)}
                  <br />
                  {majorName && majorName !== '0' ? (
                    <>
                      {t('วิชาเอก')}
                      {majorName}
                      <br />
                    </>
                  ) : null}
                  {_.get(project, `program_name_th`)}
                  <br />
                  {_.get(project, `program_type_name_th`) ? `(${_.get(project, `program_type_name_th`)})` : null}
                  {projectName && projectName !== '0' ? (
                    <>
                      <br />
                      {t('โครงการ ')}
                      {projectName}
                    </>
                  ) : null}
                </label>
                {Boolean(errors.length) && (
                  <div className={cn('t-collapse', { active: errorActive[_id] })}>
                    <div className='t-toggle' onClick={() => setErrorActive((_map) => ({ ..._map, [_id]: !_map[_id] }))}>
                      {t('ไม่ผ่านเงื่อนไขการรับ ไม่สามารถสมัครได้')}
                      <i className='i-toggle i-down-red'></i>
                    </div>
                    <div className='t-content'>
                      <ul>
                        {errors.map(({ messages = [], key, label, expected, value }) => {
                          if (messages.length > 1) {
                            return (
                              <li key={key}>
                                <i className='i-no'></i>
                                <span>{messages[0].label}</span>
                                {messages.map(({ expected, value }, index) => (
                                  <div key={index}>
                                    <b>{expected}</b>
                                    <span>
                                      ({t('ผู้สมัคร')}: <b className='color-red'>{value}</b>)
                                    </span>
                                  </div>
                                ))}
                              </li>
                            );
                          }
                          if (!value && value !== 0) {
                            return (
                              <li key={key}>
                                <i className='i-no'></i>
                                <span>{label}</span>
                              </li>
                            );
                          }
                          return (
                            <li key={key}>
                              <i className='i-no'></i>
                              <span>{label}</span>
                              <b>{expected}</b>
                              <span>
                                ({t('ผู้สมัคร')}: <b className='color-red'>{value}</b>)
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                )}
              </li>
            );
          })}
        </ul>
        <div className='t-summary'>
          <dl>
            <dt>{t('จำนวนอันดับสาขาวิชา (เลือกได้สูงสุด 10 อันดับ)')}</dt>
            <dd>
              <b>{(recentAdmission.program_projects || []).length}</b> {t('อันดับ')}
            </dd>
          </dl>
          {!paidAmount ? (
            <dl>
              <dt>
                <span className='color-red'>{t('ยอดค้างชำระทั้งหมด')}</span>
              </dt>
              <dd className='color-red'>
                <b>{recentAdmission.total_amount.toFixed(2)}</b> {t('บาท')}
              </dd>
            </dl>
          ) : paidAmount < recentAdmission.total_amount ? (
            <>
              <dl>
                <dt>
                  <span>
                    {t('ยอดชำระเงิน')} ({t('ชำระมาแล้ว')} {paidSubjectCount} {t('อันดับ')})
                  </span>
                </dt>
                <dd>
                  <b>{recentAdmission.program_projects.length - paidSubjectCount}</b> {t('อันดับ')}
                </dd>
              </dl>
              <dl>
                <dt>
                  <span className='color-red'>
                    {t('ยอดค้างชำระทั้งหมด')} ({t('จ่ายเพิ่ม')} {recentAdmission.program_projects.length - paidSubjectCount} {t('อันดับ')})
                  </span>
                </dt>
                <dd className='color-red'>
                  <b>{recentAdmission.payment_amount.toFixed(2)}</b> {t('บาท')}
                </dd>
              </dl>
            </>
          ) : paidAmount >= recentAdmission.total_amount ? (
            <>
              {paidSubjectCount > recentAdmission.program_projects.length && (
                <dl>
                  <dt>
                    <span className='color-teal'>
                      {t('สามารถเลือกได้อีก')} <b>{paidSubjectCount - recentAdmission.program_projects.length}</b> {t('อันดับโดยไม่ต้องชำระเงินเพิ่ม')}
                    </span>
                  </dt>
                </dl>
              )}
              <dl>
                <dt>
                  <span className='color-green'>{t('ยอดค้างชำระทั้งหมด')}</span>
                </dt>
                <dd className='color-green'>
                  <b>0.00</b> {t('บาท')}
                </dd>
              </dl>
            </>
          ) : null}
        </div>
        {recentAdmission.status === 'paid' ? (
          <>
            <div className='t-note'>
              {t('ท่านทำการสมัครครั้งที่')} {recentAdmission.apply_id} / {t('ยืนยันครั้งที่')} {recentAdmission.confirm_id} {t('และชำระเงินเรียบร้อยแล้ว หลังจากนี้ให้รอประกาศผล วันที่ 20 พ.ค. 68 และยืนยันสิทธิ์ วันที่ 20-21 พ.ค. 68')}
            </div>
            <p>
              <a className='btn-main -large cursor-pointer' onClick={onSummaryPdfOpen}>
                {t('พิมพ์ใบสรุปการสมัคร')}
              </a>
            </p>
          </>
        ) : recentAdmission.status === 'pending' ? (
          <div className='t-note'>
            {t('สามารถชำระเงินผ่าน QR Code ทุกธนาคาร (ฟรี ไม่มีค่าธรรมเนียม) หรือชำระเงินผ่านเคาน์เตอร์เซอร์วิส (เซเว่น อีเลฟเว่น - ค่าธรรมเนียม 10 บาทต่อรายการ) ได้')}
            <p>
              <img src='/assets/img/logo/banks.png' width='328' />
              <img src='/assets/img/logo/cs-7-11.png' width='95' />
            </p>
            <p>{t('เมื่อคลิกปุ่มพิมพ์ใบสมัคร ระบบจะสร้างใบแจ้งชำระเงินในรูปแบบ PDF ให้ท่านพิมพ์หรือจับภาพหน้าจอเพื่อนำไปชำระเงิน โดยให้ชำระเงินภายในเวลา 23.29 น. ของวันที่ 12 พ.ค. 68')}</p>
            <p>{t('หมายเหตุ: การสมัครนี้จะสมบูรณ์ เมื่อท่านกดพิมพ์ใบแจ้งชำระเงิน และชำระเงินในระบบครบถ้วนแล้ว')}</p>
            <h4 className={cn('h-status color-red')}>
              {t('สถานะ')}: {statusMap[recentAdmission.status]}
            </h4>
            <p>
              <a className={cn('btn-main -large cursor-pointer', { loading: invoiceOpening })} onClick={onInvoicePdfOpen}>
                {t('พิมพ์ใบแจ้งชำระเงิน')} {recentAdmission.payment_amount} บาท
              </a>
            </p>
            <h4>
              <a className='cursor-pointer' onClick={() => window.location.reload()}>
                <i className='i-reload' />
                {t('คลิกเพื่อตรวจสอบสถานะล่าสุด')}
              </a>
            </h4>
            {recentPaidAdmission.confirm_id && (
              <>
                <br />
                <h4 className='h-status color-red'>
                  {t('หากไม่มีการชำระเงิน ระบบจะถือว่าท่านยังยืนยัน')}{' '}
                  <a onClick={onSummaryPdfOpen} className='cursor-pointer color-red'>
                    <u>
                      {t('การสมัครครั้งที่')} {recentPaidAdmission.apply_id} / {t('ยืนยันครั้งที่')} {recentPaidAdmission.confirm_id}
                    </u>
                  </a>
                </h4>
              </>
            )}
          </div>
        ) : null}
        {isExpired ? null : recentAdmission.confirm_id < 3 ? (
          <div className={cn('t-collapse', { active: editActive })} style={{ borderBottom: 'none' }}>
            <a className='t-toggle color-orange _heading' onClick={() => setEditActive((_active) => !_active)}>
              {t('ต้องการแก้ไขสาขาวิชาที่สมัคร')}?
              <i className='i-toggle i-down-red' style={{ width: 14, height: 16 }} />
            </a>
            <div className='t-content'>
              <h4>
                <b>
                  {t('ท่านตัดสินใจไปแล้ว')} {quotaUsed} {t('ครั้ง')}
                </b>
              </h4>
              <div className='i-hearts'>
                <i className={cn('i-heart', { '-blink': quotaUsed === 2, '-used': quotaUsed > 2 })} />
                <i className={cn('i-heart', { '-blink': quotaUsed === 1, '-used': quotaUsed > 1 })} />
                <i className={cn('i-heart', { '-blink': quotaUsed === 0, '-used': quotaUsed > 0 })} />
                <small>
                  {t('สามารถตัดสินใจได้อีก')} {3 - quotaUsed} {t('ครั้ง')}
                </small>
              </div>
              <a className='btn-ghost cursor-pointer' onClick={onEdit}>
                {t('ดำเนินการแก้ไขสาขาวิชาที่สมัคร')} →
              </a>
            </div>
          </div>
        ) : (
          <Link to='/profile/set-zero' className='btn-underline cursor-pointer'>
            {t('ดำเนินการสมัครใหม่')} (Set Zero)
          </Link>
        )}
      </div>
    </div>
  );
};

export default AdmissionApplied;
