import { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { getNewestTicket } from 'helpers';

const useEditEducation = (profile = {}, pendingTickets = []) => {
  const schools = profile.schools || [];
  const maxGpaxSchool =
    _.maxBy(schools, (_school) => Math.max(_school.gpax5_score || 0, _school.gpax6_score || 0)) || {};

  const updateNameTicket = getNewestTicket(pendingTickets, 'update_name');
  const updateSchoolTicket = getNewestTicket(pendingTickets, ['update_school', 'update_foreign_school']);
  const updateGpaxTicket = getNewestTicket(pendingTickets, 'update_gpax');

  const tSchoolName = updateSchoolTicket.valueOf('school_name');
  const tSchoolProvinceName = updateSchoolTicket.valueOf('school_province_name');
  const tProgramCode = updateGpaxTicket.valueOf('program_code');
  const tSchoolYear = updateGpaxTicket.valueOf('school_year');

  // At the beginning
  let isComplete = Boolean(
    (tSchoolName || profile.school_name) &&
      (tSchoolProvinceName || profile.school_province_name) &&
      (tProgramCode || profile.program_code) &&
      (tSchoolYear || profile.school_year) &&
      // [At the beginning]
      `${tSchoolYear || profile.school_year}` === '2567'
      ? (updateGpaxTicket.valueOf('gpax5_score') || profile.gpax5_score)
      : (updateGpaxTicket.valueOf('gpax6_score') || profile.gpax6_score)

      //[ When GPAX6 is ready]
      // updateGpaxTicket.valueOf('gpax6_score') || profile.gpax6_score
  );

  if (!isComplete) {
    isComplete = Boolean(
      (profile.schools || []).length === 1 &&
        maxGpaxSchool.school_name &&
        maxGpaxSchool.school_province_name &&
        maxGpaxSchool.program_code &&
        maxGpaxSchool.school_year &&
        // [At the beginning]
        `${maxGpaxSchool.school_year}` === '2567'
        ? maxGpaxSchool.gpax5_score
        : maxGpaxSchool.gpax6_score
        
        // [When GPAX6 is ready]
        // maxGpaxSchool.gpax6_score
    );
  }

  const educationRef = useRef();

  const [collapsed, setCollapsed] = useState(isComplete);
  useEffect(() => setCollapsed(isComplete), [isComplete]);

  const hasTickets = Boolean(updateNameTicket.ticket || updateSchoolTicket.ticket || updateGpaxTicket.ticket);
  const [waiting, setWaiting] = useState(hasTickets);
  useEffect(() => setWaiting(hasTickets), [hasTickets]);

  const [initialState, setInitialState] = useState({
    schoolName: profile.school_name,
  });

  useEffect(() => {
    if (!collapsed) {
      setInitialState(educationRef.current.getState());
    }
  }, [collapsed]);

  return {
    educationRef,
    educationCollapsed: collapsed,
    onEducationCollapsedChange: (collapsed, waiting) => {
      setCollapsed(collapsed);
      setWaiting(hasTickets ? true : waiting);
    },
    educationWaiting: waiting,
    educationInitialState: initialState,
  };
};

export default useEditEducation;
