import { useSelector } from 'react-redux';
import _ from 'lodash';
import { getNewestTicket } from 'helpers';

const useEducation = (profile, pendingTickets) => {
  const selectedSchool = useSelector((state) => _.get(state, 'app.education')) || {};
  const { valueOf } = getNewestTicket(pendingTickets, 'update_gpax');

  return {
    schoolYear: `${
      selectedSchool.school_year ||
      valueOf('school_year') ||
      profile.school_year ||
      _.get(profile.schools, '0.school_year') ||
      ''
    }`,
    programCode: `${
      selectedSchool.program_code ||
      valueOf('program_code') ||
      profile.program_code ||
      _.get(profile.schools, '0.program_code') ||
      ''
    }`,
  };
};

export default useEducation;
