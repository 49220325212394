/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import _ from 'lodash';
import classNames from 'classnames';

// Hooks
import { useTranslation } from 'react-i18next';
import useSectionVNETScore from './score-hooks/useSectionVNETScore';

// Helpers
import { errorMessages, validateVnetFile } from 'validations';
import { callAPI } from 'helpers';

// Components
import ErrorBox from 'components/registrations/ErrorBox';

const ID = 'section-vnet';
const SectionVNETScore = forwardRef(
  ({ initialState, profile = {}, pendingTickets = [], collapsed, onCollapsedChange, waiting, editable }, ref) => {
    const { t } = useTranslation();
    const {
      schoolYear,
      programCode,
      scores,
      fnName,
      validateScore,
      vnetFile,
      onVnetFileChange,
      clearVnetFile,
      ...scoreProps
    } = useSectionVNETScore(profile, pendingTickets);

    const [errors, setErrors] = useState([]);

    const onScoreChange = (fieldName) => (e) => {
      const { max } = _.find(scores, (_score) => _score.fieldName === fieldName);
      const value = e.target.value;
      if (value && isNaN(value)) return;
      if (value < 0) return;
      if (value > max) return;
      if (value.includes('.') && value.split('.').pop().length > 2) return;
      scoreProps[fnName(fieldName)](e.target.value);
    };

    useImperativeHandle(ref, () => ({
      getState: () => scores.reduce((state, { fieldName }) => ({ ...state, [fieldName]: scoreProps[fieldName] }), {}),
    }));

    const noChanges = scores.reduce(
      (result, { fieldName }) => result && parseFloat(scoreProps[fieldName] || '0', 10) === parseFloat(initialState[fieldName] || '0', 10),
      true
    );
    const [saving, setSaving] = useState(false);
    const onButtonClick = async () => {
      const _messages = errorMessages(
        scores.reduce(
          (_errors, { fieldName }) => ({
            ..._errors,
            [fieldName]: validateScore(fieldName, scoreProps[fieldName]),
          }),
          { vnetFile: validateVnetFile(vnetFile) }
        )
      );
      if (noChanges) {
        onCancel();
      } else if (_messages.length) {
        setErrors(_messages);
        const container = document.getElementById(ID);
        if (container) {
          container.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        setErrors([]);

        if (saving) return;
        try {
          setSaving(true);
          const formData = new FormData();
          formData.append('vnetFile', vnetFile);
          scores.forEach(({ fieldName }) => {
            formData.append(fieldName, scoreProps[fieldName]);
          });

          await callAPI({
            method: 'PUT',
            url: '/applicants/me/vnet-scores',
            formData,
          });

          onCollapsedChange(true, true);
          clearVnetFile();
          setSaving(false);
        } catch (error) {
          const errorMessages = (_.get(error, 'error.errors') || []).map(({ msg }) => msg);
          setErrors(errorMessages);
          const container = document.getElementById(ID);
          if (container) {
            container.scrollIntoView({ behavior: 'smooth' });
          }

          console.error(`Error while trying to save V-NET score data: ${errorMessages.toString()}`);
          setSaving(false);
        }
      }
    };

    const onCancel = () => {
      scores.forEach(({ key, fieldName }) => {
        scoreProps[fnName(fieldName)](initialState[fieldName]);
      });
      onCollapsedChange(true, waiting);
    };

    if (programCode !== '3') return null;
    if (collapsed) {
      const existedScores = scores.filter(({ fieldName }) => scoreProps[fieldName]);
      return (
        <div className={classNames('t-box', { '-waiting': waiting })}>
          <h2 className="title">
            {waiting && <span>{t('(รอตรวจสอบ)')}&nbsp;</span>}
            {t('ข้อมูลคะแนน V-NET')}
            {editable && (
              <a className="cursor-pointer" onClick={() => onCollapsedChange(false, waiting)}>
                {t('ขอแก้ไขข้อมูล')}
              </a>
            )}
          </h2>
          {existedScores.length ? (
            <table className="table-line col-last-num">
              <thead>
                <tr>
                  <th>{t('วิชา')}</th>
                  <th>{t('คะแนน')}</th>
                </tr>
              </thead>
              <tbody>
                {existedScores.map(({ key, label, fieldName }) => (
                  <tr key={key}>
                    <td>{label}</td>
                    <td>{scoreProps[fieldName] || t('ยังไม่มีข้อมูล')}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div>{t('ไม่มีข้อมูลคะแนน หากต้องการเพิ่มคะแนน กรุณากดขอแก้ไขข้อมูล')}</div>
          )}
        </div>
      );
    }

    return (
      <div id={ID} className="t-box -choose">
        <h2 className="title">
          {t('แก้ไขข้อมูลคะแนน V-NET')}
          <a className="cursor-pointer" onClick={onCancel}>
            {t('ยกเลิก')}
          </a>
        </h2>
        <ErrorBox isFull hidden={!errors.length} errors={errors} />
        <div className="t-row">
          {scores.map(({ key, fieldName, label }) => {
            return (
              <div key={key} className="col-1c">
                <h3>{label}</h3>
                <label>
                  <b>{t('คะแนน')}</b>
                  <input type="text" value={scoreProps[fieldName]} onChange={onScoreChange(fieldName)} />
                </label>
              </div>
            );
          })}
        </div>
        <hr />
        {!noChanges && (
          <>
            <div className="t-row">
              <div className="col -full">
                <label>{t('แนบหลักฐาน')}</label>
              </div>
              <div className="col required">
                <div className="transcript">
                  <img src="/assets/img/v-net.png" alt="V-NET" width="220" height="305" />
                </div>
                <label>{t('ใบคะแนน V-NET')}</label>
                <input name="vnet-file" type="file" accept="image/*" onChange={onVnetFileChange} />
              </div>
            </div>
            <hr />
          </>
        )}
        <a className="btn-main cursor-pointer" onClick={onButtonClick}>
          {t('ยืนยันข้อมูลคะแนน V-NET')}
        </a>
      </div>
    );
  }
);

export default SectionVNETScore;
