/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { callAPI } from 'helpers';
import _ from 'lodash';

// Hooks
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Data
import { TGATTPATINFOS, ALEVELINFOS } from 'data/exam-days';

const ExamAnswers = ({ type }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [recheckables, setRecheckables] = useState([]);

  const subjectInfos = type === 'alevel' ? ALEVELINFOS : TGATTPATINFOS;
  const subjectMap = subjectInfos.reduce((_map, { date, dayIndex, examSlots }) => {
    return _.merge(
      _map,
      _.keyBy(
        examSlots.map((slot, slotIndex) => _.merge(slot, { date, dayIndex, slotIndex })),
        'subjectKey'
      )
    );
  }, {});

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        setLoading(true);
        const { applications } = await callAPI({
          url: '/applicants/me/exam-applications',
          query: { type }
        });
        const recheckables = applications
          .filter((app) => app.recheckable)
          .sort((a, b) => a.subject_name.localeCompare(b.subject_name))
          .slice();

        if (!recheckables.length) {
          navigate('/profile');
        }

        setRecheckables(recheckables);
        setLoading(false);
      } catch (error) {
        console.error(`Error while trying to fetch applications: ${error.message}`);
        setLoading(false);
      }
    };
    fetchApplications();
  }, [navigate, type]);

  useEffect(() => {
    const classNames = document.getElementById('root').className.split(' ');
    if (loading) {
      document.getElementById('root').className = _.uniq(classNames.concat(['is-loading'])).join(' ');
    } else {
      document.getElementById('root').className = classNames.filter((name) => name !== 'is-loading').join(' ');
    }
  }, [loading]);

  return (
    <main className="site-body">
      {loading && <div className="t-loading" />}
      <div className="t-form">
        <h1>{t('ดูคำตอบและคะแนนข้อสอบ')}</h1>
        <div className="xm-info xm-answers">
          <h2>{t('รายชื่อวิชาที่ขอทบทวนผลคะแนนสอบ')}</h2>
          <div className="list">
            <div className="sub">
              <h3>
                {t('ขอทบทวนผลคะแนนสอบ')}{' '}
                <b>
                  {(recheckables || []).length} {t('วิชา')}
                </b>
              </h3>
            </div>
            <div className="_space"></div>
            {(recheckables || [])
              .map((app, index) => {
                const subject = subjectMap[app.subject_name] || {};
                return (
                  <Link key={app._id} to={`/profile/exam-answers/${app._id}`} className="xm-box">
                    <div className="num">
                      <b>{index + 1}</b>
                    </div>
                    <ul>
                      <li>
                        {t('ชื่อวิชา')}{' '}
                        <b>
                          {subject.subjectKey} {subject.subjectName}
                        </b>
                      </li>
                      <li>
                        {t('รหัสวิชา')} <b>{subject.subjectCode}</b>
                      </li>
                      <li className="flex items-center gap-1">
                        {t('ประเภทข้อสอบ')}{' '}
                        <b className="flex items-center gap-1">
                          {app.selected_exam_type === 'cbt' ? (
                            <>
                              <i className="i-computer" />
                              {t('คอมพิวเตอร์')}
                            </>
                          ) : (
                            <>
                              <i className="i-paper" />
                              {t('กระดาษ')}
                            </>
                          )}
                        </b>
                      </li>
                      {app.is_empty_sheet && (
                        <li>
                          <b className="color-orange">
                            {t('หมายเหตุ:')} {t('ไม่ฝนคำตอบ')}
                          </b>
                        </li>
                      )}
                      {app.is_not_has_testset && (
                        <li>
                          <b className="color-orange">
                            {t('หมายเหตุ:')} {t('ไม่ฝนเลขชุด')}
                          </b>
                        </li>
                      )}
                    </ul>
                    <i className="i-answer"></i>
                  </Link>
                );
              })}
          </div>
        </div>
        <h4 className="text-center">
          <Link to="/profile">{t('กลับสู่หน้าข้อมูลการสอบ')} →</Link>
        </h4>
      </div>
    </main>
  );
};

export default ExamAnswers;
