/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import moment from 'moment';

// Hooks
import { useTranslation } from 'react-i18next';
import useSectionGEDScore from './score-hooks/useSectionGEDScore';

// Helpers
import { errorMessages, validateGedFile, validateGedDate } from 'validations';
import { callAPI } from 'helpers';

// Components
import ErrorBox from 'components/registrations/ErrorBox';

const ID = 'section-ged';
const SectionGEDScore = forwardRef(
  ({ initialState, profile = {}, pendingTickets = [], collapsed, onCollapsedChange, waiting, editable }, ref) => {
    const { t } = useTranslation();
    const {
      programCode,
      scores,
      fnName,
      validateScore,
      gedScore,
      gedDate,
      onGedDateChange,
      gedFile,
      onGedFileChange,
      ...scoreProps
    } = useSectionGEDScore(profile, pendingTickets);

    const [errors, setErrors] = useState([]);

    const onScoreChange = (fieldName) => (e) => {
      const { max } = _.find(scores, (_score) => _score.fieldName === fieldName);
      const value = e.target.value;
      if (value && isNaN(value)) return;
      if (value < 0) return;
      if (value > max) return;
      scoreProps[fnName(fieldName)](e.target.value ? parseInt(e.target.value, 10) : '');
    };

    useImperativeHandle(ref, () => ({
      getState: () =>
        scores.reduce((state, { fieldName }) => ({ ...state, [fieldName]: scoreProps[fieldName] }), {
          gedDate,
        }),
    }));

    const noChanges = scores.reduce(
      (result, { fieldName }) =>
        result && parseFloat(scoreProps[fieldName] || '0', 10) === parseFloat(initialState[fieldName] || '0', 10),
      gedDate === initialState.gedDate
    );

    const [saving, setSaving] = useState(false);
    const onButtonClick = async () => {
      const _messages = errorMessages(
        scores.reduce(
          (_errors, { fieldName }) => ({
            ..._errors,
            [fieldName]: validateScore(fieldName, scoreProps[fieldName]),
          }),
          {
            gedFile: validateGedFile(gedFile),
            gedDate: validateGedDate(gedDate),
          }
        )
      );

      if (noChanges) {
        onCancel();
      } else if (_messages.length) {
        setErrors(_messages);
        const container = document.getElementById(ID);
        if (container) {
          container.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        setErrors([]);

        if (saving) return;
        try {
          setSaving(true);
          const formData = new FormData();
          formData.append('gedFile', gedFile);
          formData.append('gedDate', gedDate);
          formData.append('gedScore', gedScore);

          scores.forEach(({ fieldName }) => {
            formData.append(fieldName, scoreProps[fieldName]);
          });

          await callAPI({
            method: 'PUT',
            url: '/applicants/me/ged-scores',
            formData,
          });

          onCollapsedChange(true, true);
          setSaving(false);
        } catch (error) {
          const errorMessages = (_.get(error, 'error.errors') || []).map(({ msg }) => msg);
          setErrors(errorMessages);
          const container = document.getElementById(ID);
          if (container) {
            container.scrollIntoView({ behavior: 'smooth' });
          }

          console.error(`Error while trying to save GED score data: ${errorMessages.toString()}`);
          setSaving(false);
        }
      }
    };

    const onCancel = () => {
      scores.forEach(({ fieldName }) => {
        scoreProps[fnName(fieldName)](initialState[fieldName]);
      });
      onGedDateChange({ target: { value: initialState.gedDate } });
      onCollapsedChange(true, waiting);
    };

    if (programCode !== '6') return null;
    if (collapsed) {
      const existedScores = scores.filter(({ fieldName }) => scoreProps[fieldName]);
      return (
        <div className={classNames('t-box', { '-waiting': waiting })}>
          <h2 className="title">
            {waiting && <span>{t('(รอตรวจสอบ)')}&nbsp;</span>}
            {t('ข้อมูลคะแนน GED')}
            {editable && (
              <a className="cursor-pointer" onClick={() => onCollapsedChange(false, waiting)}>
                {t('ขอแก้ไขข้อมูล')}
              </a>
            )}
          </h2>

          {existedScores.length ? (
            <>
              <table className="table-line col-last-num">
                <thead>
                  <tr>
                    <th>TEST RESULTS</th>
                    <th>SCORE</th>
                  </tr>
                </thead>
                <tbody>
                  {scores.map(({ key, label, fieldName }) => (
                    <tr key={key}>
                      <td>{label}</td>
                      <td>{scoreProps[fieldName] || t('ยังไม่มีข้อมูล')}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <th>TOTAL</th>
                    <th>{gedScore || t('ยังไม่มีข้อมูล')}</th>
                  </tr>
                </tfoot>
              </table>
              <br />
              <table className="table-line col-last-num">
                <tbody>
                  <tr>
                    <th>PASS DATE</th>
                    <td>
                      {gedDate
                        ? `${moment(gedDate, 'YYYY-MM-DD').format('DD/MM/')}${moment(gedDate, 'YYYY-MM-DD').year() + 543
                        }`
                        : 'ยังไม่มีข้อมูล'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          ) : (
            <div>{t('ไม่มีข้อมูลคะแนน หากต้องการเพิ่มคะแนน กรุณากดขอแก้ไขข้อมูล')}</div>
          )}
        </div>
      );
    }

    return (
      <div id={ID} className="t-box -choose">
        <h2 className="title">
          {t('แก้ไขข้อมูลคะแนน GED')}
          <a className="cursor-pointer" onClick={onCancel}>
            {t('ยกเลิก')}
          </a>
        </h2>
        <ErrorBox isFull hidden={!errors.length} errors={errors} />
        <div className="t-row">
          <div className="col-1c-date">
            <h3>Pass Date</h3>
            <label>
              <b>Date</b>
              <input
                type="date"
                max={moment().format('YYYY-MM-DD')}
                required
                onKeyDown={(e) => e.preventDefault()}
                value={gedDate}
                onChange={onGedDateChange}
              />
            </label>
          </div>
          <div className="col _desktop"></div>
          {scores.map(({ key, label, fieldName }) => (
            <div key={key} className="col-1c">
              <h3>{label}</h3>
              <label>
                <b>Score</b>
                <input type="text" value={scoreProps[fieldName]} onChange={onScoreChange(fieldName)} />
              </label>
            </div>
          ))}
        </div>
        <hr />
        {!noChanges && (
          <>
            <div className="t-row">
              <div className="col -full">
                <label>{t('แนบหลักฐาน (รูปถ่ายใบคะแนน)')}</label>
              </div>
              <div className="col required">
                <div className="transcript">
                  <img src="/assets/img/ged.png" alt="GED" width="220" height="158" />
                </div>
                <label>GED Transcript</label>
                <input name="ged-file" type="file" accept="image/*" onChange={onGedFileChange} />
              </div>
            </div>
            <hr />
          </>
        )}
        <a className="btn-main cursor-pointer" onClick={onButtonClick}>
          {t('ยืนยันข้อมูลคะแนน GED')}
        </a>
      </div>
    );
  }
);

export default SectionGEDScore;
