import { useState, useEffect } from 'react';
import { callAPI } from 'helpers';
import _ from 'lodash';

// Hooks
import { useDispatch } from 'react-redux';

const useFavorites = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [favorites, setFavorites] = useState([]);
  const [studentStatus, setStudentStatus] = useState();
  const [isApplicable, setApplicable] = useState(false);
  const [redeemableAmount, setRedeemableAmount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [courses, { favorites, student_status, applicable }, { balance }] = await Promise.all([
          callAPI({ fullURL: 'https://tcas68.s3.ap-southeast-1.amazonaws.com/mytcas/courses_round3.json?timestamp' }),
          callAPI({ url: '/applicants/me/favorites' }),
          callAPI({ url: '/applicants/me/r3-redeemable' })
        ])

        dispatch({ type: 'UPDATE_COURSES', courses });
        setFavorites(favorites.map(data => ({ ...data, _priority: data.priority })));
        setStudentStatus(student_status);
        setApplicable(applicable);
        setRedeemableAmount(balance);
        setLoading(false);
      } catch (error) {
        console.log({ error });
        setLoading(false);
      }

    }

    // fetchData();
  }, [dispatch]);

  const [adding, setAdding] = useState({});
  const onAdd = async (programProjectId) => {
    if (adding[programProjectId]) return;
    try {
      setAdding(_map => ({ ..._map, [programProjectId]: true }));
      const { result } = await callAPI({
        method: 'POST',
        url: '/applicants/me/favorites',
        body: { programProjectId }
      });
      setFavorites(_favorites => _favorites.concat([result]));
      setAdding(_map => ({ ..._map, [programProjectId]: false }));
    } catch (error) {
      console.log({ error });
      setAdding(_map => ({ ..._map, [programProjectId]: false }));
    }
  };

  const [removing, setRemoving] = useState({});
  const onRemove = async (favoriteId) => {
    if (removing[favoriteId]) return;
    try {
      setRemoving(_map => ({ ..._map, [favoriteId]: true }));
      await callAPI({
        method: 'DELETE',
        url: `/applicants/me/favorites/${favoriteId}`
      });
      const removedFavorite = _.find(favorites, ({ _id }) => _id === favoriteId);
      if (removedFavorite.priority) {
        setFavorites(_favorites => _favorites
          .filter(({ _id }) => _id !== favoriteId)
          .map(_favorite => !_favorite.priority
            ? _favorite
            : ({
              ..._favorite,
              priority: removedFavorite.priority <= _favorite.priority
                ? _favorite.priority - 1
                : _favorite.priority
            }))
        );
      } else {
        setFavorites(_favorites => _favorites.filter(({ _id }) => _id !== favoriteId));
      }
      setRemoving(_map => ({ ..._map, [favoriteId]: false }));
    } catch (error) {
      console.log({ error });
      setRemoving(_map => ({ ..._map, [favoriteId]: false }));
    }
  };

  const onSelect = (favoriteId, priority) => {
    setFavorites(_favorites => _favorites.map(data => data._id === favoriteId
      ? { ...data, priority }
      : data
    ));
    dispatch({ type: 'ADD_ADMISSION', favoriteId });
  }

  const onDragEnd = (admissions, result, courseMap) => {
    // dropped outside the list
    if (!result.destination) return;

    const [removed] = admissions.splice(result.source.index, 1);
    admissions.splice(result.destination.index, 0, removed);
    const priorityMap = admissions.reduce((map, { _id }, index) => ({ ...map, [_id]: index + 1 }), {});

    // const { draggable, project } = admissions.reduce(({ draggable, project }, { _id, program_project }) => {
    //   const _course = courseMap[program_project];
    //   return {
    //     draggable: draggable && (!_course.min_priority || _course.min_priority >= priorityMap[_id]),
    //     project: _course || (_course.min_priority && _course.min_priority < priorityMap[_id] ? _course : null)
    //   }
    // }, { draggable: true, project: null });

    // if (draggable) {
    setFavorites(_favorites => _favorites.map(data =>
      priorityMap[data._id]
        ? { ...data, priority: priorityMap[data._id] }
        : { ...data, priority: null }
    ));
    // } else {
    //   alert(`ไม่สามารถสลับลำดับได้ ลำดับขั้นต่ำในการเลือกสาขาของ ${project.program_name_th} ${project.university_name_th} คือ ${project.min_priority}`);
    // }
  }

  const onRemovePriority = (removed) => {
    setFavorites(_favorites => _favorites
      .map(_favorite => !_favorite.priority
        ? _favorite
        : ({
          ..._favorite,
          priority: removed.priority <= _favorite.priority
            ? _favorite.priority - 1
            : _favorite.priority
        }))
    );
    dispatch({ type: 'REMOVE_ADMISSION', favoriteId: removed._id });
  }

  return {
    loading,
    favorites,
    studentStatus,
    isApplicable,

    adding,
    onAdd,

    removing,
    onRemove,

    onSelect,
    onDragEnd,
    onRemovePriority,

    redeemableAmount
  }
}

export default useFavorites;