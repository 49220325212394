import { useEffect, useState, useRef } from 'react';
import { getNewestTicket } from 'helpers';
import scores from 'data/vnet.score.json';
// import useEducation from 'components/profile/score-hooks/useEducation';

const useEditVNETScore = (profile = {}, pendingTickets = []) => {
  const vnetScoreRef = useRef();
  const { ticket, valueOf } = getNewestTicket(pendingTickets, 'update_vnet_score');

  const [initialState, setInitialState] = useState(
    scores.reduce(
      (state, { key, fieldName }) => ({
        ...state,
        [fieldName]: valueOf(key) || profile[key],
      }),
      {}
    )
  );

  const [collapsed, setCollapsed] = useState(true);
  const [waiting, setWaiting] = useState(Boolean(ticket));
  useEffect(() => setWaiting(Boolean(ticket)), [ticket]);

  useEffect(() => {
    if (!collapsed) {
      setInitialState(vnetScoreRef.current.getState());
    }
  }, [collapsed]);

  return {
    vnetScoreRef,
    vnetScoreCollapsed: collapsed,
    onVNETScoreCollapsedChange: (collapsed, waiting) => {
      setCollapsed(collapsed);
      setWaiting(ticket ? true : waiting);
    },
    vnetScoreInitialState: initialState,
    vnetScoreWaiting: waiting,
  };
};

export default useEditVNETScore;
