/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import classNames from 'classnames';
import moment from 'moment';

// Components
import GoogleLogin from 'components/profile/GoogleLogin';
import FacebookLogin from 'components/profile/FacebookLogin';
import GoogleDisconnect from 'components/profile/GoogleDisconnect';
import FacebookDisconnect from 'components/profile/FacebookDisconnect';
import ErrorBox from 'components/registrations/ErrorBox';

// Hooks
import { useTranslation } from 'react-i18next';
import useBasicInfo from 'hooks/useBasicInfo';
import useCitizenCard from 'hooks/useCitizenCard';

// Helpers
import { callAPI, getCitizenIdType, getCitizenTypeLabel, handleThaiUniqueString } from 'helpers';

// Constants
import loginMethodMap from 'data/loginMethods';
import disabilities from 'data/disabilities';

import {
  notAllowed,
  errorMessages,
  validateEmail,
  validateMobile,
  validateTitle,
  validateFirstName,
  validateLastName,
  validateBirthDate,
  validateWeight,
  validateHeight,
  validateCitizenCardFile,
  validateSelfieFile,
  validateSpecialRequestFile,
  validateSpecialRequest,
} from 'validations';

const ID = 'section-basic-info';
const SectionBasicInfo = forwardRef(
  (
    {
      initialState = {},
      profile = {},
      pendingTickets = [],
      collapsed,
      onCollapsedChange,
      onEmailOtpVisibilityChange,
      onSmsOtpVisibilityChange,
      waiting,
    },
    ref
  ) => {
    // const editable = [1, 2, 3, 5, 7].includes(_.get(profile, 'student_status.code'));
    const editable = true;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
      email,
      onEmailChange,
      mobile,
      onMobileChange,
      title,
      onTitleChange,
      firstName,
      onFirstNameChange,
      lastName,
      onLastNameChange,
      firstNameAdditional,
      onFirstNameAdditionalChange,
      lastNameAdditional,
      onLastNameAdditionalChange,
      studentCode,
      onStudentCodeChange,
      weight,
      onWeightChange,
      height,
      onHeightChange,
      birthDate,
      onBirthDateChange,
      
      specialRequest,
      onSpecialRequestChange,
      
      specialRequestVisibility,
      onSpecialRequestVisibilityChange,

      specialRequestFile,
      onSpecialRequestFileChange,

      fullNameIsPending,
    } = useBasicInfo(profile, pendingTickets);
    const { citizenCardFile, selfieFile, onCitizenCardFileChange, onSelfieFileChange } = useCitizenCard();

    const [fatherName, setFatherName] = useState(profile.father_name || '');
    const [motherName, setMotherName] = useState(profile.mother_name || '');
    const [loginMethod, setLoginMethod] = useState(profile.login_method || 'password');

    useImperativeHandle(ref, () => ({
      getState: () => {
        return {
          email,
          mobile,
          title,
          firstName,
          lastName,
          firstNameAdditional,
          lastNameAdditional,
          birthDate,
          weight,
          height,
          fatherName,
          motherName,
          loginMethod,
          citizenCardFile,
          selfieFile,
          specialRequest
        };
      },
    }));

    const onFatherNameChange = (e) => {
      const value = handleThaiUniqueString(e.target.value);
      if (notAllowed('textTH', value)) return;
      setFatherName(value);
    };

    const onMotherNameChange = (e) => {
      const value = handleThaiUniqueString(e.target.value);
      if (notAllowed('textTH', value)) return;
      setMotherName(value);
    };

    const [fbConnecting, setFbConnecting] = useState(false);
    const onFacebookConnect = async (accessToken) => {
      if (fbConnecting) return;
      try {
        setFbConnecting(true);
        await callAPI({
          method: 'POST',
          url: '/socials/connect-with-facebook',
          body: { accessToken },
        });
        setLoginMethod('facebook');
        dispatch({ type: 'UPDATE_USER', result: { ...profile, loginMethod: 'facebook' } });
        setFbConnecting(false);
      } catch (error) {
        const errorMessages = [_.get(error, 'message')];
        setErrors(errorMessages);
        const container = document.getElementById(ID);
        if (container) {
          container.scrollIntoView({ behavior: 'smooth' });
        }

        console.error(new Error(`Error while trying to connect to Facebook: ${errorMessages.toString()}`));
        setFbConnecting(false);
      }
    };

    const [fbDisconnecting, setFbDisconnecting] = useState(false);
    const onFacebookDisconnect = async () => {
      if (fbDisconnecting) return;
      try {
        setFbDisconnecting(true);
        await callAPI({
          method: 'POST',
          url: '/socials/disconnect-with-facebook',
        });
        setLoginMethod('password');
        dispatch({ type: 'UPDATE_USER', result: { ...profile, loginMethod: 'password' } });
        setFbDisconnecting(false);
      } catch (error) {
        const errorMessages = [_.get(error, 'message')];
        setErrors(errorMessages);
        const container = document.getElementById(ID);
        if (container) {
          container.scrollIntoView({ behavior: 'smooth' });
        }

        console.error(new Error(`Error while trying to disconnect with Facebook: ${errorMessages.toString()}`));
        setFbDisconnecting(false);
      }
    };

    const [ggConnecting, setGgConnecting] = useState(false);
    const onGoogleConnect = async (idToken) => {
      if (ggConnecting) return;
      try {
        setGgConnecting(true);
        await callAPI({
          method: 'POST',
          url: '/socials/connect-with-google',
          body: { idToken },
        });
        setLoginMethod('google');
        dispatch({ type: 'UPDATE_USER', result: { ...profile, loginMethod: 'google' } });
        setGgConnecting(false);
      } catch (error) {
        const errorMessages = [_.get(error, 'message')];
        setErrors(errorMessages);
        const container = document.getElementById(ID);
        if (container) {
          container.scrollIntoView({ behavior: 'smooth' });
        }

        console.error(new Error(`Error while trying to connect to Google: ${errorMessages.toString()}`));
        setGgConnecting(false);
      }
    };

    const [ggDisconnecting, setGgDisconnecting] = useState(false);
    const onGoogleDisconnect = async () => {
      if (ggDisconnecting) return;
      try {
        setGgDisconnecting(true);
        await callAPI({
          method: 'POST',
          url: '/socials/disconnect-with-google',
        });
        setLoginMethod('password');
        dispatch({ type: 'UPDATE_USER', result: { ...profile, loginMethod: 'password' } });
        setGgDisconnecting(false);
      } catch (error) {
        const errorMessages = [_.get(error, 'message')];
        setErrors(errorMessages);
        const container = document.getElementById(ID);
        if (container) {
          container.scrollIntoView({ behavior: 'smooth' });
        }

        console.error(new Error(`Error while trying to disconnect with Google: ${errorMessages.toString()}`));
        setGgDisconnecting(false);
      }
    };

    const [saving, setSaving] = useState(false);
    const [errors, setErrors] = useState([]);
    const onButtonClick = async () => {
      console.log('onButtonClick');
      let _messages = errorMessages({
        email: validateEmail(email),
        mobile: validateMobile(mobile),
        title: validateTitle(title),
        firstName: validateFirstName(firstName),
        lastName: validateLastName(lastName),
        birthDate: validateBirthDate(birthDate),
        weight: validateWeight(weight),
        height: validateHeight(height),
        citizenCardFile:
          firstName !== initialState.firstName || lastName !== initialState.lastName
            ? validateCitizenCardFile(citizenCardFile)
            : null,
        selfieFile:
          firstName !== initialState.firstName || lastName !== initialState.lastName
            ? validateSelfieFile(selfieFile)
            : null,
        specialRequest: validateSpecialRequest(specialRequest, specialRequestVisibility),
        specialRequestFile: (specialRequestVisibility && specialRequest !== initialState.specialRequest)
          ? validateSpecialRequestFile(specialRequestFile)
          : null
      });

      const noChanges =
        email === initialState.email &&
        mobile === initialState.mobile &&
        title === initialState.title &&
        firstName === initialState.firstName &&
        lastName === initialState.lastName &&
        firstNameAdditional === initialState.firstNameAdditional &&
        lastNameAdditional === initialState.lastNameAdditional &&
        birthDate === initialState.birthDate &&
        studentCode === initialState.studentCode &&
        fatherName === initialState.fatherName &&
        motherName === initialState.motherName &&
        weight === initialState.weight &&
        height === initialState.height &&
        specialRequest === initialState.specialRequest;
      if (_messages.length) {
        setErrors(_messages);
        const container = document.getElementById(ID);
        if (container) {
          container.scrollIntoView({ behavior: 'smooth' });
        }
      } else if (noChanges) {
        onCancel();
      } else {
        setErrors([]);
        if (saving) return;
        try {
          setSaving(true);
          const formData = new FormData();
          formData.append('email', email);
          formData.append('mobile', mobile);
          formData.append('title', title);
          formData.append('firstName', firstName.replace(/​/g, ''));
          formData.append('lastName', lastName.replace(/​/g, ''));
          formData.append('firstNameAdditional', firstNameAdditional);
          formData.append('lastNameAdditional', lastNameAdditional);
          formData.append('birthDate', birthDate);
          formData.append('studentCode', studentCode);
          formData.append('weight', weight);
          formData.append('height', height);
          formData.append('fatherName', fatherName);
          formData.append('motherName', motherName);
          if (citizenCardFile && selfieFile) {
            formData.append('citizenCardFile', citizenCardFile);
            formData.append('selfieFile', selfieFile);
          }
          if (specialRequest) {
            formData.append('specialRequest', specialRequest);
            formData.append('specialRequestFile', specialRequestFile);
          }

          const { result } = await callAPI({
            method: 'PUT',
            url: '/applicants/me/basic-info',
            formData,
          });
          dispatch({ type: 'UPDATE_USER', result });
          onCollapsedChange(true, true);
          setSaving(false);
          window.location.reload();
        } catch (error) {
          const errorMessages = (_.get(error, 'error.errors') || []).map(({ msg }) => msg);
          setErrors(errorMessages);
          const container = document.getElementById(ID);
          if (container) {
            container.scrollIntoView({ behavior: 'smooth' });
          }

          console.error(new Error(`Error while trying to save edited basic info: ${errorMessages.toString()}`));
          setSaving(false);
        }
      }
    };

    const onCancel = () => {
      onEmailChange({ target: { value: initialState.email } });
      onMobileChange({ target: { value: initialState.mobile } });
      onTitleChange(initialState.title);
      onFirstNameChange({ target: { value: initialState.firstName } });
      onLastNameChange({ target: { value: initialState.lastName } });
      onFirstNameAdditionalChange({ target: { value: initialState.firstNameAdditional } });
      onLastNameAdditionalChange({ target: { value: initialState.lastNameAdditional } });
      onWeightChange({ target: { value: initialState.weight } });
      onHeightChange({ target: { value: initialState.height } });
      onBirthDateChange({ target: { value: initialState.birthDate } });
      setFatherName(initialState.fatherName);
      setMotherName(initialState.motherName);
      onSpecialRequestChange({ target: { value: _.findIndex(disabilities, text => text === initialState.specialRequest) }});
      onCollapsedChange(true, waiting);
    };

    const citizenTypeId = getCitizenIdType(profile.citizen_id);
    const citizenTypeLabel = getCitizenTypeLabel(t, citizenTypeId);
    const formattedCitizenId =
      citizenTypeId === 'citizen_id'
        ? [0, 1, 5, 10, 12, 13]
          .reduce(
            (result, current, index, array) =>
              !index ? [] : result.concat(profile.citizen_id.slice(array[index - 1], current)),
            []
          )
          .join(' ')
        : profile.citizen_id;

    const nameChanges = firstName !== initialState.firstName || lastName !== initialState.lastName;

    if (collapsed) {
      return (
        <div className={classNames('t-box', { '-waiting': waiting })}>
          <h2 className="title">
            {waiting && <span>{t('(รอตรวจสอบ)')}&nbsp;</span>}
            {t('ข้อมูลส่วนตัว')}
            {editable && (
              <a className="cursor-pointer" onClick={() => onCollapsedChange(false, waiting)}>
                {t('ขอแก้ไขข้อมูล')}
              </a>
            )}
          </h2>
          <table>
            <tbody>
              <tr>
                <th>{t('ชื่อ')}</th>
                <td>
                  {title}
                  {firstName} {lastName}
                  {fullNameIsPending && (
                    <small>
                      <i>({t('รอตรวจสอบ')})</i>
                    </small>
                  )}
                </td>
              </tr>
              <tr>
                <th>{citizenTypeId === 'passport' ? t('ชื่อภาษาไทย') : t('ชื่อภาษาอังกฤษ')}</th>
                <td>
                  {firstNameAdditional} {lastNameAdditional}
                </td>
              </tr>
              <tr>
                <th>{t('เลขประจำตัว')}</th>
                <td>{formattedCitizenId}</td>
              </tr>
              <tr>
                <th>{t('รหัสนักเรียน')}</th>
                <td>{studentCode}</td>
              </tr>
              <tr>
                <th>{t('อีเมล')}</th>
                {profile.email_is_verified ? (
                  <td>
                    {profile.email} <i className="i-yes" />
                  </td>
                ) : (
                  <td>
                    <b className="invalid">
                      {profile.email}{' '}
                      <a className="cursor-pointer" onClick={() => onEmailOtpVisibilityChange(true)}>
                        {t('ยืนยันอีเมล')} →
                      </a>
                    </b>
                  </td>
                )}
              </tr>
              <tr>
                <th>{t('เบอร์มือถือ')}</th>
                {profile.telephone_is_verified ? (
                  <td>
                    {profile.telephone} <i className="i-yes" />
                  </td>
                ) : (
                  <td>
                    <b className="invalid">
                      {profile.telephone}{' '}
                      <a className="cursor-pointer" onClick={() => onSmsOtpVisibilityChange(true)}>
                        {t('ยืนยันเบอร์มือถือ')} →
                      </a>
                    </b>
                  </td>
                )}
              </tr>
              <tr>
                <th>{t('วันเดือนปีเกิด')}</th>
                <td>
                  {birthDate
                    ? `${moment(birthDate, 'YYYY-MM-DD').format('DD/MM/')}${moment(birthDate, 'YYYY-MM-DD').year() + 543
                    }`
                    : ''}
                </td>
              </tr>
              <tr>
                <th>{t('การเข้าระบบ')}</th>
                <td>{loginMethodMap[profile.login_method]}</td>
              </tr>
              <tr>
                <th>{t('น้ำหนัก')}</th>
                <td>{profile.weight ? `${profile.weight} กิโลกรัม` : ''}</td>
              </tr>
              <tr>
                <th>{t('ส่วนสูง')}</th>
                <td>{profile.height ? `${profile.height} เซนติเมตร` : ''}</td>
              </tr>
              <tr>
                <th>{t('บิดา')}</th>
                <td>{profile.father_name}</td>
              </tr>
              <tr>
                <th>{t('มารดา')}</th>
                <td>{profile.mother_name}</td>
              </tr>
              {Boolean(specialRequest) && (
                <tr>
                  <th>{t('ความต้องการพิเศษ')}</th>
                  <td>{specialRequest}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      );
    }

    return (
      <div id={ID} className="t-box -choose">
        <h2 className="title">
          {t('แก้ไขข้อมูลส่วนตัว')}
          <a className="cursor-pointer" onClick={onCancel}>
            {t('ยกเลิก')}
          </a>
        </h2>

        <ErrorBox isFull hidden={!errors.length} errors={errors} />

        <div className="t-row">
          <div className="col required valid">
            <label className="has-meta">
              <span>{t('เลขประจำตัว')}</span>
              <span className="meta">({citizenTypeLabel})</span>
            </label>
            <input type="text" value={profile.citizen_id} disabled />
          </div>
          <div className="col required">
            <label>{t('อีเมล')}</label>
            <input
              type="email"
              placeholder={t('กรอกอีเมล เช่น mytcas68@gmail.com')}
              required
              value={email}
              onChange={onEmailChange}
            />
          </div>
          <div className="col required">
            <label>{t('เบอร์โทรศัพท์มือถือ')}</label>
            <input type="tel" placeholder={t('กรอกเลข 10 หลัก')} required value={mobile} onChange={onMobileChange} />
          </div>
          <div className="col required">
            <label>{t('คำนำหน้า')}</label>
            <div className="inline-group">
              <input
                type="radio"
                name="title"
                id="mr"
                checked={title === 'นาย'}
                onChange={() => onTitleChange('นาย')}
              />
              <label htmlFor="mr">{t('นาย')}</label>
              <input
                type="radio"
                name="title"
                id="ms"
                checked={title === 'นางสาว'}
                onChange={() => onTitleChange('นางสาว')}
              />
              <label htmlFor="ms">{t('นางสาว')}</label>
            </div>
          </div>
          <div className="col required">
            <label>{t('ชื่อ')}</label>
            <input
              type="text"
              name="first-name"
              placeholder={t('กรอกชื่อจริง ไม่ต้องใส่คำนำหน้า')}
              required
              value={firstName}
              onChange={onFirstNameChange}
            />
          </div>
          <div className="col required">
            <label>{t('นามสกุล')}</label>
            <input
              type="text"
              name="last-name"
              placeholder={t('กรอกนามสกุล')}
              required
              value={lastName}
              onChange={onLastNameChange}
            />
          </div>
          <div className="col">
            <label htmlFor="given-name-alt">
              {citizenTypeId === 'passport' ? t('ชื่อภาษาไทย') : t('ชื่อภาษาอังกฤษ')}
            </label>
            <input
              type="text"
              name="given-name-alt"
              id="given-name-alt"
              autoComplete="given-name"
              placeholder={
                citizenTypeId === 'passport'
                  ? t('กรอกชื่อภาษาไทย ไม่ต้องใส่คำนำหน้า')
                  : t('กรอกชื่อภาษาอังกฤษ ไม่ต้องใส่คำนำหน้า')
              }
              value={firstNameAdditional}
              onChange={onFirstNameAdditionalChange}
            />
          </div>
          <div className="col">
            <label htmlFor="family-name-alt">
              {citizenTypeId === 'passport' ? t('นามสกุลภาษาไทย') : t('นามสกุลภาษาอังกฤษ')}
            </label>
            <input
              type="text"
              name="family-name-alt"
              id="family-name-alt"
              autoComplete="family-name"
              placeholder={citizenTypeId === 'passport' ? t('กรอกนามสกุลภาษาไทย') : t('กรอกนามสกุลภาษาอังกฤษ')}
              value={lastNameAdditional}
              onChange={onLastNameAdditionalChange}
            />
          </div>
          <>
            <div className="col required">
              <label>{t('วันเดือนปีเกิด')}</label>
              <input
                type="date"
                min="1977-01-01"
                max="2009-12-31"
                required
                onKeyDown={(e) => e.preventDefault()}
                value={birthDate}
                onChange={onBirthDateChange}
              />
            </div>
            <div className="col">
              <label>{t('รหัสนักเรียน')}</label>
              <input
                type="text"
                name="studentCode"
                value={studentCode}
                onChange={onStudentCodeChange} />
            </div>
            <div className="col required">
              <label>
                {t('น้ำหนัก')} ({t('กิโลกรัม')})
              </label>
              <input
                type="text"
                name="weight"
                placeholder={t('กรอกน้ำหนัก')}
                required
                value={weight}
                onChange={onWeightChange}
              />
            </div>
            <div className="col required">
              <label>
                {t('ส่วนสูง')} ({t('เซนติเมตร')})
              </label>
              <input
                type="text"
                name="height"
                placeholder={t('กรอกส่วนสูง')}
                required
                value={height}
                onChange={onHeightChange}
              />
            </div>
          </>
          <div className="col">
            <label>
              {t('บิดา')} ({t('ไม่ต้องกรอกคำนำหน้า')})
            </label>
            <input
              type="text"
              name="first-name"
              placeholder={t('ชื่อ นามสกุล บิดา')}
              value={fatherName}
              onChange={onFatherNameChange}
            />
          </div>
          <div className="col">
            <label>
              {t('มารดา')} ({t('ไม่ต้องกรอกคำนำหน้า')})
            </label>
            <input
              type="text"
              name="last-name"
              placeholder={t('ชื่อ นามสกุล มารดา')}
              value={motherName}
              onChange={onMotherNameChange}
            />
          </div>
        </div>
        <hr />
        <div className="col -full">
          <label className="inline-group gap-1 color-orange">
            <input type="checkbox" checked={specialRequestVisibility} onChange={onSpecialRequestVisibilityChange} />
            {t('ต้องการความช่วยเหลือพิเศษ')} <img src="/assets/img/i/i-disability-orange.svg" alt="disability" width="24" height="24" />
          </label>
          {specialRequestVisibility && (
            <>
              {(!specialRequest || specialRequest !== initialState.specialRequest) && (
                <div style={{ marginBottom: 20 }}>
                  <label>{t('อัปโหลดรูปหลักฐาน')}</label>
                  <input name="special-request-file" type="file" accept="image/*" onChange={onSpecialRequestFileChange} />
                </div>
              )}
              <select required value={_.findIndex(disabilities, text => text === specialRequest)} onChange={onSpecialRequestChange}>
                <option disabled>กรุณาเลือกความต้องการพิเศษ*</option>
                {disabilities.map((text, index) => (
                  <option key={index} value={index}>
                    {text}
                  </option>
                ))}
              </select>
            </>
          )}
        </div>
        <hr />
        {/* <!-- กรณีมี Social Login (จะมีได้แค่อย่างเดียว) --> */}
        <div className="t-row">
          {loginMethod === 'google' ? (
            <div className="col">
              <label>{t('การเข้าระบบ')}</label>
              <GoogleDisconnect onDisconnect={onGoogleDisconnect} />
            </div>
          ) : loginMethod === 'facebook' ? (
            <div className="col">
              <label>{t('การเข้าระบบ')}</label>
              <FacebookDisconnect onDisconnect={onFacebookDisconnect} />
            </div>
          ) : (
            <div className="col">
              <label>{t('การเข้าระบบด้วยโซเชียล')}</label>
              <FacebookLogin buttonTitle="เชื่อมบัญชีกับ Facebook" onAuthenticate={onFacebookConnect} />
              <GoogleLogin buttonTitle="เชื่อมบัญชีกับ Google" onAuthenticate={onGoogleConnect} />
            </div>
          )}
        </div>

        {nameChanges && (
          <>
            {/* <!-- กรณีมีการเปลี่ยนแปลงชื่อ นามสกุล --> */}
            <h3>{t('เอกสารยืนยันตัวตนสำหรับการขอเปลี่ยนข้อมูลชื่อ นามสกุล วันเดือนปีเกิด')}</h3>
            <div className="t-box -info">
              {t('ให้ถ่ายรูปบัตรประชาชนและถ่ายรูปคู่กับบัตรประชาชนตามตัวอย่าง')}
              &nbsp;
              {t('หากไม่มีบัตรประชาชน ให้ใช้บัตรที่มี G-Number หรือ Passport แทน')}
            </div>

            <div className="t-row">
              <div className="col required">
                <div className="kyc">
                  <img src="/assets/img/cid.jpg" alt="รูปด้านหน้าบัตรประจำตัว" />
                  {t('รูปด้านหน้าบัตรประจำตัว')}
                </div>
                <label>{t('อัปโหลดรูปด้านหน้าบัตรประจำตัว')}</label>
                <input type="file" accept="image/*" onChange={onCitizenCardFileChange} />
              </div>
              <div className="col required">
                <div className="kyc">
                  <img src="/assets/img/cid-kyc.png" alt="รูปถ่ายคู่บัตรประจำตัว" />
                  {t('รูปถ่ายคู่บัตรประจำตัว')}
                </div>
                <label>{t('อัปโหลดรูปถ่าย')}</label>
                <input type="file" accept="image/*" onChange={onSelfieFileChange} />
              </div>
            </div>
          </>
        )}

        <hr />
        <a className="btn-main cursor-pointer" onClick={onButtonClick}>
          {t('บันทึกข้อมูลส่วนตัว')}
        </a>
      </div>
    );
  }
);

export default SectionBasicInfo;
