/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { Link } from 'react-router-dom';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useAdmission from 'hooks/round3/useAdmission';
import { notApplicable, getProjectScore, getFullScore } from 'helpers';

const prices = [150, 200, 250, 300, 400, 500, 600, 700, 800, 900];
const AdmissionConfirm = ({ favorites, studentStatus, redeemableAmount, quotaUsed, admissions = [], onEdit, onBack }) => {
  const { t } = useTranslation();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});
  const courseMap = useSelector((state) => _.get(state, 'app.courseMap')) || {};
  const { projects, nextPriority } = useAdmission(favorites, studentStatus);

  const paidAdmissions = admissions.filter(({ status }) => status === 'paid');
  const paidAmount = _.sumBy(paidAdmissions, 'payment_amount');
  const paidSubjectCount = (_.get(_.maxBy(paidAdmissions, 'total_amount'), 'program_projects') || []).length;
  const amount = projects.length > paidSubjectCount
    ? prices[projects.length - 1] - paidAmount
    : 0;

  const [errorActive, setErrorActive] = useState(false);

  return (
    <>
      <p>{t('ท่านเลือกไว้ทั้งหมด')} {projects.length} {t('อันดับ')} <b className="color-blue">{t('รอยืนยันการสมัคร')}</b></p>
      <ul className="r-choose -confirmed">
        {(projects || []).map((row, index) => {
          const lang = 'th';
          const project = courseMap[row.program_project];
          const majorName = _.get(project, `major_name_${lang}`);
          const projectName = _.get(project, `project_name_${lang}`);
          const errors = notApplicable(profile, project, { studentStatus, favorites, nextPriority });
          if (!project) return null;
          return (
            <li key={index} className={cn({ error: Boolean(errors.length) })}>
              <label>
                <div className="order">{t('อันดับที่')} {row.priority}</div>
                <h4 className="score">{t('คะแนน')} {getProjectScore(profile, project, row.priority)}/{getFullScore(project)}</h4>
                <i className="i-drag" />
                <span
                  className="u-logo"
                  style={{ backgroundImage: `url('/assets/img/logo/${_.get(project, 'university_id')}.png')` }}
                />
                <strong>{_.get(project, `university_name_${lang}`)}</strong>
                {_.get(project, `campus_name_${lang}`)}
                <br />
                {_.get(project, `faculty_name_${lang}`)}
                <br />
                {_.get(project, `field_name_${lang}`)}
                <br />
                {majorName && majorName !== '0' ? (
                  <>
                    {t('วิชาเอก')}
                    {majorName}
                    <br />
                  </>
                ) : null}
                {_.get(project, `program_name_${lang}`)}
                <br />
                {lang === 'th' ? `(${_.get(project, `program_type_name_th`)})` : null}
                {projectName && projectName !== '0' ? (
                  <>
                    <br />
                    {t('โครงการ ')}
                    {projectName}
                  </>
                ) : null}
              </label>
              {Boolean(errors.length) && (
                <div className={cn('t-collapse', { active: errorActive })}>
                  <div className="t-toggle" onClick={() => setErrorActive(_active => !_active)}>
                    {t('ไม่ผ่านเงื่อนไขการรับ ไม่สามารถสมัครได้')}<i className="i-toggle i-down-red"></i>
                  </div>
                  <div className="t-content">
                    <ul>
                      {errors.map(({ messages = [], key, label, expected, value }) => {
                        if (messages.length > 1) {
                          return (
                            <li key={key}>
                              <i className="i-no"></i>
                              <span>{messages[0].label}</span>
                              {messages.map(({ expected, value }, index) => (
                                <div key={index}>
                                  <b>{expected}</b>
                                  <span>({t('ผู้สมัคร')}: <b className="color-red">{value}</b>)</span>
                                </div>
                              ))}
                            </li>
                          )
                        }
                        if (!value && value !== 0) {
                          return (
                            <li key={key}>
                              <i className="i-no"></i>
                              <span>{label}</span>
                            </li>
                          )
                        }
                        return (
                          <li key={key}>
                            <i className="i-no"></i>
                            <span>{label}</span>
                            <b>{expected}</b>
                            <span>({t('ผู้สมัคร')}: <b className="color-red">{value}</b>)</span>
                          </li>
                        )
                      })}
                    </ul>
                  </div >
                </div >
              )}
            </li>
          )
        })}
        {Boolean(redeemableAmount) && (
          <li className="noti _heading">
            <strong className="color-orange" style={{ fontSize: '1.2em' }}>
              <span>สมัครครั้งแรก</span>
              <span style={{ textDecoration: 'underline' }}>ไม่มีค่าใช้จ่าย</span>
            </strong>
            <div>ผู้สมัครขอรับเงินสนับสนุนค่าสมัครคัดเลือก รอบที่ 3 Admissions ได้ตามจำนวนอันดับที่ยื่นสมัคร</div>
            <div className="s-flex -wrap -gap-x color-orange">
              <div className="s-flex gap-1">
                <img src="/assets/img/i/i-check-orange.svg" width={16} height={16} />
                <strong>ฟรี</strong>
              </div>
              <div className="s-flex gap-1">
                <img src="/assets/img/i/i-check-orange.svg" width={16} height={16} />
                <strong>ตัดสินใจได้ 3 ครั้ง</strong>
              </div>
              <div className="s-flex gap-1">
                <img src="/assets/img/i/i-check-orange.svg" width={16} height={16} />
                <strong>สูงสุด 10 อันดับ</strong>
              </div>
            </div>
          </li>
        )}
      </ul>
      <div className="t-summary">
        <dl>
          <dt>{t('จำนวนอันดับสาขาวิชา (เลือกได้สูงสุด 10 อันดับ)')}</dt>
          <dd><b>{(projects || []).length}</b> {t('อันดับ')}</dd>
        </dl>
        {!paidAmount ? (
          <dl>
            <dt>
              <span className={cn({ "color-red": !redeemableAmount })}>{redeemableAmount ? t('ยอดเงินสนับสนุนทั้งหมด') : t('ยอดค้างชำระทั้งหมด')}</span>
            </dt>
            <dd className={cn({ "color-red": !redeemableAmount })}><b>{amount.toFixed(2)}</b> {t('บาท')}</dd>
          </dl>
        ) : amount > 0 ? (
          <>
            <dl>
              <dt>
                <span>
                  {t('ยอดชำระเงิน')} ({t('ชำระมาแล้ว')} {paidSubjectCount} {t('อันดับ')})
                </span>
              </dt>
              <dd><b>{projects.length - paidSubjectCount}</b> {t('อันดับ')}</dd>
            </dl>
            <dl>
              <dt>
                <span className="color-red">
                  {t('ยอดค้างชำระทั้งหมด')} ({t('จ่ายเพิ่ม')} {projects.length - paidSubjectCount} {t('อันดับ')})
                </span>
              </dt>
              <dd className="color-red"><b>{amount.toFixed(2)}</b> {t('บาท')}</dd>
            </dl>
          </>
        ) : !amount ? (
          <>
            {paidSubjectCount > projects.length && (
              <dl>
                <dt>
                  <span className="color-teal">{t('สามารถเลือกได้อีก')} <b>{paidSubjectCount - projects.length}</b> {t('อันดับโดยไม่ต้องชำระเงินเพิ่ม')}</span>
                </dt>
              </dl>
            )}
            <dl>
              <dt>
                <span className="color-green">{t('ยอดค้างชำระทั้งหมด')}</span>
              </dt>
              <dd className="color-green"><b>0.00</b> {t('บาท')}</dd>
            </dl>
          </>
        ) : null}
      </div>
      <a className="btn-ghost cursor-pointer" onClick={onEdit}>
        {t('แก้ไขสาขาวิชาที่สมัคร')}
      </a>
      <p className="color-gray">
        {t('การสมัครนี้จะสมบูรณ์ เมื่อท่านกดยืนยันการสมัคร ')}
        {!redeemableAmount ? t('และได้ชำระเงินครบถ้วนเแล้ว') : ''}
      </p>
      <div className="i-hearts">
        <i className={cn('i-heart', { '-blink': quotaUsed === 2, '-used': quotaUsed > 2 })} />
        <i className={cn('i-heart', { '-blink': quotaUsed === 1, '-used': quotaUsed > 1 })} />
        <i className={cn('i-heart', { '-blink': quotaUsed === 0, '-used': quotaUsed > 0 })} />
        {!quotaUsed
          ? <small>{t('สามารถตัดสินใจได้ 3 ครั้ง')}</small>
          : <small>{t('สามารถยืนยันการสมัครได้อีก')} {3 - quotaUsed} {t('ครั้ง')}</small>}
      </div>
      <p>
        <Link to="/profile/apply-round3" className="btn-icon">
          <span className="icon"> <i className="i-heart"></i><small>-1</small></span>
          <b className="text">{t('ยืนยันการสมัคร')} {t('ครั้งที่')} {quotaUsed + 1}/3</b>
        </Link>
      </p>
      {Boolean(quotaUsed) && (
        <a className="btn-underline cursor-pointer" onClick={onBack}>
          {t('ยกเลิกและกลับไปใช้การยืนยันครั้งที่')} {quotaUsed}/3
        </a>
      )}
    </>
  )
}

export default AdmissionConfirm;