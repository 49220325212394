import { useEffect, useState, useRef } from 'react';

const useEditParent = (profile = {}) => {
  const isComplete = Boolean(profile.parent_relationship && profile.parent_name && profile.parent_telephone);

  const parentRef = useRef();

  const [collapsed, setCollapsed] = useState(isComplete);
  useEffect(() => setCollapsed(isComplete), [isComplete]);

  const [initialState, setInitialState] = useState({
    parentStatus: ['บิดา', 'มารดา', 'ครูแนะแนว'].includes(profile.parent_relationship)
      ? profile.parent_relationship
      : profile.parent_relationship
      ? 'อื่นๆ'
      : '',
    parentRelationship: ['บิดา', 'มารดา', 'ครูแนะแนว'].includes(profile.parent_relationship)
      ? ''
      : profile.parent_relationship,
    parentName: profile.parent_name,
    parentEmail: profile.parent_email,
    parentMobile: profile.parent_telephone,
  });

  useEffect(() => {
    if (!collapsed) {
      setInitialState(parentRef.current.getState());
    }
  }, [collapsed]);

  return {
    parentRef,
    parentCollapsed: collapsed,
    onParentCollapsedChange: setCollapsed,
    parentInitialState: initialState,
  };
};

export default useEditParent;
