/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { callAPI } from 'helpers';
import _ from 'lodash';

const ExamRecheckConsent = ({ type = 'tgattpat' }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});

  useEffect(() => {
    const consentAccepted = _.get(profile, `consents_accepted_at.${type}_exam_recheck_2568`);
    if (consentAccepted) {
      navigate(`/profile/exam-recheck/${type}`);
    }
  }, [navigate, profile, type]);

  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);

  const cbt = false;
  const allChecked = ![checked1, checked2, checked3, checked4, checked5, checked6].some((checked) => !checked);

  const [accepting, setAccepting] = useState(false);
  const _onAcceptConsent = async () => {
    if (!allChecked || accepting) return;
    try {
      setAccepting(true);
      await callAPI({
        method: 'POST',
        url: '/applicants/me/consents',
        body: {
          consentType: `${type}_exam_recheck_2568`,
        },
      });
      setAccepting(false);
      navigate(`/profile/exam-recheck/${type}`);
    } catch (error) {
      console.error(`Error while trying to accept consent: ${error.message}`);
      setAccepting(false);
    }
  };

  return (
    <main className="site-body">
      <div className="t-form">
        <h1>{t('การขอทบทวนผลคะแนนสอบ')}</h1>
        <div className="t-box -recheck">
          <h2 className="s-flex -center color-orange">
            <i className="i-recheck"></i> {t('การขอทบทวนผลคะแนนสอบ')}
          </h2>
          <p>
            {t('การขอทบทวนผลคะแนนสอบเป็นช่องทางสำหรับการ')}{' '}
            <span className="color-orange">
              <b>{t('ตรวจสอบคำตอบของตนเองพร้อมกับผลคะแนนสอบที่ได้')}</b>
            </span>{' '}
            {t(
              'ซึ่งหากพบข้อผิดพลาดในการตรวจให้คะแนน ผู้เข้าสอบสามารถดำเนินการยื่นคำร้องขอทบทวนผลคะแนนสอบได้ โดยมีข้อพึงระวังในการดำเนินการดังนี้'
            )}
          </p>
          <p>
            {t('กรุณาอ่านและ')} <b>{t('คลิกยอมรับ')}</b> {t('ข้อกำหนดและเงื่อนไขทุกข้อ ก่อนคลิก')} <b>{t('รับทราบ')}</b>
          </p>
          <ul className="r-consent">
            <li>
              <label>
                <input type="checkbox" checked={checked1} onChange={(e) => setChecked1(e.target.checked)} />
                <span>
                  1.{' '}
                  {t(
                    'การยื่นขอทบทวนผลคะแนนสอบมีค่าใช้จ่ายในการดำเนินการ จึงขอให้ผู้เข้าสอบไตร่ตรองให้ดีก่อนว่า คะแนนของตนเองมีความผิดปกติหรือไม่ เพื่อไม่ต้องเสียค่าใช้จ่ายในส่วนดังกล่าว'
                  )}
                </span>
              </label>
            </li>
            <li>
              <label>
                <input type="checkbox" checked={checked2} onChange={(e) => setChecked2(e.target.checked)} />
                <span>
                  2. {t('เมื่อชำระค่าดำเนินการขอทบทวนผลคะแนนสอบแล้ว ผู้เข้าสอบสามารถขอทบทวนผลคะแนนสอบของ ตนเอง จนถึง')}{' '}
                  <b className="color-orange">{type === 'alevel' ? t('วันที่ 25 เมษายน 2568 เวลา 23.59 น.') : t('วันที่ 15 มกราคม 2568 เวลา 23.59 น.')}</b>
                  {t('เท่านั้น')}{' '}
                  {t('จึงขอให้ผู้เข้าสอบบริหารจัดการเวลาในการชำระค่าดำเนินการยื่นขอทบทวนผลคะแนนให้เหมาะสม')}
                </span>
              </label>
            </li>
            <li>
              <label>
                <input type="checkbox" checked={checked3} onChange={(e) => setChecked3(e.target.checked)} />
                <span>
                  3. {t('การคำนวณคะแนนสอบจากคำตอบของแต่ละรายวิชามีความแตกต่างกัน ดูรายละเอียดที่')}
                  <a href="https://blueprint.mytcas.com/" target="_blank">
                    <b>blueprint.mytcas.com</b>
                  </a>
                </span>
              </label>
            </li>
            <li>
              <label>
                <input type="checkbox" checked={checked4} onChange={(e) => setChecked4(e.target.checked)} />
                <span>
                  4.{' '}
                  {t(
                    cbt
                      ? 'การแสดงผลคำตอบการสอบแบบคอมพิวเตอร์ (CBT) เป็นไปตามลำดับของต้นฉบับข้อสอบทั้งข้อและตัวเลือก มิได้แสดงตามปรากฎที่หน้าจอคอมพิวเตอร์ขณะทดสอบ ส่วนการสอบแบบกระดาษ (PBT) จะแสดงผลตามชุดข้อสอบ'
                      : 'การแสดงผลคำตอบการสอบแบบกระดาษ (PBT) จะแสดงผลตามชุดข้อสอบ'
                  )}
                </span>
              </label>
            </li>
            <li>
              <label>
                <input type="checkbox" checked={checked5} onChange={(e) => setChecked5(e.target.checked)} />
                <span>5. {t('การทบทวนผลคะแนนสอบเป็นข้อมูลส่วนบุคคลไม่ควรเผยแพร่สู่สาธารณะ')}</span>
              </label>
            </li>
            <li>
              <label>
                <input type="checkbox" checked={checked6} onChange={(e) => setChecked6(e.target.checked)} />
                <span>6. {t('เฉลยคำตอบเป็นสิทธิ์ของ ทปอ. ห้ามเผยแพร่โดยไม่ได้รับอนุญาตจาก ทปอ.')}</span>
              </label>
            </li>
            <li>
              <label>
                <input type="checkbox" disabled checked={allChecked} />
                <b>
                  {t('ข้าพเจ้าได้อ่านและยอมรับข้อกำหนดและเงื่อนไขดังกล่าวสำหรับกรณี')}{' '}
                  <span className="color-orange">{t('ขอตรวจสอบกระดาษคำตอบ')}</span>
                </b>
              </label>
            </li>
          </ul>
          <div className="content-bottom">
            <Link to={`/profile/exam-scores/${type}`} className="btn-cancel">
              <span className="icon">×</span> {t('ยกเลิก')}
            </Link>
            <a
              className={cn({
                'btn-main': true,
                '-danger': true,
                'cursor-pointer': allChecked,
                disabled: !allChecked,
              })}
              onClick={_onAcceptConsent}
            >
              {t('รับทราบ')}
            </a>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ExamRecheckConsent;
