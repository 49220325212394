/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react';
import { callAPI } from 'helpers';
import _ from 'lodash';
import Cookies from 'js-cookie';

// Hooks
import useBasicInfo from './useBasicInfo';
import usePassword from './usePassword';
import { useTranslation } from 'react-i18next';

// Data
import disabilities from 'data/disabilities';

import {
  errorMessages,
  validateEmail,
  validateMobile,
  validateTitle,
  validateFirstName,
  validateLastName,
  validatePassword,
  validateSpecialRequest,
  validateSpecialRequestFile
} from 'validations';

const useRegistration = ({ citizenId, stepId }) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(stepId || 1);
  useEffect(() => setCurrentStep(stepId), [stepId]);

  // Step 1
  const { prefill, ...basicInfoProps } = useBasicInfo();
  const { password, onPasswordChange, passwordValidations, passwordHidden, onPasswordHiddenChange } = usePassword();
  const [modalVisible, setModalVisible] = useState(true);
  const [step1Errors, setStep1Errors] = useState([]);
  const [registering, setRegistering] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [notFoundSubmitted, setNotFoundSubmitted] = useState(false);

  useEffect(() => {
    const fetchPrefillData = async () => {
      try {
        const { prefill_data } = await callAPI({ method: 'POST', url: '/applicants/prefill' });
        if (prefill_data) {
          prefill(prefill_data);
        }
      } catch (error) {
        console.error(new Error(`Error while trying to fetch prefill data: ${error.message}`));
      }
    };
    fetchPrefillData();
  }, [prefill]);

  useEffect(() => {
    if (!modalVisible) {
      window.scrollTo(0, 0);
    }
  }, [modalVisible]);

  const step1Props = {
    ...basicInfoProps,

    password,
    passwordHidden,
    modalVisible,
    step1Errors,

    onPasswordChange,
    onPasswordHiddenChange,
    passwordValidations,

    setModalVisible,
    notFound,
    setNotFound,
    notFoundSubmitted,
    onNotFoundSubmitSuccess: () => setNotFoundSubmitted(true),

    onRegister: async () => {
      const _messages = errorMessages({
        email: validateEmail(basicInfoProps.email),
        mobile: validateMobile(basicInfoProps.mobile),
        title: validateTitle(basicInfoProps.title),
        firstName: validateFirstName(basicInfoProps.firstName),
        lastName: validateLastName(basicInfoProps.lastName),
        password: validatePassword(password),
        specialRequest: validateSpecialRequest(basicInfoProps.specialRequest, basicInfoProps.specialRequestVisibility),
        specialRequestFile: basicInfoProps.specialRequestVisibility
          ? validateSpecialRequestFile(basicInfoProps.specialRequestFile)
          : null
      });

      if (_messages.length) {
        setStep1Errors(_messages);
        setModalVisible(false);
      } else {
        try {
          if (registering) return;
          setRegistering(true);
          const { exists } = await callAPI({
            method: 'POST',
            url: '/applicants/obec-lookup',
            body: {
              citizenId,
              firstName: (basicInfoProps.firstName || '').trim(),
              lastName: (basicInfoProps.lastName || '').trim(),
            },
          });

          if (exists) {
            if (basicInfoProps.specialRequest && basicInfoProps.specialRequestFile) {
              const formData = new FormData();
              formData.append('bypass', true);
              formData.append('email', basicInfoProps.email);
              formData.append('mobile', basicInfoProps.mobile);
              formData.append('title', basicInfoProps.title);
              formData.append('firstName', (basicInfoProps.firstName || '').trim());
              formData.append('lastName', (basicInfoProps.lastName || '').trim());
              formData.append('firstNameAdditional', basicInfoProps.firstNameAdditional);
              formData.append('lastNameAdditional',basicInfoProps.lastNameAdditional);
              formData.append('password', password);
              formData.append('specialRequest', basicInfoProps.specialRequest);
              formData.append('specialRequestFile', basicInfoProps.specialRequestFile);

              const { access_token } = await callAPI({
                method: 'POST',
                url: '/applicants/register',
                formData
              });
              Cookies.set('accessToken', access_token, { expires: 1 / 24 });
            } else {
              const { access_token } = await callAPI({
                method: 'POST',
                url: '/applicants/register',
                body: {
                  email: basicInfoProps.email,
                  mobile: basicInfoProps.mobile,
                  title: basicInfoProps.title,
                  firstName: (basicInfoProps.firstName || '').trim(),
                  lastName: (basicInfoProps.lastName || '').trim(),
                  firstNameAdditional: basicInfoProps.firstNameAdditional,
                  lastNameAdditional: basicInfoProps.lastNameAdditional,
                  password,
                  specialRequest: null
                },
              });
              Cookies.set('accessToken', access_token, { expires: 1 / 24 });
            }

            setCurrentStep(2);
            setModalVisible(false);
            setRegistering(false);
          } else {
            setModalVisible(false);
            setStep1Errors([
              <span>
                {t('ข้อมูลชื่อและนามสกุลที่กรอก ไม่ตรงกับข้อมูลในระบบ')}{' '}
                <a className="cursor-pointer" onClick={() => setNotFound(true)}>
                  {t('คลิกที่นี่เพื่อแจ้งข้อมูลเพิ่มเติม')}
                </a>
              </span>,
            ]);
            window.scrollTo(0, 0);
            setRegistering(false);
          }
        } catch (error) {
          const errorMessages = (_.get(error, 'json.errors') || []).map(({ msg }) => msg);
          setModalVisible(false);
          setStep1Errors(errorMessages);
          setRegistering(false);
          console.error(new Error(`Error while trying to submit step1: ${errorMessages.toString()}.`));
        }
      }
    },
  };

  return {
    citizenId,
    currentStep,
    setCurrentStep,

    // Step 1
    ...step1Props,
  };
};

export default useRegistration;
