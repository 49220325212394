import { useEffect, useState, useRef } from 'react';

const useEditAddress = (profile = {}) => {
  const isComplete = Boolean(
    profile.zipcode && profile.province_id && profile.amph_id && profile.tmbl_id && profile.address
  );

  const addressRef = useRef();

  const [collapsed, setCollapsed] = useState(isComplete);
  useEffect(() => setCollapsed(isComplete), [isComplete]);

  const [initialState, setInitialState] = useState({
    postalCode: profile.zipcode,
    provinceId: profile.province_id,
    districtId: profile.amph_id,
    subDistrictId: profile.tmbl_id,
    address: profile.address,
  });

  useEffect(() => {
    if (!collapsed) {
      setInitialState(addressRef.current.getState());
    }
  }, [collapsed]);

  return {
    addressRef,
    addressCollapsed: collapsed,
    onAddressCollapsedChange: setCollapsed,
    addressInitialState: initialState,
  };
};
export default useEditAddress;
