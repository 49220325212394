/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';

// Components
import NotFoundTicket from 'components/registrations/NotFoundTicket';
import EmailVerification from 'components/otp/EmailVerification';
import TcasMobileVerification from 'components/otp/MobileVerification';
import TcasUpdatePassword from 'components/registrations/TcasUpdatePassword';

// Helpers
import { callAPI, getCitizenIdType, getCitizenTypeLabel } from 'helpers';

// Hooks
import { useTranslation } from 'react-i18next';

const TcasForgotPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const studentType = useParams().studentType;

  const [verifyMethod, setVerifyMethod] = useState('email');
  const [loading, setLoading] = useState(false);
  const [studentInfo, setStudentInfo] = useState();
  const [currentStep, setCurrentStep] = useState(1);
  const [resetPasswordToken, setResetpasswordToken] = useState();

  useEffect(() => {
    const getStudentInfo = async () => {
      try {
        setLoading(true);
        const { result } = await callAPI({
          method: 'POST',
          url:
            ['tcas', 'tcas-past'].includes(studentType) ? '/tcas-past/forgotten-password-lookup' : '/applicants/forgotten-password-lookup',
        });
        setStudentInfo(result);
        setLoading(false);
      } catch (error) {
        console.error(new Error(`Error while trying to get tcas-past student info: ${error.message}.`));
        setLoading(false);
        navigate('/');
      }
    };

    getStudentInfo();
  }, [navigate, studentType]);

  const citizenId = _.get(studentInfo, 'citizen_id');
  const citizenTypeLabel = getCitizenTypeLabel(t, getCitizenIdType(citizenId));
  let title = '';
  switch (currentStep) {
    case 1:
      title = t('ยืนยันตัวตน');
      break;
    case 2:
      title =
        verifyMethod === 'email' ? t('ยืนยันอีเมล') : verifyMethod === 'mobile' ? t('ยืนยันเบอร์โทรศัพท์มือถือ') : '';
      break;
    case 3:
      title = t('ตั้งรหัสผ่านใหม่');
      break;
    case 4:
      title = t('ตั้งรหัสผ่านเรียบร้อย');
      break;
    default:
      break;
  }

  return (
    <div className="t-form">
      {title && <h1>{title}</h1>}

      {currentStep === 1 && (
        <div>
          <div className="t-box -info -half">
            {t('ระบบพบข้อมูลของท่านในระบบเดิม กรุณาเลือกช่องทางในการยืนยันตัวตน')}
          </div>
          {!loading && (
            <div>
              <div className="t-row">
                <div className="col required valid">
                  <label className="has-meta">
                    <span>{t('เลขประจำตัว')}</span>
                    <span className="meta">({citizenTypeLabel})</span>
                  </label>
                  <input type="text" value={citizenId} disabled />
                </div>
              </div>
              <div className="t-row">
                <div className="col required">
                  <label>{t('เลือกช่องทางในการยืนยันตัวตน')}</label>
                  <label className="s-flex no-x">
                    <input
                      type="radio"
                      name="channel"
                      checked={verifyMethod === 'email'}
                      onChange={() => setVerifyMethod('email')}
                    />
                    &nbsp; {t('อีเมล')}: {_.get(studentInfo, 'email')}
                  </label>
                  <label className="s-flex no-x">
                    <input
                      type="radio"
                      name="channel"
                      checked={verifyMethod === 'mobile'}
                      onChange={() => setVerifyMethod('mobile')}
                    />
                    &nbsp; {t('เบอร์โทรศัพท์มือถือ')}: {_.get(studentInfo, 'telephone')}
                  </label>
                </div>
              </div>

              <div className="action">
                <p>
                  <a onClick={() => setCurrentStep(2)} className="btn-main cursor-pointer">
                    {t('ยืนยันตัวตน')}
                  </a>
                </p>
              </div>

              <div className="text-center _heading">{t('หากไม่สามารถยืนยันตัวตนตามข้อมูลด้านบนได้')}</div>
              <details>
                <summary>{t('ส่งข้อมูลเพิ่มเติมให้เจ้าหน้าที่')}</summary>
                <div className="t-panel">
                  <NotFoundTicket citizenId={citizenId} citizenTypeLabel={citizenTypeLabel} isTcas />
                </div>
              </details>
            </div>
          )}
        </div>
      )}

      {currentStep === 2 && (
        <div>
          {verifyMethod === 'email' ? (
            <EmailVerification
              studentType={studentType}
              email={_.get(studentInfo, 'email')}
              onSuccess={(token) => {
                setCurrentStep(3);
                setResetpasswordToken(token);
              }}
            />
          ) : (
            verifyMethod === 'mobile' && (
              <TcasMobileVerification
                studentType={studentType}
                mobile={_.get(studentInfo, 'telephone')}
                onSuccess={(token) => {
                  setCurrentStep(3);
                  setResetpasswordToken(token);
                }}
              />
            )
          )}
        </div>
      )}

      {currentStep === 3 && (
        <TcasUpdatePassword
          studentType={studentType}
          token={resetPasswordToken}
          citizenId={citizenId}
          citizenTypeLabel={citizenTypeLabel}
          onSuccess={() => navigate(studentType === 'tcas' ? '/profile/applicant-info' : '/profile')}
        />
      )}
    </div>
  );
};

export default TcasForgotPassword;
