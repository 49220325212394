/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, Outlet } from 'react-router-dom';
import _ from 'lodash';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import menus from 'nav/menus';
import Logo from 'nav/logo';

const App = () => {
  const lang = useSelector((state) => _.get(state, 'app.lang'));
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(lang.toLowerCase());
    document.documentElement.setAttribute('lang', lang.toLowerCase());
    Cookies.set('lang', lang, { expires: 365 });
  }, [i18n, lang]);

  return (
    <div id="tcas">
      <div className="t-loading"></div>
      <header className="site-header">
        <nav className="site-nav">
          <ul>
            {menus.map(({ key, icon, title, to, href, desktop }) => (
              <li key={key} className={classNames(`m-${key}`, { _desktop: desktop, active: key === 'register' })}>
                {to && (
                  <Link to={to}>
                    {icon}
                    <span>{title}</span>
                  </Link>
                )}
                {href && (
                  <a href={href}>
                    {icon}
                    <span>{title}</span>
                  </a>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </header>
      <Logo />
      <main className="site-body">
        <Outlet />
      </main>

      <footer className="site-footer">
        <a href="http://www.cupt.net/" target="_blank" rel="noopener noreferrer">
          <img
            src="https://assets.mytcas.com/img/logo/cupt.png"
            alt="CUPT Logo"
            className="logo"
            width="228"
            height="44"
          />
        </a>
        <div className="address">
          <b>สมาคมที่ประชุมอธิการบดีแห่งประเทศไทย</b>
          328 อาคารสำนักงานคณะกรรมการการอุดมศึกษา แขวงทุ่งพญาไท เขตราชเทวี กรุงเทพฯ <br />
          โทรศัพท์ 02-126-5111 วันทำการ จันทร์-ศุกร์ เวลา 8.30-16.30 น. &nbsp;
          <a href="https://www.mytcas.com/privacy" target="_blank" rel="noopener noreferrer">
            เงื่อนไขการใช้งานและนโยบายความเป็นส่วนตัว
          </a>
        </div>
        <div className="social">
          <a href="https://www.facebook.com/CUPTmytcas/" target="_blank" rel="noopener noreferrer">
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15 3C8.3832 3 3 8.41706 3 15.0755C3 21.1168 7.4364 26.1215 13.2 27V18.0943H10.8C10.4688 18.0943 10.2 17.8245 10.2 17.4906V15.6792C10.2 15.3454 10.4688 15.0755 10.8 15.0755H13.2V12.8826C13.2 10.014 14.6562 8.43396 17.3004 8.43396C18.372 8.43396 19.2174 8.50883 19.2528 8.51185C19.5624 8.53962 19.8 8.80045 19.8 9.11321V11.1509C19.8 11.4848 19.5312 11.7547 19.2 11.7547H18C17.3382 11.7547 16.8 12.2963 16.8 12.9623V15.0755H19.2C19.3722 15.0755 19.536 15.1497 19.65 15.2795C19.764 15.41 19.8168 15.5826 19.7958 15.7541L19.5708 17.5654C19.533 17.8673 19.2774 18.0943 18.975 18.0943H16.8V27C22.5636 26.1215 27 21.1168 27 15.0755C27 8.41706 21.6168 3 15 3Z"
                fill="#FAFCFF"
              ></path>
            </svg>
          </a>
          <a href="https://twitter.com/MytcasC" target="_blank" rel="noopener noreferrer">
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.8125 3.75C5.5725 3.75 3.75 5.5725 3.75 7.8125V22.1875C3.75 24.4275 5.5725 26.25 7.8125 26.25H22.1875C24.4275 26.25 26.25 24.4275 26.25 22.1875V7.8125C26.25 5.5725 24.4275 3.75 22.1875 3.75H7.8125ZM8.64258 8.75H12.666L15.7019 13.0896L19.4604 8.75H20.7104L16.2659 13.8965L21.4111 21.25H17.3877L14.0442 16.4697L9.91577 21.25H8.63892L13.4778 15.6604L8.64258 8.75ZM10.5847 9.80957L17.8674 20.1904H19.469L12.1863 9.80957H10.5847Z"
                fill="#FAFCFF"
              ></path>
            </svg>
          </a>
        </div>
      </footer>
    </div>
  );
};

export default App;
