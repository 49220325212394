/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { callAPI } from 'helpers';
import moment from 'moment';

// Components
import EmailVerification from 'components/otp/EmailVerification';
import MobileVerification from 'components/otp/MobileVerification';
import ExamSlotInfo from 'components/profile/apply-exams/ExamSlotInfo';
import ExamSiteInfo from 'components/profile/apply-exams/ExamSiteInfo';
import ExamSteps from 'components/profile/apply-exams/ExamSteps';

// Hooks
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Data
import { TGATTPATINFOS } from 'data/exam-days';
import enabled from 'data/enabled.json';

const examInfoMap = _.keyBy(TGATTPATINFOS, 'dayIndex');

const TGATTPATConfirmation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});

  const [verifyMethod, setVerifyMethod] = useState();
  const [otpVisible, setOtpVisible] = useState(false);

  const [loading, setLoading] = useState(false);
  const [selections, setSelections] = useState([]);
  const [confirmations, setConfirmations] = useState([]);
  const [siteMap, setSiteMap] = useState({});

  const [flow, setFlow] = useState();
  const [redeemReplied, setRedeemReplied] = useState(false);

  useEffect(() => {
    if (profile.exam_4y_alleged) { navigate('/profile/apply-exams/tgattpat/veto', { replace: true }); }
  }, [profile, navigate]);

  useEffect(() => {
    const fetchSummary = async () => {
      try {
        setLoading(true);
        const { result } = await callAPI({ url: '/applicants/me/apply-exam-summary/tgattpat' });
        setSelections(result.selections);
        setConfirmations(result.recent_confirmations);
        setSiteMap(_.keyBy(result.exam_sites, ({ exam_type, site_code }) => `${exam_type}-${site_code}`));        
        setLoading(false);

        const expired = moment(result.timestamp).isAfter(enabled.tgattpat_apply_exams.expired);
        if (expired) { navigate('/profile/apply-exams/tgattpat') }
      } catch (error) {
        console.log({ error });
        setLoading(false);
      }
    };
    fetchSummary();
  }, [navigate]);

  const onEmailOtpSuccess = useCallback(() => {
    dispatch({ type: 'UPDATE_USER', result: { ...profile, email_is_verified: 1 } });
    navigate(-1);
  }, [dispatch, profile, navigate]);

  const onSmsOtpSuccess = useCallback(() => {
    dispatch({ type: 'UPDATE_USER', result: { ...profile, telephone_is_verified: 1 } });
    navigate(-1);
  }, [dispatch, profile, navigate]);

  if (loading) {
    return (
      <main className='site-body'>
        <div className='t-loading' />
      </main>
    );
  }

  if (profile.tgat_redeemable && !redeemReplied && selections.some(({ subjects }) => subjects.includes('TGAT'))) {
    return (
      <main className='site-body'>
        <div className="t-form">
          <div className="r-reset-bg" />
          <div className="r-reset">
            <div className="content-scroll">
              <div className="text-center">
                <i className="i-danger" />
              </div>
              <h1>คำยินยอมการรับเงินสนับสนุนค่าสมัครสอบวิชา TGAT ความถนัดทั่วไป จากสำนักงานปลัดกระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัย และนวัตกรรม</h1>
              <ol className="t-ol mb-3">
                <li>
                  <b>1.</b> ผู้สมัครยินยอมมอบสิทธิ์ให้สมาคมที่ประชุมอธิการบดีแห่งประเทศไทย
                  ดำเนินการแทนผู้สมัครในการเบิกเงินสนับสนุนค่าสมัครสอบวิชา TGAT ความถนัดทั่วไป ที่ได้สมัครนี้
                  จากสำนักงานปลัดกระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัย และนวัตกรรม
                </li>
                <li>
                  <b>2.</b>ผู้สมัครที่ไม่ประสงค์รับการสนับสนุนค่าสมัครสอบวิชา TGAT ความถนัดทั่วไป และได้กดไม่ยินยอมขอรับเงินสนับสนุนและประสงค์ชำระค่าสมัครสอบด้วยตนเองแล้ว
                  จะไม่สามารถกลับมาขอรับการสนับสนุนในภายหลังของการสมัครสอบวิชา TGAT ความถนัดทั่วไปได้อีกในปีการศึกษานี้
                </li>
              </ol>
              <ul className="r-radio">
                <li>
                  <label>
                    <input type="radio" name="agree" checked={flow === 'agree'} onClick={() => setFlow('agree')} />
                    <strong className="color-green">{t('ยินยอม')}</strong>
                    <span>&nbsp;{t('ขอรับเงินสนับสนุนค่าสมัครสอบ')}</span>
                  </label>
                </li>
                <li>
                  <label>
                    <input type="radio" name="disagree" checked={flow === 'disagree'} onClick={() => setFlow('disagree')} />
                    <strong className="color-red">{t('ไม่ยินยอม')}</strong>
                    <span>&nbsp;{t('ขอรับเงินสนับสนุนและประสงค์ชำระค่าสมัครสอบด้วยตนเอง')}</span>
                  </label>
                </li>
              </ul>
            </div>
            <div className="content-bottom -full text-center">
              <h4 className="color-red text-center mb-0 mt-0">
                <small>
                  <div>หมายเหตุ</div>
                  <div>เมื่อยืนยัน OTP แล้วจะไม่สามารถกลับมาแก้ไขในภายหลังได้</div>
                </small>
              </h4>
              <a
                className={cn('btn-main', { disabled: !flow, 'cursor-pointer': flow })}
                onClick={!flow ? () => {} : () => setRedeemReplied(true)}>
                {t('ยืนยัน')}
              </a>
            </div>
          </div>
        </div>
      </main>
    )
  }

  return (
    <main className='site-body'>
      <div className='t-form'>
        <h1>
          {flow === 'agree' && <div>{t('ยินยอมรับเงินสนับสนุนและ')}</div>}
          {flow === 'disagree' && <div>{t('ไม่ยินยอมรับเงินสนับสนุนและ')}</div>}
          <div>{t('ยืนยันการสมัครสอบ')}</div>
        </h1>
        <ExamSteps redeemed={flow === 'agree'} activeIndex={3} />
        {!otpVisible && (
          <div className='t-box -noti'>
            <div className='text-center color-orange'>
              {t('กรุณาเลือกช่องทางในการส่งรหัส OTP ยืนยันการสมัคร')}
              <br />
              <strong className='color-orange'>{t('เมื่อยืนยัน OTP แล้ว จะไม่สามารถยกเลิกหรือแก้ไขได้จนกว่าจะชำระเงินให้เรียบร้อย')}</strong>
            </div>
          </div>
        )}
        {!otpVisible && flow === 'agree' && (
          <div className='t-box -border' style={{ borderColor: '#0b9c23' }}>
            <div className='text-center color-green'>
              <strong>ยินยอม </strong>
              <span className='color-blue'>ขอรับเงินสนับสนุนค่าสมัครสอบ</span>
            </div>
          </div>
        )}
        {!otpVisible && flow === 'disagree' && (
          <div className='t-box -border' style={{ borderColor: '#ea0c42' }}>
            <div className='text-center color-red'>
              <strong>ไม่ยินยอม </strong>
              <span className='color-blue'>ขอรับเงินสนับสนุนค่าสมัครสอบ</span>
            </div>
            <div className='text-center color-red'>
              เมื่อกดยืนยันแล้วจะไม่สามารถกลับมาแก้ไขในภายหลังได้
            </div>
          </div>
        )}
        {!otpVisible &&
          (selections || []).map((sel) => {
            const { date, colorClassName, examSlots } = examInfoMap[sel.day];
            return (
              <div className={cn('exam-box exam-choose-step', { [colorClassName]: true })}>
                <div className='exam-box-header'>
                  <div className='s-flex -between'>
                    <div className='_heading'>
                      <span className='num'>{sel.day}</span>
                      <b>{date}</b>
                    </div>
                  </div>
                </div>
                <div className='exam-box-body'>
                  <div className={cn('exam-box', { [colorClassName]: true })}>
                    <ul className='exam-box-list'>
                      {examSlots
                        .filter(({ subjectKey }) => sel.subjects.includes(subjectKey))
                        .map((slot, slotIndex) => (
                          <ExamSlotInfo
                            key={slotIndex}
                            examType={sel.exam_type}
                            specialRequests={sel.special_requests}
                            confirmations={confirmations}
                            slot={slot} />
                        ))}
                    </ul>
                  </div>
                  <div className={cn('exam-box', { [colorClassName]: true })}>
                    <ul className='exam-box-list'>
                      <ExamSiteInfo data={sel} examType={sel.exam_type} siteMap={siteMap} />
                    </ul>
                  </div>
                </div>
              </div>
            );
          })}
        {!otpVisible ? (
          <>
            <div className='text-center'>
              <strong className='color-orange'>{t('เมื่อยืนยัน OTP แล้ว จะไม่สามารถยกเลิกหรือแก้ไขได้จนกว่าจะชำระเงินให้เรียบร้อย')}</strong>
            </div>
            <MethodSelector verifyMethod={verifyMethod} setVerifyMethod={setVerifyMethod} onClick={() => setOtpVisible(true)} />
          </>
        ) : verifyMethod === 'email' ? (
          <EmailVerification
            studentType='apply-exams'
            email={profile.email}
            bodyParams={{
              updates: selections.map(({ updated_id }) => updated_id),
              tgat_redeem_agree: typeof flow === 'undefined' ? undefined : flow === 'agree'
            }}
            onSuccess={onEmailOtpSuccess} />
        ) : verifyMethod === 'mobile' ? (
          <MobileVerification
            studentType='apply-exams'
            mobile={profile.telephone}
            bodyParams={{
              updates: selections.map(({ updated_id }) => updated_id),
              tgat_redeem_agree: typeof flow === 'undefined' ? undefined : flow === 'agree'
            }}
            onSuccess={onSmsOtpSuccess} />
        ) : null}
      </div>
    </main>
  );
};

export default TGATTPATConfirmation;

const MethodSelector = ({ onClick, verifyMethod, setVerifyMethod }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});
  const formattedMobile = [profile.telephone.slice(0, 3), profile.telephone.slice(3, 6), profile.telephone.slice(-4)].join(' ');

  return (
    <>
      <ul className='r-radio'>
        <li>
          <label>
            <input type='radio' name='confirm' checked={verifyMethod === 'mobile'} onClick={() => setVerifyMethod('mobile')} />
            <strong>{t('ยืนยันการสมัครผ่านเบอร์มือถือ')}</strong>
            <small>
              {t('หมายเลข')} {formattedMobile}
            </small>
          </label>
        </li>
        <li>
          <label>
            <input type='radio' name='confirm' checked={verifyMethod === 'email'} onClick={() => setVerifyMethod('email')} />
            <strong>{t('ยืนยันการสมัครผ่านอีเมล')}</strong>
            <small>
              {t('อีเมล')} {profile.email}
            </small>
          </label>
        </li>
      </ul>

      <div className='action'>
        <a className={cn('btn-main', { disabled: !verifyMethod, 'cursor-pointer': Boolean(verifyMethod) })} onClick={!verifyMethod ? () => {} : onClick}>
          {t('ส่ง OTP เพื่อยืนยันการสมัครสอบ')}
        </a>
        <h4>
          <a onClick={() => navigate(-1)} className='cursor-pointer color-gray'>
            {t('ยกเลิก')}
          </a>
        </h4>
      </div>
      <p className='text-center _small color-gray'>
        {t('หากมีการเปลี่ยนแปลงเบอร์มือถือหรืออีเมล')}
        {t('สามารถแก้ไขได้ที่หน้า')}{' '}
        <Link to='/profile/applicant-info' className='color-gray'>
          <u>{t('ข้อมูลการลงทะเบียน')}</u>
        </Link>
      </p>
    </>
  );
};
