/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import _ from 'lodash';
import { callAPI } from 'helpers';
import moment from 'moment';

// Components
import EmailVerification from 'components/otp/EmailVerification';
import MobileVerification from 'components/otp/MobileVerification';

// Hooks
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Data
import enabled from 'data/enabled.json';

const TGATTPATPostpone = ({ action }) => {
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(action === 'cancel' ? 2 : 1);
  const [loading, setLoading] = useState(false);
  const [applications, setApplications] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const { pp_type, postponable, timestamp } = await callAPI({ url: '/applicants/me/exam-applications' });
      const expired = moment(timestamp).isAfter(enabled.tgattpat_postpone.expired);

      console.log({ pp_type, postponable, expired });
      // if (pp_type !== '2' || !postponable || expired) {
      //   navigate('/profile');
      // }
    };

    fetchData();
  }, [navigate]);

  const [verifyMethod, setVerifyMethod] = useState('');

  return (
    <div className="site-body">
      {currentStep === 1 ? (
        <PostponeStep1 onStepChange={setCurrentStep} />
      ) : currentStep === 2 ? (
        <PostponeStep2
          action={action}
          verifyMethod={verifyMethod}
          setVerifyMethod={setVerifyMethod}
          onStepChange={setCurrentStep}
        />
      ) : currentStep === 3 ? (
        <PostponeStep3 action={action} verifyMethod={verifyMethod} />
      ) : null}
    </div>
  );
};

export default TGATTPATPostpone;

const PostponeStep1 = ({ onStepChange }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="modal-form">
      <div className="content-scroll">
        <h1 className="flex items-center gap-2 justify-center">
          <i className="i-postpone" style={{ width: '24px', height: '24px' }}></i>
          <b className="color-orange">{t('ลงทะเบียนย้ายวันสอบ')}</b>
        </h1>

        <ol className="t-ol">
          <li>
            <b>1.</b>อนุญาตให้ผู้สมัครในสนามสอบจังหวัดปัตตานี และจังหวัดสงขลาที่อาจจะประสบปัญหาในการ
            เดินทางมาเข้าสอบในวันที่ 7 – 9 ธันวาคม 2567 สามารถลงทะเบียนย้ายวันสอบมาเป็นวันที่ 21 – 23 ธันวาคม 2567 ได้
            &nbsp;
            <u>ภายในวันที่ 5 ธันวาคม 2567 เวลา 23.59 น.</u>
          </li>
          <li>
            <b>2.</b>ข้อสอบที่ใช้ในการจัดสอบวันที่ 21 – 23 ธันวาคม 2567 เป็น <u>ข้อสอบชุดใหม่ที่คงมาตรฐานเดิม</u>
            และครอบคลุมเนื้อหาตาม Blueprint
            โดยผู้ออกข้อสอบเป็นชุดเดียวกันและออกในคราวเดียวกันเพื่อให้เกิดความเสมอภาคสําหรับผู้เข้าสอบทุกคน
          </li>
        </ol>
        <div className="color-orange">
          <b>
            *หากท่านลงทะเบียนย้ายวันสอบ <u>สนามสอบใหม่จะเป็นตามที่ ทปอ.กำหนดให้</u>
            <br />
            ภายในจังหวัดเดิมเท่านั้น!!
          </b>
        </div>
      </div>
      <div className="content-bottom">
        <a className="btn-cancel cursor-pointer" onClick={() => navigate(-1)}>
          <span className="icon">×</span> {t('ยกเลิก')}
        </a>
        <a className="btn-main -danger cursor-pointer" onClick={() => onStepChange((_step) => _step + 1)}>
          {t('ลงทะเบียน')}
          <span className="_desktop">ย้ายวันสอบ</span>
        </a>
      </div>
    </div>
  );
};

const PostponeStep2 = ({ action, verifyMethod, setVerifyMethod, onStepChange }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});

  return (
    <div className="t-form">
      <h1 className="text-center">
        <b>{action === 'cancel' ? t('ยกเลิกลงทะเบียนย้ายวันสอบ') : t('ยืนยันลงทะเบียนย้ายวันสอบ')}</b>
      </h1>
      <h4 className="text-center">
        {action === 'confirm'
          ? t('กรุณาเลือกช่องทางในการส่งรหัส OTP "ลงทะเบียนย้ายวันสอบ"')
          : t('กรุณาเลือกช่องทางในการส่งรหัส OTP "ยกเลิกการลงทะเบียนย้ายวันสอบ"')}
      </h4>
      <ul className="r-radio">
        <li>
          <label>
            <input
              type="radio"
              name="confirm"
              checked={verifyMethod === 'mobile'}
              onClick={() => setVerifyMethod('mobile')}
            />
            <strong>{t('ยืนยันผ่านเบอร์มือถือ')}</strong>
            <small>
              {t('หมายเลข')} {profile.telephone}
            </small>
          </label>
        </li>
        <li>
          <label>
            <input
              type="radio"
              name="confirm"
              checked={verifyMethod === 'email'}
              onClick={() => setVerifyMethod('email')}
            />
            <strong>{t('ยืนยันผ่านอีเมล')}</strong>
            <small>
              {t('อีเมล')} {profile.email}
            </small>
          </label>
        </li>
      </ul>

      <div className="text-center">
        <p>
          <a
            className={cn('btn-main -danger', { disabled: !verifyMethod, 'cursor-pointer': verifyMethod })}
            onClick={!verifyMethod ? () => {} : () => onStepChange((_step) => _step + 1)}
          >
            {action === 'cancel'
              ? t('ส่ง OTP เพื่อยกเลิกลงทะเบียนย้ายวันสอบ')
              : t('ส่ง OTP เพื่อยืนยันลงทะเบียนย้ายวันสอบ')}
          </a>
        </p>
        <a className="_heading color-gray justify-center cursor-pointer" onClick={() => navigate(-1)}>
          {t('ยกเลิก')}
        </a>
      </div>
      <p className="text-center _small">
        {t('หากมีการเปลี่ยนแปลงเบอร์มือถือหรืออีเมล')}
        {t('สามารถแก้ไขได้ที่หน้า')}{' '}
        <Link to="/profile/applicant-info" className="color-black">
          <u>{t('ข้อมูลการลงทะเบียน')}</u>
        </Link>
      </p>
    </div>
  );
};

const PostponeStep3 = ({ action, verifyMethod }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});

  return (
    <div className="t-form">
      <h1 className="text-center">
        <b>{action === 'cancel' ? t('ยกเลิกลงทะเบียนย้ายวันสอบ') : t('ลงทะเบียนย้ายวันสอบ')}</b>
      </h1>
      {verifyMethod === 'email' ? (
        <EmailVerification
          studentType="tgattpat-postpone"
          email={profile.email}
          bodyParams={{ action }}
          onSuccess={() => navigate(-1)}
        />
      ) : verifyMethod === 'mobile' ? (
        <MobileVerification
          studentType="tgattpat-postpone"
          mobile={profile.telephone}
          bodyParams={{ action }}
          onSuccess={() => navigate(-1)}
        />
      ) : null}
    </div>
  );
};
