/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useTranslation } from 'react-i18next';

const ModalTermsAndConditions = ({ visible, onSubmit, onClose }) => {
  const { t } = useTranslation();
  if (!visible) return null;
  return (
    <div className='t-modal' style={{ visibility: 'visible', opacity: 1, pointerEvents: 'auto' }}>
      <div className='content'>
        <a onClick={onClose} className='cursor-pointer close'>
          ×
        </a>
        <h1>ข้อกำหนดและเงื่อนไขในการใช้งานระบบ TCAS</h1>
        <p>ข้อกำหนดและเงื่อนไขในการใช้งาน TCAS (student.mytcas.com) ฉบับนี้ ได้ระบุถึงข้อกำหนดและเงื่อนไขในการใช้งานและบริการใดๆ ของ TCAS (student.mytcas.com) แก่ผู้ใช้บริการ (โดยแต่ละรายเรียกว่า “ผู้ใช้” หรือ “ผู้ใช้รายต่างๆ” ขึ้นอยู่กับเนื้อหา)</p>
        <h2>1. คำนิยาม</h2>
        <p>คำและข้อความดังต่อไปนี้ให้มีความหมายตามที่ได้กำหนดไว้ด้านล่างเมื่อมีการใช้ในข้อกำหนดและเงื่อนไขฯ ฉบับนี้</p>
        <ol className='t-ol'>
          <li>
            <b>1.1</b> “เนื้อหา” หมายถึง ข้อมูลต่าง ๆ เช่น ข้อความ รูปภาพ ไฟล์ รหัสคอมพิวเตอร์ และข้อมูลอื่น ๆ
          </li>
          <li>
            <b>1.2</b> “เนื้อหาหลัก” หมายถึง เนื้อหาที่สามารถเข้าถึงได้ผ่านทางบริการฯ
          </li>
          <li>
            <b>1.3</b> “เนื้อหาจากผู้ใช้” หมายถึง เนื้อหาที่ผู้ใช้ได้ส่ง ส่งผ่าน หรือ อัปโหลดบนระบบการให้บริการฯ
          </li>
          <li>
            <b>1.4</b> “ระบบ” หมายถึง เว็บไซต์ TCAS (student.mytcas.com)
          </li>
        </ol>
        <h2>2. การตกลงยอมรับข้อกำหนดและเงื่อนไขฯ ฉบับนี้</h2>
        <ol className='t-ol'>
          <li>
            <b>2.1</b>
            ผู้ใช้ทุกรายที่ต้องการใช้บริการต่าง ๆ ของระบบ ต้องอ่านและรับทราบข้อกำหนดและเงื่อนไขฯ ฉบับนี้ก่อน จึงจะสามารถใช้บริการฯ
          </li>
          <li>
            <b>2.2</b> กรณีที่ผู้ใช้เป็นผู้เยาว์จะถือว่าได้รับความยินยอมจากบิดา มารดา หรือผู้แทนโดยชอบกฎหมาย
          </li>
          <li>
            <b>2.3 </b>หากมีข้อกำหนดและเงื่อนไขฯ เพิ่มเติมใด ๆ ซึ่งเกี่ยวข้องกับการให้บริการฯ ผู้ใช้จะต้องปฏิบัติตามข้อกำหนดและเงื่อนไขฯ เพิ่มเติมดังกล่าวเช่นเดียวกับข้อกำหนดและเงื่อนไขฯ ในการใช้งานฉบับนี้
          </li>
        </ol>
        <h2>3. การแก้ไขข้อกำหนดและเงื่อนไขฯ ฉบับนี้</h2>
        <p>ระบบอาจเปลี่ยนแปลงแก้ไขข้อกำหนดและเงื่อนไขฯ ฉบับนี้ได้ตลอดเวลาตามที่เห็นสมควรซึ่งจะอยู่ภายใต้ขอบวัตถุประสงค์ของข้อกำหนดและเงื่อนไขฯ ฉบับนี้ ในกรณีดังกล่าว ระบบจะแจ้งเนื้อหาของข้อกำหนดฉบับแก้ไข รวมถึงวันที่มีผลบังคับใช้บนเว็บไซต์ของระบบหรืออาจแจ้งให้ผู้ใช้ทราบด้วยวิธีการอื่นใดตามที่ระบบกำหนด ทั้งนี้ ข้อกำหนดและเงื่อนไขฯ ฉบับแก้ไขจะมีผลบังคับใช้ตามวันที่กำหนดต่อไป</p>
        <h2>4. บัญชี</h2>
        <ol className='t-ol'>
          <li>
            <b>4.1</b> ผู้ใช้ต้องลงทะเบียนใช้บริการฯ ด้วยข้อมูลที่เป็นความจริง ถูกต้อง และครบถ้วน มีหน้าที่ต้องปรับปรุงและแก้ไขข้อมูลดังกล่าวให้เป็นปัจจุบันอยู่เสมอ
          </li>
          <li>
            <b>4.2</b> ในกรณีที่ผู้ใช้ลงทะเบียนข้อมูลการยืนยันตัวตน (Authentication information) ผู้ใช้ต้องใช้ความระมัดระวังในการจัดการข้อมูลดังกล่าวด้วยความรับผิดชอบของตน เพื่อให้เป็นที่แน่ใจว่าข้อมูลดังกล่าวจะไม่ถูกนำไปใช้ในลักษณะที่ไม่ชอบด้วยกฎหมาย ทั้งนี้ระบบอาจถือว่ากิจกรรมใด ๆ ซึ่งดำเนินการโดยการใช้ข้อมูลการยืนยันตัวตนดังกล่าว
            เสมือนเป็นกิจกรรมที่ผู้เป็นเจ้าของข้อมูลได้ดำเนินการด้วยตนเองทั้งสิ้น
          </li>
          <li>
            <b>4.3</b> บัญชีแต่ละบัญชีในการใช้บริการฯ นั้น มีไว้เพื่อการใช้งานเฉพาะบุคคลและเป็นของเจ้าของบัญชีนั้นแต่เพียงผู้เดียว ผู้ใช้ไม่สามารถโอน ให้ยืม หรือจำหน่ายสิทธิในการใช้บัญชีของตนแก่บุคคลภายนอก ขณะเดียวกันบุคคลภายนอกก็ไม่สามารถรับช่วงสิทธิหรือสืบทอดบัญชีจากผู้ใช้ดังกล่าวได้เช่นกัน
          </li>
        </ol>
        <h2>5. การคุ้มครองข้อมูลส่วนบุคคล</h2>
        <ol className='t-ol'>
          <li>
            <b>5.1</b> ระบบให้ความสำคัญแก่ความเป็นส่วนตัวของผู้ใช้บริการฯ เป็นลำดับแรก
          </li>
          <li>
            <b>5.2</b> ระบบจะคุ้มครองความเป็นส่วนตัวและข้อมูลส่วนบุคคลของผู้ใช้บริการฯ อย่างเหมาะสม
          </li>
          <li>
            <b>5.3</b> ระบบให้คำมั่นที่จะใช้ความระมัดระวังและความเอาใส่ใจอย่างสูงสุดกับมาตรการรักษาความปลอดภัย เพื่อให้แน่ใจว่ามีการจัดการข้อมูลใด ๆ ซึ่งเก็บรวบรวมจากผู้ใช้บริการฯ อย่างเหมาะสมและปลอดภัย
          </li>
        </ol>
        <h2>6. การให้บริการฯ</h2>
        <ol className='t-ol'>
          <li>
            <b>6.1 </b>ผู้ใช้จะเป็นผู้รับผิดชอบจัดหาเครื่องคอมพิวเตอร์ อุปกรณ์สื่อสารเคลื่อนที่ สมาร์ทโฟน อุปกรณ์สื่อสารอื่นๆ ระบบปฏิบัติการ วิธีการเชื่อมต่อสื่อสารและกระแสไฟฟ้า ฯลฯ ซึ่งจำเป็นต่อการใช้บริการฯ ทั้งนี้ ผู้ใช้เป็นผู้ออกค่าใช้จ่ายเองทั้งสิ้น
          </li>
          <li>
            <b>6.2</b>
            ระบบขอสงวนสิทธิในการจำกัดการเข้าถึงการใช้บริการฯ ทั้งหมดหรือบางส่วนของผู้ใช้บริการฯ การสงวนสิทธิดังกล่าวขึ้นอยู่กับเงื่อนไขที่ระบบเห็นสมควร เช่น อายุ การระบุตัวตนของผู้ใช้บริการฯ การลงทะเบียน สถานภาพ และองค์ประกอบอื่นใดที่มีความคล้ายคลึงกัน
          </li>
          <li>
            <b>6.3</b>
            ระบบขอสงวนสิทธิในการแก้ไขหรือเปลี่ยนแปลงข้อกำหนดและเงื่อนฯ ในการใช้บริการทั้งหมดหรือบางส่วนไม่ว่าในเวลาใดตามที่ระบบเห็นสมควร ทั้งนี้ โดยไม่ต้องบอกกล่าวล่วงหน้าแก่ผู้ใช้
          </li>
          <li>
            <b>6.4</b> ระบบอาจหยุดการให้บริการฯ ทั้งหมดหรือบางส่วนโดยไม่ต้องบอกกล่าวล่วงหน้าแก่ผู้ใช้ในกรณีดังต่อไปนี้ <br />
            (1) เมื่อทำการบำรุงรักษาหรือซ่อมแซมระบบ <br />
            (2) เมื่อไม่สามารถให้บริการได้เนื่องจากเหตุสุดวิสัยบางประการ เช่น อุบัติเหตุ (ไฟไหม้ ไฟดับ ฯลฯ ) ปรากฏการณ์ธรรมชาติ สงคราม การจลาจล และข้อพิพาทแรงงาน เป็นต้น <br />
            (3) เมื่อระบบเกิดความขัดข้องหรือมีการใช้งานระบบมากเกินกำลัง (Overload) <br />
            (4) เพื่อรักษาความปลอดภัยของผู้ใช้หรือบุคคลภายนอกหรือเพื่อดำเนินภาระกิจอันเป็นประโยชน์ต่อสาธารณะในกรณีฉุกเฉิน <br />
            (5) เมื่อระบบเห็นว่ามีความจำเป็นและเป็นการสมควรนอกจากกรณีที่ได้ระบุไว้ในข้อ (1) ถึง (4) ข้างต้น
          </li>
        </ol>
        <h2>7. การแจ้งเหตุฉุกเฉิน</h2>
        <p>ผู้ใช้ไม่สามารถพึ่งพาบริการฯ นี้เป็นบริการหลักในการแจ้งเหตุฉุกเฉินได้ ไม่ว่าจะเป็นการแจ้งเหตุไปยังหน่วยงานบังคับใช้กฎหมาย หน่วยงานด้านความปลอดภัยทางทะเล หน่วยงานดับเพลิง หรือหน่วยงานอื่น ๆ ซึ่งให้บริการฉุกเฉิน</p>
        <h2>8. การประกาศ</h2>
        <p>ระบบขอสงวนสิทธิ ในการประกาศแจ้งข้อมูลของระบบหรือของบุคคลภายนอกบนระบบการให้บริการฯ</p>
        <h2>9. ผู้ให้บริการภายนอก</h2>
        <p>การให้บริการฯ นี้อาจมีเนื้อหา หรือบริการอื่นใดซึ่งให้บริการหรือจัดทำโดยผู้ให้บริการภายนอก ในการนี้ผู้ให้บริการภายนอกดังกล่าวจะเป็นผู้รับผิดชอบแต่เพียงผู้เดียวเกี่ยวกับเนื้อหา และ/หรือการให้บริการดังกล่าว นอกจากนี้ เนื้อหา หรือบริการฯ ดังกล่าวอาจตกอยู่ภายใต้ข้อกำหนด การใช้ หรือข้อกำหนดและเงื่อนไขฯ อื่นใดซึ่งผู้ให้บริการภายนอกได้กำหนดไว้สำหรับเนื้อหาและบริการนั้น ๆ</p>
        <h2>10. เนื้อหา</h2>
        <ol className='t-ol'>
          <li>
            <b>10.1</b> ระบบอนุญาตให้ผู้ใช้ใช้บริการฯ เนื้อหาหลักที่จัดให้เพื่อวัตถุประสงค์ในการใช้บริการฯ ของผู้ใช้โดยไม่จำกัดจำนวนผู้ใช้ อย่างไรก็ตาม ผู้ใช้ไม่สามารถส่งหรือโอน อนุญาตช่วง หรืออนุญาตให้ใช้สิทธิ แก่บุคคลภายนอกได้ (non-transferable, non-re-licensable, non-exclusive license)
          </li>
          <li>
            <b>10.2</b> ผู้ใช้จะต้องไม่ใช้เนื้อหาหลักเกินจากวัตถุประสงค์ของการใช้บริการฯ (ซึ่งรวมถึงแต่ไม่จำกัดเพียงการทำสำเนา การส่ง การทำซ้ำ และการแก้ไขเปลี่ยนแปลง)
          </li>
          <li>
            <b>10.3</b> ในกรณีที่ผู้ใช้ต้องการที่จะสำรองเนื้อหาจากผู้ใช้ (Back-up) ไม่ว่าทั้งหมดหรือเพียงบางส่วน ผู้ใช้จะต้องดำเนินการดังกล่าวด้วยตนเอง ระบบไม่มีหน้าที่และภาระในการสำรองเนื้อหาจากผู้ใช้แต่อย่างใด
          </li>
        </ol>
        <h2>11. ค่าธรรมเนียมการใช้บริการ</h2>
        <ol className='t-ol'>
          <li>
            <b>11.1</b> ในรอบการสมัครใด ๆ หากมีเงื่อนไขที่ต้องชำระค่าธรรมเนียม ผู้ใช้มีหน้าที่ต้องชำระค่าธรรมเนียมการสมัคร เพื่อยืนยันการขอสมัครและประมวลผลการคัดเลือกเข้าศึกษาในระดับอุดมศึกษาของสถาบันการศึกษาที่อยู่ภายในระบบในช่วงเวลาที่ระบบกำหนด ซึ่งผู้ใช้จะต้องปฏิบัติตามข้อกำหนดและเงื่อนไขฯ เหล่านี้อย่างเคร่งครัดเพื่อรักษาประโยชน์ของตนเองและรักษาสิทธิ์ในใบสมัครนั้น
          </li>
          <li>
            <b>11.2</b>
            ทั้งนี้ผู้ใช้สามารถดำเนินการตามขั้นตอนเพื่อขอยกเลิกการยืนยันการสมัครในระบบตามช่วงเวลาที่ระบบกำหนด อย่างไรก็ตามหากผู้ใช้ดำเนินตามขั้นตอนเพื่อขอยกเลิกการสมัครดังกล่าวแล้ว ผู้ใช้ไม่สามารถยกเลิกการขอยกเลิกใบสมัครดังกล่าวได้ ในกรณีนี้ ค่าธรรมเนียมซึ่งผู้ใช้ได้ชำระไปแล้วนั้นไม่สามารถขอเรียกรับเงินคืนได้
          </li>
        </ol>
        <h2>12. ข้อจำกัดการใช้งาน</h2>
        <p>ผู้ใช้ไม่สามารถกระทำการดังต่อไปนี้เมื่อใช้บริการฯ ได้ ตามที่ระบบกำหนด</p>
        <ol className='t-ol'>
          <li>
            <b>12.1</b> การกระทำที่ฝ่าฝืนกฎหมาย ข้อบังคับ คำพิพากษา มติหรือคำสั่งศาล หรือมาตรการทางปกครองที่มีผลผูกพันทางกฎหมาย
          </li>
          <li>
            <b>12.2</b> การกระทำที่อาจขัดต่อความสงบเรียบร้อยหรือศีลธรรมอันดีของประชาชน{' '}
          </li>
          <li>
            <b>12.3</b> การกระทำที่ละเมิดสิทธิในทรัพย์สินทางปัญญา เช่น ลิขสิทธิ์ เครื่องหมายการค้าและสิทธิบัตร สิทธิในชื่อเสียง ความเป็นส่วนตัว และสิทธิอื่น ๆ ทั้งหมดที่ได้รับตามกฎหมายหรือตามสัญญากับ ระบบและ/หรือบุคคลที่สาม
          </li>
          <li>
            <b>12.4</b> การกระทำที่อาจทำให้ผู้อื่นเข้าใจผิดเกี่ยวกับ ระบบและ/หรือบุคคลภายนอก หรือการจงใจเผยแพร่ข้อมูลเท็จ
          </li>
          <li>
            <b>12.5</b> การกระทำที่เป็นการแลกเปลี่ยนสิทธิในการใช้เนื้อหาฯ เป็นเงินสด ทรัพย์สินหรือผลประโยชน์ทางการเงินอื่น ๆ นอกเสียจากที่ระบบกำหนดไว้
          </li>
          <li>
            <b>12.6</b> การกระทำที่เป็นการเก็บรวบรวม เปิดเผย หรือการให้ข้อมูลส่วนบุคคลของบุคคลอื่น ข้อมูลที่จดทะเบียน ประวัติผู้ใช้ หรือข้อมูลอื่น ๆ ในทำนองเดียวกันโดยไม่ชอบด้วยกฎหมายหรือไม่เหมาะสม
          </li>
          <li>
            <b>12.7</b> การกระทำซึ่งเป็นการแทรกแซงเครื่องแม่ข่ายและ/หรือระบบเครือข่ายของการให้บริการฯ การกระทำซึ่งเป็นการใช้บริการฯ ในทางที่ผิดด้วยการใช้บ็อท (bots) เป็นเครื่องมือเพื่อโกงหรือด้วยวิธีการทางเทคนิคอื่น ๆ การกระทำที่ใช้ข้อบกพร่องของการบริการฯ โดยไตร่ตรองไว้ก่อน การกระทำที่ทำการสอบถามคำถามซ้ำ ๆ เกินความจำเป็น และการกระทำที่เป็นการแทรกแซงการให้บริการฯ ของระบบหรือการใช้บริการฯ ของผู้ใช้
          </li>
          <li>
            <b>12.8</b> การถอดรหัสซอร์สโค้ด (Source Code) ของบริการฯ โดยวิธีวิศวกรรมย้อนกลับ (Reverse engineering) การถอดประกอบหรือสิ่งที่คล้ายคลึงกัน เพื่อวัตถุประสงค์ที่ไม่เหมาะสมหรือในลักษณะที่ไม่เป็นธรรม
          </li>
          <li>
            <b>12.9</b> การกระทำที่ให้ความช่วยเหลือหรือส่งเสริมกิจกรรมใด ๆ ที่ระบุไว้ในข้อ 12.1 ถึงข้อ 12.8 ข้างต้น
          </li>
          <li>
            <b>12.10</b> การกระทำอื่นใด นอกเหนือจากที่ระบุในข้อ 12.1 ถึงข้อ 12.9 ที่ ทปอ. เห็นว่าไม่เหมาะสม
          </li>
        </ol>
        <h2>13. ความรับผิดชอบของผู้ใช้</h2>
        <ol className='t-ol'>
          <li>
            <b>13.1</b> ผู้ใช้มีหน้าที่ดำเนินการตามช่วงเวลาที่ระบบกำหนดเปิดให้บริการ รวมทั้งการตรวจสอบข้อมูล ให้เป็นปัจจุบันและถูกต้องอยู่เสมอ
          </li>

          <li>
            <b>13.2</b> ในกรณีที่เกิดความสูญเสียหรือความเสียหายแก่ระบบไม่ว่าทางตรงหรือทางอ้อม (ซึ่งรวมถึงโดยไม่จำกัดเพียงค่าทนายความ) เนื่องจากการใช้บริการฯ ของผู้ใช้ (ซึ่งรวมถึงโดยไม่จำกัดเพียงกรณีที่มีบุคคลภายนอกฟ้องร้องเรียกค่าเสียหายจากระบบเนื่องจากการใช้บริการฯ ดังกล่าว) ผู้ใช้มีหน้าที่ต้องชดใช้ค่าเสียหายให้แก่ระบบทันที ที่ระบบร้องขอ
          </li>
        </ol>
        <h2>14. ข้อจำกัดความรับผิดของระบบ</h2>
        <ol className='t-ol'>
          <li>
            <b>14.1</b> ระบบจะไม่รับผิดชอบใดๆ ต่อความเสียหายอันเกิดจากการกระทำของผู้ใช้ในการใช้บริการฯ{' '}
          </li>
          <li>
            <b>14.2</b> ระบบจะไม่รับผิดชอบต่อความเสียหาย ค่าใช้จ่าย ที่อาจเกิดขึ้นจากการใช้ระบบอื่น หรือเว็บไซต์ที่เชื่อมโยงกับระบบซึ่งรวมถึงจากความไม่ถูกต้องแม่นยำของข้อมูล ความล่าช้าในการถ่ายโอนข้อมูล ระยะเวลาในการเผยแพร่ข้อมูล เหตุขัดข้องหรืออุปสรรคของการเชื่อมต่อ ความผิดพลาดในการแสดงผล ความเสถียรของระบบไวรัสคอมพิวเตอร์
          </li>
        </ol>
        <h2>15. การแจ้งเตือนและการติดต่อ</h2>
        <ol className='t-ol'>
          <li>
            <b>15.1</b> สำหรับการแจ้งเตือนจาก ระบบหรือ หน่วยงานในเครือ ต่อผู้ใช้เกี่ยวกับบริการ ระบบอาจใช้วิธีการที่เห็นว่าเหมาะสม เช่น การประกาศไว้ในระบบการให้บริการหรือเว็บไซต์ของ ระบบเอง
          </li>
        </ol>
        <h2>16. วัตถุประสงค์ในการเก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูล</h2>
        <ol className='t-ol'>
          <li>
            <b>16.1</b> ทปอ. จะนำข้อมูลของท่านมาใช้เพื่อการประมวลผลการเข้าศึกษาระดับอุดมศึกษา พัฒนาและปรับปรุงเว็บไซต์ https://student.mytcas.com แพลตฟอร์มออนไลน์ และช่องทางโซเชียลมีเดียอื่น ๆ ในเครือ ตลอดจนการวิเคราะห์และประมวลผลข้อมูลส่วนบุคคล เพื่อให้ตอบโจทย์การใช้งานของผู้ใช้งาน ด้วยวิธีการทางอิเล็กทรอนิกส์แก่ท่านให้มีประสิทธิภาพมากยิ่งขึ้น
          </li>
          <li>
            <b>16.2</b> หากภายหลังมีการเปลี่ยนแปลงวัตถุประสงค์ ทปอ.จะแจ้งให้ท่านทราบ และจัดให้มีบันทึกการแก้ไขเพิ่มเติมไว้เป็นหลักฐาน
          </li>
          <li>
            <b>16.3 </b>ทั้งนี้ ทปอ.จะไม่กระทำการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน เพื่อประโยชน์อย่างอื่น ที่นอกเหนือจากวัตถุประสงค์ที่ได้แจ้งไว้กับท่านไว้ก่อนหรือขณะเก็บรวบรวม
          </li>
        </ol>
        <h2>17. การเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล</h2>
        <ol className='t-ol'>
          <li>
            <b>17.1</b> การเก็บรวบรวมข้อมูลส่วนบุคคล ทปอ.จะเก็บรวบรวมข้อมูลของท่านโดยแจ้งให้ท่านทราบก่อน เว้นแต่กรณีที่กฎหมายให้อำนาจไว้ตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 มาตรา 24 หรือ มาตรา 26
          </li>

          <li>
            <b>17.2</b> ทปอ.จะเก็บรวบรวมข้อมูลของท่านไว้เป็นระยะเวลา 10 ปี นับแต่วันที่เริ่มเปิดใช้งานระบบ
          </li>

          <li>
            <b>17.3</b> ทปอ.จะเก็บรวบรวมข้อมูลของท่านเฉพาะเท่าที่จำเป็น ภายใต้วัตถุประสงค์ที่ ทปอ.ได้แจ้งไว้ใน ข้อ 16
          </li>

          <li>
            <b>17.4</b> ทปอ.ได้รวบรวมข้อมูลของท่านจากท่านโดยตรงและจากแหล่งที่มาอื่น อันได้แก่ สํานักงานคณะกรรมการการศึกษาขั้นพื้นฐาน (สพฐ) มหาวิทยาลัย และสถาบันการเงิน เป็นต้น ภายใต้วัตถุประสงค์ของระบบ TCAS และประโยชน์ของท่าน
          </li>

          <li>
            <b>17.5</b> ทปอ.จะไม่เก็บรวบรวมข้อมูลส่วนบุคคลที่เกี่ยวกับเชื้อชาติ เผ่าพันธ์ุ ความคิดเห็นทางการเมือง ความเชื่อในลัทธิ ศาสนาหรือปรัชญา พฤติกรรมทางเพศ ประวัติอาชญากรรม ข้อมูลสุขภาพ ความพิการ ข้อมูลสหภาพแรงงาน ข้อมูลพันธุกรรม ข้อมูลชีวภาพ หรือข้อมูลอื่นใดซึ่งกระทบต่อท่านในทำนองเดียวกันตามประกาศของคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล โดยไม่ได้รับความยินยอมโดยชัดแจ้งจากท่าน
            เว้นแต่เป็นกรณีตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 มาตรา 26 บัญญัติให้กระทำได้
          </li>

          <li>
            <b>17.6</b> การใช้ หรือเปิดเผยข้อมูลส่วนบุคคล ทปอ.จะไม่ใช้ หรือเปิดเผยข้อมูลของท่านก่อนที่จะได้รับความยินยอมจากท่าน เว้นแต่เป็นข้อมูลของท่านที่เก็บรวบรวมได้โดยไม่ต้องขอความยินยอม ตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 มาตรา 24 หรือมาตรา 26
          </li>

          <li>
            <b>17.7</b> ทปอ.จะดูแลให้เจ้าหน้าที่ที่ได้รับมอบหมายในเรื่องนี้โดยเฉพาะ มิให้ ใช้ หรือเปิดเผย แสดง หรือทำให้ปรากฏในลักษณะอื่นใดซึ่งข้อมูลส่วนบุคคลของท่าน นอกเหนือไปจากวัตถุประสงค์ ภายใต้หลักเกณฑ์ที่กฎหมายอนุญาตให้เปิดเผยได้ตามขอบเขตที่ท่านได้ให้ความยินยอม หรือขอบเขตที่เกี่ยวข้องในนโยบายฉบับนี้
          </li>

          <li>
            <b>17.8</b> ในกรณีที่ ทปอ.จะเปิดเผยข้อมูลส่วนบุคคลของท่านให้แก่บุคคลหรือนิติบุคคลภายนอก บุคคลหรือนิติบุคคลภายนอกนั้นจะต้องแจ้งวัตถุประสงค์ในการ ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่านให้ ทปอ.ทราบก่อน และต้องไม่ใช้หรือเปิดเผยข้อมูลส่วนบุคคล นอกเหนือจากวัตถุประสงค์ที่ได้ให้ไว้แก่ ทปอ.
          </li>

          <li>
            <b>17.9</b> ทปอ.อาจเปิดเผยข้อมูลส่วนบุคคลของท่านแก่ หน่วยงานผู้ร่วมวิเคราะห์ข้อมูล เพื่อการประมวลผล วิเคราะห์และ/หรือบริการ ให้แก่ท่าน
          </li>

          <li>
            <b>17.10</b> ในกรณีที่ ทปอ.ส่งหรือโอนข้อมูลส่วนบุคคลไปยังต่างประเทศ ประเทศปลายทางหรือองค์กรระหว่างประเทศที่รับข้อมูลส่วนบุคคลต้องมีมาตราฐานคุ้มครองข้อมูลส่วนบุคคลที่เพียงพอ ทั้งนี้ ต้องเป็นไปตามหลักเกณฑ์การคุ้มครองข้อมูลส่วนบุคคลตามที่คณะกรรมการคุ้มครองข้อมูลส่วนบุคคลกำหนด เว้นแต่เป็นกรณีตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 มาตรา 28 (1) – (6)
          </li>
        </ol>
        <h2>18. ความสัมพันธ์ระหว่างกฎหมาย กฎระเบียบ และข้อกำหนดและเงื่อนไขฯ ฉบับนี้</h2>
        <p>การใช้บริการเว็บไซต์นี้หรือการตีความข้อตกลงและเงื่อนไขการใช้บริการเว็บไซต์นี้ให้เป็นไปตามกฎหมายไทย</p>
        <h2>19. คู่มือการใช้งาน</h2>
        <p>
          ต้องศึกษาวิธีการใช้งานระบบอย่างละเอียดรอบคอบ โดย Download คู่มือได้ที่เมนูหน้าแรก (Home) หรือ เว็บไซต์ <a href='https://www.mytcas.com'>mytcas.com</a>
        </p>

        <div className='text-right'>
          <small>
            <em>ปรับปรุงล่าสุดเมื่อวันที่ 28 ตุลาคม 2565</em>
          </small>
        </div>
        <hr />

        <div className='action'>
          <a className='btn-main cursor-pointer' onClick={onSubmit}>
            {t('รับทราบ และ เริ่มยืนยันตัวตน')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ModalTermsAndConditions;
