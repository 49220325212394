import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { callAPI, getRoundState, getRoundDescription } from 'helpers';
import _ from 'lodash';

// Components
import RoundContent from '../RoundContent';
import ModalOfferReplyConsent from './ModalRound2OfferReplyConsent';
import ModalOfferCancelConsent from './ModalRound2OfferCancelConsent';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import useQuery from 'hooks/useQuery';

const roundType = '2_2568';
const Round2 = () => {
  const { t } = useTranslation();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});
  // const query = useQuery();

  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [offerCount, setOfferCount] = useState(0);
  const [offers, setOffers] = useState([]);
  const [replies, setReplies] = useState([]);
  const [timestamp, setTimestamp] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [alreadyApplied, setAlreadyApplied] = useState(false);

  const { flow } = getRoundState({ roundType, timestamp });
  const description = getRoundDescription({ roundType, timestamp, offers, replies, alreadyApplied });

  useEffect(() => {
    const fetchEnrollmentOffers = async () => {
      try {
        setLoading(true);
        setActive(false);
        const { replies: repliesR1 } = await callAPI({
          url: '/applicants/me/enrollment-offers',
          query: { type: '1_2568' }
        });
        const lastR1 = _.last(repliesR1);
        if (lastR1 && lastR1.enrollment_offer && !lastR1.is_canceled) {
          setAlreadyApplied(true);
        } else {
          const { offerCount, offers, replies, timestamp } = await callAPI({
            url: '/applicants/me/enrollment-offers',
            query: { type: '2_2568' },
          });
          setOfferCount(offerCount);
          setOffers(offers);
          setReplies(replies);
          setTimestamp(timestamp);
          setLoading(false);
          setActive(true);
        }
      } catch (error) {
        console.error(`Error while trying to fetch enrollment offers: ${error.message}`);
        setLoading(false);
      }
    };

    // fetchEnrollmentOffers();
  }, []);

  useEffect(() => {
    if (flow === 'default' && !_.get(profile, 'consents_accepted_at.offer_reply_2_2568')) {
      setModalVisible(true);
    } else if (flow === 'cancel' && !_.get(profile, 'consents_accepted_at.offer_cancel_2_2568')) {
      setModalVisible(true);
    }
  }, [flow, profile]);

  const [accepting, setAccepting] = useState(false);
  const _onAcceptConsent = async (consentType) => {
    if (accepting) return;
    try {
      setAccepting(true);
      await callAPI({
        method: 'POST',
        url: '/applicants/me/consents',
        body: {
          consentType,
        },
      });
      setAccepting(false);
    } catch (error) {
      console.error(`Error while trying to accept consent: ${error.message}`);
      setAccepting(false);
    }
    setModalVisible(false);
  };

  return (
    <div className={cn('r-2', { active })}>
      <header onClick={() => setActive((_active) => !_active)}>
        <b>
          <small>{t('รอบ')}</small>2
        </b>
        <h3>
          Quota
          <small>{t(description)}</small>
        </h3>
        <i className="i-down"></i>
      </header>
      <RoundContent
        flow={flow}
        alreadyApplied={alreadyApplied}
        roundType={roundType}
        loading={loading}
        offerCount={offerCount}
        offers={offers}
        replies={replies}
        timestamp={timestamp} />
      <ModalOfferReplyConsent
        visible={offers.length && modalVisible && flow === 'default'}
        onSubmit={() => _onAcceptConsent('offer_reply_2_2568')}
      />
      <ModalOfferCancelConsent
        visible={offers.length && modalVisible && flow === 'cancel'}
        onSubmit={() => _onAcceptConsent('offer_cancel_2_2568')}
      />
    </div>
  );
};

export default Round2;
