import { useState } from 'react';
import { notAllowed } from 'validations';
import { getNewestTicket } from 'helpers';

const useProgram = (school = {}, pendingTickets) => {
  const { valueOf } = getNewestTicket(pendingTickets, 'update_gpax');
  const [programCode, setProgramCode] = useState(valueOf('program_code') || school.program_code);
  const [schoolYear, setSchoolYear] = useState(valueOf('school_year') || school.school_year);

  // [At the beginning]
  const gpaxField = programCode > 4 || schoolYear < 2567 ? 'gpax6_score' : 'gpax5_score';

  // [When GPAX6 is ready]
  // const gpaxField = 'gpax6_score';

  const [gpax, setGpax] = useState(valueOf(gpaxField) || school[gpaxField]);

  return {
    programCode,
    onProgramCodeChange: (e) => setProgramCode(e.target.value),

    schoolYear,
    onSchoolYearChange: (e) => {
      if (notAllowed('number', e.target.value)) return;
      setSchoolYear(e.target.value);
    },

    gpaxField,
    gpax,
    onGpaxChange: (e) => {
      if (notAllowed('gpax', e.target.value)) return;
      setGpax(e.target.value);
    },
  };
};

export default useProgram;
