import { useState, useRef } from 'react';
import _ from 'lodash';

const useOTPHandlers = (onSubmit) => {
  const [digits, setDigits] = useState(['', '', '', '', '', '']);

  return {
    digits,
    setDigits,

    digitRefs: [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()],
    onOTPChange: (index, value) => {
      setDigits((_digits) => {
        if (index) {
          let array = _.clone(_digits);
          array[index] = value.slice(-1);
          return array;
        }
        return value.split('');
      });
    },
  };
};

export default useOTPHandlers;
