import { useState } from 'react';
import { compressImage } from 'helpers';

/*
ให้ถ่ายรูปบัตรประชาชนและถ่ายรูปคู่กับบัตรประชาชนตามตัวอย่าง
หากไม่มีบัตรประชาชน ให้ใช้ Passport แทน
*/
const useCitizenCard = () => {
  const [citizenCardFile, setCitizenCardFile] = useState();
  const [selfieFile, setSelfieFile] = useState();

  return {
    citizenCardFile,
    selfieFile,

    onCitizenCardFileChange: async (e) => {
      const _file = await compressImage(e.target.files[0]);
      setCitizenCardFile(_file);
    },
    onSelfieFileChange: async (e) => {
      const _file = await compressImage(e.target.files[0]);
      setSelfieFile(_file);
    },
  };
};

export default useCitizenCard;
