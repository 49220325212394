import { useEffect, useState } from 'react';
import _ from 'lodash';

// Helpers
import { getNewestTicket } from 'helpers';
import { notAllowed } from 'validations';

// Master data
import postalCodes from 'data/postalCodes.json';

const postalCodeMap = _.keyBy(postalCodes, 'zipCode');
const useNewSchool = (profile, pendingTickets, schoolCountryType) => {
  const { valueOf } = getNewestTicket(pendingTickets, ['update_school', 'update_foreign_school']);

  const [visible, setVisible] = useState(false);
  const [schoolName, setSchoolName] = useState(valueOf('school_name') || profile.school_name || '');
  const [postalCode, setPostalCode] = useState(valueOf('school_zipcode') || profile.school_zipcode || '');
  const [provinceId, setProvinceId] = useState(valueOf('school_province_id') || profile.school_province_id || '');
  const [districtId, setDistrictId] = useState(valueOf('school_district_id') || profile.school_district_id || '');
  const [subDistrictId, setSubDistrictId] = useState(
    valueOf('school_sub_district_id') || profile.school_sub_district_id || ''
  );
  const [address, setAddress] = useState(valueOf('school_address') || profile.school_address);
  const [countryState, setCountryState] = useState(valueOf('school_country_state') || profile.school_country_state);
  const [countryName, setCountryName] = useState(valueOf('school_country_name') || profile.school_country_name);

  useEffect(() => {
    if (schoolCountryType === 'thai') {
      setSchoolName('');
      setAddress('');
    }
  }, [schoolCountryType]);

  useEffect(() => {
    const { provinceList = [], districtList = [], subDistrictList = [] } = postalCodeMap[postalCode] || {};
    if (provinceList.length <= 1) {
      setProvinceId(_.get(provinceList, '0.provinceId'));
    }
    if (districtList.length <= 1) {
      setDistrictId(_.get(districtList, '0.districtId'));
    }
    if (subDistrictList.length <= 1) {
      setSubDistrictId(_.get(subDistrictList, '0.subDistrictId'));
    }
  }, [postalCode]);

  return {
    newSchoolVisible: visible,
    onNewSchoolVisibilityChange: (visible) => setVisible(visible),

    schoolName,
    onSchoolNameChange: (e) => {
      if (notAllowed('textTH', e.target.value)) return;
      setSchoolName(e.target.value);
    },

    postalCode,
    onPostalCodeChange: (e) => {
      if (notAllowed('postalCode', e.target.value)) return;
      setPostalCode(e.target.value);
    },
    onPostalCodeEnChange: (e) => {
      if (notAllowed('postalCodeEn', e.target.value)) return;
      setPostalCode(e.target.value);
    },

    provinceId,
    onProvinceIdChange: (e) => setProvinceId(e.target.value),

    districtId,
    onDistrictIdChange: (e) => setDistrictId(e.target.value),

    subDistrictId,
    onSubDistrictIdChange: (e) => setSubDistrictId(e.target.value),

    address,
    onAddressChange: (e) => {
      if (notAllowed('textTH', e.target.value)) return;
      setAddress(e.target.value);
    },

    countryState,
    onCountryStateChange: (e) => setCountryState(e.target.value),

    countryName,
    onCountryNameChange: (e) => setCountryName(e.target.value),
  };
};

export default useNewSchool;
