import { Link } from 'react-router-dom';
export default function Logo() {
  return (
    <div className="site-logo">
      <Link to="/">
        <img src="/assets/img/logo.svg" alt="TCAS Logo" className="logo" width="160" height="50" />
      </Link>
    </div>
  );
}
