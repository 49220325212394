/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useTranslation } from 'react-i18next';

const ModalRound2OfferCancelConsent = ({ visible, onSubmit }) => {
  const { t } = useTranslation();
  if (!visible) return null;
  return (
    <div className="t-modal" style={{ visibility: 'visible', opacity: 1, pointerEvents: 'auto' }}>
      <div
        className="content"
        style={{ padding: '20px 30px', opacity: 1, height: 'initial', overflow: 'auto', textAlign: 'left' }}
      >
        <h1>ข้อกำหนดและเงื่อนไขในการสละสิทธิ์</h1>
        <p className="text-center">
          กรุณาอ่านรายละเอียดให้ครบถ้วน และคลิกปุ่ม <strong>"รับทราบ"</strong> ในส่วนล่างสุด
        </p>
        <br />
        <h2>การสละสิทธิ์</h2>
        <p>
          ผู้สมัครต้องดำเนินการสละสิทธิ์ให้แล้วเสร็จภายในเวลา 23.59 น. ของวันที่ 4 พฤษภาคม 2568 เพื่อสมัครรอบ 3 หรือรอบ
          4
        </p>

        <p>โดย</p>
        <ol>
          <li>การสละสิทธิ์ต้องดำเนินการในระบบ TCAS68 เท่านั้น และขอยกเลิกการสละสิทธิ์ในภายหลังไม่ได้</li>
          <li>
            การแจ้งสละสิทธิ์ที่มหาวิทยาลัย โดยไม่ดำเนินการกดปุ่ม “สละสิทธิ์” ในระบบ TCAS68 ตามเวลาที่กำหนด
            ไม่ถือเป็นการสละสิทธิ์ในระบบ TCAS68 ซึ่งจะไม่สามารถสมัครคัดเลือกในรอบต่อไปได้
          </li>
        </ol>

        <div className="text-right">
          <small>
            <em>ปรับปรุงล่าสุดเมื่อวันที่ 29 เมษายน 2566</em>
          </small>
        </div>
        <hr />

        <div className="action">
          <a className="btn-main cursor-pointer" onClick={onSubmit}>
            {t('รับทราบ')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ModalRound2OfferCancelConsent;
