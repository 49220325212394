/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import classNames from 'classnames';
import Cookies from 'js-cookie';

// Components
import ModalTermsAndConditions from 'components/registrations/ModalTermsAndConditions';

// Helpers
import { callAPI } from 'helpers';

// Hooks
import { useTranslation } from 'react-i18next';
import usePassword from 'hooks/usePassword';

const TcasUpdatePassword = ({ token, citizenId, citizenTypeLabel, studentType, onSuccess }) => {
  const { t } = useTranslation();
  const { password, onPasswordChange, passwordValidations, passwordHidden, onPasswordHiddenChange } = usePassword();
  const [modalVisible, setModalVisible] = useState(false);

  const [submitting, setSubmitting] = useState(false);
  const onSubmit = async () => {
    if (submitting) return;
    try {
      setSubmitting(true);
      const { access_token } = await callAPI({
        method: 'POST',
        url:
          ['tcas', 'tcas-past'].includes(studentType)
            ? '/tcas-past/update-password'
            : studentType === 'registered'
            ? '/applicants/update-password'
            : null,
        body: {
          password,
          token,
        },
      });
      Cookies.set('accessToken', access_token, { expires: 1 / 24 });
      setSubmitting(false);
      onSuccess();
    } catch (error) {
      console.error(new Error(`Error while trying to update a password: ${error.message}`));
      setSubmitting(false);
    }
  };

  const onPasswordEnter = (e) => {
    if (e.key === 'Enter') {
      setModalVisible(true);
    }
  };

  return (
    <div>
      <div className="t-box -info -half">{t('กรุณาตั้งรหัสผ่านเพื่อใช้ในการเข้าสู่ระบบครั้งต่อไป')}</div>

      <div className="t-row">
        <div className="col required valid">
          <label className="has-meta">
            <span>{t('เลขประจำตัว')}</span>
            <span className="meta">({citizenTypeLabel})</span>
          </label>
          <input type="text" value={citizenId} disabled />
        </div>
      </div>
      <div className="t-row">
        <div className="col required">
          <label>{t('รหัสผ่าน')}</label>
          <input
            type={passwordHidden ? 'password' : 'text'}
            placeholder={t('กรอกรหัสผ่าน')}
            value={password}
            onChange={onPasswordChange}
            onKeyPress={onPasswordEnter}
          />
          <i
            className={classNames('i-viewpass cursor-pointer', { active: !passwordHidden })}
            onClick={onPasswordHiddenChange}
          />
          <ul className="t-small">
            <li>
              <i
                className={classNames({
                  'i-yes': passwordValidations.lengthAtLeast8,
                  'i-no': !passwordValidations.lengthAtLeast8,
                })}
              />{' '}
              {t('จำนวนไม่น้อยกว่า 8 หลัก')}
            </li>
            <li>
              <i
                className={classNames({
                  'i-yes': passwordValidations.upperCaseAtLeast1,
                  'i-no': !passwordValidations.upperCaseAtLeast1,
                })}
              />{' '}
              {t('ตัวอักษรภาษาอังกฤษพิมพ์ใหญ่ A - Z อย่างน้อย 1 ตัว')}
            </li>
            <li>
              <i
                className={classNames({
                  'i-yes': passwordValidations.lowerCaseAtLeast1,
                  'i-no': !passwordValidations.lowerCaseAtLeast1,
                })}
              />{' '}
              {t('ตัวอักษรภาษาอังกฤษพิมพ์เล็ก a - z อย่างน้อย 1 ตัว')}
            </li>
            <li>
              <i
                className={classNames({
                  'i-yes': passwordValidations.numberAtLeast1,
                  'i-no': !passwordValidations.numberAtLeast1,
                })}
              />{' '}
              {t('ตัวเลข 0 - 9 อย่างน้อย 1 ตัว')}
            </li>
          </ul>
        </div>
      </div>
      <div className="action">
        <p>
          <a
            onClick={
              submitting ? () => {} : studentType === 'registered' ? () => onSubmit() : () => setModalVisible(true)
            }
            className={classNames('btn-main cursor-pointer', { disabled: submitting })}
          >
            ตั้งรหัสผ่านใหม่
          </a>
        </p>
      </div>
      <ModalTermsAndConditions visible={modalVisible} onSubmit={onSubmit} onClose={() => setModalVisible(false)} />
    </div>
  );
};

export default TcasUpdatePassword;
