import React from 'react';
import Cookies from 'js-cookie';

// Components
import ModalTermsAndConditions from 'components/registrations/ModalTermsAndConditions';

// Hooks
import { useNavigate, useParams } from 'react-router-dom';

const RedirectOldStudent = () => {
  const token = useParams().token;
  const navigate = useNavigate();

  const _onSubmit = () => {
    Cookies.set('accessToken', token, { expires: 1 / 24 });
    navigate('/profile');
  }

  return (
    <>
      <ModalTermsAndConditions visible onSubmit={_onSubmit} onClose={() => navigate('/')} />
    </>
  )
}

export default RedirectOldStudent;
