import { useState } from 'react';

const usePassword = () => {
  const [password, setPassword] = useState('');
  const [passwordHidden, setPasswordHidden] = useState(true);

  return {
    password,
    onPasswordChange: (e) => {
      const value = `${e.target.value || ''}`;
      if (value.includes(' ')) return;
      setPassword(value);
    },
    onPasswordClear: () => setPassword(''),

    passwordHidden,
    onPasswordHiddenChange: () => setPasswordHidden((_hidden) => !_hidden),

    passwordValidations: {
      lengthAtLeast8: password.match(new RegExp('(?=.{8,})', 'g')),
      upperCaseAtLeast1: password.match(new RegExp('(?=.*[A-Z])', 'g')),
      lowerCaseAtLeast1: password.match(new RegExp('(?=.*[a-z])', 'g')),
      numberAtLeast1: password.match(new RegExp('(?=.*[0-9])', 'g')),
    },
  };
};

export default usePassword;
