/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { callAPI } from 'helpers';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { useParams, useNavigate } from 'react-router-dom';

import menus from 'nav/menus';
import Logo from 'nav/logo';

const prices = [150, 200, 250, 300, 400, 500, 600, 700, 800, 900];
const priceMap = {
  150: '150.00 บาท (หนึ่งร้อยห้าสิบบาทถ้วน)',
  200: '200.00 บาท (สองร้อยบาทถ้วน)',
  250: '250.00 บาท (สองร้อยห้าสิบบาทถ้วน)',
  300: '300.00 บาท (สามร้อยบาทถ้วน)',
  400: '400.00 บาท (สี่ร้อยบาทถ้วน)',
  500: '500.00 บาท (ห้าร้อยบาทถ้วน)',
  600: '600.00 บาท (หกร้อยบาทถ้วน)',
  700: '700.00 บาท (เจ็ดร้อยบาทถ้วน)',
  800: '800.00 บาท (แปดร้อยบาทถ้วน)',
  900: '900.00 บาท (เก้าร้อยบาทถ้วน)'
}

const R3RedemptionReceipt = ({ type }) => {
  const { hash } = useParams();
  const navigate = useNavigate();

  const lang = useSelector((state) => _.get(state, 'app.lang'));
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(lang.toLowerCase());
    document.documentElement.setAttribute('lang', lang.toLowerCase());
    Cookies.set('lang', lang, { expires: 365 });
  }, [i18n, lang]);

  const [loading, setLoading] = useState(true);
  const [receipt, setReceipt] = useState();
  useEffect(() => {
    const fetchReceipt = async () => {
      try {
        setLoading(true);
        const { result } = await callAPI({
          method: 'POST',
          url: '/external/r3-redemption-receipt',
          body: { hash }
        });
        setReceipt(result);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        navigate('/');
      }
    }

    fetchReceipt();
  }, [hash, navigate]);

  useEffect(() => {
    const classNames = document.getElementById('root').className.split(' ');
    if (loading) {
      document.getElementById('root').className = _.uniq(classNames.concat(['is-loading'])).join(' ');
    } else {
      document.getElementById('root').className = classNames.filter((name) => name !== 'is-loading').join(' ');
    }
  }, [loading]);

  const amount = _.get(receipt, 'summary.total_amount') || 0;
  return (
    <div id="tcas">
      <div className="t-loading"></div>
      <header className="site-header">
        <nav className="site-nav">
          <ul>
            {menus.map(({ key, icon, title, to, href, desktop }) => (
              <li key={key} className={cn(`m-${key}`, { _desktop: desktop, active: key === 'register' })}>
                {to && (
                  <Link to={to}>
                    {icon}
                    <span>{title}</span>
                  </Link>
                )}
                {href && (
                  <a href={href}>
                    {icon}
                    <span>{title}</span>
                  </a>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </header>
      <Logo />
      <main className="site-body">
        {loading && <div className='t-loading' />}
        <div className='t-form'>
          <h1>{t('ใบเสร็จรับเงิน TCAS68 รอบ 3 แอดมิชชั่น')}</h1>
          <div className='xm-info'>
            <h2>{t('ใบเสร็จรับเงิน')}</h2>
            <div className='list' style={{ background: 'white', fontSize: 14 }}>
              <div>
                <span>ชื่อ-นามสกุล&nbsp;&nbsp;&nbsp;</span>
                <b>{_.get(receipt, 'full_name')}</b>
              </div>
              <div>
                <span>รหัสประจำตัว&nbsp;&nbsp;&nbsp;</span>
                <b>{_.get(receipt, 'citizen_id')}</b>
              </div>
              <div>
                <span>เลขที่เอกสาร&nbsp;&nbsp;&nbsp;</span>
                <b>{_.get(receipt, 'summary.document_id')}</b>
              </div>
              <div>
                <span>จำนวนสาขาวิชาที่สมัคร&nbsp;&nbsp;&nbsp;</span>
                <b>{prices.indexOf(amount) + 1} อันดับสาขาวิชา</b>
              </div>
              <div>
                <span>จำนวนเงิน&nbsp;&nbsp;&nbsp;</span>
                <b>{priceMap[amount]}</b>
              </div>
              <div style={{ marginTop: 20 }} className='color-red'>
                ผู้สมัครยินยอมรับเงินสนับสนุนค่าสมัครคัดเลือกรอบที่ 3 แอดมิชชั่น จากสำนักงานปลัดกระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัย และนวัตกรรม และยินยอมมอบสิทธิ์ให้สมาคมที่ประชุมอธิการบดีแห่งประเทศไทยดำเนินการแทนผู้สมัครในการเบิกเงินสนับสนุนค่าสมัคร
              </div>
            </div>
            <div style={{ fontSize: 14, background: 'white', padding: 16, borderTop: '1px solid #e5e5e5' }}>
              <span>ผู้จ่ายเงิน&nbsp;&nbsp;&nbsp;</span>
              <b>สำนักงานปลัดกระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัย และนวัตกรรม</b>
            </div>
            <div style={{ fontSize: 14, background: 'white', padding: 16, borderTop: '1px solid #e5e5e5' }}>
              <span>ผู้รับเงินแทน&nbsp;&nbsp;&nbsp;</span>
              <b>สมาคมที่ประชุมอธิการบดีแห่งประเทศไทย</b>
            </div>
            <div style={{ fontSize: 14, background: 'white', padding: 16, borderTop: '1px solid #e5e5e5', borderRadius: '0 0 5px 5px' }}>
              <span>จำนวนเงินรวมทั้งสิ้น&nbsp;&nbsp;&nbsp;</span>
              <b style={{ fontSize: 18 }}>{amount.toFixed(2)} บาท</b>
            </div>
          </div>
        </div>
      </main>
      {/* <!-- END BODY --> */}
    </div>
  );
};

export default R3RedemptionReceipt;
