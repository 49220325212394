/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useTranslation } from 'react-i18next';

const Button = ({ onDisconnect }) => {
  const { t } = useTranslation();
  const _onDisconnect = () => {
    if (window.confirm('ยกเลิกการเข้าระบบด้วย Facebook เหลือเพียงการเข้าระบบด้วยรหัสผ่านเท่านั้น?')) {
      onDisconnect();
    }
  };

  return (
    <p>
      <a className="btn-fb cursor-pointer" onClick={_onDisconnect}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1365.3 1365.3" height="24" width="24">
          <path
            d="M1365.3 682.7A682.7 682.7 0 10576 1357V880H402.7V682.7H576V532.3c0-171.1 102-265.6 257.9-265.6 74.6 0 152.8 13.3 152.8 13.3v168h-86.1c-84.8 0-111.3 52.6-111.3 106.6v128h189.4L948.4 880h-159v477a682.8 682.8 0 00576-674.3"
            fill="#1877f2"
          ></path>
        </svg>
        <span>{t('ยกเลิกการเข้าระบบด้วย Facebook')}</span>
      </a>
    </p>
  );
};

export default Button;
