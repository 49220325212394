import { useState } from 'react';
import { callAPI } from 'helpers';
import _ from 'lodash';

// Hooks
import useBasicInfo from './useBasicInfo';
import useCitizenCard from './useCitizenCard';
import {
  errorMessages,
  validateEmail,
  validateMobile,
  validateTitle,
  validateFirstName,
  validateLastName,
  validateCitizenCardFile,
  validateSelfieFile,
  validateBirthDate,
} from 'validations';

const useNotFoundTicket = (citizenId, isTcas, onSubmitSuccess, prefill = {}) => {
  const basicInfoProps = useBasicInfo(prefill);
  const citizenCardProps = useCitizenCard();

  const [submitting, setSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [errors, setErrors] = useState([]);
  const { email, mobile, title, firstName, lastName, birthDate } = basicInfoProps;
  const { citizenCardFile, selfieFile } = citizenCardProps;
  const [schoolCode, setSchoolCode] = useState('');

  return {
    ...basicInfoProps,
    ...citizenCardProps,
    errors,
    onSchoolCodeChange: (e) => setSchoolCode(e.target.value),

    submitting,
    submitSuccess,
    onSubmit: async (selectedOption) => {
      if (submitting) return;
      // Validate fields
      const _messages = errorMessages({
        email: validateEmail(email),
        mobile: validateMobile(mobile),
        title: validateTitle(title),
        firstName: validateFirstName(firstName),
        lastName: validateLastName(lastName),
        birthDate: validateBirthDate(birthDate),
        schoolCode: !_.get(selectedOption, 'value') && 'จำเป็นต้องระบุโรงเรียน',
        citizenCard: validateCitizenCardFile(citizenCardFile),
        selfie: validateSelfieFile(selfieFile),
      });

      if (_messages.length) {
        setErrors(_messages);
        const container = document.getElementById('not-found-ticket');
        if (container) {
          container.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        if (submitting) return;
        try {
          setSubmitting(true);
          const formData = new FormData();
          formData.append('citizenId', citizenId);
          formData.append('email', email);
          formData.append('mobile', mobile);
          formData.append('title', title);
          formData.append('firstName', firstName.replace(/​/g, ''));
          formData.append('lastName', lastName.replace(/​/g, ''));
          formData.append('birthDate', birthDate);
          formData.append('schoolCode', _.get(selectedOption, 'value'));
          formData.append('citizenCardFile', citizenCardFile);
          formData.append('selfieFile', selfieFile);
          formData.append('isTcas', isTcas);

          await callAPI({
            method: 'POST',
            url: '/applicants/tickets/not-found',
            formData,
          });
          setSubmitting(false);
          setSubmitSuccess(true);
          if (onSubmitSuccess) {
            onSubmitSuccess(email);
          }
        } catch (error) {
          console.error(new Error(`Error while trying to submit new [NOT-FOUND] ticket: ${error.message}`));
          setSubmitting(false);
          setSubmitSuccess(false);
        }
      }
    },
  };
};

export default useNotFoundTicket;
