import { useState } from 'react';
import _ from 'lodash';
import scores from 'data/english.score.json';

const useEnglishScore = (profile) => {
  const [toeflIBT, setToeflIBT] = useState(profile.toefl_ibt || '');
  const [toeflPBT, setToeflPBT] = useState(profile.toefl_pbt || '');
  const [toeflCBT, setToeflCBT] = useState(profile.toefl_cbt || '');
  const [toeflITP, setToeflITP] = useState(profile.toefl_itp || '');
  const [toeflIPT, setToeflIPT] = useState(profile.toefl_ipt || '');
  const [ielts, setIelts] = useState(profile.ielts || '');
  const [toeic, setToeic] = useState(profile.toeic || '');
  const [cutep, setCutep] = useState(profile.cutep || '');
  const [tuget, setTuget] = useState(profile.tuget || '');
  const [kept, setKept] = useState(profile.kept || '');
  const [psutep, setPsutep] = useState(profile.psutep || '');
  const [kuept, setKuept] = useState(profile.kuept || '');
  const [cmuetegs, setCmuetegs] = useState(profile.cmuetegs || '');
  const [swuSET, setSwuSET] = useState(profile.swu_set || '');
  const [sat, setSat] = useState(profile.sat || '');
  const [cefr, setCefr] = useState(profile.cefr || '');

  return {
    toeflIBT,
    setToeflIBT,
    toeflPBT,
    setToeflPBT,
    toeflCBT,
    setToeflCBT,
    toeflITP,
    setToeflITP,
    toeflIPT,
    setToeflIPT,
    ielts,
    setIelts,
    toeic,
    setToeic,
    cutep,
    setCutep,
    tuget,
    setTuget,
    kept,
    setKept,
    psutep,
    setPsutep,
    kuept,
    setKuept,
    cmuetegs,
    setCmuetegs,
    swuSET,
    setSwuSET,
    sat,
    setSat,
    cefr,
    setCefr,

    fnName: (fieldName) => `set${fieldName[0].toUpperCase()}${fieldName.slice(1)}`,
    scores,
    validateScore: (fieldName, score) => {
      const { label, min, max } = _.find(scores, (_score) => _score.fieldName === fieldName);
      if (score !== '' && score < min) return `คะแนน ${label} ต้องมีค่าไม่น้อยกว่า ${min}`;
      if (score > max) return `คะแนน ${label} ต้องมีค่าไม่มากกว่า ${max}`;
      return null;
    },
  };
};

export default useEnglishScore;
