/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { callAPI, notApplicable } from 'helpers';
import moment from 'moment';

// Components
import { useNavigate, Link } from 'react-router-dom';
import EmailVerification from 'components/otp/EmailVerification';
import MobileVerification from 'components/otp/MobileVerification';

// Hooks
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useFavorites from 'hooks/round3/useFavorites';
import useAdmission from 'hooks/round3/useAdmission';

// Data
import enabled from 'data/enabled';

const Round3Apply = () => {
  const { t } = useTranslation();
  const { loading: favoritesLoading, favorites, studentStatus } = useFavorites();
  const { projects } = useAdmission(favorites, studentStatus);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profile = useSelector((state) => _.get(state, 'app.user') || {});
  const courseMap = useSelector((state) => _.get(state, 'app.courseMap')) || {};
  const project_errors = projects.map(({ program_project }) => {
    const project = courseMap[program_project];
    return notApplicable(profile, project, { studentStatus, favorites });
  });

  const [verifyMethod, setVerifyMethod] = useState();
  const [otpVisible, setOtpVisible] = useState(false);

  const priorities = projects.map(({ program_project, priority }) => ({ program_project, priority }));

  const [quotaLoading, setQuotaLoading] = useState(false);
  const [quotaUsed, setQuotaUsed] = useState(false);
  useEffect(() => {
    const fetchAdmissions = async () => {
      try {
        setQuotaLoading(true);
        const { result, timestamp } = await callAPI({ url: '/applicants/me/admissions' });
        const count = result.filter(({ set_zero }) => !set_zero).length;
        setQuotaUsed(count);
        setQuotaLoading(false);

        if (count >= 3 || moment(timestamp).isAfter(enabled.round3.apply_end)) {
          navigate('/profile');
        }
      } catch (error) {
        console.log({ error });
        setQuotaLoading(false);
      }
    }

    fetchAdmissions();
  }, [navigate]);

  useEffect(() => {
    if (project_errors.some(errors => errors.length)) {
      navigate('/profile');
    }
  }, [project_errors, navigate])

  const onEmailOtpSuccess = useCallback(() => {
    dispatch({ type: 'UPDATE_USER', result: { ...profile, email_is_verified: 1 } });
    navigate(-1);
  }, [dispatch, profile, navigate]);

  const onSmsOtpSuccess = useCallback(() => {
    dispatch({ type: 'UPDATE_USER', result: { ...profile, telephone_is_verified: 1 } });
    navigate(-1);
  }, [dispatch, profile, navigate]);


  const loading = favoritesLoading && quotaLoading;
  useEffect(() => {
    const classNames = document.getElementById('root').className.split(' ');
    if (loading) {
      document.getElementById('root').className = _.uniq(classNames.concat(['is-loading'])).join(' ');
    } else {
      document.getElementById('root').className = classNames.filter((name) => name !== 'is-loading').join(' ');
    }
  }, [loading]);

  if (loading) {
    return (
      <main className="site-body">
        <div className="t-loading" />
      </main>
    )
  }

  return (
    <main className="site-body">
      <div className="t-form">
        <h1>{t('รอบ')} 3 Admission</h1>
        {!otpVisible && (
          <>
            <h2 className="sub">{t('กรุณาเลือกช่องทางในการรับรหัส OTP ยืนยันการสมัคร')}</h2>
            <div className="r-programs">
              {(projects || []).map(({ program_project, priority }) => {
                const lang = 'th';
                const project = courseMap[program_project];
                const majorName = _.get(project, `major_name_${lang}`);
                const projectName = _.get(project, `project_name_${lang}`);
                return (
                  <div className="r-program">
                    <div className="order">{t('อันดับที่')} {priority}</div>
                    <div
                      className="u-logo"
                      style={{ backgroundImage: `url('/assets/img/logo/${_.get(project, 'university_id')}.png')` }} />
                    <ul>
                      <li>
                        <i className="i-u"></i>
                        <b>{_.get(project, `university_name_${lang}`)}</b>
                      </li>
                      <li>
                        <i className="i-pin"></i>
                        <b>{_.get(project, `campus_name_${lang}`)}</b>
                      </li>
                      <li>
                        <i>•</i>
                        <span>{_.get(project, `faculty_name_${lang}`)}</span>
                      </li>
                      <li>
                        <i>•</i>
                        <span>{_.get(project, `field_name_${lang}`)}</span>
                      </li>
                      {majorName && majorName !== '0' ? (
                        <li>
                          <i>•</i>
                          <span>{t('วิชาเอก')} {majorName}</span>
                        </li>
                      ) : null}
                      <li>
                        <i>•</i>
                        <span>{_.get(project, `program_name_${lang}`)}</span>
                      </li>
                      {lang === 'th' ? (
                        <li>
                          <i>•</i>
                          <span>{_.get(project, `program_type_name_th`)}</span>
                        </li>
                      ) : null}
                      {projectName && projectName !== '0' ? (
                        <li>
                          <i>•</i>
                          <span>{t('โครงการ ')}{projectName}</span>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                );
              })}
            </div>
          </>
        )}
        <div className="i-hearts">
          <i className={cn('i-heart', { '-blink': quotaUsed === 2, '-used': quotaUsed > 2 })} />
          <i className={cn('i-heart', { '-blink': quotaUsed === 1, '-used': quotaUsed > 1 })} />
          <i className={cn('i-heart', { '-blink': quotaUsed === 0, '-used': quotaUsed > 0 })} />
          <small>
            {t('ตัดสินใจครั้งที่')} {1 + quotaUsed}/3
          </small>
        </div>
        {Boolean(priorities.length) && (
          <>
            {!otpVisible ? (
              <MethodSelector
                verifyMethod={verifyMethod}
                setVerifyMethod={setVerifyMethod}
                onClick={() => setOtpVisible(true)}
              />
            ) : verifyMethod === 'email' ? (
              <EmailVerification
                studentType="apply-round3"
                roundType="3_2568"
                email={profile.email}
                bodyParams={{ priorities }}
                onSuccess={onEmailOtpSuccess} />
            ) : verifyMethod === 'mobile' ? (
              <MobileVerification
                studentType="apply-round3"
                roundType="3_2568"
                mobile={profile.telephone}
                bodyParams={{ priorities }}
                onSuccess={onSmsOtpSuccess} />
            ) : null}
          </>
        )}
      </div>
    </main>
  );
}

export default Round3Apply;


const MethodSelector = ({ onClick, verifyMethod, setVerifyMethod }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});
  const formattedMobile = [
    profile.telephone.slice(0, 3),
    profile.telephone.slice(3, 6),
    profile.telephone.slice(-4),
  ].join(' ');

  return (
    <>
      <ul className="r-radio">
        <li>
          <label>
            <input
              type="radio"
              name="confirm"
              checked={verifyMethod === 'mobile'}
              onClick={() => setVerifyMethod('mobile')}
            />
            <strong>{t('ยืนยันการสมัครผ่านเบอร์มือถือ')}</strong>
            <small>
              {t('หมายเลข')} {formattedMobile}
            </small>
          </label>
        </li>
        <li>
          <label>
            <input
              type="radio"
              name="confirm"
              checked={verifyMethod === 'email'}
              onClick={() => setVerifyMethod('email')}
            />
            <strong>{t('ยืนยันการสมัครผ่านอีเมล')}</strong>
            <small>{profile.email}</small>
          </label>
        </li>
      </ul>

      <div className="action">
        <a
          className={cn('btn-main', { disabled: !verifyMethod, 'cursor-pointer': Boolean(verifyMethod) })}
          onClick={!verifyMethod ? () => { } : onClick}
        >
          {t('ส่ง OTP เพื่อยืนยันการสมัคร')}
        </a>
        <h4><a onClick={() => navigate(-1)} className="cursor-pointer color-gray">{t('ยกเลิก')}</a></h4>
      </div>
      <p className="text-center _small">{t('หากมีการเปลี่ยนแปลงเบอร์มือถือหรืออีเมล')}
        {t('สามารถแก้ไขได้ที่หน้า')} <Link to='/profile/applicant-info' className="color-black"><u>ข้อมูลการลงทะเบียน</u></Link></p>
    </>
  );
};
