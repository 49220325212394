import _ from 'lodash';
import { notApplicable } from 'helpers';

// Hooks
import { useSelector } from 'react-redux';

const useAdmission = (favorites = [], studentStatus = {}) => {
  const profile = useSelector((state) => _.get(state, 'app.user') || {});
  const courseMap = useSelector((state) => _.get(state, 'app.courseMap')) || {};
  const removes = useSelector((state) => _.get(state, 'app.admissionRemoves')) || [];

  const projects = favorites.filter(({ _id, priority }) => priority && !removes.includes(_id)).sort((a, b) => a.priority - b.priority);
  const nextPriority = (_.max(projects.map(({ priority }) => priority)) || 0) + 1
  const availables = favorites.filter(({ _id, priority, program_project }) => (!priority || removes.includes(_id)) && !notApplicable(profile, courseMap[program_project], { studentStatus, favorites, nextPriority }).length);

  return {
    projects,
    availables,
    nextPriority,
    removes
  }
}

export default useAdmission;
