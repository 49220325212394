import Cookies from 'js-cookie';
import URL from 'url';
import config from 'config';
import _ from 'lodash';
import axios from 'axios';
import Compressor from 'compressorjs';
import moment from 'moment';

// Constants
import fields from 'data/condition.json';
import subjectLabels from 'data/subject_labels.json';
import programCodes from 'data/programs.json';
import enabled from 'data/enabled.json';

const subjectMap = _.keyBy(subjectLabels, 'key');
const _ensureScore = (value) => isNaN(value) ? 0 : parseFloat(value || '0', 10);

const tgattpat_subjects = [
  'tgat',
  'tgat1',
  'tgat2',
  'tgat3',
  'tpat1',
  'tpat11',
  'tpat12',
  'tpat13',
  'tpat2',
  'tpat21',
  'tpat22',
  'tpat23',
  'tpat3',
  'tpat4',
  'tpat5'
];
const alevel_subjects = [
  'a_lv_61',
  'a_lv_62',
  'a_lv_63',
  'a_lv_64',
  'a_lv_65',
  'a_lv_66',
  'a_lv_70',
  'a_lv_81',
  'a_lv_82',
  'a_lv_83',
  'a_lv_84',
  'a_lv_85',
  'a_lv_86',
  'a_lv_87',
  'a_lv_88',
  'a_lv_89',
];
const vnet_subjects = [
  'vnet_51',
  'vnet_511',
  'vnet_512',
  'vnet_513',
  'vnet_514'
];

export const callAPI = async ({ fullURL, url, method, query, body, genPdf, formData, onUploadProgress, accessToken, sessionToken }) => {
  if (formData) {
    const response = await axios({
      method: method || 'POST',
      url: `${genPdf ? config.genPdfApiHost : config.apiHost}${url}`,
      data: formData,
      headers: _.omitBy(
        {
          'Content-Type': formData ? null : 'application/json; charset=utf-8',
          accessToken: accessToken || Cookies.get('accessToken'),
          sessionToken: sessionToken || Cookies.get('sessionToken'),
        },
        _.isNil
      ),
      onUploadProgress,
    }).catch((error) => {
      return Promise.reject({ statusCode: error.response.status, error: error.response.data });
    });

    return Promise.resolve(response.data);
  } else {
    const response = await fetch(
      // eslint-disable-line
      `${fullURL || `${genPdf ? config.genPdfApiHost : config.apiHost}${encodeURI(url)}`}${URL.format({ query })}`,
      _.omitBy(
        {
          method: method || (body ? 'POST' : 'GET'),
          headers: _.omitBy(
            {
              'Content-Type': 'application/json; charset=utf-8',
              accessToken: accessToken || Cookies.get('accessToken'),
              sessionToken: sessionToken || Cookies.get('sessionToken'),
            },
            _.isNil
          ),
          body: body ? JSON.stringify(body) : undefined,
        },
        _.isNil
      )
    );
    if (response.status === 200) {
      const json = await response.json();
      return Promise.resolve(json);
    } else if (response.status === 401) {
      return Promise.reject({ statusCode: response.status, message: 'Unauthorized' }); // eslint-disable-line
    } else {
      const json = await response.json();
      return Promise.reject({ statusCode: response.status, json, message: _.get(json, 'error.message') }); // eslint-disable-line
    }
  }
};

export const isCitizenId = (id = '') => {
  if (!id.toLowerCase().match(/^[0-9]{13}$/i)) return false;
  const sum = Array(12)
    .fill()
    .reduce((sum, _, index) => sum + id[index] * (13 - index), 0);
  return parseInt(id.slice(-1), 10) === (11 - (sum % 11)) % 10;
};
export const isGNumber = (id = '') => {
  return id.toLowerCase().match(/^g[0-9]{12}$/i);
};
export const isPassportNumber = (id = '') => {
  return id.toLowerCase().match(/^[a-z0-9]{7,13}$/);
};

export const getCitizenIdType = (id) => {
  if (isCitizenId(id)) return 'citizen_id';
  if (isGNumber(id)) return 'gnumber';
  if (!isNaN(id) && id.length >= 10) return null;
  if (isPassportNumber(id)) return 'passport';

  return null;
};

export const getCitizenTypeLabel = (t, citizenIdType) => {
  return {
    citizen_id: t('เลขประจำตัวประชาชน'),
    gnumber: 'Gnumber',
    passport: 'Passport Number',
  }[citizenIdType];
};

export const compressImage = (file) => {
  if (!file) return Promise.resolve(file);
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.8,
      maxWidth: 1920,
      maxHeight: 1920,
      resize: 'cover',
      success(result) {
        resolve(result);
      },
      error(err) {
        reject(err);
      },
    });
  });
};

export const getProvinceName = (provinceList, provinceId) =>
  _.get(
    _.find(provinceList, (province) => province.provinceId === provinceId),
    'provinceName'
  );

export const getDistrictName = (districtList, districtId) =>
  _.get(
    _.find(districtList, (district) => district.districtId === districtId),
    'districtName'
  );

export const getSubDistrictName = (subDistrictList, subDistrictId) =>
  _.get(
    _.find(subDistrictList, (subDistrict) => subDistrict.subDistrictId === subDistrictId),
    'subDistrictName'
  );

export const getNewestTicket = (pendingTickets, category) => {
  const categories = Array.isArray(category) ? category : [category];
  const ticket = _.last(pendingTickets.filter((_ticket) => categories.includes(_ticket.category)));
  return {
    ticket,
    valueOf: (fieldName) => _.get(ticket, `requested_data.${fieldName}.value`),
  };
};

export const handleThaiUniqueString = (stringToReplace) => {
  let result = stringToReplace.replace(/เเ/g, 'แ');
  result = result.replace(/ํา/g, 'ำ').replace(/าํ/g, 'ำ');
  result = result.replace(/์ิ/g, 'ิ์');
  result = result.replace(/์ุ/g, 'ุ์');
  return result;
};



export const wordsWithStyles = (keywords, string) => {
  const words = keywords
    .reduce((data, word) => {
      // Create an array of indexes of bold string.
      const _start = string.indexOf(word);
      if (_start === -1) return data;

      const _end = _start + word.length;
      if (data.some(({ start, end }) => _.inRange(_start, start, end) || _.inRange(_end, start, end))) {
        return data.map(({ start, end }) => {
          if (_.inRange(_start, start, end)) return { start, end: _end, word: string.slice(start, _end) };
          if (_.inRange(_end, start, end)) return { start: _start, end, word: string.slice(_start, end) };
          return { start, end, word: string.slice(start, end) };
        });
      }
      return data.concat([{ start: _start, end: _end, word: string.slice(_start, _end) }]);
    }, [])
    .sort((a, b) => a.start - b.start)
    .slice()
    .reduce((_words, { start, end }, index, array) => {
      // Create an array of words with bold style.
      const lastWord = index === array.length - 1 && end < string.length ? [{ word: string.slice(end, string.length), bold: false }] : [];

      if (!index) {
        return (
          start > 0
            ? [
              { word: string.slice(0, start), bold: false },
              { word: string.slice(start, end), bold: true },
            ]
            : [{ word: string.slice(start, end), bold: true }]
        ).concat(lastWord);
      }

      const { end: prevEnd } = array[index - 1];
      return _words.concat(
        [
          { word: string.slice(prevEnd, start), bold: false },
          { word: string.slice(start, end), bold: true },
        ].concat(lastWord),
      );
    }, []);

  if (!words.length) return [{ word: string, bold: false }];
  return words;
};

export const renderBoldString = ({ word, bold }, index) => (bold ? <strong key={index}>{word}</strong> : <span key={index}>{word}</span>);

export const formatDate = (date) => {
  const monthNamesThai = [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม',
  ];
  const dayNames = ['วันอาทิตย์ที่', 'วันจันทร์ที่', 'วันอังคารที่', 'วันพุธที่', 'วันพฤหัสบดีที่', 'วันศุกร์ที่', 'วันเสาร์ที่'];

  return `${dayNames[date.getDay()]} ${date.getDate()} ${monthNamesThai[date.getMonth()]} ${date.getFullYear() + 543}`;
};

export const notApplicable = (student, programProject, { studentStatus = {}, favorites = [], nextPriority = 1 }) => {
  let errors = [];
  if (!student) return [];
  if (!programProject) return [];

  // Validate receive_student_number greater than 0
  if (!programProject.receive_student_number) {
    errors.push({ key: 'disabled', label: 'งดรับผู้สมัคร' });
  }

  switch (studentStatus.code) {
    case 10:
      if (programProject.university_type_id !== '4' && programProject.group_field_id === '10') {
        errors.push({ key: studentStatus.code, label: studentStatus.message.split(' --> ')[0] });
      }
      break;
    case 11:
      if (programProject.university_type_id !== '4' && programProject.group_field_id === '12') {
        errors.push({ key: studentStatus.code, label: studentStatus.message.split(' --> ')[0] });
      }
      break;
    case 12:
      if (programProject.university_type_id !== '4' && programProject.group_field_id === '13') {
        errors.push({ key: studentStatus.code, label: studentStatus.message.split(' --> ')[0] });
      }
      break;
    case 16:
      if (programProject.university_type_id !== '4' && programProject.group_field_id === '10') {
        errors.push({ key: studentStatus.code, label: studentStatus.message.split(' --> ')[0] });
      }
      break;
    case 17:
      if (programProject.university_type_id !== '4' && programProject.group_field_id === '12') {
        errors.push({ key: studentStatus.code, label: studentStatus.message.split(' --> ')[0] });
      }
      break;
    case 18:
      if (programProject.university_type_id !== '4' && programProject.group_field_id === '13') {
        errors.push({ key: studentStatus.code, label: studentStatus.message.split(' --> ')[0] });
      }
      break;
    case 19:
      if (programProject.university_type_id !== '4' && programProject.group_field_id === '14') {
        errors.push({ key: studentStatus.code, label: studentStatus.message.split(' --> ')[0] });
      }
      break;
    default:
      break;
  }

  // Validate gender_male_number
  if (programProject.gender_male_number === programProject.receive_student_number && (!student.title || ['นาง', 'นางสาว', 'น.ส.', 'Miss'].includes(student.title))) {
    errors.push({ key: 'gender_male_number', label: 'รับเฉพาะเพศ', expected: 'ชาย', value: !student.title ? 'ไม่ระบุ' : 'หญิง' });
  }

  // Validate gender_female_number
  if (programProject.gender_female_number === programProject.receive_student_number && (!student.title || ['นาย', 'สามเณร', 'Mr', 'นชท.'].includes(student.title))) {
    errors.push({ key: 'gender_female_number', label: 'รับเฉพาะเพศ', expected: 'หญิง', value: !student.title ? 'ไม่ระบุ' : 'ชาย' });
  }

  // Validate no program_code
  if (!student.program_code) {
    errors.push({ key: 'no_program_code', label: 'ไม่พบข้อมูลหลักสูตรของผู้สมัคร' });
  }

  // Validate only_formal
  if (programProject.only_formal === 2 && student.program_code === '1') {
    errors.push({ key: 'only_formal', label: 'ไม่รับผู้สมัครจากหลักสูตร', expected: 'แกนกลาง (สามัญ)', value: 'แกนกลาง (สามัญ)' });
  }

  // Validate only_international
  if (programProject.only_international === 2 && student.program_code === '2') {
    errors.push({ key: 'only_international', label: 'ไม่รับผู้สมัครจากหลักสูตร', expected: 'นานาชาติ', value: 'หลักสูตรนานาชาติ' });
  }

  // Validate only_vocational
  if (programProject.only_vocational === 2 && student.program_code === '3') {
    errors.push({ key: 'only_vocational', label: 'ไม่รับผู้สมัครจากหลักสูตร', expected: 'อาชีวะ', value: 'หลักสูตรอาชีวะ' });
  }

  // Validate only_non_formal
  if (programProject.only_non_formal === 2 && student.program_code === '4') {
    errors.push({ key: 'only_non_formal', label: 'ไม่รับผู้สมัครจากหลักสูตร', expected: 'ตามอัธยาศัย (กศน.)', value: 'หลักสูตรตามอัธยาศัย (กศน.)' });
  }

  // Validate only_ged
  if (programProject.only_ged === 2 && ['6'].includes(student.program_code)) {
    errors.push({ key: 'only_ged', label: 'ไม่รับผู้สมัครจากหลักสูตร', expected: 'สอบเทียบ GED', value: programCodes[student.program_code] });
  }

  // Validate min priority
  if (programProject.min_priority) {
    const current = _.find(favorites, ({ program_project }) => program_project === programProject._id) || {};
    if ((current.priority && current.priority > programProject.min_priority) || (!current.priority && nextPriority > programProject.min_priority)) {
      errors.push({ key: 'min_priority', label: 'ลำดับขั้นต่ำในการเลือกสาขา', expected: programProject.min_priority, value: current.priority || `มากกว่า ${programProject.min_priority}` })
    }
  }

  // Validate min age
  if (programProject.min_age) {
    const birth_date = moment(student.birth_date, 'YYYY-MM-DD');
    if (programProject.min_age_date) {
      if (moment(programProject.min_age_date).diff(birth_date, 'year') < programProject.min_age) {
        errors.push({
          key: 'min_age_date',
          label: `ในวันที่ ${moment(programProject.min_age_date).format('DD/MM/YYYY')} ${fields.min_age.label}`,
          expected: `${programProject.min_age} ปี`,
          value: `${moment(programProject.min_age_date).diff(birth_date, 'year')} ปี`
        });
      }
    } else {
      if (moment().year() - birth_date.year() < programProject.min_age) {
        errors.push({
          key: 'min_age',
          label: fields.min_age.label,
          expected: `${programProject.min_age} ปี`,
          value: `${moment().year() - birth_date.year()} ปี`
        });
      }
    }
  }

  // Validate max age
  if (programProject.max_age) {
    const birth_date = moment(student.birth_date, 'YYYY-MM-DD');
    if (programProject.max_age_date) {
      if (moment(programProject.max_age_date).diff(birth_date, 'year') > programProject.max_age) {
        errors.push({
          key: 'max_age_date',
          label: `ในวันที่ ${moment(programProject.max_age_date).format('DD/MM/YYYY')} ${fields.max_age.label}`,
          expected: `${programProject.max_age} ปี`,
          value: `${moment(programProject.max_age_date).diff(birth_date, 'year')} ปี`
        });
      }
    } else {
      if (moment().year() - birth_date.year() > programProject.max_age) {
        errors.push({
          key: 'max_age',
          label: fields.max_age.label,
          expected: `${programProject.max_age} ปี`,
          value: `${moment().year() - birth_date.year()} ปี`
        });
      }
    }
  }

  const isMale = ['นาย', 'สามเณร'].includes(student.title);
  const bmi = !(student.weight && student.height) ? 0 : (student.weight / (student.height * student.height / 10000));

  // Validate min height of male student
  if (programProject.min_height_male && isMale && (!student.height || student.height < programProject.min_height_male)) {
    errors.push({
      key: 'min_height_male',
      label: fields.min_height_male.label,
      expected: `${programProject.min_height_male} ซม.`,
      value: !student.height ? 'ไม่ระบุ' : `${student.height} ซม.`
    });
  };

  // Validate min height of female student
  if (programProject.min_height_female && !isMale && (!student.height || student.height < programProject.min_height_female)) {
    errors.push({
      key: 'min_height_female',
      label: fields.min_height_female.label,
      expected: `${programProject.min_height_female} ซม.`,
      value: !student.height ? 'ไม่ระบุ' : `${student.height} ซม.`
    });
  }

  // Validate min weight of male student
  if (programProject.min_weight_male && isMale && (!student.weight || student.weight < programProject.min_weight_male)) {
    errors.push({
      key: 'min_weight_male',
      label: fields.min_weight_male.label,
      expected: `${programProject.min_weight_male} กก.`,
      value: !student.weight ? 'ไม่ระบุ' : `${student.weight} กก.`
    });
  }

  // Validate min weight of female student
  if (programProject.min_weight_female && !isMale && (!student.weight || student.weight < programProject.min_weight_female)) {
    errors.push({
      key: 'min_weight_female',
      label: fields.min_weight_female.label,
      expected: `${programProject.min_weight_female} กก.`,
      value: !student.weight ? 'ไม่ระบุ' : `${student.weight} กก.`
    });
  }

  // Validate max weight of male student
  if (programProject.max_weight_male && isMale && (!student.weight || student.weight > programProject.max_weight_male)) {
    errors.push({
      key: 'max_weight_male',
      label: fields.max_weight_male.label,
      expected: `${programProject.max_weight_male} กก.`,
      value: !student.weight ? 'ไม่ระบุ' : `${student.weight} กก.`
    });
  }

  // Validate max weight of female student
  if (programProject.max_weight_female && !isMale && (!student.weight || student.weight > programProject.max_weight_female)) {
    errors.push({
      key: 'max_weight_female',
      label: fields.max_weight_female.label,
      expected: `${programProject.max_weight_female} กก.`,
      value: !student.weight ? 'ไม่ระบุ' : `${student.weight} กก.`
    });
  }

  // Validate min bmi of male student
  if (programProject.min_bmi_male && isMale && (!bmi || bmi < programProject.min_bmi_male)) {
    errors.push({
      key: 'min_bmi_male',
      label: fields.min_bmi_male.label,
      expected: programProject.min_bmi_male,
      value: !bmi ? 'ไม่ระบุ' : bmi.toFixed(2)
    });
  }

  // Validate min bmi of female student
  if (programProject.min_bmi_female && !isMale && (!bmi || bmi < programProject.min_bmi_female)) {
    errors.push({
      key: 'min_bmi_female',
      label: fields.min_bmi_female.label,
      expected: programProject.min_bmi_female,
      value: !bmi ? 'ไม่ระบุ' : bmi.toFixed(2)
    });
  }

  // Validate max bmi of male student
  if (programProject.max_bmi_male && isMale && (!bmi || bmi > programProject.max_bmi_male)) {
    errors.push({
      key: 'max_bmi_male',
      label: fields.max_bmi_male.label,
      expected: programProject.max_bmi_male,
      value: !bmi ? 'ไม่ระบุ' : bmi.toFixed(2)
    });
  }

  // Validate max bmi of female student
  if (programProject.max_bmi_female && !isMale && (!bmi || bmi > programProject.max_bmi_female)) {
    errors.push({
      key: 'max_bmi_female',
      label: fields.max_bmi_female.label,
      expected: programProject.max_bmi_female,
      value: !bmi ? 'ไม่ระบุ' : bmi.toFixed(2)
    });
  }

  // Validate min gpax
  if (programProject.min_gpax && _ensureScore(student.gpax6_score) < programProject.min_gpax) {
    errors.push({
      key: 'min_gpax',
      label: fields.min_gpax.label,
      expected: programProject.min_gpax,
      value: !student.gpax6_score ? 'ไม่ระบุ' : student.gpax6_score
    });
  }

  // Validate GPAs and their credits
  [21, 22, 23, 24, 25, 26, 27, 28, 29].forEach(gpa_code => {
    if (programProject[`min_credit_gpa${gpa_code}`] && _ensureScore(student[`credit_gpa${gpa_code}`]) < programProject[`min_credit_gpa${gpa_code}`]) {
      errors.push({
        key: `min_credit_gpa${gpa_code}`,
        label: fields[`min_credit_gpa${gpa_code}`].label,
        expected: programProject[`min_credit_gpa${gpa_code}`],
        value: _ensureScore(student[`credit_gpa${gpa_code}`])
      });
    }
    if (programProject[`min_gpa${gpa_code}`] && _ensureScore(student[`gpa${gpa_code}`]) < programProject[`min_gpa${gpa_code}`]) {
      errors.push({
        key: `min_gpa${gpa_code}`,
        label: fields[`min_gpa${gpa_code}`].label,
        expected: programProject[`min_gpa${gpa_code}`],
        value: _ensureScore(student[`gpa${gpa_code}`])
      });
    }
  });

  // Validate min_credit_gpa22_23
  if (programProject.min_credit_gpa22_23 && (_ensureScore(student.credit_gpa22) + _ensureScore(student.credit_gpa23)) < programProject.min_credit_gpa22_23) {
    errors.push({
      key: 'min_credit_gpa22_23',
      label: fields.min_credit_gpa22_23.label,
      expected: programProject.min_credit_gpa22_23,
      value: (_ensureScore(student.credit_gpa22) + _ensureScore(student.credit_gpa23)).toFixed(4)
    });
  }

  // Validate min_credit_gpa22_23_28
  if (programProject.min_credit_gpa22_23_28 && (_ensureScore(student.credit_gpa22) + _ensureScore(student.credit_gpa23) + _ensureScore(student.credit_gpa28)) < programProject.min_credit_gpa22_23_28) {
    errors.push({
      key: 'min_credit_gpa22_23_28',
      label: fields.min_credit_gpa22_23_28.label,
      expected: programProject.min_credit_gpa22_23_28,
      value: (_ensureScore(student.credit_gpa22) + _ensureScore(student.credit_gpa23) + _ensureScore(student.credit_gpa28)).toFixed(4)
    });
  }

  // Validate min_gpa22_23
  if (programProject.min_gpa22_23 && ((_ensureScore(student.gpa22) + _ensureScore(student.gpa23)) / 2) < programProject.min_gpa22_23) {
    errors.push({
      key: 'min_gpa22_23',
      label: fields.min_gpa22_23.label,
      expected: programProject.min_gpa22_23,
      value: ((_ensureScore(student.gpa22) + _ensureScore(student.gpa23)) / 2).toFixed(4)
    });
  }

  // Validate min_gpa22_23_28
  if (programProject.min_gpa22_23_28 && ((_ensureScore(student.gpa22) + _ensureScore(student.gpa23) + _ensureScore(student.gpa28)) / 3) < programProject.min_gpa22_23_28) {
    errors.push({
      key: 'min_gpa22_23_28',
      label: fields.min_gpa22_23_28.label,
      expected: programProject.min_gpa22_23_28,
      value: ((_ensureScore(student.gpa22) + _ensureScore(student.gpa23) + _ensureScore(student.gpa28)) / 3)
    });
  }

  // Validate grad current
  if (programProject.grad_current && student.school_year !== '2567') {
    errors.push({
      key: 'grad_current',
      label: 'ต้องสำเร็จการศึกษาปี',
      expected: '2567',
      value: student.school_year
    });
  }

  // Validate scores
  (tgattpat_subjects.concat(alevel_subjects)).forEach(subject => {
    const min_score = _.get(programProject, `score_conditions.min_${subject}`);
    if (min_score && _ensureScore(student[subject]) < min_score) {
      errors.push({
        key: `min_${subject}`,
        label: `คะแนนขั้นต่ำของ ${subjectMap[subject].label}`,
        expected: min_score,
        value: _ensureScore(student[subject]) || 0
      });
    }

    const min_tscore = _.get(programProject, `score_conditions.min_${subject}_tscore`);
    if (min_tscore && _ensureScore(student[`${subject}_tscore`]) < min_tscore) {
      errors.push({
        key: `min_${subject}_tscore`,
        label: `คะแนนขั้นต่ำของคะแนน T-Score ของ ${subjectMap[subject].label}`,
        expected: min_tscore,
        value:  _ensureScore(student[`${subject}_tscore`]) || 0
      });
    }

    const min_pr = _.get(programProject, `score_conditions.min_${subject}_pr`);
    if (min_pr && _ensureScore(student[`${subject}_ps`]) < min_pr) {
      errors.push({
        key: `min_${subject}_pr`,
        label: `Percentile Rank ขั้นต่ำของ ${subjectMap[subject].label}`,
        expected: min_pr,
        value: _ensureScore(student[`${subject}_ps`]) || 0
      });
    }
  });

  [
    'toefl_ibt',
    'toefl_pbt',
    'toefl_cbt',
    'toefl_itp',
    'ielts',
    'toeic',
    'cutep',
    'tuget',
    'kept',
    'psutep',
    'kuept',
    'cmuetegs',
    'swu_set',
    'det',
    'sat',
    'cefr',
    'ged_score',
    'cotmes_01',
    'cotmes_02',
    'cotmes_03',
    'mu001',
    'mu002',
    'mu003',
    'su001',
    'su002',
    'su003',
    'su004',
    'su005',
    'su006',
    'su007',
    'su008',
    'su009',
    'su010',
    'su011',
    'su012',
    'su013',
    'su014',
    'tu001',
    'tu002',
    'tu003',
    'tu004',
    'tu005',
    'tu061',
    'tu062',
    'tu071',
    'tu072',
    'tu081',
    'tu082',
    'tu091',
    'tu092',
    'gsat',
    'gsat_l',
    'gsat_m',
    'mu_elt',
    'netsat_math',
    'netsat_lang_th',
    'netsat_lang_en',
    'netsat_sci',
    'netsat_phy',
    'netsat_chem',
    'netsat_bio'
  ].forEach(subject => {
    const min_score = _ensureScore(_.get(programProject, `score_conditions.min_${subject}`));
    if (min_score && (_ensureScore(student[subject]) || 0) < min_score) {
      errors.push({
        key: `min_${subject}`,
        label: `คะแนนขั้นต่ำของ ${subjectMap[subject].label}`,
        expected: min_score,
        value: _ensureScore(student[subject]) || 0
      });
    }
  });

  vnet_subjects.forEach(subject => {
    const min_score = _.get(programProject, `score_conditions.min_${subject}`);
    if (min_score && (_ensureScore(student[subject]) < min_score || student.program_code !== '3')) {
      errors.push({
        key: `min_${subject}`,
        label: `คะแนนขั้นต่ำ${subjectMap[subject].label}`,
        expected: min_score,
        value: _ensureScore(student[subject])
      });
    }
  });

  const subject_names = (_.get(programProject, 'score_conditions.subject_names') || '').trim().split(' ').map(key => key.replace(/min_/g, '').replace(/_pr/g, '_ps'));
  const score_condition = `${_.get(programProject, 'score_conditions.score_condition')}`

  switch (score_condition) {
    case '1':
      const min_scores = (_.get(programProject, 'score_conditions.score_minimum') || '0').trim().split(' ').map(score => parseFloat(score, 10));
      if (!subject_names.some((subject, index) => _ensureScore(student[subject]) >= min_scores[index])) {
        subject_names.forEach((subject, index) => {
          const _score = _ensureScore(student[subject]);
          if (_score < min_scores[index]) {
            errors.push({
              key: 'score_condition_1',
              label: `คะแนนขั้นต่ำของวิชาใดวิชาหนึ่งระหว่าง ${subject_names.map(subject => subjectMap[subject.replace(/_tscore/g, '').replace(/_ps/g, '')].label).join(', ').toUpperCase()}`,
              expected: `${subjectMap[subject.replace(/_tscore/g, '').replace(/_ps/g, '')].label} ไม่ต่ำกว่า ${min_scores[index]}`,
              value: _score
            });
          }
        });
      }
      break;
    case '2':
      const score_minimum = parseInt(_.get(programProject, 'score_conditions.score_minimum') || '0', 10);
      const _sum = _.sumBy(subject_names, subject => _ensureScore(student[subject]));
      if (_sum < score_minimum) {
        errors.push({
          key: 'score_condition_2',
          label: `คะแนนขั้นต่ำของผลรวมวิชา ${subject_names.map(subject => subjectMap[subject.replace(/_tscore/g, '').replace(/_ps/g, '')].label).join(', ').toUpperCase()}`,
          expected: score_minimum,
          value: _sum
        });
      }
      break;
    default:
      break;
  }

  const project_score = getProjectScore(student, programProject, nextPriority);
  if (programProject.min_total_score > project_score) {
    errors.push({
      key: 'min_total_score',
      label: 'คะแนนรวมขั้นต่ำ',
      expected: programProject.min_total_score,
      value: project_score
    });
  }

  const error_groups = _.groupBy(errors, 'key');
  return Object.keys(error_groups).map(key => {
    const messages = error_groups[key];
    if (messages.length === 1) return error_groups[key][0];
    return { key, messages }
  });
};

const max_value_map = subjectLabels.reduce((map, { key, max_value }) => ({ ...map, [key]: max_value }), {});
export const getProjectScore = (student, programProject, priority) => {
  if (!student || !programProject) return '0.0000';

  const getScore = (_subject) => {
    const subjects = typeof _subject === 'string' ? [_subject] : _subject;
    if (!programProject) return '0.0000';
    if (programProject.t_score) return _.sumBy(subjects, subject => _ensureScore(student[`${subject}_tscore`])).toFixed(4) || '0.0000';
    return _.sumBy(subjects, subject => _ensureScore(student[subject])).toFixed(4) || '0.0000';
  };

  const tscore_subjects = (tgattpat_subjects.concat(alevel_subjects));
  const data = tscore_subjects.reduce((sc, subject) => {
    return _.merge(sc, { [subject]: getScore(subject) });
  }, _.merge(_.cloneDeep(student), {
    priority_score: 100 - 10 * (parseInt(priority, 10) - 1),
    gpax: parseFloat(student.gpax6_score, 10),
    gpa22_23: _.mean(['gpa22', 'gpa23'].map(gpa => _ensureScore(student[gpa]))),
    gpa22_23_28: _.mean(['gpa22', 'gpa23', 'gpa28'].map(gpa => _ensureScore(student[gpa]))),
  }));

  const scores = programProject.scores || {};
  if (scores.cal_type) {
    const { cal_score } = _.maxBy((scores.cal_subject_name || '').trim().split('|').map(subject_string => {
      const subject_names = subject_string.split(' ');
      const total_scores = _.sumBy(subject_names, subject => (parseFloat(data[subject], 10) / max_value_map[subject] * 100) || 0);
      const max_value = 100 * subject_names.length;
      const cal_score = total_scores / max_value * scores.cal_score_sum;
      
      return {
        subject_names,
        total_scores,
        max_value,
        cal_score
      };
    }), 'cal_score');

    const total_score = parseFloat(Object.keys(scores).reduce((sum, key) => {
      return sum + (key.startsWith('cal_') ? 0 : ((parseFloat(data[key], 10) || 0) * scores[key] / ((programProject.t_score && tscore_subjects.includes(key)) ? 100 : max_value_map[key])))
    }, cal_score), 10);

    return parseFloat(total_score.toFixed(6)).toFixed(4);
  } else {
    const total_score = parseFloat(Object.keys(scores).reduce((sum, key) => {
      return sum + (key.startsWith('cal_') ? 0 : ((parseFloat(data[key], 10) || 0) * scores[key] / ((programProject.t_score && tscore_subjects.includes(key)) ? 100 : max_value_map[key])))
    }, 0), 10);
    return parseFloat(total_score.toFixed(6)).toFixed(4);
  }
};

export const getFullScore = (programProject = {}) => {
  const scores = programProject.scores || {};
  // if (scores.tpat1) return (100 - (scores.tpat1 * 2 /3)).toFixed(2);
  if (scores.tpat11) return 100 - scores.tpat11;
  return 100;
}

export const getRoundState = ({ roundType, timestamp, query }) => {
  const round = enabled[`round${roundType.split('_')[0]}`];
  const roundKeys = Object.keys(round).sort((a, b) => round[a].localeCompare(round[b]));
  const index = roundKeys.reduce((result, key, current) => {
    return moment(timestamp).isAfter(round[key])
      ? current + 1
      : result
  }, 0);

  return {
    flow: roundType === '3_2568'
      // ? 'apply'
      ? 'not-open'
      : (query && query.get('flow')) || ['not-open', 'default', 'expired', 'cancel', 'cancel-expired'][index],
        //  || (roundType === '1_2568' ? 1 : 0)],
    period: `${moment(round[_.first(roundKeys)]).format('D MMM')} - ${moment(round[_.last(roundKeys)]).format('D MMM')} ${moment(round[roundKeys.slice(-1)]).add(543, 'years').format('YY')}`
  }
}

export const getRoundDescription = ({ roundType, timestamp, alreadyApplied, offers = [], replies = [] }) => {
  const { flow, period } = getRoundState({ roundType, timestamp });
  if (flow === 'not-open') return period;
  if (alreadyApplied) return 'ไม่มีสิทธิ์ในรอบนี้';
  if (!replies.length && flow === 'default') return offers.length ? `ผ่านการคัดเลือก ${offers.length} สาขาวิชา` : 'ท่านไม่ผ่านการคัดเลือกในรอบนี้';
  if (_.get(_.last(replies), 'is_canceled')) return 'สละสิทธิ์ในรอบนี้';
  if (_.get(_.last(replies), 'enrollment_offer')) return 'ยืนยันสิทธิ์เรียบร้อยแล้ว';
  return 'ไม่ใช้สิทธิ์ในรอบนี้';
}
export const isFlSubject = (subjectCode) =>
  subjectCode.startsWith('8') && subjectCode > 82;
