import React from 'react';
import _ from 'lodash';

// Hooks
import { useTranslation } from 'react-i18next';

const getProvince = (province) => (['นนทบุรี', 'สมุทรปราการ', 'กรุงเทพมหานคร'].includes(province) ? 'กรุงเทพและปริมณฑล' : province);
const ExamSiteInfo = ({ examType, siteMap, data }) => {
  const { t } = useTranslation();
  const _renderSiteInfo = (site_code, exam_type) => {
    return (
      <b>
        {_.get(siteMap, `${exam_type}-${site_code}.school_name`)} <br />
        {_.get(siteMap, `${exam_type}-${site_code}.province`)}
      </b>
    );
  };

  const confirmedProvince = getProvince(_.get(siteMap, `pbt-${data.place_1}.province`));
  return (
    <li>
      {examType === 'cbt' && (
        <>
          <div className='_heading color-blue'>
            <b>{t('สนามสอบคอมพิวเตอร์')}</b>
          </div>
          <ul className='exam-venues'>
            {Array(5)
              .fill()
              .map((__, index) => (
                <li key={index}>
                  {t('ลำดับที่')} {index + 1}
                  {data[`cbt_place_${index + 1}`] ? (
                    <>
                      <i className='i-computer' />
                      <div className='col'>{_renderSiteInfo(data[`cbt_place_${index + 1}`], 'cbt')}</div>
                    </>
                  ) : (
                    <span>-</span>
                  )}
                </li>
              ))}
          </ul>
        </>
      )}
      <div className='_heading color-blue'>
        <b>
          {t('สนามสอบกระดาษ')}
          {examType === 'cbt' && ` (${t('สำรอง')})`}
        </b>
      </div>
      <ul className='exam-venues'>
        {Array(5)
          .fill()
          .map((__, index) => (
            <li key={index}>
              {t('ลำดับที่')} {index + 1}
              {data[`place_${index + 1}`] ? (
                <>
                  <i className='i-paper' />
                  <div className='col'>{_renderSiteInfo(data[`place_${index + 1}`], 'pbt')}</div>
                </>
              ) : (
                <span>-</span>
              )}
            </li>
          ))}
      </ul>
      {data.province_confirmed && (
        <>
          <hr className="hr-dashed" />
          <h4 className="color-orange m-0">
            <b>{t('เลือกสนามสอบใน')} <u>{t('จังหวัด')}{confirmedProvince}</u> {t('เป็นหลัก')}</b>
            <small className="block">
              {t('กรณีสนามสอบที่เลือกไว้ใน')} <u>{t('จังหวัด')}{confirmedProvince}</u> {t('เต็มและมีการเปิดสนามสอบใหม่ ให้เลือกสนามสอบที่เปิดใหม่นี้ก่อนเลือกสนามสอบในจังหวัดอื่น')}
            </small>
          </h4>
        </>
      )}
    </li>
  );
};

export default ExamSiteInfo;
