/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { callAPI } from 'helpers';
import _ from 'lodash';

// Hooks
import { useTranslation } from 'react-i18next';
import useSectionTgatTpatScore from './score-hooks/useSectionTgatTpatScore';

const ID = 'section-tgattpat';
const SectionTgatTpatScore = ({ profile }) => {
  const { t } = useTranslation();
  const { scores } = useSectionTgatTpatScore();

  const [loading, setLoading] = useState(false);
  const [applications, setApplications] = useState([]);
  useEffect(() => {
    const fetchApplications = async () => {
      try {
        setLoading(true);
        const { applications } = await callAPI({
          url: '/applicants/me/exam-applications',
          query: { type: 'tgattpat' },
        });
        setApplications(applications);
        setLoading(false);
      } catch (error) {
        console.error(`Error while trying to fetch applications: ${error.message}`);
        setLoading(false);
      }
    };

    fetchApplications();
  }, []);

  const existedScores =
    !loading &&
    scores.filter(({ fieldName }) => {
      // if (fieldName.includes('tpat1') && profile[fieldName]) return true;
      return _.find(applications, (app) => app.subject_name === fieldName.toUpperCase() && app.score);
    });
  return (
    <div id={ID} className="t-box">
      <h2 className="title">{t('ข้อมูลคะแนน TGAT/TPAT')}</h2>
      {existedScores.length ? (
        <div className="table-responsive">
          <table className="table-line col-last-num">
            <thead>
              <tr>
                <th nowrap="true" className="sticky">
                  {t('รหัสวิชา')}
                </th>
                <th>{t('ชื่อวิชา')}</th>
                <th>{t('คะแนน')}</th>
                <th className="text-right" nowrap="true">
                  T-SCORE
                </th>
                <th className="text-right" nowrap="true">
                  Percentile
                </th>
              </tr>
            </thead>
            <tbody>
              {existedScores.map(({ key, code, label, fieldName }) => {
                const app = _.find(applications, (app) => app.subject_name === fieldName.toUpperCase());
                // if (key.includes('tpat1')) {
                //   return (
                //     <tr key={key}>
                //       <td>{code}</td>
                //       <td>{label}</td>
                //       <td>{profile[key]}</td>
                //       <td></td>
                //       <td></td>
                //     </tr>
                //   );
                // }

                return (
                  <tr key={key}>
                    <td className="sticky">{code}</td>
                    <td>{label}</td>
                    <td className="text-right">{app.score || t('-')}</td>
                    <td className="text-right">{app.tscore || t('-')}</td>
                    <td className="text-right">{app.pscore || t('-')}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <div>{t('ไม่มีข้อมูลคะแนน')}</div>
      )}
    </div>
  );
};

export default SectionTgatTpatScore;
