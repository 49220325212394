/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { callAPI } from 'helpers';

// Hooks
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import EmailVerification from 'components/otp/EmailVerification';
import MobileVerification from 'components/otp/MobileVerification';

const roundMap = {
  '1_2568': '1 Portfolio',
  '2_2568': '2 Quota',
  '3_2568': '3 Admission',
  '41_2568': '4 Direct Admission',
  '42_2568': '4 Direct Admission'
}
const OfferReject = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});
  const roundType = useParams().roundType;

  const [loading, setLoading] = useState(true);
  const [replies, setReplies] = useState([]);
  const [offer, setOffer] = useState();
  useEffect(() => {
    const fetchEnrollmentOffers = async () => {
      try {
        setLoading(true);
        const { offers, replies } = await callAPI({
          url: '/applicants/me/enrollment-offers',
          query: { type: roundType },
        });
        if (!offers.length) {
          navigate('/profile');
        } else {
          setReplies(replies);
          setLoading(false);
        }
      } catch (error) {
        console.error(`Error while trying to fetch enrollment offers: ${error.message}`);
        setLoading(false);
      }
    };

    const fetchAdmissionResult = async () => {
      try {
        setLoading(true);
        const { admissions = [], replies = [] } = await callAPI({ url: '/applicants/me/admission-result' });
        const reprocessed = (_.get(_.last(replies), 'ds_priorities') || []).length > 0;
        const offer = _.find(admissions, ({ applicant_status }) => applicant_status === '2');
        if (!admissions.length || (!reprocessed && !offer) || replies.length >= 3) {
          navigate('/profile')
        } else {
          setOffer(offer);
          setReplies(replies);
          setLoading(false);
        }
      } catch (error) {
        console.error(`Error while trying to fetch admission result: ${error.message}`);
        setLoading(false);
      }
    }

    // if (roundType === '3_2568') {
    //   fetchAdmissionResult();
    // } else {
    //   fetchEnrollmentOffers();
    // }
  }, [navigate, roundType]);

  const quotaUsed = replies.length;
  const [currentStep, setCurrentStep] = useState(1);
  const [allChecked, setAllChecked] = useState(false);
  const disabled = currentStep === 2 && !allChecked;
  const [verifyMethod, setVerifyMethod] = useState();
  useEffect(() => {
    if (quotaUsed === 2 && roundType === '3_2568') { setCurrentStep(0); }
  }, [quotaUsed, roundType])

  const onEmailOtpSuccess = useCallback(() => {
    dispatch({ type: 'UPDATE_USER', result: { ...profile, email_is_verified: 1 } });
    navigate(-1);
  }, [dispatch, profile, navigate]);

  const onSmsOtpSuccess = useCallback(() => {
    dispatch({ type: 'UPDATE_USER', result: { ...profile, telephone_is_verified: 1 } });
    navigate(-1);
  }, [dispatch, profile, navigate]);

  if (loading) {
    return (
      <main className="site-body">
        <div className="t-form">
          <div className="r-reset-bg"></div>
        </div>
      </main>
    );
  }

  return (
    <main className="site-body">
      <div className="t-form">
        <div className={cn("r-reset-bg", { '-danger': currentStep === 0 })}></div>
        <div className="r-reset">
          <div className="content-scroll">
            <div className="text-center">
              <i className="i-danger"></i>
            </div>
            {currentStep === 0
              ? <LastChance />
              : currentStep === 1 ? (
                <Step1 roundType={roundType} offer={offer} />
              ) : currentStep === 2 ? (
                <Step2 allChecked={allChecked} setAllChecked={setAllChecked} />
              ) : (
                <CautionMessage roundType={roundType} offer={offer} />
              )}
            {currentStep > 0 && (
              <div className="i-hearts">
                <i className={cn('i-heart', { '-blink': quotaUsed === 2, '-used': quotaUsed > 2 })} />
                <i className={cn('i-heart', { '-blink': quotaUsed === 1, '-used': quotaUsed > 1 })} />
                <i className={cn('i-heart', { '-blink': quotaUsed === 0, '-used': quotaUsed > 0 })} />
                <small>
                  {t('ตัดสินใจครั้งที่')} {1 + quotaUsed}/3
                </small>
              </div>
            )}
            {currentStep === 3 ? (
              <Step3
                mobile={profile.telephone}
                email={profile.email}
                verifyMethod={verifyMethod}
                setVerifyMethod={setVerifyMethod}
                onButtonClick={() => setCurrentStep(4)}
              />
            ) : currentStep === 4 && verifyMethod === 'email' ? (
              <EmailVerification
                studentType="offer-reply"
                offerId={null}
                roundType={roundType}
                email={profile.email}
                onSuccess={onEmailOtpSuccess}
              />
            ) : currentStep === 4 && verifyMethod === 'mobile' ? (
              <MobileVerification
                studentType="offer-reply"
                offerId={null}
                roundType={roundType}
                mobile={profile.telephone}
                onSuccess={onSmsOtpSuccess}
              />
            ) : null}
          </div>
          {currentStep < 3 && (
            <div className="content-bottom">
              <a className="btn-cancel cursor-pointer" onClick={() => navigate(-1)}>
                <span className="icon">×</span> {t('ยกเลิก')}
              </a>
              <a
                className={cn('btn-main -danger', { disabled, 'cursor-pointer': !disabled })}
                onClick={disabled ? () => { } : () => setCurrentStep((_step) => _step + 1)}
              >
                {t('ไม่ใช้สิทธิ์')}
              </a>
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

const LastChance = () => {
  const { t } = useTranslation();
  return (
    <>
      <h1>{t('ตัดสินใจครั้งสุดท้ายของรอบ 3 Admission')}
        <br />
        <b className="color-red">"{t('ไม่ใช้สิทธิ์และไม่ขอใช้สิทธิ์ประมวลผลครั้งที่ 2')}"</b>
      </h1>
      <div className="t-box -noti">
        {t('หากการตัดสินใจครั้งสุดท้ายเป็นการเลือก ')}
        <b>{t('ไม่ใช้สิทธิ์และไม่ขอใช้สิทธิ์ประมวลผลครั้งที่ 2')}</b>
        <br />
        <br />
        {t('เมื่อยืนยันเรียบร้อยแล้ว จะทำให้ท่าน ')}
        <b className="color-red">{t('ไม่สามารถมีโอกาสผ่านการคัดเลือกในรอบ 3 Admission ได้อีก')}</b>
      </div>
      <div className="i-hearts">
        <i className="i-heart -blink"></i>
        <small>{t('สามารถตัดสินใจได้ 1 ครั้งเท่านั้น')}</small>
      </div>
    </>
  );
}

const Step1 = ({ roundType, offer = {} }) => {
  const { t } = useTranslation();
  if (roundType === '3_2568') {
    const rejectText = offer._id
      ? (offer.priority !== 1 ? 'ไม่ใช้สิทธิ์และไม่ขอใช้สิทธิ์ประมวลผลครั้งที่ 2' : 'ไม่ใช้สิทธิ์')
      : 'ไม่ขอใช้สิทธิ์ประมวลผลครั้งที่ 2';
    return (
      <>
        <h1>
          {t('กรณีผู้สมัครต้องการเปลี่ยนแปลงสถานะ เป็น')}{' '}
          <br />
          <b className="color-red">"{rejectText}"</b>
        </h1>
        <div className="t-box -noti">
          <b>{t('การ')}{rejectText}</b>{' '}
          {t('คือ การที่ผู้สมัครเข้าไปในระบบ เพื่อตรวจสอบผลการคัดเลือก แต่ผู้สมัครไม่ประสงค์จะเข้าศึกษาในสาขาวิชา/สถาบันอุดมศึกษาตามที่ประกาศรายชื่อว่า ตนเป็นผู้ผ่านการศึกษา และไม่ขอใช้สิทธิ์ประมวลผลครั้งที่ 2 เพื่อเลื่อนอันดับที่ติดให้ดีขึ้นหากมีที่ว่าง')}
          <br /><br />
          {t('ผู้สมัครจะเลือก')}{' '}
          <b>"{rejectText}"</b>{' '}
          {t('ในระบบเพื่อคัดเลือก รอบที่ 4 ต่อไป')}
        </div>
      </>
    )
  }
  return (
    <>
      <h1>
        {t('กรณีผู้สมัครต้องการเปลี่ยนแปลงสถานะ เป็น')} <b className="color-red">"{t('ไม่ใช้สิทธิ์')}"</b>
      </h1>
      <div className="t-box -noti">
        <b>{t('การไม่ใช้สิทธิ์')}</b> {t('คือ การที่ผู้สมัครเข้าไปในระบบเพื่อตรวจสอบผลการคัดเลือก')}
        {t('แต่ผู้สมัครไม่ประสงค์จะเข้าศึกษาในทุกสาขาวิชา/สถาบันอุดมศึกษาตามที่ประกาศรายชื่อว่าตนเป็นผู้ผ่านการศึกษา')}
        <br />
        <br />
        {t('ผู้สมัครจะเลือก')} <b>"{t('ไม่ใช้สิทธิ์')}"</b> {t('ในระบบ หรือ ไม่เลือกยืนยันสิทธิ์ในระบบ')}
      </div>
    </>
  );
};

const Step2 = ({ allChecked, setAllChecked }) => {
  const { t } = useTranslation();
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);

  useEffect(() => {
    setAllChecked(checked1 && checked2 && checked3);
  }, [setAllChecked, checked1, checked2, checked3]);

  return (
    <>
      <h1>
        {t('ข้อกำหนดและเงื่อนไขกรณี')} <b className="color-red">"{t('การไม่ใช้สิทธิ์')}"</b>
      </h1>
      <h4 className="text-center">
        {t('กรุณาอ่านและ')} <b>{t('คลิกยอมรับ')}</b> {t('ข้อกำหนดและเงื่อนไขทุกข้อ ก่อนคลิก')}{' '}
        <b>{t('ไม่ใช้สิทธิ์')}</b>
      </h4>
      <ul className="r-consent">
        <li>
          <label>
            <input type="checkbox" checked={checked1} onChange={(e) => setChecked1(e.target.checked)} />
            1.{' '}
            {t(
              'ถ้าผู้สมัครไม่ประสงค์จะเข้าศึกษาในทุกสาขาวิชาที่ผ่านการคัดเลือก ให้กด “ไม่ใช้สิทธิ์” และยืนยันตัวตนผ่านโทรศัพท์มือถือหรืออีเมลด้วยรหัส OTP'
            )}
          </label>
        </li>
        <li>
          <label>
            <input type="checkbox" checked={checked2} onChange={(e) => setChecked2(e.target.checked)} />
            2.{' '}
            {t(
              'ถ้าผู้สมัครได้กด “ไม่ใช้สิทธิ์” ในการตัดสินใจครั้งแรกเรียบร้อยแล้ว และต้องการเปลี่ยนการตัดสินใจ ผู้สมัครสามารถทำการเปลี่ยนแปลงได้อีกเพียง 2 ครั้งเท่านั้น จนถึงเวลาปิดระบบการยืนยันสิทธิ์ (นับรวมจำนวนครั้งที่ยืนยันตัวตนด้วยรหัส OTP สำเร็จได้สูงสุด 3 ครั้ง โดยนับทั้งการกดยืนยันสิทธิ์ และการกดไม่ใช้สิทธิ์) โดยระบบจะนับเป็นหนึ่งครั้งของการยืนยันสิทธิ์'
            )}
          </label>
        </li>
        <li>
          <label>
            <input type="checkbox" checked={checked3} onChange={(e) => setChecked3(e.target.checked)} />
            3.{' '}
            {t(
              'ถ้าผู้สมัครไม่ประสงค์จะเข้าศึกษาในทุกสาขาวิชาที่ผ่านการคัดเลือก และไม่ได้กด “ไม่ใช้สิทธิ์” ภายในช่วงเวลาที่กำหนด จะถือว่า ผู้สมัครยังไม่ได้ใช้สิทธิ์ในการเข้าศึกษา และไม่ต้องการเข้าศึกษาในทุกสาขาวิชาที่ได้รับการคัดเลือก ซึ่งจะขอใช้สิทธิ์เข้าศึกษาภายหลังจากช่วงเวลาที่กำหนดไม่ได้ แต่ผู้สมัครสามารถสมัครคัดเลือกในรอบต่อไปได้'
            )}
          </label>
        </li>
        <li>
          <label>
            <input type="checkbox" checked={allChecked} />
            <b>{t('ข้าพเจ้าได้อ่านและยอมรับข้อกำหนดและเงื่อนไขดังกล่าวสำหรับกรณี "ไม่ใช้สิทธิ์"')}</b>
          </label>
        </li>
      </ul>
    </>
  );
};

const CautionMessage = ({ roundType, offer = {} }) => {
  const { t } = useTranslation();
  if (roundType === '3_2568') {
    const rejectText = offer._id
      ? (offer.priority !== 1 ? 'ไม่ใช้สิทธิ์และไม่ขอใช้สิทธิ์ประมวลผลครั้งที่ 2' : 'ไม่ใช้สิทธิ์')
      : 'ไม่ขอใช้สิทธิ์ประมวลผลครั้งที่ 2';
    return (
      <>
        <h1 className="color-red">{t('ยืนยัน')}{rejectText} {t('รอบ 3 Admission')}</h1>
        <div className="t-box -error">
          <b>{t('การ')}{rejectText}</b>
          {' '}{t('คือ การที่ท่านตัดสินใจไม่ใช้สิทธิ์เข้าศึกษาในทุกสาขาวิชาที่ผ่านการคัดเลือกในรอบนี้ หากตัดสินใจครบกำหนดแล้วจะขอกลับมาใช้สิทธิ์เข้าศึกษาต่อในภายหลังไม่ได้')}
        </div>
      </>
    )
  }
  return (
    <>
      <h1 className="color-red">{t('ยืนยันไม่ใช้สิทธิ์ รอบ')} {roundMap[roundType]}</h1>
      <div className="t-box -error">
        {t('การไม่ใช้สิทธิ์ คือ การที่ท่านตัดสินใจไม่ใช้สิทธิ์เข้าศึกษาในทุกสาขาวิชาที่ผ่านการคัดเลือกในรอบนี้')}
        {t('หากตัดสินใจครบกำหนดแล้วจะขอกลับมาใช้สิทธิ์เข้าศึกษาต่อในภายหลังไม่ได้')}
      </div>
    </>
  );
};

const Step3 = ({ mobile = '', email = '', verifyMethod, setVerifyMethod, onButtonClick }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formattedMobile = [mobile.slice(0, 3), mobile.slice(3, 6), mobile.slice(-4)].join(' ');
  const disabled = !verifyMethod;
  return (
    <>
      <h4 className="text-center">{t('กรุณาเลือกช่องทางในการส่งรหัส OTP ไม่ใช้สิทธิ์')}</h4>
      <ul className="r-radio">
        <li>
          <label>
            <input
              type="radio"
              name="confirm"
              checked={verifyMethod === 'mobile'}
              onClick={() => setVerifyMethod('mobile')}
            />
            <strong>{t('ยืนยัน "ไม่ใช้สิทธิ์" ผ่านเบอร์มือถือ')}</strong>
            <small>
              {t('หมายเลข')} {formattedMobile}
            </small>
          </label>
        </li>
        <li>
          <label>
            <input
              type="radio"
              name="confirm"
              checked={verifyMethod === 'email'}
              onClick={() => setVerifyMethod('email')}
            />
            <strong>{t('ยืนยัน "ไม่ใช้สิทธิ์" ผ่านอีเมล')}</strong>
            <small>{email}</small>
          </label>
        </li>
      </ul>
      <div className="action">
        <a
          className={cn('btn-main', { disabled, 'cursor-pointer': !disabled })}
          onClick={disabled ? () => { } : onButtonClick}
        >
          {t('ส่ง OTP เพื่อไม่ใช้สิทธิ์')}
        </a>
      </div>
      <div className="text-center _heading">
        <a onClick={() => navigate(-1)}>{t('ยกเลิก')}</a>
      </div>
    </>
  );
};

export default OfferReject;
