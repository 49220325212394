/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import _ from 'lodash';

// Components
import MethodSelector from 'components/otp/MethodSelector';
import EmailVerification from 'components/otp/EmailVerification';
import MobileVerification from 'components/otp/MobileVerification';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const RedeemAgreement = () => {
  const { t } = useTranslation();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [flow, setFlow] = useState();

  useEffect(() => {
    if (!profile.r3_redeemable) {
      navigate('/profile');
    }
    // if (typeof profile.skip_redemption !== 'undefined') {
    //   navigate('/profile');
    // } else if (!isCitizenId(profile.citizen_id) && !isGNumber(profile.citizen_id)) {
    //   navigate('/profile');
    // }
  }, [profile, navigate]);

  const [verifyMethod, setVerifyMethod] = useState();
  const onEmailOtpSuccess = () => {
    navigate('/profile');
  };
  const onSmsOtpSuccess = () => {
    navigate('/profile');
  };

  if (flow === 'agree') {
    return (
      <main className="site-body">
        <div className="t-form">
          <h1>{t('ยินยอมรับเงินสนับสนุนค่าสมัครคัดเลือกฯรอบที่ 3 Admission')}</h1>
          {!verifyMethod && (
            <div className="-noti">
              <div className="text-center">{t('กรุณาเลือกช่องทางในการส่งรหัส OTP เพื่อยืนยัน')}</div>
            </div>
          )}
          {!verifyMethod ? (
            <MethodSelector topic="ยืนยันการขอรับเงินสนับสนุน" onClick={setVerifyMethod} />
          ) : verifyMethod === 'email' ? (
            <EmailVerification studentType="redeem-round3-agree" email={profile.email} onSuccess={onEmailOtpSuccess} />
          ) : verifyMethod === 'mobile' ? (
            <MobileVerification
              studentType="redeem-round3-agree"
              mobile={profile.telephone}
              onSuccess={onSmsOtpSuccess}
            />
          ) : null}
        </div>
      </main>
    );
  }

  return (
    <main className="site-body">
      <div className="t-form">
        {loading && <div className="t-loading" />}
        <div className="r-reset-bg" />
        <div className="r-reset">
          {!flow ? (
            <FlowSelector onNext={setFlow} />
          ) : (
            <div className="content-scroll">
              <div className="text-center">
                <i className="i-danger" />
              </div>
              <div className="t-form">
                <h1>
                  <div>ไม่ยินยอมการรับเงินสนับสนุน</div>
                  <div>ค่าสมัครคัดเลือกฯ รอบที่ 3 Admission</div>
                </h1>
                {!verifyMethod && (
                  <div className="-noti">
                    <div className="text-center">{t('กรุณาเลือกช่องทางในการส่งรหัส OTP เพื่อยืนยัน')}</div>
                  </div>
                )}
                {!verifyMethod ? (
                  <MethodSelector topic="ยืนยันไม่ขอรับเงินสนับสนุน" onClick={setVerifyMethod} />
                ) : verifyMethod === 'email' ? (
                  <EmailVerification
                    studentType="redeem-round3-disagree"
                    email={profile.email}
                    onSuccess={onEmailOtpSuccess}
                  />
                ) : verifyMethod === 'mobile' ? (
                  <MobileVerification
                    studentType="redeem-round3-disagree"
                    mobile={profile.telephone}
                    onSuccess={onSmsOtpSuccess}
                  />
                ) : null}
              </div>
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export default RedeemAgreement;

const FlowSelector = ({ onNext }) => {
  const { t } = useTranslation();
  const [flow, setFlow] = useState();

  return (
    <React.Fragment>
      <div className="content-scroll">
        <div className="text-center">
          <i className="i-danger" />
        </div>
        <h1>
          <div>{t('คำยินยอม')}</div>
          <div>
            {t(
              'การรับเงินสนับสนุนค่าสมัครคัดเลือกฯ รอบที่ 3 Admission จากสำนักงานปลัดกระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัย และนวัตกรรม'
            )}
          </div>
        </h1>
        <ol className="t-ol mb-3">
          <li>
            <b>1.</b> ผู้สมัครยินยอมมอบสิทธิ์ให้สมาคมที่ประชุมอธิการบดีแห่งประเทศไทย
            ดำเนินการแทนผู้สมัครในการเบิกเงินสนับสนุนค่าสมัครคัดเลือกฯ รอบที่ 3 Admission ที่ได้สมัครนี้
            จากสำนักงานปลัดกระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัย และนวัตกรรม
          </li>
          <li>
            <b>2.</b> ผู้สมัครสามารถรับเงินสนับสนุนค่าสมัครคัดเลือกฯ รอบที่ 3 Admissions ได้ตามจำนวนอันดับที่ยื่นสมัคร
            สูงสุดไม่เกิน 900 บาทต่อคน ตัดสินใจได้ 3 ครั้ง
          </li>
          <li>
            <b>3.</b> หากต่อมามีการเปลี่ยนแปลงหรือเพิ่มเติมรายการสมัคร (Set Zero) และมีค่าใช้จ่ายเพิ่มเติม
            ผู้สมัครจะต้องดำเนินการจ่ายค่าสมัครคัดเลือกส่วนที่เพิ่มขึ้นนี้เองโดยไม่สามารถนำมาขอรับเงินสนับสนุนค่าสมัครเพิ่มเติมได้อีก
          </li>
          <li>
            <b>4.</b> ผู้สมัครที่ไม่ประสงค์รับการสนับสนุนค่าสมัครคัดเลือกฯ นี้
            และได้กดยินยอมการไม่ขอรับการสนับสนุนเรียบร้อยแล้ว
            จะไม่สามารถกลับมาขอรับการสนับสนุนในภายหลังของการสมัครรอบที่ 3 Admissions ในระบบ TCAS68 ได้อีก
          </li>
        </ol>
        <ul className="r-radio">
          <li>
            <label>
              <input type="radio" name="agree" checked={flow === 'agree'} onClick={() => setFlow('agree')} />
              <strong className="color-green">{t('ยินยอม')}</strong>
              <span>&nbsp;{t('ขอรับเงินสนับสนุนค่าสมัครคัดเลือก')}</span>
            </label>
          </li>
          <li>
            <label>
              <input type="radio" name="Flow" checked={flow === 'disagree'} onClick={() => setFlow('disagree')} />
              <strong className="color-red">{t('ไม่ยินยอม')}</strong>
              <span>&nbsp;{t('ขอรับเงินสนับสนุนและประสงค์ชำระค่าสมัครคัดเลือกด้วยตนเอง')}</span>
            </label>
          </li>
        </ul>
      </div>

      <div className="content-bottom -full text-center">
        <h4 className="color-red text-center mb-0 mt-0">
          <small>
            <strong>หมายเหตุ:</strong> เลื่อนอ่านข้อความแล้วเลือกยินยอมหรือไม่ยินยอม
            เมื่อกดยืนยันแล้วจะไม่สามารถกลับมาแก้ไขในภายหลังได้
          </small>
        </h4>
        <a
          className={cn('btn-main', { disabled: !flow, 'cursor-pointer': flow })}
          onClick={!flow ? () => {} : () => onNext(flow)}
        >
          {t('ยืนยัน')}
        </a>
      </div>
    </React.Fragment>
  );
};
