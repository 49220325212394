/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// Helpers
import { getCitizenIdType, getCitizenTypeLabel } from 'helpers';

// Components
import ErrorBox from './ErrorBox';
import ModalTermsAndConditions from './ModalTermsAndConditions';

// Hooks
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NotFoundTicket from './NotFoundTicket';
import NotFoundSubmitted from './NotFoundSubmitted';

// Data
import disabilities from 'data/disabilities';

const BasicInfoForm = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    citizenId,
    passwordValidations,
    modalVisible,
    setModalVisible,
    notFound,
    setNotFound,
    notFoundSubmitted,
    onNotFoundSubmitSuccess,

    email,
    mobile,
    title,
    firstName,
    lastName,
    firstNameAdditional,
    lastNameAdditional,

    specialRequest,
    specialRequestVisibility,

    password,
    passwordHidden,
    onPasswordHiddenChange,
    onEmailChange,
    onMobileChange,
    onTitleChange,
    onFirstNameChange,
    onLastNameChange,
    onFirstNameAdditionalChange,
    onLastNameAdditionalChange,
    onPasswordChange,
    onSpecialRequestChange,
    onSpecialRequestVisibilityChange,
    onSpecialRequestFileChange,

    onRegister,
    step1Errors,
  } = props;

  useEffect(() => {
    if (step1Errors.length) {
      const container = document.getElementById('basic-info-form');
      if (container) {
        container.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [step1Errors]);

  const citizenTypeId = getCitizenIdType(citizenId);
  const citizenTypeLabel = getCitizenTypeLabel(t, citizenTypeId);
  if (notFoundSubmitted) return <NotFoundSubmitted email={email} />;
  if (notFound) {
    return (
      <NotFoundTicket
        isTcas
        citizenId={citizenId}
        citizenTypeLabel={citizenTypeLabel}
        headingTitle='กรุณาอัปโหลดเอกสารเพิ่มเติม'
        prefill={{ email, telephone: mobile, title, first_name: firstName, last_name: lastName }}
        onSubmitSuccess={onNotFoundSubmitSuccess}
        onBack={() => {
          setNotFound(false);
          window.scrollTo(0, 0);
        }}
      />
    );
  }

  return (
    <section id='basic-info-form'>
      {step1Errors.length ? <ErrorBox isFull errors={step1Errors} /> : <div className='t-box -info'>{t('พบข้อมูลของท่านในระบบ กรุณากรอกข้อมูลเพิ่มเติมเพื่อยืนยันตัวตน')}</div>}
      <div className='t-row'>
        <div className='col required valid'>
          <label className='has-meta'>
            <span>{t('เลขประจำตัว')}</span>
            <span className='meta'>({citizenTypeLabel})</span>
          </label>
          <input type='text' value={citizenId} disabled />
        </div>
        <div className='col required'>
          <label>{t('อีเมล')}</label>
          <input type='email' placeholder={t('กรอกอีเมล เช่น mytcas68@gmail.com')} value={email} onChange={onEmailChange} />
        </div>
        <div className='col required'>
          <label>{t('เบอร์โทรศัพท์มือถือ')}</label>
          <input type='tel' placeholder={t('กรอกเลข 10 หลัก')} value={mobile} onChange={onMobileChange} />
        </div>
        <div className='col required'>
          <label>{t('คำนำหน้า')}</label>
          <div className='inline-group'>
            <input type='radio' name='title' id='mr' checked={title === 'นาย'} onChange={() => onTitleChange('นาย')} />
            <label htmlFor='mr'>{t('นาย')}</label>
            <input type='radio' name='title' id='ms' checked={title === 'นางสาว'} onChange={() => onTitleChange('นางสาว')} />
            <label htmlFor='ms'>{t('นางสาว')}</label>
          </div>
        </div>
        <div className='col required'>
          <label htmlFor='given-name'>{t('ชื่อ')}</label>
          <input type='text' name='given-name' id='given-name' autoComplete='given-name' placeholder={t('กรอกชื่อจริง ไม่ต้องใส่คำนำหน้า')} value={firstName} onChange={onFirstNameChange} />
        </div>
        <div className='col required'>
          <label htmlFor='family-name'>{t('นามสกุล')}</label>
          <input type='text' name='family-name' id='family-name' autoComplete='family-name' placeholder={t('กรอกนามสกุล')} value={lastName} onChange={onLastNameChange} />
        </div>
        <div className='col'>
          <label htmlFor='given-name-alt'>{citizenTypeId === 'passport' ? t('ชื่อภาษาไทย') : t('ชื่อภาษาอังกฤษ')}</label>
          <input type='text' name='given-name-alt' id='given-name-alt' autoComplete='given-name' placeholder={citizenTypeId === 'passport' ? t('กรอกชื่อภาษาไทย ไม่ต้องใส่คำนำหน้า') : t('กรอกชื่อภาษาอังกฤษ ไม่ต้องใส่คำนำหน้า')} value={firstNameAdditional} onChange={onFirstNameAdditionalChange} />
        </div>
        <div className='col'>
          <label htmlFor='family-name-alt'>{citizenTypeId === 'passport' ? t('นามสกุลภาษาไทย') : t('นามสกุลภาษาอังกฤษ')}</label>
          <input type='text' name='family-name-alt' id='family-name-alt' autoComplete='family-name' placeholder={citizenTypeId === 'passport' ? t('กรอกนามสกุลภาษาไทย') : t('กรอกนามสกุลภาษาอังกฤษ')} value={lastNameAdditional} onChange={onLastNameAdditionalChange} />
        </div>
        <div className='col required'>
          <label>{t('รหัสผ่าน')}</label>
          <input type={passwordHidden ? 'password' : 'text'} placeholder={t('กรอกรหัสผ่าน')} value={password} onChange={onPasswordChange} />
          <i className={classNames('i-viewpass cursor-pointer', { active: !passwordHidden })} onClick={onPasswordHiddenChange} />
          <small className='remark'>
            <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' style={{ verticalAlign: '-4px' }}>
              <path
                d='M9 1.5C4.8645 1.5 1.5 4.8645 1.5 9C1.5 13.1355 4.8645 16.5 9 16.5C13.1355 16.5 16.5 13.1355 16.5 9C16.5 4.8645 13.1355 1.5 9 1.5ZM9.5625 12.5625C9.5625 12.873 9.3105 13.125 9 13.125C8.6895 13.125 8.4375 12.873 8.4375 12.5625V8.4375C8.4375 8.127 8.6895 7.875 9 7.875C9.3105 7.875 9.5625 8.127 9.5625 8.4375V12.5625ZM9 6.75C8.58562 6.75 8.25 6.41438 8.25 6C8.25 5.58562 8.58562 5.25 9 5.25C9.41438 5.25 9.75 5.58562 9.75 6C9.75 6.41438 9.41438 6.75 9 6.75Z'
                fill='#1A477F'
              />
            </svg>
            {t('ตัวอักษรอักขระพิเศษ')} <b>{` !@#$%^&*()_+|~-=\\\`{}[]:”;'<>?,./`}</b> {t('มีหรือไม่มีก็ได้')}
          </small>
        </div>
        <div className='col required'>
          <ul className='t-small'>
            <li>
              <i
                className={classNames({
                  'i-yes': passwordValidations.lengthAtLeast8,
                  'i-no': !passwordValidations.lengthAtLeast8,
                })}
              />{' '}
              {t('จำนวนไม่น้อยกว่า 8 หลัก')}
            </li>
            <li>
              <i
                className={classNames({
                  'i-yes': passwordValidations.upperCaseAtLeast1,
                  'i-no': !passwordValidations.upperCaseAtLeast1,
                })}
              />{' '}
              {t('ตัวอักษรภาษาอังกฤษพิมพ์ใหญ่ A - Z อย่างน้อย 1 ตัว')}
            </li>
            <li>
              <i
                className={classNames({
                  'i-yes': passwordValidations.lowerCaseAtLeast1,
                  'i-no': !passwordValidations.lowerCaseAtLeast1,
                })}
              />{' '}
              {t('ตัวอักษรภาษาอังกฤษพิมพ์เล็ก a - z อย่างน้อย 1 ตัว')}
            </li>
            <li>
              <i
                className={classNames({
                  'i-yes': passwordValidations.numberAtLeast1,
                  'i-no': !passwordValidations.numberAtLeast1,
                })}
              ></i>{' '}
              {t('ตัวเลข 0 - 9 อย่างน้อย 1 ตัว')}
            </li>
          </ul>
        </div>
        <div className='col -full'>
          <label className='inline-group gap-1 color-orange'>
            <input type='checkbox' checked={specialRequestVisibility} onChange={onSpecialRequestVisibilityChange} />
            {t('ต้องการความช่วยเหลือพิเศษ')} <img src='/assets/img/i/i-disability-orange.svg' alt='disability' width='24' height='24' />
          </label>
          {specialRequestVisibility && (
            <>
              <div style={{ marginBottom: 20 }}>
                <label>{t('อัปโหลดรูปหลักฐาน')}</label>
                <input name="special-request-file" type="file" accept="image/*" onChange={onSpecialRequestFileChange} />
              </div>
              <select required value={_.findIndex(disabilities, text => text === specialRequest)} onChange={onSpecialRequestChange}>
                <option>กรุณาเลือกความต้องการพิเศษ*</option>
                {disabilities.map((text, index) => (
                  <option key={index} value={index}>
                    {text}
                  </option>
                ))}
              </select>
            </>
          )}
        </div>
      </div>
      <hr />

      <div className='action'>
        <a className='btn-main cursor-pointer' onClick={onRegister}>
          {t('ไปหน้าถัดไป →')}
        </a>
      </div>

      <ModalTermsAndConditions visible={modalVisible} onSubmit={() => setModalVisible(false)} onClose={() => navigate('/')} />
    </section>
  );
};

export default BasicInfoForm;
