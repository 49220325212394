/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { callAPI } from 'helpers';

// Hooks
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import EmailVerification from 'components/otp/EmailVerification';
import MobileVerification from 'components/otp/MobileVerification';

const expirationMap = {
  '1_2568': '7 กุมภาพันธ์ 2568',
  '2_2568': '4 พฤษภาคม 2568',
  '3_2568': '26 พฤษภาคม 2568'
}
const titleMap = {
  '1_2568': 'รอบ 1 Portfolio',
  '2_2568': 'รอบ 2 Quota',
  '3_2568': "รอบ 3 Admission"
}

const OfferReject = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});
  const roundType = useParams().roundType;

  const [loading, setLoading] = useState(true);
  const [offers, setOffers] = useState([]);
  const [replies, setReplies] = useState([]);

  useEffect(() => {
    const fetchEnrollmentOffers = async () => {
      try {
        setLoading(true);
        const { offers, replies } = await callAPI({ url: '/applicants/me/enrollment-offers', query: { type: roundType } });
        setOffers(offers);
        setReplies(replies);
        setLoading(false);
      } catch (error) {
        console.error(`Error while trying to fetch enrollment offers: ${error.message}`);
        setLoading(false);
      }
    };

    // fetchEnrollmentOffers();
  }, [roundType]);

  const [currentStep, setCurrentStep] = useState(1);
  const [allChecked, setAllChecked] = useState(false);
  const disabled = currentStep === 2 && !allChecked;
  const [verifyMethod, setVerifyMethod] = useState();

  const onEmailOtpSuccess = useCallback(() => {
    dispatch({ type: 'UPDATE_USER', result: { ...profile, email_is_verified: 1 } });
    navigate(-1);
  }, [dispatch, profile, navigate]);

  const onSmsOtpSuccess = useCallback(() => {
    dispatch({ type: 'UPDATE_USER', result: { ...profile, telephone_is_verified: 1 } });
    navigate(-1);
  }, [dispatch, profile, navigate]);

  const offerId = roundType === '3_2568'
    ? _.get(_.find(offers, offer => offer.tcas_status === '3'), '_id')
    : _.get(_.last(replies), 'enrollment_offer._id');
  return (
    <main className="site-body">
      <div className="t-form">
        <div className={cn('r-reset-bg', { '-danger': Boolean(profile.cancel_count) })}></div>
        <div className="r-reset">
          <div className="content-scroll">
            <div className="text-center">
              <i className="i-danger"></i>
              {Boolean(profile.cancel_count) && <i className="i-danger"></i>}
            </div>
            {currentStep === 1 ? (
              <Step1 roundType={roundType} first={!profile.cancel_count} />
            ) : currentStep === 2 ? (
              <Step2 allChecked={allChecked} setAllChecked={setAllChecked} />
            ) : (
              <ProgramInfo roundType={roundType} offers={offers} replies={replies} />
            )}
            {currentStep >= 3 && (
              <div className="i-hearts">
                <i className="i-heart -blink"></i>
                <small>สามารถตัดสินใจได้ 1 ครั้งเท่านั้น</small>
              </div>
            )}
            {currentStep === 3 ? (
              <Step3
                mobile={profile.telephone}
                email={profile.email}
                verifyMethod={verifyMethod}
                setVerifyMethod={setVerifyMethod}
                onButtonClick={() => setCurrentStep(4)}
              />
            ) : currentStep === 4 && verifyMethod === 'email' ? (
              <EmailVerification
                isCanceled
                studentType="offer-reply"
                offerId={offerId}
                roundType={roundType}
                email={profile.email}
                onSuccess={onEmailOtpSuccess}
              />
            ) : currentStep === 4 && verifyMethod === 'mobile' ? (
              <MobileVerification
                isCanceled
                studentType="offer-reply"
                offerId={offerId}
                roundType={roundType}
                mobile={profile.telephone}
                onSuccess={onSmsOtpSuccess}
              />
            ) : null}
          </div>
          {currentStep < 3 && (
            <div className="content-bottom">
              <a className="btn-cancel cursor-pointer" onClick={() => navigate(-1)}>
                <span className="icon">×</span> {t('ยกเลิก')}
              </a>
              <a
                className={cn('btn-main -danger', { disabled, 'cursor-pointer': !disabled })}
                onClick={disabled ? () => { } : () => setCurrentStep((_step) => _step + 1)}
              >
                {t('สละสิทธิ์')}
              </a>
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

const Step1 = ({ roundType, first }) => {
  const { t } = useTranslation();
  if (first) {
    return (
      <>
        <h1>
          {t('กรณีผู้สมัครต้องการ')} <b className="color-red">"{t('สละสิทธิ์')}"</b>
        </h1>
        <div className="t-box -noti">
          <p>
            {t('การสละสิทธิ์ ระบบกำหนดให้ทำได้เพียง 1 ครั้ง ภายในวันที่')}
            <b> {t(expirationMap[roundType])} {t('เวลา 23:59 น.')} </b>
            {t('เพื่อสมัครใน')}
            {['1_2568'].includes(roundType) && t('รอบ 2 หรือ')}
            {['1_2568', '2_2568'].includes(roundType) && t('รอบ 3 หรือ')}
            {['1_2568', '2_2568', '3_2568'].includes(roundType) && t('รอบ 4 ได้')}
            {roundType !== '3_2568' && t('หากสละสิทธิ์ครั้งที่ 2 จะไม่สามารถเข้าสู่กระบวนการสมัครและคัดเลือกในรอบต่อไปได้')}{' '}
          </p>
          <p>
            <strong className="color-red">
              {t('ท่านไม่สามารถขอยกเลิกการสละสิทธิ์ ภายหลังจากที่ได้สละสิทธิ์เรียบร้อยแล้ว')}
            </strong>
          </p>
        </div>

        <div className="i-hearts">
          <i className="i-heart -blink"></i>
          <small>{t('สามารถตัดสินใจได้ 1 ครั้งเท่านั้น เพื่อสมัครในรอบต่อไป')}</small>
        </div>
      </>
    );
  }

  return (
    <>
      <h1>{t('กรณีผู้สมัครต้องการ')} <b className="color-red">"{t('สละสิทธิ์ครั้งที่')} 2"</b></h1>
      <div className="t-box -noti">
        {t('การสละสิทธิ์สามารถ')} <b>{t('ทำได้เพียงครั้งเดียว เพื่อสมัครคัดเลือกในรอบต่อไป')}</b><br /><br />
        {t('หากท่านสละสิทธิ์ครั้งนี้ จะทำให้ท่าน')} <b className="color-red">{t('ไม่สามารถสมัครคัดเลือกในรอบต่อไปของ TCAS68 ได้อีก')}</b>
      </div>
      <div className="i-hearts">
        <i className="i-heart -blink"></i>
        <small>{t('สามารถตัดสินใจได้ 1 ครั้งเท่านั้น')}</small>
      </div>
    </>
  )
};

const Step2 = ({ allChecked, setAllChecked }) => {
  const { t } = useTranslation();
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);

  useEffect(() => {
    setAllChecked(checked1 && checked2 && checked3);
  }, [setAllChecked, checked1, checked2, checked3]);

  return (
    <>
      <h1>
        {t('ข้อกำหนดและเงื่อนไขกรณี')} <b className="color-red">"{t('สละสิทธิ์')}"</b>
      </h1>
      <h4 className="text-center">
        {t('กรุณาอ่านและ')} <b>{t('คลิกยอมรับ')}</b> {t('ข้อกำหนดและเงื่อนไขทุกข้อ ก่อนคลิก')}{' '}
        <b>{t('ยืนยันการสละสิทธิ์')}</b>
      </h4>
      <ul className="r-consent">
        <li>
          <label>
            <input type="checkbox" checked={checked1} onChange={(e) => setChecked1(e.target.checked)} />
            <span>
              {' '}
              1. {t('ผู้ที่กดปุ่มสละสิทธิ์แล้ว จะ')} <b className="color-red">{t('ไม่สามารถยกเลิก')}</b>{' '}
              {t('การสละสิทธิ์เพื่อขอสิทธิ์กลับเข้าศึกษาในภายหลังได้ ระบบจะคืนสิทธิ์ให้สถาบันนั้นๆ ทันที')} <br />
              {t('แต่ผู้สมัครสามารถสมัครเข้ารับการคัดเลือกในรอบต่อไปรอบใดก็ได้')}
            </span>
          </label>
        </li>
        <li>
          <label>
            <input type="checkbox" checked={checked2} onChange={(e) => setChecked2(e.target.checked)} />
            <span>
              {' '}
              2. {t('การสละสิทธิ์สามารถ')} <b className="color-red">{t('ทำได้เพียงครั้งเดียว')}</b>{' '}
              {t('เพื่อสมัครคัดเลือกในรอบต่อไป')}
              {t('หากสละสิทธิ์ครบ 2 ครั้งแล้วจะไม่สามารถสมัครคัดเลือกใน TCAS68 ได้อีก')}{' '}
            </span>
          </label>
        </li>
        <li>
          <label>
            <input type="checkbox" checked={checked3} onChange={(e) => setChecked3(e.target.checked)} />
            <span>
              {' '}
              3. {t('การสละสิทธิ์ใน')}{' '}
              <b className="color-red">{t('หลักสูตรแพทยศาสตร์บัณฑิต ทันตแพทยศาสตรบัณฑิต และเภสัชศาสตรบัณฑิต')}</b>{' '}
              {t('ของสถาบันอุดมศึกษาของรัฐ/ในกำกับของรัฐ หากสละสิทธิ์แล้วจะ')}{' '}
              <b className="color-red">{t('ไม่สามารถสมัครหลักสูตรเดิม')}</b>{' '}
              {t(
                'ในสถาบันอุดมศึกษาเดิมและสถาบันอื่น ๆ ของรัฐในรอบถัดไปได้ (สถาบันอุดมศึกษาของรัฐ เท่ากับ สถาบันในสมาชิก ทปอ. มรภ. มทร.'
              )}
              {t('และสถาบันสมทบ')}){' '}
            </span>
          </label>
        </li>
        <li>
          <label>
            <input type="checkbox" checked={allChecked} />
            <b>{t('ข้าพเจ้าได้อ่านและยอมรับข้อกำหนดและเงื่อนไขดังกล่าวสำหรับกรณี "สละสิทธิ์"')}</b>
          </label>
        </li>
      </ul>
      <div className="i-hearts">
        <i className="i-heart -blink"></i>
        <small>{t('สามารถตัดสินใจได้ 1 ครั้งเท่านั้น เพื่อสมัครในรอบต่อไป')}</small>
      </div>
    </>
  );
};

const ProgramInfo = ({ roundType, offers, replies }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language.toLowerCase();
  const program = roundType === '3_2568'
    ? _.get(_.find(offers, offer => offer.tcas_status === '3'), 'program')
    : _.get(_.last(replies), 'enrollment_offer.program');
  const majorName = _.get(program, `major_name_${lang}`);
  const projectName = _.get(_.last(replies), `program_project.project_name_${lang}`);
  
  return (
    <>
      <h1 className="color-red">{t('ยืนยันการสละสิทธิ์')} {titleMap[roundType]}</h1>
      <ul className="r-choose -cancel">
        <li>
          <label>
            <div className="num">
              <i className="i-check"></i>
            </div>
            <span
              className="u-logo"
              style={{ backgroundImage: `url('/assets/img/logo/${_.get(program, 'university_id')}.png')` }}
            />
            <strong>{_.get(program, `university_name_${lang}`)}</strong>
            <b>{_.get(program, `campus_name_${lang}`)}</b>
            <br />
            {_.get(program, `faculty_name_${lang}`)}
            <br />
            {_.get(program, `field_name_${lang}`)}
            <br />
            {majorName && majorName !== '0' ? (
              <>
                {t('วิชาเอก')}
                {majorName}
                <br />
              </>
            ) : null}
            {_.get(program, `program_name_${lang}`)}
            <br />
            {lang === 'th' ? `(${_.get(program, `program_name_th`)})` : ''}
            {projectName && projectName !== '0' ? (
              <>
                <br />
                {t('โครงการ ')}
                {projectName}
              </>
            ) : null}
          </label>
        </li>
      </ul>
    </>
  );
};

const Step3 = ({ mobile = '', email = '', verifyMethod, setVerifyMethod, onButtonClick }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formattedMobile = [mobile.slice(0, 3), mobile.slice(3, 6), mobile.slice(-4)].join(' ');
  const disabled = !verifyMethod;
  return (
    <>
      <h4 className="text-center">{t('กรุณาเลือกช่องทางในการส่งรหัส OTP สละสิทธิ์')}</h4>
      <ul className="r-radio">
        <li>
          <label>
            <input
              type="radio"
              name="confirm"
              checked={verifyMethod === 'mobile'}
              onClick={() => setVerifyMethod('mobile')}
            />
            <strong>{t('ยืนยัน "สละสิทธิ์" ผ่านเบอร์มือถือ')}</strong>
            <small>
              {t('หมายเลข')} {formattedMobile}
            </small>
          </label>
        </li>
        <li>
          <label>
            <input
              type="radio"
              name="confirm"
              checked={verifyMethod === 'email'}
              onClick={() => setVerifyMethod('email')}
            />
            <strong>{t('ยืนยัน "สละสิทธิ์" ผ่านอีเมล')}</strong>
            <small>{email}</small>
          </label>
        </li>
      </ul>
      <div className="action">
        <a
          className={cn('btn-main', { disabled, 'cursor-pointer': !disabled })}
          onClick={disabled ? () => { } : onButtonClick}
        >
          {t('ส่ง OTP เพื่อยืนยันการสละสิทธิ์')}
        </a>
      </div>
      <div className="text-center _heading">
        <a onClick={() => navigate(-1)}>{t('ยกเลิก')}</a>
      </div>
    </>
  );
};

export default OfferReject;
