/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const ModalPassportTgat = ({ visible, onClick }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.removeAttribute('style');
    }
  }, [visible]);

  if (!visible) return null;
  return (
    <div className='t-modal' style={{ visibility: 'visible', opacity: 1, pointerEvents: 'auto' }}>
      <div className='content' style={{ padding: '20px 30px', opacity: 1, height: 'initial', overflow: 'auto', textAlign: 'left' }}>
        <div className='text-center'>
          <i className="i-danger" />
          <h3>
            ผู้สมัครที่ใช้หมายเลข Passport ไม่สามารถขอรับเงินสนับสนุนค่าสมัครสอบวิชา TGAT ความถนัดทั่วไปได้
          </h3>
          <p>
            ผู้มีสิทธิ์ขอรับเงินสนับสนุนจะต้องเป็นนักเรียนที่มีสัญชาติไทยที่สมัครด้วยเลขบัตรประจำตัวประชาชน หรือ G-Number เท่านั้น
          </p>
          <a className="btn-main cursor-pointer" onClick={onClick}>
            {t('รับทราบ')}
          </a>
        </div>
      </div>
    </div>
  );
}

export default ModalPassportTgat;
