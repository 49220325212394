import Cookies from 'js-cookie';
import _ from 'lodash';

const INITIAL_STATE = {
  lang: Cookies.get('lang') || 'TH',
  user: {},
  pendingTickets: [],
  courses: [],
  courseMap: {},
  admissionRemoves: []
};

const appReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'TOGGLE_LANGUAGE':
      return { ...state, lang: state.lang === 'TH' ? 'EN' : 'TH' };
    case 'UPDATE_USER':
      return { ...state, user: action.result };
    case 'UPDATE_PENDING_TICKETS':
      return { ...state, pendingTickets: action.tickets };
    case 'UPDATE_EDUCATION':
      return { ...state, education: action.result };
    case 'UPDATE_COURSES':
      return { ...state, courses: action.courses, courseMap: _.keyBy(action.courses, '_id') };
    case 'ADD_ADMISSION':
      return { ...state, admissionRemoves: (state.admissionRemoves || []).filter(_id => _id !== action.favoriteId) };
    case 'REMOVE_ADMISSION':
      return { ...state, admissionRemoves: (state.admissionRemoves || []).concat([action.favoriteId]) };
    default:
      return state;
  }
};

export default appReducer;
