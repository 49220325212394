/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { callAPI, isCitizenId, isGNumber } from 'helpers';
import cn from 'classnames';
import moment from 'moment';

// Components
import { Link, useNavigate } from 'react-router-dom';
import ExamSlotInfo from 'components/profile/apply-exams/ExamSlotInfo';
import ExamSiteInfo from 'components/profile/apply-exams/ExamSiteInfo';
import ExamSteps from 'components/profile/apply-exams/ExamSteps';
import ModalPassportTgat from 'components/profile/apply-exams/ModalPassportTgat';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Data
import { TGATTPATINFOS } from 'data/exam-days';
import enabled from 'data/enabled.json';

const getProvince = (province) => (['นนทบุรี', 'สมุทรปราการ', 'กรุงเทพมหานคร'].includes(province) ? 'กรุงเทพและปริมณฑล' : province);

const TGATTPATSummary = () => {
  const profile = useSelector((state) => _.get(state, 'app.user') || {});
  const navigate = useNavigate();

  const [consentVisible, setConsentVisible] = useState(false);
  useEffect(() => {
    if (_.get(profile, 'consents_accepted_at.apply_exams_tgattpat')) {
      setConsentVisible(true);
    }
    if (!profile.picture) { navigate('/profile') }
    if (!profile.program_code) { navigate('/profile') }
    if (profile.exam_4y_alleged) { navigate('/profile/apply-exams/tgattpat/veto', { replace: true }); }
  }, [profile, navigate]);

  const [accepting, setAccepting] = useState(false);
  const _onAcceptConsent = async (consentType) => {
    if (accepting) return;
    try {
      setAccepting(true);
      await callAPI({
        method: 'POST',
        url: '/applicants/me/consents',
        body: {
          consentType,
        },
      });
      setAccepting(false);
    } catch (error) {
      console.error(`Error while trying to accept consent: ${error.message}`);
      setAccepting(false);
    }
    setConsentVisible(false);
  };

  return (
    <main className='site-body'>
      <div className='t-loading' />
      {consentVisible ? <SectionConsent onSubmit={_onAcceptConsent} /> : <SectionSummary />}
    </main>
  );
};

export default TGATTPATSummary;

const SectionConsent = ({ onSubmit }) => {
  const { t } = useTranslation();
  return (
    <div className='t-form'>
      <div className='t-box'>
        <h1>{t('ข้อกำหนดและเงื่อนไข')}</h1>
        <p>
          1. {t('เลือกวิชาที่ต้องการสอบทั้งหมด พร้อมสนามสอบ ของแต่ละวันสอบโดยเรียงลำดับสนามสอบที่ต้องการ วันละ ไม่เกิน 5 ลำดับ')} <br />
          2. {t('กดยืนยันการเลือกวิชาสอบและสนามสอบ โดยยืนยัน รหัส 0TP ผ่านช่องทาง SMS หรืออีเมล พร้อมทั้ง ชำระเงินค่าสมัครสอบให้ครบถ้วน')}​ <br />
          3. {t('เมื่อสมัครเสร็จสมบูรณ์แล้ว จะไม่สามารถยกเลิกรายวิชาสอบที่สมัครไว้ได้ ยกเว้นรายวิชา A-Level ภาษาต่างประเทศ')} <br />
          4. {t('กรณีต้องการเพิ่มรายวิชาสอบ สามารถดำเนินการได้โดยเลือก “เพิ่มรายวิชาสอบ” และดำเนินการตามกระบวนการสมัครจนเสร็จสิ้น')} <br />
          5. {t('กรณีต้องการเปลี่ยนแปลงการเลือกสนามสอบ สามารถดำเนินการได้ไม่เกิน 3 ครั้ง โดยระบบจะใช้สนามสอบที่เลือกครั้งสุดท้ายในการประมวลผล')}
          <br />
          6. {t('ทั้งนี้ หากมีการเพิ่มวิชาสอบที่ยังไม่ชำระเงิน จะไม่สามารถเปลี่ยนแปลงสนามสอบได้ จนกว่าจะชำระเงินแล้ว')}
        </p>
        <p>*{t('ขอสงวนสิทธิ์ในการคืนเงินคำสมัครสอบทุกกรณี')}*</p>

        <div className='content-bottom'>
          <Link to='/profile' className='btn-cancel'>
            <span className='icon'>×</span>
            {t('ยกเลิก')}
          </Link>
          <a className='btn-main btn-noti cursor-pointer' onClick={onSubmit}>
            รับทราบ
          </a>
        </div>
      </div>
    </div>
  );
};

const SectionSummary = () => {
  const { t } = useTranslation();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selections, setSelections] = useState([]);
  const [historyLogs, setHistoryLogs] = useState([]);
  const [recentConfirmations, setRecentConfirmations] = useState([]);
  const [siteMap, setSiteMap] = useState({});
  const [expired, setExpired] = useState(false);
  
  const [passportVisible, setPassportVisible] = useState(false);
  useEffect(() => {
    if (!isCitizenId(profile.citizen_id) && !isGNumber(profile.citizen_id)) {
      if (!_.get(profile, 'consents_accepted_at.passport_tgat_2568')) {
        setPassportVisible(true);
      }
    }
  }, [profile, navigate]);

  const [accepting, setAccepting] = useState(false);
  const _onAcceptConsent = async (consentType) => {
    if (accepting) return;
    try {
      setAccepting(true);
      await callAPI({
        method: 'POST',
        url: '/applicants/me/consents',
        body: {
          consentType,
        },
      });
      setAccepting(false);
    } catch (error) {
      console.error(`Error while trying to accept consent: ${error.message}`);
      setAccepting(false);
    }
    setPassportVisible(false);
  };

  // Initial fetch
  useEffect(() => {
    const fetchSummary = async () => {
      try {
        setLoading(true);
        const { result } = await callAPI({ url: '/applicants/me/apply-exam-summary/tgattpat' });
        setSelections(result.selections);
        setHistoryLogs(result.history_logs);
        const _expired = moment(result.timestamp).isAfter(enabled.tgattpat_apply_exams.expired);
        setExpired(_expired);

        if (_expired) {
          const confirmationGroups = _.groupBy(result.history_logs.filter(({ order_status }) => order_status === 'paid'), 'day');
          setRecentConfirmations(Object.keys(confirmationGroups)
            .reduce((array, key) => array.concat(_.last(confirmationGroups[key])), []));
        } else {
          setRecentConfirmations(result.recent_confirmations);
        }

        setSiteMap(_.keyBy(result.exam_sites, ({ exam_type, site_code }) => `${exam_type}-${site_code}`));
        setLoading(false);
      } catch (error) {
        console.log({ error });
        setLoading(false);
      }
    };
    fetchSummary();
  }, []);

  // Cancel selection
  const [cancelVisible, setCancelVisible] = useState(false);
  const [canceling, setCanceling] = useState(false);
  const _onSelectionsCancel = async () => {
    if (canceling) return;
    try {
      setCanceling(true);
      const { result } = await callAPI({
        method: 'DELETE',
        url: '/applicants/me/exam-selections/tgattpat',
      });
      setSelections(result);
      setCanceling(false);
    } catch (error) {
      console.log({ error });
      setCanceling(false);
    }
  };

  // Open summary PDF
  const [opening, setOpening] = useState(false);
  const _onPdfOpen = async () => {
    if (opening) return;
    try {
      setOpening(true);
      const ua = window.navigator.userAgent;
      const { pdf } = await callAPI({
        genPdf: true,
        method: 'POST',
        url: '/apply-exams/tgattpat-summary-pdf',
      });

      if (ua.includes('Safari')) {
        window.location.href = pdf;
      } else {
        window.open(pdf, '_blank');
      }

      setTimeout(() => setOpening(false), 1000);
    } catch (error) {
      console.error(`Error while trying to download summary pdf: ${error.message}`);
      setOpening(false);
    }
  };

  useEffect(() => {
    const classNames = document.getElementById('root').className.split(' ');
    if (opening) {
      document.getElementById('root').className = _.uniq(classNames.concat(['is-loading'])).join(' ');
    } else {
      document.getElementById('root').className = classNames.filter((name) => name !== 'is-loading').join(' ');
    }
  }, [opening]);

  const _hasSitesChanged = (confirmation, selection) => {
    if (!confirmation && selection) return true;
    return Array(5)
      .fill()
      .reduce((array, __, i) => array.concat([`place_${i + 1}`, `cbt_place_${i + 1}`, 'province_confirmed']), [])
      .some((field) => {
        if (field === 'province_confirmed') return Boolean(confirmation.province_confirmed) !== Boolean(selection.province_confirmed);
        return confirmation[field] !== selection[field]
      });
  };

  const tgatRedeemed = historyLogs.some(({ tgat_redeemed }) => tgat_redeemed);
  const d13SubjectCount = _.sumBy(recentConfirmations.filter(({ day }) => day !== '2'), ({ subjects = [] }) => subjects.length);
  const d2SubjectCount = _.uniq((_.get(_.find(recentConfirmations, ({ day }) => day === '2'), 'subjects') || []).concat(tgatRedeemed ? ['TGAT'] : [])).length;
  const confirmedSubjectCount = d13SubjectCount + d2SubjectCount;

  const totalSubjects = _.uniq(selections.reduce((total, { subjects = [] }) => total.concat(subjects), []));
  const totalSubjectCount = totalSubjects.length;
  const paidAmount = _.sumBy(recentConfirmations, ({ order_status, payment_amount, total_amount }) => {
    if (order_status === 'paid') return total_amount;
    return total_amount - payment_amount;
  });
  const paymentAmount = (140 * totalSubjectCount) - paidAmount;

  if (!expired && historyLogs.some(({ order_status }) => order_status !== 'paid')) {
    return <PendingState
      historyLogs={historyLogs}
      recentConfirmations={recentConfirmations}
      totalSubjectCount={totalSubjectCount}
      paymentAmount={paymentAmount}
      siteMap={siteMap} />;
  }

  const currentApplyId = _.max([_.get(_.last(recentConfirmations), 'apply_id'), _.get(_.find(historyLogs, ({ tgat_redeemed }) => tgat_redeemed), 'apply_id'), 0]);
  const nextApplyId = currentApplyId + 1;
  const quotaUsed = _.get(_.maxBy(recentConfirmations, 'site_confirm_id'), 'site_confirm_id') || 0;
  
  const someSitesChanged = recentConfirmations.some((confirmation) =>
    _hasSitesChanged(confirmation, _.find(selections, ({ day }) => confirmation.day === day))
  );
  const allPaid = recentConfirmations.length && recentConfirmations.every((con) => con.order_status === 'paid') && !paymentAmount;
  const logsByKey = _.groupBy(historyLogs, ({ apply_id, site_confirm_id }) => `${apply_id}-${site_confirm_id}`);
  
  const [, s1] = (_.get(_.reverse(Object.keys(logsByKey)), 0) || '').split('-');
  const [, s2] = (_.get(_.reverse(Object.keys(logsByKey)), 1) || '').split('-');
  const sitesChangedSuccess = s1 - s2 === 1;
  
  return (
    <div className='t-form'>
      <h1>{t('รายละเอียดการสมัครสอบ')}</h1>
      {expired ? null : someSitesChanged ? (
        <>
          <ExamSteps someSitesChanged activeIndex={3} />
          <div className='t-box -noti text-center -border'>
            {t('แก้ไขสนามสอบ')}
            <br />
            <div className='color-orange _heading'>
              <b>
                {t('สถานะ')} : {t('กรุณายืนยันแก้ไขสนามสอบ')}
              </b>
            </div>
            <span>{t('กรุณายืนยันตามรายละเอียดด้านล่าง')}</span>
          </div>
        </>
      ) : sitesChangedSuccess && !paymentAmount ? (
        <>
          <ExamSteps activeIndex={5} />
          <div className='t-box -border -success'>
            <div className='text-center'>
              {t('แก้ไขสนามสอบเรียบร้อยแล้ว')}
              <div className='color-green _heading'>
                <b>
                  {t('สถานะ')} : {t('ชำระเงินเรียบร้อยแล้ว')}
                </b>
              </div>
              <span>{t('รอประกาศผลที่นั่งสอบวันที่ 25 พ.ย. 67')}</span>
            </div>
          </div>
        </>
      ) : allPaid ? (
        <>
          <ExamSteps activeIndex={5} />
          <div className='t-box -border -success'>
            <div className='text-center'>
              {t('ยืนยันการสมัครเรียบร้อยแล้ว')}
              <div className='color-green _heading'>
                <b>
                  {t('สถานะ')} : {t('ชำระเงินเรียบร้อยแล้ว')}
                </b>
              </div>
              <span>{t('รอประกาศผลที่นั่งสอบวันที่ 25 พ.ย. 67')}</span>
            </div>
          </div>
        </>
      ) : totalSubjectCount !== confirmedSubjectCount ? (
        <>
          <ExamSteps activeIndex={2} />
          <div className='t-box text-center color-blue -border -noti'>
            {t('เลือกวิชาเรียบร้อยแล้ว')}
            <div className='text-center'>
              <h4 className='color-orange m-0'>{t('สถานะ : รอยืนยันการสมัคร')}</h4>
            </div>
            <span>{t('กรุณายืนยันตามรายละเอียดด้านล่าง')}</span>
          </div>
        </>
      ) : null}
      <div className='exam-box -info'>
        <div className='exam-box-header'>
          <div className='s-flex -between'>
            <span className='_heading'>
              <b>{t('สมัครสอบ')}</b>
            </span>
            {expired ? null : someSitesChanged ? (
              <span className='_heading'>
                {t('แก้ไขสนามสอบครั้งที่')} {quotaUsed + 1}
              </span>
            ) : (
              <span className='_heading'>
                {t('สมัครครั้งที่')} {!confirmedSubjectCount || confirmedSubjectCount !== totalSubjectCount ? nextApplyId : currentApplyId}
              </span>
            )}
          </div>
        </div>
        <div className='exam-box-body'>
          {!(selections.length || []) && <div className='_heading text-center'>{t('กรุณาเลือกวิชาสอบและสนามสอบที่ต้องการสมัคร')}</div>}
          <h4 className='mt-0 _gap'>
            <b>TGAT/TPAT2-5</b>
            {profile.special_request && (
              <div>
                <img src='/assets/img/i/i-disability-orange.svg' alt='disability' width='24' height='24' />
                <b style={{ verticalAlign: 'top', lineHeight: '24px' }} className='color-orange'>
                  {profile.special_request}
                </b>
              </div>
            )}
          </h4>
          {TGATTPATINFOS.map(({ dayIndex, date, colorClassName }, index) => {
            const selection = _.find(selections, ({ day }) => `${day}` === `${dayIndex}`);
            const totalSubjects = _.get(selection, 'subjects') || [];
            const confirmation = _.find(recentConfirmations, ({ day }) => `${day}` === `${dayIndex}`);
            const tgat_redeemed = historyLogs.some(({ tgat_redeemed }) => tgat_redeemed);
            let confirmedSubjects = (_.get(confirmation, 'subjects') || []);
            if (dayIndex === 2 && tgat_redeemed && !confirmedSubjects.includes('TGAT')) { confirmedSubjects = confirmedSubjects.concat('TGAT'); }

            return (
              <>
                {Boolean(index) && <div className='_space_0' />}
                <div key={dayIndex} className={cn('exam-box', { [colorClassName]: true })}>
                  <div className='exam-box-header'>
                    <div className='s-flex -between'>
                      <div className='_heading'>
                        <span className='num'>{dayIndex}</span>
                        <b>{date}</b>
                      </div>
                      {(!expired || confirmedSubjects.length > 0) && <IconArrow to={`/profile/apply-exams/tgattpat/${dayIndex}`} />}
                    </div>
                  </div>
                  {((confirmation && confirmation.order_status === 'paid' && totalSubjects.length === confirmedSubjects.length) || (expired && (confirmedSubjects.length > 0))) ? (
                    <div className='exam-box-body color-blue _heading'>
                      <div className='s-flex -between'>
                        <div>
                          {t('สมัครสอบ')}{' '}
                          <b>
                            {t('จำนวน')} {confirmedSubjects.length} {t('วิชา')}
                          </b>
                        </div>
                        <b className='color-green'>{t('ชำระเงินเรียบร้อยแล้ว')}</b>
                      </div>
                      {!expired && _hasSitesChanged(confirmation, selection) && (
                        <>
                          <hr className='hr-dashed' />
                          <div className='s-flex -center'>
                            <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
                              <path
                                d='M18.5088 3.68992C18.3853 3.70067 18.2617 3.74363 18.1579 3.81883L14.2083 6.69236V10.2856C14.3659 10.3304 14.5288 10.3769 14.6935 10.4253C15.0659 10.5327 15.4043 10.7332 15.6764 11.0071L19.0996 14.4267C19.1945 14.3068 19.25 14.1582 19.25 14.0006V4.37563C19.25 4.11782 19.105 3.8797 18.8758 3.76333C18.7594 3.70425 18.6323 3.67918 18.5088 3.68992ZM9.16667 4.02472V15.3488L12.8333 18.0164V16.5877L11.4655 15.2181C11.1916 14.9477 10.991 14.6076 10.8836 14.237C10.5148 12.9533 10.189 11.7949 10.189 11.7949C10.112 11.5944 10.078 11.3831 10.0833 11.1665C10.0977 10.7601 10.2642 10.3787 10.5506 10.0923C10.8389 9.80581 11.2166 9.64109 11.6213 9.62498C11.8325 9.61782 12.0509 9.65184 12.2533 9.72882C12.2533 9.72882 12.4753 9.79149 12.8333 9.89354V6.69057L9.16667 4.02472ZM7.79167 4.02472L3.03288 7.4855C2.85384 7.6144 2.75 7.82208 2.75 8.0423V17.6673C2.75 17.9251 2.89502 18.1614 3.12598 18.2796C3.22445 18.3297 3.33008 18.3548 3.4375 18.3548C3.58073 18.3548 3.72217 18.31 3.84212 18.2241L7.79167 15.3506V4.02472ZM11.6553 10.5416C11.4888 10.5488 11.3258 10.6133 11.1987 10.7404C11.0716 10.8675 11.0054 11.0322 11 11.1987C10.9964 11.29 11.0125 11.3831 11.0448 11.4708L11.7645 13.9809C11.8289 14.2029 11.9489 14.407 12.1118 14.5718L17.5205 19.9787C17.8481 20.3063 18.2349 20.5176 18.6395 20.5892C18.7666 20.6142 18.8937 20.625 19.0208 20.625C19.5669 20.6268 20.0843 20.4137 20.4782 20.0198C20.8721 19.626 21.0833 19.1085 21.0833 18.5625C21.0815 18.4354 21.0708 18.3083 21.0475 18.1811C20.9759 17.7765 20.7646 17.3898 20.437 17.0622L15.0283 11.6535C14.8636 11.4905 14.6613 11.3706 14.4393 11.3061L11.9292 10.5864C11.8415 10.5524 11.7466 10.5381 11.6553 10.5416Z'
                                fill='#FF6600'
                              ></path>
                            </svg>
                            <b className='color-orange'>{t('รอยืนยันแก้ไขสนามสอบ')}</b>
                          </div>
                        </>
                      )}
                    </div>
                  ) : (totalSubjects.length !== confirmedSubjects.length && !expired) ? (
                    <div className='exam-box-body color-blue _heading'>
                      <div className='s-flex -between'>
                        <div>
                          {t('สมัครสอบ')}{' '}
                          <b>
                            {t('จำนวน')} {(selection.subjects || []).length} {t('วิชา')}
                          </b>
                        </div>
                        <b className='color-orange'>{t('รอยืนยันการสมัครสอบ')}</b>
                      </div>
                    </div>
                  ) : (
                    <div className='exam-box-body'>
                      <span className='color-blue _heading'>{expired ? t('ไม่มีรายการสมัครสอบ') : t('เลือกวิชาสอบและสนามสอบ')}</span>
                    </div>
                  )}
                </div>
              </>
            );
          })}
          {expired ? null : someSitesChanged && (
            <>
              <div className='_space' />
              <h4 className='text-center color-blue'>
                {t('ผู้สมัครสามารถแก้ไขสนามสอบทั้งหมดได้ 3 ครั้ง')}
                <br />
                {t('โดยนับจากการยืนยัน OTP')}
              </h4>
              <div className='i-hearts'>
                <i className={cn('i-heart', { '-blink': quotaUsed === 2, '-used': quotaUsed > 2 })} />
                <i className={cn('i-heart', { '-blink': quotaUsed === 1, '-used': quotaUsed > 1 })} />
                <i className={cn('i-heart', { '-blink': quotaUsed === 0, '-used': quotaUsed > 0 })} />
                <small>
                  {t('สามารถตัดสินใจได้อีก')} {3 - quotaUsed} {t('ครั้ง')}
                </small>
              </div>
              <div className='text-center'>
                <Link to='/profile/apply-exams/tgattpat/update-sites' className='btn-icon'>
                  <span className='icon'>
                    <i className='i-heart'></i>
                    <small>-1</small>
                  </span>
                  <b className='text'>
                    {t('ยืนยันแก้ไขสนามสอบ')} {t('ครั้งที่')} {quotaUsed + 1}/3
                  </b>
                </Link>
              </div>
              <div className={cn('r-more', { active: cancelVisible })}>
                <h4 className='text-center color-orange' onClick={() => setCancelVisible((_visible) => !_visible)}>
                  {t('ยกเลิกการแก้ไข')}? <i className='i-more' />
                </h4>
                <div className='more'>
                  <div className='text-center'>
                    {t('หากต้องการกลับไปที่การสมัครครั้งที่ผ่านมา')} <br />
                    {t('ให้คลิกเลือกยกเลิกการแก้ไข')}
                  </div>
                  <div className='_space' />
                  <div className='action'>
                    <a className='btn-eaxm-cancel cursor-pointer _heading' onClick={_onSelectionsCancel}>
                      {t('ยกเลิกการแก้ไข')}
                    </a>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {((!expired && someSitesChanged) || (expired && !confirmedSubjectCount && !tgatRedeemed)) ? null : (expired || allPaid || tgatRedeemed) ? (
        <>
          <div className='_space' />
          <div className='exam-box -info'>
            <div className='exam-box-header'>
              <div className='_heading'>{t('สรุปการสมัครสอบ')}</div>
            </div>
            <div className='exam-box-body'>
              <ul className='exam-summary _heading'>
                <li>
                  <dl>
                    <dt>
                      <b>{t('วิชาสอบที่เลือกทั้งหมด')}</b>
                    </dt>
                    <dd>
                      <b>{confirmedSubjectCount}</b> วิชา
                    </dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt className='color-green'>
                      <b>{t('ไม่มียอดค้างชำระ')}</b>
                    </dt>
                  </dl>
                </li>
              </ul>
              <div className='_space' />
              <div className='note text-center'>
                {t('ผู้สมัครได้ทำการสมัครครั้งที่')} {currentApplyId} {t('และชำระเงินเรียบร้อยแล้ว')}
                <br />
                {t('หลังจากนี้รอประกาศผลการเลือกสนามสอบ')}
                {t('และพิมพ์ใบที่นั่งสอบรายวิชา วันที่ 25 พ.ย. - 9 ธ.ค. 67')}
              </div>
              <div className='text-center'>
                <h4 className='color-green'>{t('สถานะ : ชำระเงินเรียบร้อยแล้ว')}</h4>
              </div>
              <div className='action'>
                <a className='btn-main cursor-pointer' onClick={_onPdfOpen}>
                  {t('พิมพ์ใบสมัครสอบ')}
                </a>
              </div>
            </div>
          </div>
        </>
      ) : selections.length ? (
        <>
          <div className='_space' />
          <div className='exam-box -info'>
            <div className='exam-box-header'>
              <div className='_heading'>{t('สรุปการสมัครสอบ')}</div>
            </div>
            <div className='exam-box-body'>
              <ul className='exam-summary _heading'>
                <li>
                  <dl>
                    <dt>
                      <b>{t('วิชาสอบที่เลือกทั้งหมด')}</b>
                    </dt>
                    <dd>
                      <b>{totalSubjectCount}</b> วิชา
                    </dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt className='color-red'>
                      <b>{t('ยอดค้างชำระเงิน')}</b>
                    </dt>
                    <dd>
                      <b>{paymentAmount.toFixed(2)}</b> {t('บาท')}
                    </dd>
                  </dl>
                </li>
              </ul>
              <div className='_space'></div>
              <p className='color-gray text-center'>
                {t('การสมัครนี้จะสมบูรณ์ เมื่อท่านกดยืนยันการสมัคร และ ได้ชำระเงินครบถ้วนเแล้ว')}
                <br />
                <strong className='color-orange'>{t('เมื่อยืนยัน OTP แล้ว จะไม่สามารถยกเลิกหรือแก้ไขได้จนกว่าจะชำระเงินให้เรียบร้อย')}</strong>
              </p>

              <p className='text-center'>
                <Link to='/profile/apply-exams/tgattpat/confirmation' className='btn-main'>
                  {t('ยืนยันการสมัครสอบ ครั้งที่')} {nextApplyId}
                </Link>
                {Boolean(currentApplyId) && (
                  <h4 className='cursor-pointer color-gray' onClick={_onPdfOpen}>
                    {t('พิมพ์ใบสรุปการสมัคร')}
                  </h4>
                )}
              </p>
            </div>
          </div>
        </>
      ) : null}
      <SectionHistory historyLogs={historyLogs} siteMap={siteMap} />
      <ModalPassportTgat
        visible={passportVisible}
        onClick={() => _onAcceptConsent('passport_tgat_2568')} />
    </div>
  );
};

const PendingState = ({ historyLogs, recentConfirmations, totalSubjectCount, paymentAmount, siteMap }) => {
  const { t } = useTranslation();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});

  const tgatRedeemed = historyLogs.some(({ tgat_redeemed }) => tgat_redeemed);
  const totalAmount = _.sumBy(recentConfirmations, ({ total_amount }) => total_amount) - paymentAmount;

  const [opening, setOpening] = useState(false);
  const _onPdfOpen = async () => {
    if (opening) return;
    try {
      setOpening(true);
      const ua = window.navigator.userAgent;
      const { pdf } = await callAPI({
        genPdf: true,
        method: 'POST',
        url: '/apply-exams/invoice-pdf',
      });

      if (ua.includes('Safari')) {
        window.location.href = pdf;
      } else {
        window.open(pdf, '_blank');
      }

      setTimeout(() => setOpening(false), 1000);
    } catch (error) {
      console.error(`Error while trying to download invoice pdf: ${error.message}`);
      setOpening(false);
    }
  };

  useEffect(() => {
    const classNames = document.getElementById('root').className.split(' ');
    if (opening) {
      document.getElementById('root').className = _.uniq(classNames.concat(['is-loading'])).join(' ');
    } else {
      document.getElementById('root').className = classNames.filter((name) => name !== 'is-loading').join(' ');
    }
  }, [opening]);


  const currentApplyId = _.max([_.get(_.last(recentConfirmations), 'apply_id'), _.get(_.find(historyLogs, ({ tgat_redeemed }) => tgat_redeemed), 'apply_id'), 0]);
  return (
    <div className='t-form'>
      <ExamSteps activeIndex={4} />
      <div className='t-box -noti'>
        <div className='color-blue text-center'>
          {t('ยืนยันการสมัครเรียบร้อยแล้ว')}
          <div className='text-center'>
            <h4 className='color-red m-0'>{t('สถานะ : รอการชำระเงิน')}</h4>
          </div>
          <span>{t('กรุณายืนยันตามรายละเอียดด้านล่าง')}</span>
        </div>
      </div>
      <h1>{t('รายละเอียดการสมัครสอบ')}</h1>
      <div className='exam-box exam-choose-step -info'>
        <div className='exam-box-header'>
          <div className='s-flex -between'>
            <span className='_heading'>
              <b>{t('สมัครสอบ')}</b>
            </span>
            <span className='_heading'>
              {t('สมัครครั้งที่')} {currentApplyId}
            </span>
          </div>
        </div>
        <div className='exam-box-body'>
          <h4 className='mt-0 _gap'>
            <b>TGAT/TPAT2-5</b>
            {profile.special_request && (
              <div>
                <img src='/assets/img/i/i-disability-orange.svg' alt='disability' width='24' height='24' />
                <b style={{ verticalAlign: 'top', lineHeight: '24px' }} className='color-orange'>
                  {profile.special_request}
                </b>
              </div>
            )}
          </h4>
          {TGATTPATINFOS.map(({ dayIndex, date, colorClassName, examSlots }, index) => {
            const confirmation = _.find(recentConfirmations, ({ day }) => `${day}` === `${dayIndex}`);
            if (!confirmation) return null;
            return (
              <div key={index} className={cn('exam-box', { [colorClassName]: true })}>
                <div className='exam-box-header'>
                  <div className='_heading'>
                    <span className='num'>{dayIndex}</span>
                    <b>{date}</b>
                  </div>
                </div>
                <ul className='exam-box-list'>
                  {examSlots
                    .filter(({ subjectKey }) => confirmation.subjects.includes(subjectKey))
                    .map((slot, slotIndex) => (
                      <ExamSlotInfo
                        key={slotIndex}
                        showPaymentStatus
                        examType={confirmation.exam_type}
                        specialRequests={confirmation.special_requests}
                        confirmations={historyLogs}
                        slot={slot} />
                    ))}
                  <ExamSiteInfo examType={confirmation.exam_type} data={confirmation} siteMap={siteMap} />
                </ul>
              </div>
            );
          })}
          <div className='_space' />
          <ul className='exam-summary _heading'>
            <li>
              <dl>
                <dt>
                  <b>{t('วิชาสอบที่เลือกทั้งหมด')}</b>
                </dt>
                <dd>
                  <b>{totalSubjectCount}</b> {t('วิชา')}
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>
                  <b>{t('ยอดชำระเงินเรียบร้อยแล้ว')}</b>
                </dt>
                <dd>
                  <b>{(totalAmount + (tgatRedeemed ? 140 : 0)).toFixed(2)}</b> {t('บาท')}
                </dd>
              </dl>
            </li>
            <li>
              <dl className='color-red'>
                <dt>
                  <b>{t('ยอดค้างชำระทั้งหมด')}</b>
                </dt>
                <dd>
                  <b>{(paymentAmount - (tgatRedeemed ? 140 : 0)).toFixed(2)}</b> {t('บาท')}
                </dd>
              </dl>
            </li>
          </ul>
          <div className='_space'></div>
          <div className='note t-small'>
            {t('สามารถชำระเงินผ่าน QR Code ทุกธนาคาร หรือ ชำระเงินผ่านเคาน์เตอร์เซอร์วิส (เซเว่น อีเลฟเว่น) ได้')}
            <p />
            <div className='s-flex -gap'>
              <div>
                <img alt='Banks' src='/assets/img/logo/banks.png' width='328' />
              </div>
              <div>
                <img alt='CS' src='/assets/img/logo/cs-7-11.png' width='95' />
              </div>
            </div>
            <p>
              {t('เมื่อคลิกปุ่มชำระเงิน ระบบจะสร้างใบแจ้งชำระเงินใน รูปแบบ PDF')}
              {t('ให้ท่านพิมพ์หรือจับภาพหน้าจอเพื่อนำไป ชำระเงิน โดยให้ชำระเงิน')}
              {t('ภายในเวลา 23.29 น. ของ วันที่ 5 พ.ย. 67')}
            </p>
            <b>{t('หมายเหตุ : การสมัครนี้จะสมบูรณ์ เมื่อท่านกดยืนยัน และชำระเงินในระบบครบถ้วนแล้ว')}</b>
          </div>

          <div className='text-center'>
            <h4 className='color-red'>{t('สถานะ : รอการชำระเงิน')}</h4>
          </div>

          <div className='action'>
            <a className={cn('btn-main cursor-pointer', { loading: opening })} onClick={_onPdfOpen}>
              {t('พิมพ์ใบแจ้งชำระเงิน')}
            </a>
          </div>
        </div>
      </div>
      <SectionHistory historyLogs={historyLogs} siteMap={siteMap} />
    </div>
  );
};

const SectionHistory = ({ historyLogs = [], siteMap = {} }) => {
  const { t } = useTranslation();
  const [active, setActive] = useState(false);
  const [currentTab, setCurrentTab] = useState('register');

  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setLoading(true);
        const { result } = await callAPI({ url: '/applicants/me/apply-exam-orders/tgattpat' });
        setOrders(result.slice(0, 1).concat(result.slice(1).filter(({ status }) => status !== 'canceled')));
        setLoading(false);
      } catch (error) {
        console.log({ error });
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  if (loading || !historyLogs.length) return null;
  const logsByKey = _.groupBy(historyLogs, ({ apply_id, site_confirm_id = 0 }) => `${apply_id}-${site_confirm_id}`);

  return (
    <>
      <div className='_space'></div>
      <div className={cn('r-more', { active })}>
        <h4 className='text-center' onClick={() => setActive((_active) => !_active)}>
          {t('ประวัติการดำเนินการ')} <i className='i-more'></i>
        </h4>
        <div className='more _heading'>
          <div className='t-tabs -exam'>
            <ul className='t-tab-nav'>
              <li className={cn('t-nav-item', { active: currentTab === 'register' })} data-tab='register' onClick={() => setCurrentTab('register')}>
                {t('การสมัคร')}
              </li>
              <li className={cn('t-nav-item', { active: currentTab === 'payment' })} data-tab='payment' onClick={() => setCurrentTab('payment')}>
                {t('การชำระเงิน')}
              </li>
            </ul>
            <div id='register' className={cn('t-tab-content', { active: currentTab === 'register' })}>
              {Object.keys(logsByKey).sort((a, b) => b.localeCompare(a)).map((key, index, all) => {
                const [applyId, siteConfirmId] = key.split('-');
                const confirmations = logsByKey[key];
                const subjectCount = _.sumBy(confirmations, ({ subjects }) => subjects.length);
                const timestamp = _.get(confirmations, '0.created_at');

                let title = `${t('สมัครครั้งที่')} ${applyId}`;
                if (index + 1 < all.length) {
                  const [prevApplyId, prevSiteConfirmId] = all[index + 1].split('-');
                  if (prevApplyId === applyId) {
                    if (prevSiteConfirmId !== siteConfirmId) {
                      title = `${t('แก้ไขสนามสอบครั้งที่')} ${siteConfirmId}`
                    }
                  }
                }

                return (
                  <>
                    {Boolean(index)}
                    <div key={index} className='card-log'>
                      <h4 className='m-0'>
                        <strong>{title}</strong>
                      </h4>
                      <ul>
                        <li className='color-blue'>
                          {t('จำนวนวิชาสอบ')} : {subjectCount} {t('วิชา')}
                        </li>
                      </ul>
                      {TGATTPATINFOS.map(({ dayIndex, date, colorClassName, examSlots }, index) => {
                        const confirmation = _.find(confirmations, ({ day }) => `${day}` === `${dayIndex}`);
                        if (!confirmation) return null;
                        return (
                          <>
                            {examSlots
                              .filter(({ subjectKey }) => confirmation.subjects.includes(subjectKey))
                              .map((slot, slotIndex) => {
                                const specialRequest =  _.get(confirmation, `special_requests.${slot.subjectKey}`);
                                return (
                                  <div key={slotIndex}>
                                    {t('รหัสวิชา')} <b>{slot.subjectCode}</b> {t('ชื่อวิชา')}{' '}
                                    <b>
                                      {slot.subjectKey} {slot.subjectName}
                                    </b>
                                    <br />
                                    {t('เวลา')} <b>{slot.time}</b>
                                    <br />
                                    {t('ประเภทข้อสอบ')}
                                    {confirmation.exam_type === 'pbt' ? (
                                      <>
                                        <i className='i-paper' /> <b>{t('สนามสอบด้วยกระดาษ')}</b>
                                      </>
                                    ) : (
                                      <>
                                        <i className='i-computer' /> <b>{t('สนามสอบคอมพิวเตอร์')}</b>
                                      </>
                                    )}
                                    {Boolean(specialRequest) && (
                                      <>
                                        <div className='_heading color-blue'>
                                          <b>{t('ความช่วยเหลือพิเศษ')}</b>
                                        </div>
                                        <div className='col'>
                                          <img src='/assets/img/i/i-disability.svg' alt='' />
                                          {specialRequest}
                                        </div>
                                      </>
                                    )}
                                    {confirmation.exam_type === 'cbt' && (
                                      <>
                                        <div className='_heading color-blue'>
                                          <b>{t('สนามสอบคอมพิวเตอร์')}</b>
                                        </div>
                                        <ul className='exam-venues'>
                                          {confirmation.province_confirmed && (
                                            <li className='color-orange'>
                                              {t('เลือกสนามสอบใน จังหวัด')}{getProvince(_.get(siteMap, `pbt-${confirmation.place_1}.province`))} {t('เป็นหลัก')}
                                            </li>
                                          )}
                                          <li>
                                            {t('ลำดับที่')} 1{' '}
                                            <div className='col'>
                                              {_.get(siteMap, `cbt-${confirmation.cbt_place_1}.school_name`) || '-'} {_.get(siteMap, `cbt-${confirmation.cbt_place_1}.province`)}
                                            </div>
                                          </li>
                                          <li>
                                            {t('ลำดับที่')} 2{' '}
                                            <div className='col'>
                                              {_.get(siteMap, `cbt-${confirmation.cbt_place_2}.school_name`) || '-'} {_.get(siteMap, `cbt-${confirmation.cbt_place_2}.province`)}
                                            </div>
                                          </li>
                                          <li>
                                            {t('ลำดับที่')} 3{' '}
                                            <div className='col'>
                                              {_.get(siteMap, `cbt-${confirmation.cbt_place_3}.school_name`) || '-'} {_.get(siteMap, `cbt-${confirmation.cbt_place_3}.province`)}
                                            </div>
                                          </li>
                                          <li>
                                            {t('ลำดับที่')} 4{' '}
                                            <div className='col'>
                                              {_.get(siteMap, `cbt-${confirmation.cbt_place_4}.school_name`) || '-'} {_.get(siteMap, `cbt-${confirmation.cbt_place_4}.province`)}
                                            </div>
                                          </li>
                                          <li>
                                            {t('ลำดับที่')} 5{' '}
                                            <div className='col'>
                                              {_.get(siteMap, `cbt-${confirmation.cbt_place_5}.school_name`) || '-'} {_.get(siteMap, `cbt-${confirmation.cbt_place_5}.province`)}
                                            </div>
                                          </li>
                                        </ul>
                                      </>
                                    )}
                                    <div className='_heading color-blue'>
                                      <b>{t('สนามสอบด้วยกระดาษ')}</b>
                                    </div>
                                    <ul className='exam-venues'>
                                      {confirmation.province_confirmed && (
                                        <li className='color-orange'>
                                          {t('เลือกสนามสอบใน จังหวัด')}{getProvince(_.get(siteMap, `pbt-${confirmation.place_1}.province`))} {t('เป็นหลัก')}
                                        </li>
                                      )}
                                      <li>
                                        {t('ลำดับที่')} 1{' '}
                                        <div className='col'>
                                          {_.get(siteMap, `pbt-${confirmation.place_1}.school_name`) || '-'} {_.get(siteMap, `pbt-${confirmation.place_1}.province`)}
                                        </div>
                                      </li>
                                      <li>
                                        {t('ลำดับที่')} 2{' '}
                                        <div className='col'>
                                          {_.get(siteMap, `pbt-${confirmation.place_2}.school_name`) || '-'} {_.get(siteMap, `pbt-${confirmation.place_2}.province`)}
                                        </div>
                                      </li>
                                      <li>
                                        {t('ลำดับที่')} 3{' '}
                                        <div className='col'>
                                          {_.get(siteMap, `pbt-${confirmation.place_3}.school_name`) || '-'} {_.get(siteMap, `pbt-${confirmation.place_3}.province`)}
                                        </div>
                                      </li>
                                      <li>
                                        {t('ลำดับที่')} 4{' '}
                                        <div className='col'>
                                          {_.get(siteMap, `pbt-${confirmation.place_4}.school_name`) || '-'} {_.get(siteMap, `pbt-${confirmation.place_4}.province`)}
                                        </div>
                                      </li>
                                      <li>
                                        {t('ลำดับที่')} 5{' '}
                                        <div className='col'>
                                          {_.get(siteMap, `pbt-${confirmation.place_5}.school_name`) || '-'} {_.get(siteMap, `pbt-${confirmation.place_5}.province`)}
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                );
                            })}
                          </>
                        );
                      })}
                      <ul className='color-blue'>
                        <li>
                          {t('ยอดชำระ')} : {_.sumBy(confirmations, 'payment_amount').toFixed(2)} {t('บาท')}
                        </li>
                      </ul>
                      <div className='datetime'>
                        <small className='color-gray'>
                          {moment(timestamp).format('DD MMM')} {moment(timestamp).add(543, 'years').format('YYYY HH:mm:ss น.')}
                        </small>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            <div id='payment' className={cn('t-tab-content', { active: currentTab === 'payment' })}>
              {(orders || []).map((order, index) => {
                const redeemed = _.get(order, 'apply_exams.tgat_redeemed');
                return (
                  <>
                    {Boolean(index)}
                    <div key={index} className='card-log'>
                      <h4>
                        <strong>
                          {t('ครั้งที่')} {orders.length - index}
                        </strong>
                      </h4>
                      <ul>
                        <li>
                          {t('ยอดชำระ')} : {order.amount.toFixed(2)} {t('บาท')}
                        </li>
                        <li>
                          {t('สถานะ')} : {order.status === 'paid'
                            ? <span className='color-green'>ชำระเงินเรียบร้อย{redeemed ? ' (ชำระโดยเงินสนับสนุนจากรัฐฯ 140 บาท)' : ''}</span>
                            : <span className='color-red'>{order.status === 'pending' ? t('รอการชำระเงิน') : t('ไม่ได้ชำระเงินในเวลาที่กำหนด')}</span>}
                        </li>
                      </ul>
                      <div className='datetime'>
                        {order.paid_at ? (
                          <small className='color-gray'>
                            {moment(order.paid_at).format('DD MMM')} {moment(order.paid_at).add(543, 'years').format('YYYY HH:mm:ss น.')}
                          </small>
                        ) : (
                          <small className='color-gray'>
                            {moment(order.created_at).format('DD MMM')} {moment(order.created_at).add(543, 'years').format('YYYY HH:mm:ss น.')}
                          </small>
                        )}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const IconArrow = ({ to }) => (
  <Link to={to}>
    <svg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#a)' stroke='#fff' strokeWidth='1.6' strokeLinecap='round' strokeLinejoin='round'>
        <path d='M10 18.333a8.333 8.333 0 1 0 0-16.666 8.333 8.333 0 0 0 0 16.666Z' />
        <path d='M10 13.333 13.333 10 10 6.667M6.667 10h6.666' />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M0 0h20v20H0z' />
        </clipPath>
      </defs>
    </svg>
  </Link>
);
