import { useEffect, useState } from 'react';
import _ from 'lodash';
import { callAPI, getNewestTicket } from 'helpers';
import { useDispatch } from 'react-redux';

const useSchoolSelect = (profile = {}, pendingTickets = []) => {
  const dispatch = useDispatch();
  const { valueOf } = getNewestTicket(pendingTickets, 'update_school');
  const schools = profile.schools || [];
  const maxGpaxSchool =
    _.maxBy(schools, (_school) => Math.max(_school.gpax5_score || 0, _school.gpax6_score || 0)) || {};

  const [schoolCode, setSchoolCode] = useState(
    valueOf('school_code') || profile.school_code || _.get(maxGpaxSchool, 'school_code')
  );
  const [selectedOption, setSelectedOption] = useState({
    label: valueOf('school_name') || profile.school_name || _.get(maxGpaxSchool, 'school_name'),
    value: valueOf('school_code') || profile.school_code || _.get(maxGpaxSchool, 'school_code'),
  });

  const [loading, setLoading] = useState(false);
  const [currentSchool, setCurrentSchool] = useState({});
  useEffect(() => {
    const getCurrentSchool = async (req, res) => {
      try {
        setLoading(true);
        const schoolCode = _.get(selectedOption, 'value');
        if (schoolCode) {
          const { data } = await callAPI({
            url: `/schools/${schoolCode}/info`,
          });
          if (data) {
            setCurrentSchool({
              school_amph_id: data.amph_id,
              school_amph_name: data.amph_name,
              school_code: data.code,
              school_name: data.name,
              school_province_id: data.province_id,
              school_province_name: data.province_name,
              school_tmbl_id: data.tmbl_id,
              school_tmbl_name: data.tmbl_name,
              school_zipcode: data.zipcode,
            });
          }
        }
        setLoading(false);
      } catch (error) {
        console.error(new Error(`Error while trying to get current school: ${error.message}`));
        setLoading(false);
      }
    };

    if (_.get(selectedOption, 'value') !== 'other') {
      getCurrentSchool();
    }
  }, [selectedOption]);

  return {
    schoolCode,
    onSchoolCodeChange: (code) => {
      setSchoolCode(code);
      dispatch({ type: 'UPDATE_EDUCATION', result: _.find(schools, (_school) => _school.school_code === code) });
    },

    selectedSchool:
      _.find(schools, (_school) => _school.school_code === _.get(selectedOption, 'value')) || currentSchool || {},

    loading,
    currentSchool,

    onOptionsLoad: async (keyword, done) => {
      const { data } = await callAPI({
        url: '/schools/autocomplete',
        query: { q: keyword },
      });
      done(data.map((_school) => ({ value: _school.code, label: _school.name })));
    },
    selectedOption,
    onSelectedOptionChange: setSelectedOption,
  };
};

export default useSchoolSelect;
