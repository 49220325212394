import { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { notAllowed } from 'validations';
import { getNewestTicket, handleThaiUniqueString } from 'helpers';

// Data
import disabilities from 'data/disabilities';

const useBasicInfo = (profile = {}, pendingTickets = []) => {
  const { valueOf: nameValueOf } = getNewestTicket(pendingTickets, 'update_name');
  const { valueOf: srValueOf } = getNewestTicket(pendingTickets, 'update_special_request');

  const [email, setEmail] = useState(profile.email || '');
  const [mobile, setMobile] = useState(profile.telephone || '');
  const [title, setTitle] = useState(nameValueOf('title') || profile.title || '');
  const [firstName, setFirstName] = useState(nameValueOf('first_name') || profile.first_name || '');
  const [lastName, setLastName] = useState(nameValueOf('last_name') || profile.last_name || '');
  const [firstNameAdditional, setFirstNameAdditional] = useState(profile.first_name_additional || '');
  const [lastNameAdditional, setLastNameAdditional] = useState(profile.last_name_additional || '');
  const [weight, setWeight] = useState(profile.weight || '');
  const [height, setHeight] = useState(profile.height || '');
  const [birthDate, setBirthDate] = useState(nameValueOf('birth_date') || profile.birth_date || '');
  const [studentCode, setStudentCode] = useState(profile.student_code || '');

  const disabilityIndex = _.findIndex(disabilities, text => text === (srValueOf('special_request') || profile.special_request));
  const [specialRequestVisibility, setSpecialRequestVisibility] = useState(disabilityIndex !== -1);
  const [specialRequest, setSpecialRequest] = useState(disabilityIndex === -1 ? null : disabilities[disabilityIndex]);
  const [specialRequestFile, setSpecialRequestFile] = useState();

  useEffect(() => {
    if (!specialRequestVisibility) { setSpecialRequest(); }
  }, [specialRequestVisibility]);

  return {
    email,
    mobile,
    title,
    firstName,
    lastName,
    firstNameAdditional,
    lastNameAdditional,
    weight,
    height,
    birthDate,
    studentCode,
    specialRequestVisibility,
    specialRequest,
    specialRequestFile,

    fullNameIsPending:
      (nameValueOf('first_name') && nameValueOf('first_name') !== profile.first_name) ||
      (nameValueOf('last_name') && nameValueOf('last_name') !== profile.last_name),

    onEmailChange: (e) => {
      if (notAllowed('email', e.target.value)) return;
      setEmail(e.target.value.trim());
    },
    onMobileChange: (e) => {
      if (notAllowed('mobile', e.target.value)) return;
      setMobile(e.target.value);
    },
    onTitleChange: (value) => {
      setTitle(value);
    },
    onFirstNameChange: (e) => {
      const value = handleThaiUniqueString(e.target.value);
      if (notAllowed('textTH', value)) return;
      setFirstName(value);
    },
    onLastNameChange: (e) => {
      if (e.target.value === '-') {
        setLastName('ไม่มีนามสกุล');
      } else {
        const value = handleThaiUniqueString(e.target.value);
        if (notAllowed('textTH', value)) return;
        setLastName(value);
      }
    },
    onFirstNameAdditionalChange: (e) => {
      const value = handleThaiUniqueString(e.target.value);
      if (notAllowed('textTH', value)) return;
      setFirstNameAdditional(value);
    },
    onLastNameAdditionalChange: (e) => {
      if (e.target.value === '-') {
        setLastName('ไม่มีนามสกุล');
      } else {
        const value = handleThaiUniqueString(e.target.value);
        if (notAllowed('textTH', value)) return;
        setLastNameAdditional(value);
      }
    },
    onWeightChange: (e) => {
      if (notAllowed('weight', e.target.value)) return;
      setWeight(e.target.value);
    },
    onHeightChange: (e) => {
      if (notAllowed('height', e.target.value)) return;
      setHeight(e.target.value);
    },
    onBirthDateChange: (e) => {
      setBirthDate(e.target.value);
    },
    onStudentCodeChange: (e) => {
      const num = e.target.value;
      if (num && isNaN(num)) return;
      setStudentCode(e.target.value);
    },
    onSpecialRequestChange: (e) => {
      setSpecialRequest(disabilities[e.target.value]);
    },
    onSpecialRequestVisibilityChange: (e) => {
      setSpecialRequestVisibility(e.target.checked)
    },
    onSpecialRequestFileChange: (e) => {
      setSpecialRequestFile(e.target.files[0])
    },

    prefill: useCallback((profile, ticket) => {
      setEmail(profile.email || '');
      setMobile(profile.telephone || '');
      setTitle(_.get(ticket, 'requested_data.title.value') || profile.title || '');
      setFirstName(_.get(ticket, 'requested_data.first_name.value') || profile.first_name || '');
      setLastName(_.get(ticket, 'requested_data.last_name.value') || profile.last_name || '');
      setFirstNameAdditional(profile.first_name_additional || '');
      setLastNameAdditional(profile.last_name_additional || '');
      setBirthDate(_.get(ticket, 'requested_data.birth_date.value') || profile.birth_date || '');
      setWeight(profile.weight || '');
      setHeight(profile.height || '');
    }, []),
  };
};

export default useBasicInfo;
