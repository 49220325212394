/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef } from 'react';
import _ from 'lodash';
import { callAPI, getFullScore, getProjectScore, notApplicable } from 'helpers';
import cn from 'classnames';

// Components
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useAdmission from 'hooks/round3/useAdmission';

const AdmissionEdit = ({ favorites, studentStatus, onModalOpen, onSaveSuccess, onDragEnd, onRemovePriority, onBack, quotaUsed, redeemableAmount }) => {
  const dragDropRef = useRef();

  const { t } = useTranslation();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});
  const courseMap = useSelector((state) => _.get(state, 'app.courseMap')) || {};
  const { projects, availables, nextPriority } = useAdmission(favorites, studentStatus);

  const [errorActive, setErrorActive] = useState(false);
  const [height, setHeight] = useState(0);
  const [saving, setSaving] = useState(false);
  const _onSave = async () => {
    if (saving) return;
    const reordered = projects.map((adm, index) => ({ ...adm, priority: index + 1 }));
    const priorities = reordered.reduce((map, { _id, priority }) => ({ ...map, [_id]: priority }), {});
    try {
      setSaving(true);
      await callAPI({
        method: 'POST',
        url: '/applicants/me/favorites/reprioritize',
        body: { priorities }
      })
      setSaving(false);
      onSaveSuccess();
    } catch (error) {
      console.log({ error });
      setSaving(false);
    }
  }

  const disabled = (projects || []).some(({ program_project }) => {
    const project = courseMap[program_project];
    const errors = notApplicable(profile, project, { studentStatus, favorites, nextPriority });
    return errors.length;
  });
  return (
    <>
      <p>{t('กรุณาเลือกสาขาวิชาที่จะสมัคร เรียงตามความสนใจ สามารถเลือกได้สูงสุด 10 อันดับ')}</p>
      <ul ref={dragDropRef} className="r-choose -drag" style={height ? { height } : null}>
        <DragDropContext
          onBeforeDragStart={() => setHeight((dragDropRef.current || {}).offsetHeight || 0)}
          onDragEnd={(result) => { onDragEnd(projects, result, courseMap); setHeight(0); }}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}>
                {(projects || []).map((row, index) => {
                  const lang = 'th';
                  const project = courseMap[row.program_project];
                  const majorName = _.get(project, `major_name_${lang}`);
                  const projectName = _.get(project, `project_name_${lang}`);
                  if (!project) return null;

                  let errors = notApplicable(profile, project, { studentStatus, favorites, nextPriority });
                  return (
                    <Draggable key={project._id} draggableId={project._id} index={index}>
                      {(provided) => {
                        return (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{ useSelect: 'none', ...provided.draggableProps.style }}>
                            <li className={cn({ error: Boolean(errors.length) })}>
                              <label>
                                <div className="order">{t('อันดับที่')} {row.priority}</div>
                                <h4 className="score">{t('คะแนน')} {getProjectScore(profile, project, row.priority)}/{getFullScore(project)}</h4>
                                <i className="i-drag" />
                                <span
                                  className="u-logo"
                                  style={{ backgroundImage: `url('/assets/img/logo/${_.get(project, 'university_id')}.png')` }}
                                />
                                <strong>{_.get(project, `university_name_${lang}`)}</strong>
                                {_.get(project, `campus_name_${lang}`)}
                                <br />
                                {_.get(project, `faculty_name_${lang}`)}
                                <br />
                                {_.get(project, `field_name_${lang}`)}
                                <br />
                                {majorName && majorName !== '0' ? (
                                  <>
                                    {t('วิชาเอก')}
                                    {majorName}
                                    <br />
                                  </>
                                ) : null}
                                {_.get(project, `program_name_${lang}`)}
                                <br />
                                {lang === 'th' ? `(${_.get(project, `program_type_name_th`)})` : null}
                                {projectName && projectName !== '0' ? (
                                  <>
                                    <br />
                                    {t('โครงการ ')}
                                    {projectName}
                                  </>
                                ) : null}
                              </label>
                              {Boolean(errors.length) && (
                                <div className={cn('t-collapse', { active: errorActive })}>
                                  <div className="t-toggle" onClick={() => setErrorActive(_active => !_active)}>
                                    {t('ไม่ผ่านเงื่อนไขการรับ ไม่สามารถสมัครได้')}<i className="i-toggle i-down-red"></i>
                                  </div>
                                  <div className="t-content">
                                    <ul>
                                      {errors.map(({ messages = [], key, label, expected, value }) => {
                                        if (messages.length > 1) {
                                          return (
                                            <li key={key} style={{ padding: 0 }}>
                                              <i className="i-no"></i>
                                              <span>{messages[0].label}</span>
                                              {messages.map(({ expected, value }, index) => (
                                                <div key={index}>
                                                  <b>{expected}</b>
                                                  <span>({t('ผู้สมัคร')}: <b className="color-red">{value}</b>)</span>
                                                </div>
                                              ))}
                                            </li>
                                          )
                                        }
                                        if (!value && value !== 0) {
                                          return (
                                            <li key={key} style={{ padding: 0 }}>
                                              <i className="i-no"></i>
                                              <span>{label}</span>
                                            </li>
                                          )
                                        }
                                        return (
                                          <li key={key} style={{ padding: 0 }}>
                                            <i className="i-no"></i>
                                            <span>{label}</span>
                                            <b>{expected}</b>
                                            <span>({t('ผู้สมัคร')}: <b className="color-red">{value}</b>)</span>
                                          </li>
                                        )
                                      })}
                                    </ul>
                                  </div >
                                </div >
                              )}
                              <i
                                className="i-del cursor-pointer"
                                onClick={() => onRemovePriority(row)} />
                            </li>
                          </div>
                        );
                      }}
                    </Draggable>
                  )
                })}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </ul>
      {Boolean(redeemableAmount) && (
        <div className="r-choose" style={{ margin: '0 -10px' }}>
          <li className="noti _heading">
            <strong className="color-orange" style={{ fontSize: '1.2em' }}>
              <span>สมัครครั้งแรก</span>
              <span style={{ textDecoration: 'underline' }}>ไม่มีค่าใช้จ่าย</span>
            </strong>
            <div>ผู้สมัครขอรับเงินสนับสนุนค่าสมัครคัดเลือก รอบที่ 3 Admissions ได้ตามจำนวนอันดับที่ยื่นสมัคร</div>
            <div className="s-flex -wrap -gap-x color-orange">
              <div className="s-flex gap-1">
                <img src="/assets/img/i/i-check-orange.svg" width={16} height={16} />
                <strong>ฟรี</strong>
              </div>
              <div className="s-flex gap-1">
                <img src="/assets/img/i/i-check-orange.svg" width={16} height={16} />
                <strong>ตัดสินใจได้ 3 ครั้ง</strong>
              </div>
              <div className="s-flex gap-1">
                <img src="/assets/img/i/i-check-orange.svg" width={16} height={16} />
                <strong>สูงสุด 10 อันดับ</strong>
              </div>
            </div>
          </li>
        </div>
      )}
      {Boolean(availables.length) && nextPriority <= 10 && (
        <>
          <br />
          <a className="btn-add cursor-pointer" onClick={onModalOpen}>
            <i className="i-add" />
            {t('เลือกสาขาวิชาอันดับ')} {nextPriority}
          </a>
        </>
      )}
      <br />
      <a className={cn('btn-main', { 'cursor-pointer': !disabled, disabled })} onClick={disabled ? () => { } : _onSave}>
        {t('บันทึกรายการ')}
      </a>
      {Boolean(quotaUsed) && (
        <>
          <br />
          <br />
          <a className="btn-underline cursor-pointer" onClick={onBack}>
            {t('ยกเลิกและกลับไปใช้การยืนยันครั้งที่')} {quotaUsed}/3
          </a>
        </>
      )}
    </>
  )
}

export default AdmissionEdit;
