/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { callAPI, getRoundDescription, getRoundState } from 'helpers';
import _ from 'lodash';

// Components
import RoundContent from '../RoundContent';
import ModalOfferReplyConsent from './ModalRound1OfferReplyConsent';
import ModalOfferCancelConsent from './ModalRound1OfferCancelConsent';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useQuery from 'hooks/useQuery';

const roundType = '1_2568';
const Round1 = () => {
  const { t } = useTranslation();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});
  const query = useQuery();

  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [offerCount, setOfferCount] = useState(0);
  const [offers, setOffers] = useState([]);
  const [replies, setReplies] = useState([]);
  const [timestamp, setTimestamp] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const { flow } = getRoundState({ roundType, timestamp, query });
  const description = getRoundDescription({ roundType, timestamp, offers, replies });

  useEffect(() => {
    const fetchEnrollmentOffers = async () => {
      try {
        setLoading(true);
        const { offerCount, offers, replies, timestamp } = await callAPI({
          url: '/applicants/me/enrollment-offers',
          query: { type: '1_2568' },
        });
        setOfferCount(offerCount);
        setOffers(offers);
        setReplies(replies);
        setTimestamp(timestamp);
        setLoading(false);
        setActive(true);
      } catch (error) {
        console.error(`Error while trying to fetch enrollment offers: ${error.message}`);
        setLoading(false);
      }
    };

    // fetchEnrollmentOffers();
  }, []);

  useEffect(() => {
    if (flow === 'default' && !_.get(profile, 'consents_accepted_at.offer_reply_1_2568')) {
      // In reply period, and has not accepted the reply consent yet.
      setModalVisible(true);
    } else if (flow === 'cancel' && !_.get(profile, 'consents_accepted_at.offer_cancel_1_2568')) {
      // In cancel period, and has not accepted the cancel consent yet.
      setModalVisible(true);
    }
  }, [flow, profile]);

  const [accepting, setAccepting] = useState(false);
  const _onAcceptConsent = async (consentType) => {
    if (accepting) return;
    try {
      setAccepting(true);
      await callAPI({
        method: 'POST',
        url: '/applicants/me/consents',
        body: {
          consentType,
        },
      });
      setAccepting(false);
    } catch (error) {
      console.error(`Error while trying to accept consent: ${error.message}`);
      setAccepting(false);
    }
    setModalVisible(false);
  };
  
  return (
    <>
      <div className={cn('r-1', { active })}>
        <header onClick={() => setActive((_active) => !_active)}>
          <b>
            <small>{t('รอบ')}</small>1
          </b>
          <h3>
            Portfolio
            <small>{t(description)}</small>
          </h3>
          <i className="i-down"></i>
        </header>
        <RoundContent
          flow={flow}
          roundType={roundType}
          loading={loading}
          offerCount={offerCount}
          offers={offers}
          replies={replies}
          timestamp={timestamp}
        />
      </div>
      <ModalOfferReplyConsent
        visible={offers.length && modalVisible && flow === 'default'}
        onSubmit={() => _onAcceptConsent('offer_reply_1_2568')}
      />
      <ModalOfferCancelConsent
        visible={offers.length && modalVisible && flow === 'cancel'}
        onSubmit={() => _onAcceptConsent('offer_cancel_1_2568')}
      />
    </>
  );
};

export default Round1;
