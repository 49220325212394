import { useState } from 'react';
import { compressImage } from 'helpers';

const useDocument = (profile, pendingTickets) => {
  const [documentType, setDocumentType] = useState('transcript');
  const [transcriptFrontFile, setTranscriptFrontFile] = useState();
  const [transcriptBackFile, setTranscriptBackFile] = useState();
  const [requestForActualEquivalenceCertificateFile, setRequestForActualEquivalenceCertificateFile] = useState();
  const [gpaEquivalencyFile, setGpaEquivalencyFile] = useState();

  return {
    documentType,
    onDocumentTypeChange: setDocumentType,

    transcriptFrontFile,
    onTranscriptFrontFileChange: async (e) => {
      const _file = await compressImage(e.target.files[0]);
      setTranscriptFrontFile(_file);
    },

    transcriptBackFile,
    onTranscriptBackFileChange: async (e) => {
      const _file = await compressImage(e.target.files[0]);
      setTranscriptBackFile(_file);
    },

    raeFile: requestForActualEquivalenceCertificateFile,
    onRAEFileChange: async (e) => {
      const _file = await compressImage(e.target.files[0]);
      setRequestForActualEquivalenceCertificateFile(_file);
    },

    gpaFile: gpaEquivalencyFile,
    onGPAFileChange: async (e) => {
      const _file = await compressImage(e.target.files[0]);
      setGpaEquivalencyFile(_file);
    },
  };
};

export default useDocument;
