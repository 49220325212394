import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import moment from 'moment';

// Components
import Round41 from './Round41';
import Round42 from './Round42';

// Hooks
import { useTranslation } from 'react-i18next';
import { callAPI } from 'helpers';

// Data
import enabled from 'data/enabled';

const Round4 = () => {
  const { t } = useTranslation();

  const [active, setActive] = useState(true);
  const [loading, setLoading] = useState(true);

  const [offerCountR41, setOfferCountR41] = useState(0);
  const [offersR41, setOffersR41] = useState([]);
  const [repliesR41, setRepliesR41] = useState([]);
  const [timestampR41, setTimestampR41] = useState();

  const [offerCountR42, setOfferCountR42] = useState(0);
  const [offersR42, setOffersR42] = useState([]);
  const [repliesR42, setRepliesR42] = useState([]);
  const [timestampR42, setTimestampR42] = useState();
  
  const r41Expired = moment(timestampR41).isAfter(enabled.round41.reply_end);
  const r42Expired = moment(timestampR42).isAfter(enabled.round42.reply_end);

  useEffect(() => {
    const fetchEnrollmentOffers = async () => {
      try {
        setLoading(true);
        const { offerCount, offers, replies, timestamp } = await callAPI({
          url: '/applicants/me/enrollment-offers',
          query: { type: '41_2568' }
        });
        setOfferCountR41(offerCount);
        setOffersR41(offers);
        setRepliesR41(replies);
        setTimestampR41(timestamp);

        const r42 = await callAPI({
          url: '/applicants/me/enrollment-offers',
          query: { type: '42_2568' }
        });
        setOfferCountR42(r42.offerCount);
        setOffersR42(r42.offers);
        setRepliesR42(r42.replies);
        setTimestampR42(r42.timestamp);

        setLoading(false);
        setActive(true);
        // setActive(offers.length || r42.offers.length);
      } catch (error) {
        console.error(`Error while trying to fetch enrollment offers: ${error.message}`);
        setLoading(false);
      }
    }

    // fetchEnrollmentOffers();
  }, []);

  return (
    <div className={cn('r-4', { active })}>
      <header onClick={() => setActive((_active) => !_active)}>
        <b><small>{t('รอบ')}</small>4</b>
        <h3>
          Direct Admission
          <small>{moment(timestampR41).isBefore(enabled.round41.reply_start)
            ? t(`${moment(enabled.round41.reply_start).format('D MMM')} - ${moment(enabled.round42.reply_end).format('D MMM')} ${moment(enabled.round42.reply_end).add(543, 'years').format('YY')}`)
            : (!offerCountR41 && !offerCountR42)
              ? t('ท่านไม่ได้สมัครในรอบนี้')
              : (r41Expired && offersR42.length)
                ? t(`ผ่านการคัดเลือก ${offersR42.length} สาขาวิชา`)
                : offersR41.length
                  ? t(`ผ่านการคัดเลือก ${offersR41.length} สาขาวิชา`)
                  : t('ท่านไม่ผ่านการคัดเลือกในรอบนี้')}</small>
        </h3>
        <i className="i-down"></i>
      </header>
      <div className="content">
        <Round41
          expired={r41Expired}
          loading={loading}
          offerCount={offerCountR41}
          offers={offersR41}
          replies={repliesR41}
          timestamp={timestampR41} />
        <Round42
          expired={r42Expired}
          loading={loading}
          offerCount={offerCountR42}
          offers={offersR42}
          replies={repliesR42}
          timestamp={timestampR42} />
      </div>
    </div>
  )
}

export default Round4;