/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import _ from 'lodash';

// Hooks
import { useTranslation } from 'react-i18next';

// Helpers
import { notAllowed, errorMessages, validateMobile } from 'validations';
import { callAPI, handleThaiUniqueString } from 'helpers';

// Components
import ErrorBox from 'components/registrations/ErrorBox';

const isListed = (relationship) => ['บิดา', 'มารดา', 'ครูแนะแนว'].includes(relationship);
const getParentStatus = (relationship) => (isListed(relationship) ? relationship : relationship ? 'อื่นๆ' : '');

const ID = 'section-parent';
const SectionParent = forwardRef(({ initialState, profile = {}, collapsed, onCollapsedChange }, ref) => {
  const { t } = useTranslation();

  const [parentStatus, setParentStatus] = useState(getParentStatus(profile.parent_relationship));
  const [parentRelationship, setParentRelationship] = useState(
    isListed(profile.parent_relationship) ? '' : profile.parent_relationship
  );
  const [parentName, setParentName] = useState(profile.parent_name || '');
  const [parentMobile, setParentMobile] = useState(profile.parent_telephone || '');
  const [parentEmail, setParentEmail] = useState(profile.parent_email || '');
  const [errors, setErrors] = useState([]);

  const onParentStatusChange = (e) => {
    setParentStatus(e.target.value);
    switch (e.target.value) {
      case 'บิดา':
        setParentName(fatherName || '');
        setParentMobile(fatherMobile || '');
        break;
      case 'มารดา':
        setParentName(motherName || '');
        setParentMobile(motherMobile || '');
        break;
      default:
        setParentName('');
        setParentMobile('');
        break;
    }
  };
  const onParentRelationshipChange = (e) => {
    if (notAllowed('textTH', e.target.value)) return;
    setParentRelationship(e.target.value);
  };
  const onParentNameChange = (e) => {
    const value = handleThaiUniqueString(e.target.value);
    if (notAllowed('textTH', value)) return;
    setParentName(value);
  };
  const onParentMobileChange = (e) => {
    if (notAllowed('mobile', e.target.value)) return;
    setParentMobile(e.target.value);
  };
  const onParentEmailChange = (e) => {
    if (notAllowed('email', e.target.value)) return;
    setParentEmail(e.target.value);
  };

  const fatherName = profile.father_name;
  const fatherMobile = profile.father_telephone;
  const motherName = profile.mother_name;
  const motherMobile = profile.mother_telephone;

  // const updateParentInfo = useCallback(() => {
  //   if (parentStatus === 'บิดา') {
  //     setParentName(fatherName || parentName || '');
  //     setParentMobile(fatherMobile || parentMobile || '');
  //   }
  //   if (parentStatus === 'มารดา') {
  //     setParentName(motherName || parentName || '');
  //     setParentMobile(motherMobile || parentMobile || '');
  //   }
  // }, [fatherName, fatherMobile, motherName, motherMobile, parentStatus, parentMobile, parentName]);

  // useEffect(updateParentInfo, [fatherName, fatherMobile, motherName, motherMobile, updateParentInfo]);

  const [mode, setMode] = useState(collapsed ? 'edit' : 'add');

  useImperativeHandle(ref, () => ({
    getState: () => {
      return {
        parentStatus,
        parentRelationship,
        parentName,
        parentMobile,
        parentEmail,
      };
    },
  }));

  const getRelationship = (status, relationship) => (status === 'อื่นๆ' ? relationship : status);
  const [saving, setSaving] = useState(false);
  const onButtonClick = async () => {
    const _messages = errorMessages({
      parentStatus: !parentStatus && 'จำเป็นต้องระบุสถานะผู้ปกครอง',
      parentRelationship: parentStatus === 'อื่นๆ' && !parentRelationship && 'จำเป็นต้องระบุความสัมพันธ์',
      parentName: !parentName && 'จำเป็นต้องระบุชื่อ-นามสกุล',
      parentMobile: validateMobile(parentMobile),
    });
    const noChanges =
      getRelationship(parentStatus, parentRelationship) ===
      getRelationship(initialState.parentStatus, initialState.parentRelationship) &&
      parentName === initialState.parentName &&
      parentMobile === initialState.parentMobile &&
      parentEmail === initialState.parentEmail;

    if (_messages.length) {
      setErrors(_messages);
      const container = document.getElementById(ID);
      if (container) {
        container.scrollIntoView({ behavior: 'smooth' });
      }
    } else if (noChanges) {
      onCancel();
    } else {
      setErrors([]);
      setMode('edit');

      if (saving) return;
      try {
        setSaving(true);
        const { result } = await callAPI({
          method: 'PUT',
          url: '/applicants/me/parents',
          body: {
            relationship: getRelationship(parentStatus, parentRelationship),
            name: parentName,
            email: parentEmail || null,
            mobile: parentMobile,
          },
        });

        setParentStatus(getParentStatus(result.parent_relationship));
        setParentRelationship(isListed(result.parent_relationship) ? '' : result.parent_relationship);
        setParentName(result.parent_name || '');
        setParentMobile(result.parent_telephone || '');
        setParentEmail(result.parent_email || '');

        onCollapsedChange(true);
        setSaving(false);
      } catch (error) {
        const errorMessages = (_.get(error, 'json.errors') || []).map(({ msg }) => msg);
        setErrors(errorMessages);
        const container = document.getElementById(ID);
        if (container) {
          container.scrollIntoView({ behavior: 'smooth' });
        }

        console.error(`Error while trying to save parent data: ${errorMessages.toString()}`);
        setSaving(false);
      }
    }
  };

  const onCancel = () => {
    setParentStatus(initialState.parentStatus);
    setParentRelationship(initialState.parentRelationship);
    setParentName(initialState.parentName);
    setParentEmail(initialState.parentEmail);
    setParentMobile(initialState.parentMobile);
    onCollapsedChange(true);
  };

  if (collapsed) {
    return (
      <div>
        <div className="t-box">
          <h2 className="title">
            {t('ข้อมูลผู้ปกครอง')}
            <a className="cursor-pointer" onClick={() => onCollapsedChange(false)}>
              {t('ขอแก้ไขข้อมูล')}
            </a>
          </h2>
          <table>
            <tbody>
              <tr>
                <th>{t('สถานะผู้ปกครอง')}</th>
                <td>{parentStatus}</td>
              </tr>
              {Boolean(parentRelationship) && (
                <tr>
                  <th>{t('ความสัมพันธ์')}</th>
                  <td>{parentRelationship}</td>
                </tr>
              )}
              <tr>
                <th>{t('ชื่อ')}</th>
                <td>{parentName}</td>
              </tr>
              <tr>
                <th>{t('เบอร์มือถือ')}</th>
                <td>{parentMobile}</td>
              </tr>
              <tr>
                <th>{t('อีเมล')}</th>
                <td>{parentEmail}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return (
    <div id={ID} className="t-box -choose">
      <h2 className="title">
        {mode === 'add' ? t('เพิ่มข้อมูลผู้ปกครอง') : t('แก้ไขข้อมูลผู้ปกครอง')}
        {mode === 'edit' && (
          <a className="cursor-pointer" onClick={onCancel}>
            {t('ยกเลิก')}
          </a>
        )}
      </h2>

      <ErrorBox isFull hidden={!errors.length} errors={errors} />
      <div className="t-row">
        <div className="col required">
          <label>{t('สถานะผู้ปกครอง')}</label>
          <select required value={parentStatus} onChange={onParentStatusChange}>
            <option value=""></option>
            <option value="บิดา">{t('บิดา')}</option>
            <option value="มารดา">{t('มารดา')}</option>
            <option value="ครุแนะแนว">{t('ครูแนะแนว')}</option>
            <option value="อื่นๆ">{t('อื่นๆ')}</option>
          </select>
        </div>
        {parentStatus === 'อื่นๆ' && (
          <div className="col required">
            <label>{t('ความสัมพันธ์')}</label>
            <input type="text" value={parentRelationship} onChange={onParentRelationshipChange} />
          </div>
        )}
        <div className="col required">
          <label>
            {t('ชื่อ-นามสกุล')} ({t('ไม่ต้องกรอกคำนำหน้า')})
          </label>
          <input type="text" value={parentName} onChange={onParentNameChange} />
        </div>
        <div className="col required">
          <label>{t('เบอร์มือถือ')}</label>
          <input type="tel" value={parentMobile} onChange={onParentMobileChange} />
        </div>
        <div className="col">
          <label>{t('อีเมล')}</label>
          <input type="email" value={parentEmail} onChange={onParentEmailChange} />
        </div>
      </div>
      <hr />
      <a className="btn-main cursor-pointer" onClick={onButtonClick}>
        {t('ยืนยันข้อมูลผู้ปกครอง')}
      </a>
    </div>
  );
});

export default SectionParent;
