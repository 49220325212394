import { useEffect, useState } from 'react';
import _ from 'lodash';
import { compressImage, getNewestTicket } from 'helpers';
import scores from 'data/ged.score.json';
import useEducation from './useEducation';

const useSectionGEDScore = (profile, pendingTickets) => {
  const { valueOf } = getNewestTicket(pendingTickets, 'update_ged_score');
  const [gedRLA, setGedRLA] = useState(valueOf('ged_rla') || profile.ged_rla || '');
  const [gedSS, setGedSS] = useState(valueOf('ged_ss') || profile.ged_ss || '');
  const [gedMR, setGedMR] = useState(valueOf('ged_mr') || profile.ged_mr || '');
  const [gedS, setGedS] = useState(valueOf('ged_s') || profile.ged_s || '');
  const [gedScore, setGedScore] = useState(valueOf('ged_score') || profile.ged_score || '');
  const [gedDate, setGedDate] = useState(valueOf('ged_date') || profile.ged_date || '');
  const [gedFile, setGedFile] = useState();

  useEffect(
    () => setGedScore(parseInt(gedRLA, 10) + parseInt(gedSS, 10) + parseInt(gedMR, 10) + parseInt(gedS, 10)),
    [gedRLA, gedSS, gedMR, gedS]
  );

  return {
    ...useEducation(profile, pendingTickets),

    gedRLA,
    setGedRLA,
    gedSS,
    setGedSS,
    gedMR,
    setGedMR,
    gedS,
    setGedS,
    gedScore,

    gedDate,
    onGedDateChange: async (e) => {
      setGedDate(e.target.value);
    },

    gedFile,
    onGedFileChange: async (e) => {
      const _file = await compressImage(e.target.files[0]);
      setGedFile(_file);
    },

    fnName: (fieldName) => `set${fieldName[0].toUpperCase()}${fieldName.slice(1)}`,
    scores,
    validateScore: (fieldName, score) => {
      const { label, min, max } = _.find(scores, (_score) => _score.fieldName === fieldName);
      if (score < min) return `คะแนน ${label} ต้องมีค่าไม่น้อยกว่า ${min}`;
      if (score > max) return `คะแนน ${label} ต้องมีค่าไม่มากกว่า ${max}`;
      return null;
    },
  };
};

export default useSectionGEDScore;
