/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import cn from 'classnames';
import _ from 'lodash';

// Hooks
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const MethodSelector = ({ topic = 'ยืนยัน', onClick }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});
  const formattedMobile = profile.telephone ? [
    profile.telephone.slice(0, 3),
    profile.telephone.slice(3, 6),
    profile.telephone.slice(-4),
  ].join(' ') : '-';

  const [verifyMethod, setVerifyMethod] = useState();
  return (
    <>
      <ul className="r-radio">
        <li>
          <label>
            <input
              type="radio"
              name="confirm"
              checked={verifyMethod === 'mobile'}
              onClick={() => setVerifyMethod('mobile')}
            />
            <strong>{topic}ผ่านเบอร์มือถือ</strong>
            <small>
              {t('หมายเลข')} {formattedMobile}
            </small>
          </label>
        </li>
        <li>
          <label>
            <input
              type="radio"
              name="confirm"
              checked={verifyMethod === 'email'}
              onClick={() => setVerifyMethod('email')}
            />
            <strong>{topic}ผ่านอีเมล</strong>
            <small>{profile.email}</small>
          </label>
        </li>
      </ul>

      <div className="action">
        <a
          className={cn('btn-main', { disabled: !verifyMethod, 'cursor-pointer': Boolean(verifyMethod) })}
          onClick={!verifyMethod ? () => { } : () => onClick(verifyMethod)}
        >
          ส่ง OTP เพื่อ{topic}
        </a>
        <h4><a onClick={() => navigate(-1)} className="cursor-pointer color-gray">{t('ยกเลิก')}</a></h4>
      </div>
      <p className="text-center _small">{t('หากมีการเปลี่ยนแปลงเบอร์มือถือหรืออีเมล')}
        {t('สามารถแก้ไขได้ที่หน้า')} <Link to='/profile/applicant-info' className="color-black"><u>ข้อมูลการลงทะเบียน</u></Link></p>
    </>
  );
};

export default MethodSelector;
