/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

// Components
import FacebookLogin from 'components/profile/FacebookLogin';
import GoogleLogin from 'components/profile/GoogleLogin';
import { callAPI } from 'helpers';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router';

const SocialLoginForm = ({ infoHeading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const toNextStep = () => navigate('/profile/applicant-info');

  const [fbSigningUp, setFbSigningUp] = useState(false);
  const _onFacebookSignup = async (accessToken) => {
    if (fbSigningUp) return;
    try {
      setFbSigningUp(true);
      const { access_token } = await callAPI({
        method: 'POST',
        url: '/socials/signup-with-facebook',
        body: { accessToken },
      });
      Cookies.set('accessToken', access_token, { expires: 1 / 24 });
      toNextStep();
      setFbSigningUp(false);
    } catch (error) {
      const errorMessage = _.get(error, 'json.error.message');
      alert(errorMessage);
      console.error(new Error(`Error while trying to signup with facebook: ${errorMessage}`));
      setFbSigningUp(false);
    }
  };

  const [ggSigninUp, setGgSigningUp] = useState(false);
  const _onGoogleSignup = async (idToken) => {
    if (ggSigninUp) return;
    try {
      setGgSigningUp(true);
      const { access_token } = await callAPI({
        method: 'POST',
        url: '/socials/signup-with-google',
        body: { idToken },
      });
      Cookies.set('accessToken', access_token, { expires: 1 / 24 });
      toNextStep();
      setGgSigningUp(false);
    } catch (error) {
      console.log({ error });
      const errorMessage = _.get(error, 'json.error.message');
      alert(errorMessage);
      console.error(new Error(`Error while trying to signup with google: ${errorMessage}`));
      setGgSigningUp(false);
    }
  };

  return (
    <section>
      <div className="t-box -info -center -half">{infoHeading}</div>

      <div className="text-center">
        <FacebookLogin onAuthenticate={_onFacebookSignup} />
        <GoogleLogin onAuthenticate={_onGoogleSignup} />
        <p className="t-or">
          <span className="or">{t('หรือ')}</span>
        </p>

        <p>
          <a onClick={() => toNextStep()} className="_heading cursor-pointer">
            {t('เข้าสู่ระบบด้วยรหัสผ่านเท่านั้น')} →
          </a>
        </p>
      </div>
    </section>
  );
};

export default SocialLoginForm;
