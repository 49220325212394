/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { callAPI } from 'helpers';
import moment from 'moment';

// Components
import { Link } from 'react-router-dom';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Data
import { pbt_tgattpat_recheck, tgattpat_postpone } from 'data/enabled';

const SectionApplyExams = () => {
  const { t } = useTranslation();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});
  const [loading, setLoading] = useState(false);
  const [applicationCount, setApplicationCount] = useState(false);
  const [announcable, setAnnouncable] = useState(false);
  const [recheckable, setRecheckable] = useState(false);

  // Postpone Flow
  const [ppType, setPpType] = useState();
  const [ppExpired, setPpExpired] = useState(false);

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        setLoading(true);
        const { applications, pp_type, postponable, timestamp } = await callAPI({
          url: '/applicants/me/exam-applications',
        });
        setApplicationCount(applications.length);
        setAnnouncable(false);
        setPpType(pp_type);
        setPpExpired(moment(timestamp).isAfter(tgattpat_postpone.expired));

        // setAnnouncable(applications.some(app => app.selected_exam_type === 'cbt'));
        // setRecheckable(false);
        setRecheckable(
          applications.some((app) => app.recheckable) &&
            moment(timestamp).isBetween(pbt_tgattpat_recheck.start, pbt_tgattpat_recheck.end)
        );
        setLoading(false);
      } catch (error) {
        console.error(`Error while trying to fetch applications: ${error.message}`);
        setLoading(false);
      }
    };
    fetchApplications();
  }, []);

  if (loading) return null;
  return (
    <div className="t-box -exam">
      <h2 className="title">{t('ข้อมูลการสมัครสอบ TGAT/TPAT2-5')}</h2>
      {!applicationCount ? (
        <div className="text-center">
          <p>{t('ไม่มีข้อมูลการสมัครสอบ TGAT/TPAT2-5')}</p>
        </div>
      ) : (
        <div className="text-center">
          <p>{t('ข้อมูลการสมัครสอบ TGAT/TPAT2-5')}</p>
          {!profile.picture ? (
            <p className="color-orange">
              {t('กรุณาอัปโหลดรูปถ่ายผู้สมัคร เพื่อใช้ในการพิมพ์บัตรประจำตัวผู้เข้าสอบรายวิชา')}
              <br />
              {t('สามารถแก้ไขได้ที่หน้า ข้อมูลการลงทะเบียน')}
            </p>
          ) : !profile.program_code ? (
            <p className="color-orange">
              {t('กรุณากรอกข้อมูลหลักสูตรการศึกษา เพื่อใช้ในการพิมพ์บัตรประจำตัวผู้เข้าสอบรายวิชา')}
              <br />
              {t('สามารถแก้ไขได้ที่หน้า ข้อมูลการลงทะเบียน')}
            </p>
          ) : null}
          {profile.picture && profile.program_code ? (
            <div>
              {announcable ? (
                <>
                  <p>
                    <Link
                      to="/profile/exam-scores/tgattpat"
                      className="btn-main"
                      style={{ width: '100%', maxWidth: '320px' }}
                    >
                      {t('ประกาศผลสอบ TGAT/TPAT2-5')}
                    </Link>
                  </p>
                  {/* <p className="color-orange">{t('ประกาศผลสอบด้วยกระดาษ วันที่ 7 ม.ค. 2568')}</p> */}
                  {recheckable && (
                    <p>
                      <Link to="/profile/exam-answers/tgattpat" className="btn-recheck -border">
                        <i className="i-recheck"></i>
                        {t('ดูคำตอบและคะแนนข้อสอบ')}
                      </Link>
                    </p>
                  )}
                  {/* <h4 className="mb-0">
                    <Link to="/profile/exam-application-info/tgattpat" className="color-gray">
                      {t('รายละเอียดการสอบ')} →
                    </Link>
                  </h4> */}
                </>
              ) : (
                <>
                  <p className="mb-6">
                    <Link to="/profile/exam-application-info/tgattpat" className="btn-main btn-noti">
                      {t('รายละเอียดการสอบ')} →
                    </Link>
                  </p>
                  {ppType === '1' ? (
                    <div class="text-center">
                      <hr />
                      <h4 className="color-orange flex items-center gap-2 justify-center mb-2">
                        <i className="i-postpone i-20"></i>
                        {t('อัปเดตข้อมูลที่นั่งสอบและสนามสอบเรียบร้อยแล้ว')}
                      </h4>
                      <p>กรุณาตรวจสอบ <b>"รายละเอียดการสอบ"</b></p>
                    </div>
                  ) : ppType === '2' ? (
                    <p class="text-center">
                      {profile.tgattpat_postpone_confirmed_at ? (
                        <>
                          <hr />
                          <h4 className="color-orange flex items-center gap-2 justify-center mb-2">
                            <i className="i-postpone i-20"></i>
                            {t('อัปเดตข้อมูลที่นั่งสอบและสนามสอบเรียบร้อยแล้ว')}
                          </h4>
                          <p>กรุณาตรวจสอบ <b>"รายละเอียดการสอบ"</b></p>
                        </>
                      ) : ppExpired ? null : (
                        <>
                          <hr />
                          <h4 className="color-orange flex items-center gap-2 justify-center mb-2">
                            ต้องการย้ายวันสอบ?
                          </h4>
                          <p>
                            ผู้สมัครในสนามสอบจังหวัดปัตตานีและจังหวัดสงขลา ที่ประสบปัญหาการเดินทาง สามารถขอย้ายวันสอบ
                            เป็นวันที่ <strong>21 - 23 ธันวาคม 2567</strong> แทนได้
                          </p>
                          <Link to="/profile/tgattpat-postpone/confirm" className="btn-ghost -large">
                            <i className="i-postpone i-20"></i>
                            {t('ลงทะเบียนย้ายวันสอบ')}
                          </Link>
                        </>
                      )}
                    </p>
                  ) : null}
                </>
              )}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default SectionApplyExams;
