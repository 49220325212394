/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { callAPI } from 'helpers';
import moment from 'moment';

// Components
import EmailVerification from 'components/otp/EmailVerification';
import MobileVerification from 'components/otp/MobileVerification';
import ExamSlotInfo from 'components/profile/apply-exams/ExamSlotInfo';
import ExamSiteInfo from 'components/profile/apply-exams/ExamSiteInfo';

// Hooks
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Data
import { TGATTPATINFOS } from 'data/exam-days';
import enabled from 'data/enabled.json';

const examInfoMap = _.keyBy(TGATTPATINFOS, 'dayIndex');
const TGATTPATUpdateSites = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});

  useEffect(() => {
    if (profile.exam_4y_alleged) { navigate('/profile/apply-exams/tgattpat/veto', { replace: true }); }
  }, [profile, navigate]);

  const [verifyMethod, setVerifyMethod] = useState();
  const [otpVisible, setOtpVisible] = useState(false);

  const [loading, setLoading] = useState(false);
  const [selections, setSelections] = useState([]);
  const [confirmations, setConfirmations] = useState([]);
  const [siteMap, setSiteMap] = useState({});
  useEffect(() => {
    const fetchSummary = async () => {
      try {
        setLoading(true);
        const { result } = await callAPI({ url: '/applicants/me/apply-exam-summary/tgattpat' });
        setSelections(result.selections);
        setConfirmations(result.recent_confirmations);
        setSiteMap(_.keyBy(result.exam_sites, ({ exam_type, site_code }) => `${exam_type}-${site_code}`))
        setLoading(false);

        const expired = moment(result.timestamp).isAfter(enabled.tgattpat_apply_exams.expired);
        if (expired) { navigate('/profile/apply-exams/tgattpat') }
      } catch (error) {
        console.log({ error });
        setLoading(false);
      }
    }
    fetchSummary();
  }, [navigate]);

  const onEmailOtpSuccess = useCallback(() => {
    dispatch({ type: 'UPDATE_USER', result: { ...profile, email_is_verified: 1 } });
    navigate(-1);
  }, [dispatch, profile, navigate]);

  const onSmsOtpSuccess = useCallback(() => {
    dispatch({ type: 'UPDATE_USER', result: { ...profile, telephone_is_verified: 1 } });
    navigate(-1);
  }, [dispatch, profile, navigate]);

  const nextConfirmId = (_.get(_.maxBy(confirmations, 'site_confirm_id'), 'site_confirm_id') || 0) + 1;

  if (loading) {
    return (
      <main className="site-body">
        <div className="t-loading" />
      </main>
    )
  }

  return (
    <main className="site-body">
      <div className="t-form">
        <h1>{t('แก้ไขสนามสอบ')}</h1>
        {!otpVisible && (
          <div className="t-box -border -noti">
            <div className="text-center color-orange">
              {t('กรุณาเลือกช่องทางในการส่งรหัส OTP ยืนยันการแก้ไขสนามสอบ')}
            </div>
          </div>
        )}

        {!otpVisible &&
          <div className="exam-box -info">
            <div className="exam-box-header">
              <div className="s-flex -between">
                <span className="_heading"><b>{t('สมัครสอบ')}</b></span>
                <span className="_heading">{t('แก้ไขสนามสอบครั้งที่')} {nextConfirmId}</span>
              </div>
            </div>
            <div className="exam-box-body">
              <h4><b>TGAT/TPAT2-5</b></h4>
              {(selections || []).map(sel => {
                const { date, colorClassName, examSlots } = examInfoMap[sel.day];
                return (
                  <div className={cn("exam-box exam-choose-step", { [colorClassName]: true })}>
                    <div className="exam-box-header">
                      <div className="s-flex -between">
                        <div className="_heading">
                          <span className="num">{sel.day}</span><b>{date}</b>
                        </div>
                      </div>
                    </div>
                    <ul className="exam-box-list">
                      {examSlots.filter(({ subjectKey }) => sel.subjects.includes(subjectKey))
                        .map((slot, slotIndex) =>
                          <ExamSlotInfo
                            key={slotIndex}
                            slot={slot}
                            examType={sel.exam_type}
                            specialRequests={sel.specialRequests} />
                      )}
                      <ExamSiteInfo
                        data={sel}
                        examType={sel.exam_type}
                        siteMap={siteMap} />
                    </ul>
                  </div>
                );
              })}
            </div>
          </div>
        }
        {!otpVisible ? (
          <MethodSelector
            verifyMethod={verifyMethod}
            setVerifyMethod={setVerifyMethod}
            onClick={() => setOtpVisible(true)}
          />
        ) : verifyMethod === 'email' ? (
          <EmailVerification
            studentType="update-exam-sites"
            email={profile.email}
            bodyParams={{ updates: selections.map(({ updated_id }) => updated_id)}}
            onSuccess={onEmailOtpSuccess} />
        ) : verifyMethod === 'mobile' ? (
          <MobileVerification
            studentType="update-exam-sites"
            mobile={profile.telephone}
            bodyParams={{ updates: selections.map(({ updated_id }) => updated_id)}}
            onSuccess={onSmsOtpSuccess} />
        ) : null}
      </div>
    </main>
  );
}

export default TGATTPATUpdateSites;


const MethodSelector = ({ onClick, verifyMethod, setVerifyMethod }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});
  const formattedMobile = [
    profile.telephone.slice(0, 3),
    profile.telephone.slice(3, 6),
    profile.telephone.slice(-4),
  ].join(' ');

  return (
    <>
      <ul className="r-radio">
        <li>
          <label>
            <input
              type="radio"
              name="confirm"
              checked={verifyMethod === 'mobile'}
              onClick={() => setVerifyMethod('mobile')}
            />
            <strong>{t('ยืนยันการสมัครผ่านเบอร์มือถือ')}</strong>
            <small>
              {t('หมายเลข')} {formattedMobile}
            </small>
          </label>
        </li>
        <li>
          <label>
            <input
              type="radio"
              name="confirm"
              checked={verifyMethod === 'email'}
              onClick={() => setVerifyMethod('email')}
            />
            <strong>{t('ยืนยันการสมัครผ่านอีเมล')}</strong>
            <small>{t('อีเมล')} {profile.email}</small>
          </label>
        </li>
      </ul>

      <div className="action">
        <a
          className={cn('btn-main', { disabled: !verifyMethod, 'cursor-pointer': Boolean(verifyMethod) })}
          onClick={!verifyMethod ? () => { } : onClick}
        >
          {t('ส่ง OTP เพื่อยืนยันการสมัครสอบ')}
        </a>
        <h4><a onClick={() => navigate(-1)} className="cursor-pointer color-gray">{t('ยกเลิก')}</a></h4>
      </div>
      <p className="text-center _small color-gray">{t('หากมีการเปลี่ยนแปลงเบอร์มือถือหรืออีเมล')}
        {t('สามารถแก้ไขได้ที่หน้า')} <Link to='/profile/applicant-info' className="color-gray"><u>{t('ข้อมูลการลงทะเบียน')}</u></Link></p>
    </>
  );
};
