import { useState } from 'react';
import _ from 'lodash';
import { compressImage, getNewestTicket } from 'helpers';
import scores from 'data/vnet.score.json';
import useEducation from './useEducation';

const useSectionVNETScore = (profile, pendingTickets) => {
  const { valueOf } = getNewestTicket(pendingTickets, 'update_vnet_score');
  const [vnet51, setVnet51] = useState(valueOf('vnet_51') || profile.vnet_51 || '');
  const [vnet511, setVnet511] = useState(valueOf('vnet_511') || profile.vnet_511 || '');
  const [vnet512, setVnet512] = useState(valueOf('vnet_512') || profile.vnet_512 || '');
  const [vnet513, setVnet513] = useState(valueOf('vnet_513') || profile.vnet_513 || '');
  const [vnet514, setVnet514] = useState(valueOf('vnet_514') || profile.vnet_514 || '');
  const [vnetFile, setVnetFile] = useState();

  return {
    ...useEducation(profile, pendingTickets),

    vnet51,
    setVnet51,
    vnet511,
    setVnet511,
    vnet512,
    setVnet512,
    vnet513,
    setVnet513,
    vnet514,
    setVnet514,

    vnetFile,
    onVnetFileChange: async (e) => {
      const _file = await compressImage(e.target.files[0]);
      setVnetFile(_file);
    },
    clearVnetFile: () => setVnetFile(null),

    fnName: (fieldName) => `set${fieldName[0].toUpperCase()}${fieldName.slice(1)}`,
    scores,
    validateScore: (fieldName, score) => {
      const { label, min, max } = _.find(scores, (_score) => _score.fieldName === fieldName);
      if (score < min) return `คะแนน ${label} ต้องมีค่าไม่น้อยกว่า ${min}`;
      if (score > max) return `คะแนน ${label} ต้องมีค่าไม่มากกว่า ${max}`;
      return null;
    },
  };
};

export default useSectionVNETScore;
