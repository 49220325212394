/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useTranslation } from 'react-i18next';

const ModalExamConsent = ({ visible, onSubmit }) => {
  const { t } = useTranslation();
  if (!visible) return null;
  return (
    <div className="t-modal -short" style={{ visibility: 'visible', opacity: 1, pointerEvents: 'auto' }}>
      <div
        className="content"
        style={{
          padding: '20px',
          maxWidth: '480px',
          opacity: 1,
          height: 'initial',
          overflow: 'auto',
          textAlign: 'left',
        }}
      >
        <h1>ข้อกำหนดและเงื่อนไขในการพิมพ์บัตรประจำตัวผู้เข้าสอบรายวิชา</h1>
        <hr />
        <p>
          ให้ผู้เข้าสอบพิมพ์บัตรประจำตัวผู้เข้าสอบรายวิชา <strong className="color-red">วิชาละ 1 ใบ </strong>
          เพื่อให้ผู้คุมสอบตรวจสอบและเก็บไว้เป็นหลักฐานตอนลงลายมือชื่อ
        </p>
        <p>
          <strong>* </strong>
          <small>
            ผู้เข้าสอบสามารถแสดงบัตรประจำตัวผู้เข้าสอบรายวิชาบนหน้าจอโทรศัพท์ให้กับผู้คุมสอบตรวจสอบก่อนเข้าสอบได้เฉพาะกรณีที่แสดงบัตรประชาชนตัวจริงในการเข้าสอบ
            <u>เท่านั้น</u>
          </small>
        </p>
        <p>
          <strong>
            ** ทั้งนี้ไม่อนุญาตให้นำโทรศัพท์เข้าห้องสอบ ผู้เข้าสอบต้องบริหารจัดการเรื่องฝากโทรศัพท์ก่อนเข้าห้องสอบเอง
          </strong>
        </p>
        <br />
        <div className="text-right">
          <small>
            <em>ปรับปรุงล่าสุดเมื่อวันที่ 22 พฤศจิกายน 2566</em>
          </small>
        </div>
        <hr />
        <div className="action text-center">
          <a className="btn-main cursor-pointer" style={{ minWidth: '40%' }} onClick={onSubmit}>
            {t('รับทราบ')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ModalExamConsent;
