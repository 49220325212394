/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { callAPI } from 'helpers';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { useParams, useNavigate } from 'react-router-dom';

import menus from 'nav/menus';
import Logo from 'nav/logo';
import { TGATTPATINFOS, ALEVELINFOS } from 'data/exam-days';

const App = ({ type }) => {
  const { hash } = useParams();
  const navigate = useNavigate();

  const lang = useSelector((state) => _.get(state, 'app.lang'));
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(lang.toLowerCase());
    document.documentElement.setAttribute('lang', lang.toLowerCase());
    Cookies.set('lang', lang, { expires: 365 });
  }, [i18n, lang]);

  const subjectInfos = type === 'tgattpat' ? TGATTPATINFOS : ALEVELINFOS;
  const subjectMap = subjectInfos.reduce((_map, { date, dayIndex, examSlots }) => {
    return _.merge(
      _map,
      _.keyBy(
        examSlots.map((slot, slotIndex) => _.merge(slot, { date, dayIndex, slotIndex })),
        'subjectKey'
      )
    );
  }, {});

  const [loading, setLoading] = useState(true);
  const [examApps, setExamApps] = useState([]);
  useEffect(() => {
    const fetchExamResult = async () => {
      try {
        setLoading(true);
        const { result } = await callAPI({
          method: 'POST',
          url: '/external/exam-result',
          body: { hash, type },
        });
        setExamApps(result);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        navigate('/profile');
      }
    };

    fetchExamResult();
  }, [hash, type, navigate]);

  useEffect(() => {
    const classNames = document.getElementById('root').className.split(' ');
    if (loading) {
      document.getElementById('root').className = _.uniq(classNames.concat(['is-loading'])).join(' ');
    } else {
      document.getElementById('root').className = classNames.filter((name) => name !== 'is-loading').join(' ');
    }
  }, [loading]);

  return (
    <div id="tcas">
      <div className="t-loading"></div>
      <header className="site-header">
        <nav className="site-nav">
          <ul>
            {menus.map(({ key, icon, title, to, href, desktop }) => (
              <li key={key} className={cn(`m-${key}`, { _desktop: desktop, active: key === 'register' })}>
                {to && (
                  <Link to={to}>
                    {icon}
                    <span>{title}</span>
                  </Link>
                )}
                {href && (
                  <a href={href}>
                    {icon}
                    <span>{title}</span>
                  </a>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </header>
      <Logo />
      <main className="site-body">
        {loading && <div className='t-loading' />}
        <div className='t-form'>
          <h1>{type === 'alevel' ? t('ประกาศผลสอบ A-Level') : t('ประกาศผลสอบ TGAT/TPAT2-5')}</h1>
          <div className='xm-info'>
            <h2>{t('รายละเอียดผลสอบ')}</h2>
            <div className='list'>
              <div className='sub'>
                <h3>
                  {t('เลขประจำตัวประชาชน')}
                  &nbsp;&nbsp;
                  <b>{_.get(_.first(examApps), 'citizen_id')}</b>
                </h3>
              </div>
              <div className='sub'>
                <h3>
                  {t('ผลสอบจำนวน')}{' '}
                  <b>
                    {(examApps || []).length} {t('วิชา')}
                  </b>
                </h3>
                <div className='_space'></div>
              </div>
              {(examApps || [])
                .sort((a, b) => {
                  return type === 'tgattpat'
                    ? a.subject_name.localeCompare(b.subject_name)
                    : subjectMap[a.subject_name].subjectCode.localeCompare(subjectMap[b.subject_name].subjectCode)
                })
                .map((app, index) => {
                  const subject = subjectMap[app.subject_name] || {};
                  const score = app.score;
                  const is_abnormal = app.is_absent || app.is_dishonest || app.is_empty_sheet || app.is_not_has_testset || app.is_not_announced;
                  const announcable = true; // app.selected_exam_type === 'cbt';
                  
                  if (['TGAT', 'TPAT2'].includes(app.subject_name)) {
                    const tgat = parseFloat(score);
                    const hasScore = Boolean(!is_abnormal || (is_abnormal && tgat));
                    return (
                      <div key={app._id} className='xm-box'>
                        <div className='num'>
                          <b>{index + 1}</b>
                        </div>
                        <ul>
                          <li>
                            {t('ชื่อวิชา')} <b>{subject.subjectKey} {subject.subjectName}</b>
                          </li>
                          <li>
                            {t('รหัสวิชา')} <b>{subject.subjectCode}</b>
                          </li>
                          <li className='score'>
                            <dl>
                              <dt>{t('คะแนนที่ได้ / คะแนนเต็ม')}</dt>
                              {!announcable ? (
                                <dd className='text-center'>
                                  <b>{t('รอประกาศผล')}</b>
                                </dd>
                              ) : hasScore ? ( 
                                <dd>
                                  <b>{score}</b> / {subject.maxValue}
                                </dd>
                              ) : (
                                <dd className='text-center'>
                                  <strong>{app.is_absent ? t('ขาดสอบ') : (app.is_dishonest || app.is_not_announced) ? t('ไม่ประกาศผล') : app.is_empty_sheet ? t('ไม่ฝนคำตอบ') : app.is_not_has_testset ? 'ไม่ฝนเลขชุด' : ''}</strong>
                                </dd>
                              )}
                            </dl>
                            {announcable && hasScore && (
                              <>
                                {subject.subSubjects.map(({ subjectCode, subjectKey, subjectName, maxValue }) => {
                                  const score = _.get(_.find(app.sub_scores, sub => sub.subject_name === subjectKey), 'score');
                                  const noScore = !parseFloat(score) && tgat;
                                  return (
                                    <dl key={subjectCode}>
                                      <dt>{subjectKey} {subjectName}</dt>
                                      {app.is_absent && noScore ? (
                                        <dd>
                                          <strong>{t('ขาดสอบ')}</strong>
                                        </dd>
                                      ) : app.is_empty_sheet && noScore ? (
                                        <dd>
                                          <strong>{t('ไม่ฝนคำตอบ')}</strong>
                                        </dd>
                                      ) : app.is_not_has_testset && noScore ? (
                                        <dd>
                                          <strong>{t('ไม่ฝนเลขชุด')}</strong>
                                        </dd>
                                      ) : app.is_not_announced && noScore ? (
                                        <dd>
                                          <strong>{t('ไม่ประกาศผล')}</strong>
                                        </dd>
                                      ) : (
                                        <dd>
                                          <b>{score}</b> / {maxValue}
                                        </dd>
                                      )}
                                    </dl>
                                  );
                                })}
                              </>
                            )}
                          </li>
                        </ul>
                      </div>
                    );
                  }
    
                  return (
                    <div key={app._id} className='xm-box'>
                      <div className='num'>
                        <b>{index + 1}</b>
                      </div>
                      <ul>
                        <li>
                          {t('ชื่อวิชา')} <b>{subject.subjectKey} {subject.subjectName}</b>
                        </li>
                        <li>
                          {t('รหัสวิชา')} <b>{subject.subjectCode}</b>
                        </li>
                        <li className='score'>
                          <dl>
                            <dt>{t('คะแนนที่ได้ / คะแนนเต็ม')}</dt>
                            {!is_abnormal ? (
                              <dd>
                                <b>{score}</b> / {subject.maxValue}
                              </dd>
                            ) : (
                              <dd className='text-center'>
                                <strong>{app.is_absent ? t('ขาดสอบ') : (app.is_dishonest || app.is_not_announced) ? t('ไม่ประกาศผล') : app.is_empty_sheet ? t('ไม่ฝนคำตอบ') : app.is_not_has_testset ? 'ไม่ฝนเลขชุด' : ''}</strong>
                              </dd>
                            )}
                          </dl>
                        </li>
                      </ul>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </main>
      {/* <!-- END BODY --> */}
    </div>
  );
};

export default App;
