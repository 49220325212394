import React from 'react';
import { useTranslation } from 'react-i18next';

const ErrorBox = ({ id, hidden, errors = [], isFull }) => {
  const { t } = useTranslation();
  return (
    <div id={id} className={`t-box -error ${(isFull ? '' : '-half', hidden ? 'hide' : '')}`}>
      <h2>{t('ผิดพลาด')}</h2>
      {errors.map((msg, index) => (
        <li key={index}>{msg}</li>
      ))}
    </div>
  );
};

export default ErrorBox;
